import { Button, Grid, Radio, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import money from "../../Assets/Icons/money-bill.svg";
import goback from "../../Assets/Icons/chevron-left.svg";
import {
  setSelectedStep,
  setSelectedTenure,
  toggleContinue,
} from "../../Redux/Slices/PlanSlice";
import { currencyFormatter } from "../../Utils/helpers";

const Tenure = () => {
  const {
    selectedPlanNew,
    selectedStep,
    selectedStateNew,
    summary,
    selectedTenure,
  } = useSelector((state) => state.planSlice);
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const handleSelectTenure = (tenure, price) =>
    dispatch(setSelectedTenure({ tenure, price }));
  const showDiscountedPrice = (tenure) => {
    // !tenure.actualPrice && !tenure.actualPriceMultiplier;
    if (!tenure?.actualPrice && !tenure?.actualPriceMultiplier) return false;
    else return true;
  };
  useEffect(() => {
    if (!summary.tenure) {
      dispatch(toggleContinue({ state: true }));
    } else dispatch(toggleContinue({ state: false }));
  }, [summary.tenure, selectedStep]);

  useEffect(() => {
    xs && window.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedStep]);

  const handleTenurePrice = (tenure) => {
    if (selectedStateNew.price) {
      if (tenure.discountedPrice) {
        return selectedStateNew.price * tenure.discountedPrice;
      } else return selectedStateNew.price * tenure.discountedPriceMultiplier;
    } else {
      if (tenure.discountedPrice) return tenure.discountedPrice;
      else if (tenure.price) return tenure.price;
    }
  };

  const handleGoBack = () => dispatch(setSelectedStep(selectedStep - 1));
  // useEffect(() => {
  //   dispatch(toggleContinue({state:false}));
  // }, [third])

  return (
    <div>
      <Title level={3} className="plan-step-heading d-flex align-center">
        <Button
          icon={<img src={goback} alt="go back" width="28px" />}
          type="text"
          className="go-prev-btn"
          onClick={handleGoBack}
        />
        {selectedPlanNew.additionalStepDescription}{" "}
      </Title>
      <div className="tenures-container">
        {selectedPlanNew?.additionalItems?.map((tenure) => (
          <div
            className={`tenure-container cursor ${
              summary.tenure === tenure.name && "selected-tenure"
            }`}
            onClick={() =>
              handleSelectTenure(tenure, handleTenurePrice(tenure))
            }
          >
            {console.log("tenure.title", tenure.name, selectedTenure.name)}
            <div className="tenure-upper">
              <div className="tenure-title-container">
                <Radio checked={summary.tenure === tenure.name} />
                <Typography className="tenure-title text-18 bold">
                  {" "}
                  {tenure.name}
                </Typography>
              </div>
              <div className="tenure-detail-container d-flex align-center">
                <Typography className="text-18 bold">
                  ₹{currencyFormatter(handleTenurePrice(tenure))}
                </Typography>
                {showDiscountedPrice() && (
                  <Typography className="font-grey-2 text-18 discounted-price">
                    ₹
                    {tenure.actualPrice
                      ? selectedStateNew?.price * tenure?.actualPrice
                      : selectedStateNew?.price * tenure?.actualPriceMultiplier}
                  </Typography>
                )}
              </div>
            </div>
            {tenure.savings && (
              <div className="tenure-extra-text-container">
                <span className="text-primary d-flex align-center">
                  <img src={money} width="20px" alt="money" />
                  {tenure.savings.price}
                </span>
                <span className="d-flex align-center font-grey-2">
                  {" "}
                  {tenure.savings.extras}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tenure;
