import React, { useEffect, useRef } from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import {
  Button,
  Col,
  Form,
  Grid,
  Input,
  Row,
  Select,
  Skeleton,
  Tag,
  Typography,
} from "antd";
import bannerRight from "../../../../Assets/Images/banner-image-new.png";
import Lottie from "lottie-web";
import phone from "../../../../Assets/Lotties/call.json";
import arrowRight from "../../../../Assets/Images/btn-rt-arrow.svg";
import {
  bannerChipTexts,
  homeBannerChipsData,
} from "../../../../Utils/staticsData";
import { fancyUlTextRenderer } from "../../../../Utils/renderer";
import fancyLine from "../../../../Assets/Icons/styledLine.svg";
import dropdown from "../../../../Assets/Icons/select-drpdwn.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Banner = () => {
  const { loading } = useSelector((state) => state.planSlice);
  const container = useRef();
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  useEffect(() => {
    Lottie.loadAnimation({
      name: `animation`,
      container: container.current,
      renderer: "svg",
      loop: true,
      // autoplay: false,
      animationData: phone,
    });

    return () => {
      Lottie.destroy();
    };
  }, []);
  return (
    <div className="banner-container">
      <div className="banner-top">
        <div className="banner-content-left">
          <Title
            level={2}
            className="banner-text "
            style={{ letterSpacing: "2px" }}
          >
            All your compliances made{" "}
            {fancyUlTextRenderer(fancyLine, "bold", "super easy", "#2F2F30")}
          </Title>

          <Paragraph className="banner-description font-300 text-14   text-center banner-description font-300 text-16 text-black  typography-reset-margin">
            Welcome to the realm of VPOB and APOB – where the magic of virtual
            office and accounting services intertwine to revolutionise your
            business journey!
          </Paragraph>
          <div className="banner-chips-container">
            {loading ? (
              <div>
                {[0, 1, 2, 3, 4, 5].map(() => (
                  // <Tag className="white-tag">
                  <Skeleton.Button
                    style={{ marginRight: "12px", width: "100px" }}
                    active
                  />
                  // </Tag>
                ))}
                <br />
                {[0, 1].map(() => (
                  // <Tag className="white-tag">
                  <Skeleton.Button
                    style={{
                      marginRight: "12px",
                      marginTop: "12px",
                      width: "100px",
                    }}
                    active
                  />
                  // </Tag>
                ))}
              </div>
            ) : (
              homeBannerChipsData.map(
                ({
                  label,
                  description,
                  faq_Config,
                  link,
                  serviceId,
                  planId,
                  name,
                }) => (
                  <div>
                    {" "}
                    <Link
                      to={link}
                      state={{
                        label,
                        faq: JSON.stringify(faq_Config),
                        description,
                        name,
                        serviceId,
                        planId,
                      }}
                    >
                      <Tag className="white-tag">
                        <Typography>{label}</Typography>
                      </Tag>
                    </Link>
                  </div>
                )
              )
            )}
            {/* {bannerChipTexts.map((text, index) => (
             
            ))} */}
          </div>
        </div>
        <div className="banner-content-right-home" />
      </div>
      {/* {!xs && (
        <Title
          level={4}
          className="banner-consultation-upper-heading-home text "
        >
          Get your <span className="text-primary">free consultation </span>by
          expert
        </Title>
      )} */}

      {/* <div className="banner-form"> */}
      <div className="desktop-form-wrapper">
        <Title level={4} className="banner-consultation-lower-heading text ">
          Get your <span className="text-primary">free consultation</span> by
          expert
        </Title>
        <Form className="banner-form">
          <Form.Item
            // label=""
            name="companyName"
            rules={[
              {
                required: true,
                message: "Please enter company name",
              },
            ]}
            className="banner-field-form-item"
          >
            <Input placeholder="Company name" size="large" />
          </Form.Item>
          <Form.Item
            // label=""
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter your name",
              },
            ]}
            className="banner-field-form-item"
          >
            <Input placeholder="Your name" size="large" />
          </Form.Item>
          <Form.Item
            // label=""
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email id",
              },
            ]}
            className="banner-field-form-item"
          >
            <Input placeholder="Email ID" size="large" />
          </Form.Item>
          <Form.Item
            // label=""
            name="service"
            rules={[]}
            // style={{ flex: 1, marginRight: "12px", width: "100%" }}
            className="banner-field-form-item"
          >
            <Select
              defaultValue="vpobAndApob"
              size="large"
              suffixIcon={<img src={dropdown} width="24px" alt="ddwn" />}
              options={[
                {
                  value: "vpobAndApob",
                  label: "VPOB & APOB",
                },
                {
                  value: "startupCompliance",
                  label: "Startup and Compliance",
                },
                {
                  value: "gstAdvisory",
                  label: "GST Advisory",
                },
                {
                  value: "fixedDesk",
                  label: "Fixed Desk",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              // offset: 8,
              span: 32,
            }}
            // style={{ width: "100%" }}
            className="banner-cta-form-item"
          >
            <Button
              type="primary"
              htmlType="submit"
              // style={{ width: "100%" }}
              className="contained-primary  banner-form-cta"
              size="small"
              icon={<img src={arrowRight} width="100%" alt="banner-img" />}
            >
              {xs && "Continue"}
            </Button>
          </Form.Item>
        </Form>
      </div>
      {/* </div> */}
    </div>
  );
};
export default Banner;
