import { Button, Modal, Typography } from "antd";
import React from "react";
import success from "../../../Assets/Images/sucess-image.svg";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { closePaymentSuccessDialog } from "../../../Redux/Slices/PlanSlice";

const SuccessDialog = () => {
  const { paymentSuccessDialog } = useSelector((state) => state.planSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOk = () => {
    dispatch(closePaymentSuccessDialog());
    navigate("/");
    navigate(0);
  };
  return (
    <Modal
      open={paymentSuccessDialog}
      footer={null}
      className="payment-modal"
      // onCancel={handleCancel}
      closable={false}
    >
      <img src={success} alt="success" />
      <Typography className="payment-modal-title">
        Payment Confirmed! 🎉
      </Typography>
      <Typography className="payment-modal-description font-grey-2 ">
        Thank you for your purchase. Our team will reach out to you within the
        next 24 hours to assist you with the next steps. If you have any
        questions in the meantime, feel free to contact us.
      </Typography>
      <Button
        type="primary"
        htmlType="submit"
        // style={{ width: "100%" }}
        className="contained-primary  payment-success-dialog-cta"
        onClick={handleOk}
      >
        Continue
      </Button>
    </Modal>
  );
};

export default SuccessDialog;
