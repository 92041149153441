import { Button, Modal, Typography } from "antd";
import React from "react";
import declined from "../../../Assets/Images/declined-image.svg";
import { closePaymentFailedDialog } from "../../../Redux/Slices/PlanSlice";
import { useDispatch, useSelector } from "react-redux";
// import "./index.scss";

const PaymentDeclined = ({ handleFormFinish }) => {
  const { paymentFailedDialog } = useSelector((state) => state.planSlice);
  const dispatch = useDispatch();
  const handleTryAgain = () => {
    dispatch(closePaymentFailedDialog());
  };
  const handleCancel = () => dispatch(closePaymentFailedDialog());
  return (
    <Modal open={paymentFailedDialog} footer={null} className="payment-modal">
      <img src={declined} alt="declined" />
      <Typography className="payment-modal-title">
        Payment Declined ❌
      </Typography>
      <Typography className="payment-modal-description font-grey-2 ">
        Unfortunately, your payment could not be processed. Please check your
        payment details and try again. If the issue persists, feel free to
        contact our support team for assistance.
      </Typography>
      <div className="payment-declined-dialog-footer">
        <Button
          type="primary"
          className="contained-primary  payment-success-dialog-cta"
          onClick={handleTryAgain}
        >
          Try again
        </Button>
      </div>
    </Modal>
  );
};

export default PaymentDeclined;
