import { Button, Form, Grid, Input, Select } from "antd";
import React, { useEffect } from "react";
import dropdown from "../../Assets/Icons/select-drpdwn.svg";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import "./index.scss";
import TextArea from "antd/es/input/TextArea";
import Body2 from "../ContactUs/Body2";
import formBg from "../../Assets/Images/new-form-bg.png";

const ContactUsBasic = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  return (
    <div className="contact-us-basic-container">
      <div className="contact-us-basic-banner">
        <div className="contact-us-basic-banner-left">
          <Title
            level={1}
            className="compliance-banner-middle-text bold typography-reset-margin"
          >
            Contact us
          </Title>

          <Paragraph className="text-center banner-description font-300 text-16 text-black  typography-reset-margin">
            Our accounting services cover a wide range of tasks, including
            bookkeeping, financial statement preparation, budgeting, payroll
            processing, and tax planning. We ensure that your financial records
            are accurate, up-to-date, and compliant with relevant accounting
            standards and regulations. By outsourcing your accounting needs to
            us, you can focus on core business activities while having peace of
            mind knowing that your finances are in good hands.
          </Paragraph>
        </div>
        <div className="contact-us-basic-banner-right">
          <div className=" banner-form-wrapper contact-us-2-form-wrapper get-started-form-wrapper">
            <img src={formBg} alt="formBg" className="internal-form-bg" />
            <div className="contact-us-form contact-us-basic-form">
              <Form>
                {xs && (
                  <Title
                    level={4}
                    className="banner-consultation-lower-heading text "
                  >
                    Get your{" "}
                    <span className="text-primary">free consultation</span> by
                    expert
                  </Title>
                )}
                <Form.Item
                  // label=""
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company name",
                    },
                  ]}
                >
                  <Input placeholder="Company name" size="large" />
                </Form.Item>
                <Form.Item
                  // label=""
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name",
                    },
                  ]}
                >
                  <Input placeholder="Your name" size="large" />
                </Form.Item>
                <Form.Item
                  // label=""
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email id",
                    },
                  ]}
                >
                  <Input placeholder="Email ID" size="large" />
                </Form.Item>
                <Form.Item
                  // label=""
                  name="service"
                  rules={[]}
                >
                  <Select
                    defaultValue="vpobAndApob"
                    size="large"
                    suffixIcon={<img src={dropdown} width="24px" alt="ddwn" />}
                    options={[
                      {
                        value: "vpobAndApob",
                        label: "VPOB & APOB",
                      },
                      {
                        value: "startupCompliance",
                        label: "Startup and Compliance",
                      },
                      {
                        value: "gstAdvisory",
                        label: "GST Advisory",
                      },
                      {
                        value: "fixedDesk",
                        label: "Fixed Desk",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  // label=""
                  name="enquiry"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your enquiry",
                    },
                  ]}
                >
                  <TextArea placeholder="Your enquiry" size="large" rows={4} />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    // offset: 8,
                    span: 32,
                  }}
                  style={{ marginBottom: "0" }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", padding: "12px, 24px, 12px, 24p" }}
                    className="contained-primary home-bottom-form-cta"
                    size="large"
                  >
                    Contact Us Now
                  </Button>
                </Form.Item>
              </Form>
            </div>
            {/* <img src={human} alt="human" className="human-image" /> */}
          </div>
        </div>
      </div>
      <Body2 />
    </div>
  );
};

export default ContactUsBasic;
