import logo1 from "../Assets/Images/amazon.svg";
import logo2 from "../Assets/Images/flipkart.svg";
import logo3 from "../Assets/Images/meesho.svg";
import logo4 from "../Assets/Images/myntra.svg";
import logo5 from "../Assets/Images/jiomart.svg";
import customer1 from "../Assets/Images/customer5.svg";
import customer2 from "../Assets/Images/customer2.svg";
import customer3 from "../Assets/Images/customer3.svg";
import customer4 from "../Assets/Images/customer4.svg";
import customer5 from "../Assets/Images/customer1.svg";
import building2 from "../Assets/Icons/building-orange.svg";
import message from "../Assets/Icons/message-square-list.svg";
import cart2 from "../Assets/Icons/cart-shopping.svg";
import handShake from "../Assets/Icons/handshake.svg";
import messageWrite from "../Assets/Icons/money-check-pen.svg";
import penWrite from "../Assets/Icons/pen-field.svg";

import cart from "../Assets/Icons/cart.svg";
import headset from "../Assets/Icons/hPhone.svg";
import shield from "../Assets/Icons/shield.svg";
import userCheck from "../Assets/Icons/userCheck.svg";
import callLotie from "../Assets/Lotties/call.json";
import shareLottie from "../Assets/Lotties/share.json";
import orderLottie from "../Assets/Lotties/orderComplete.json";
import avatar1 from "../Assets/Images/avatar1.png";
import avatar2 from "../Assets/Images/avatar2.png";
import avatar3 from "../Assets/Images/avatar3.png";
import demoAdvant from "../Assets/Images/demo-advant.svg";
import premiumAdvant from "../Assets/Images/premium-advant.svg";
import superiorAdvant from "../Assets/Images/superior-advant.svg";
import authorisedAdvant from "../Assets/Images/authorised-advant.svg";
import certiAdvant from "../Assets/Images/certi-advant.svg";
import fasterAdvant from "../Assets/Images/faster-advant.svg";
import building from "../Assets/Icons/building-white.svg";
import cartCheck from "../Assets/Icons/cart-check-white.svg";
import headphone from "../Assets/Icons/headphones-alt-2-white.svg";
import pc from "../Assets/Icons/desktop-computer-white.svg";
import gst1 from "../Assets/Images/gst1.png";
import gst2 from "../Assets/Images/gst2.png";
import gst3 from "../Assets/Images/gst3.png";
import gst4 from "../Assets/Images/gst4.png";
import gst5 from "../Assets/Images/gst5.png";
import gst6 from "../Assets/Images/gst6.png";
import assam from "../Assets/Icons/IN-AS.svg";
import haryana from "../Assets/Icons/IN-HR.svg";
import Karnataka from "../Assets/Icons/IN-KA.svg";
import Delhi from "../Assets/Icons/IN-DL.svg";
import UP from "../Assets/Icons/IN-UP.svg";
import MP from "../Assets/Icons/IN-MP.svg";
import Gujarat from "../Assets/Icons/IN-GJ.svg";
import Telangana from "../Assets/Icons/IN-TG.svg";
import Maharashtra from "../Assets/Icons/IN-MH.svg";
import Punjab from "../Assets/Icons/IN-PB.svg";
import Rajasthan from "../Assets/Icons/IN-RJ.svg";
import TN from "../Assets/Icons/IN-TN.svg";
import WB from "../Assets/Icons/IN-WB.svg";
import Bihar from "../Assets/Icons/IN-BR.svg";
import office1 from "../Assets/Images/of1.jpg";
import office2 from "../Assets/Images/of2.jpg";
import office3 from "../Assets/Images/of3.jpg";
import office4 from "../Assets/Images/of4.jpg";
import office5 from "../Assets/Images/of5.jpg";
import office6 from "../Assets/Images/of6.jpg";
import office7 from "../Assets/Images/of7.jpg";
import office8 from "../Assets/Images/of8.jpg";
import office9 from "../Assets/Images/of9.jpg";
import office10 from "../Assets/Images/of10.jpg";
import office11 from "../Assets/Images/of11.jpg";
import officeA from "../Assets/Images/office-1.png";
import officeB from "../Assets/Images/office-2.png";

import { ReactSVG } from "react-svg";

// import assamOfc from "../Assets/Images/assam.jpeg";
// import gujaratOfc from "../Assets/Images/gujarat.jpg";
// import haryanaOfc from "../Assets/Images/haryana.jpeg";
// import telanganaOfc from "../Assets/Images/telangana.jpeg";
// import wbOfc from "../Assets/Images/wb.JPG";

import cartOrange from "../Assets/Icons/cart-shopping-orange.svg";
import headphonesOrange from "../Assets/Icons/headphones-alt-2-orange.svg";
import shieldOrange from "../Assets/Icons/shield-check-orange.svg";
import userCheckOrange from "../Assets/Icons/square-user-check-orange.svg";
import { Typography } from "antd";
import { Link } from "react-router-dom";

export const navLinks = [
  {
    id: 1,
    link: "/startup-compliance",
    label: "Startup and Compliance",
    hasSubContent: true,
  },
  {
    id: 2,
    link: "/vpob-apob",
    label: "VPOB APOB",
  },
  {
    id: 3,
    link: "/gst-advisory",
    label: "GST Advisory",
  },
  {
    id: 4,
    link: "/fixed-desk",
    label: "Fixed Desk",
    noContent: true,
  },
  {
    id: 4,
    link: "/contact-us-basic",
    label: "Contact Us",
    noContent: true,
  },
  // {
  //   id: 6,
  //   link: "",
  //   label: "Blogs",
  // },
];

export const sellerLogos = [logo1, logo2, logo3, logo4, logo5];
export const customerLogos = [
  customer1,
  customer2,
  customer3,
  customer4,
  customer5,
];

export const companyStatData = [
  {
    id: 1,
    image: userCheck,
    text1: "17,000+",
    text2: "GST Queries Solved",
  },
  {
    id: 2,
    image: shield,
    text1: "96%",
    text2: "GST Approval Rate",
  },
  {
    id: 3,
    image: headset,
    text1: "Lifetime",
    text2: "VPPOB Support",
  },
  {
    id: 4,
    image: cart,
    text1: "15,000+",
    text2: "Ecommerce Sellers",
  },
];

export const itWorksData = [
  {
    id: 1,
    lottie: callLotie,
    number: "01",
    title: "Call to Discuss",
    description: "Get in Touch with our Experts.",
  },
  {
    id: 2,
    lottie: shareLottie,
    number: "02",
    title: "Share Documents",
    description: "Our Team will Complete KYC",
  },
  {
    id: 3,
    lottie: orderLottie,
    number: "03",
    title: "Order Complete",
    description: "Requirements Fulfilled",
  },
];

export const officeSpaces = [
  { image: office1, location: "Assam", offices: 2 },
  { image: office2, location: "Haryana", offices: 2 },
  { image: office3, location: "Uttar Pradesh", offices: 2 },
  { image: office4, location: "Delhi", offices: 2 },
  { image: office5, location: "Telangana", offices: 2 },
  { image: office6, location: "Madhya Pradesh", offices: 2 },
  { image: office7, location: "Punjab", offices: 2 },
  { image: officeA, location: "Rajasthan", offices: 2 },
  { image: officeB, location: "West Bengal", offices: 2 },
  { image: office8, location: "Maharashtra", offices: 2 },
  { image: office9, location: "Tamil Nadu", offices: 2 },
  { image: office10, location: "Gujarat", offices: 2 },
  { image: office11, location: "Bihar", offices: 2 },
];
export const clientSayings = [
  {
    id: 1,
    description: `I can't say enough good things about Easyworks. Their virtual office services have completely transformed the way I run my business.`,
    image: avatar1,
    name: "Albert Coalark",
    designation: "CEO of Walmart",
  },
  {
    id: 2,
    description: `Easy Works has been an invaluable partner for Mitchell USA. Their exceptional virtual office and accounting services have streamlined our processes and allowed us to focus on our core business.`,
    image: avatar2,
    name: "Prajwal Cerejo",
    designation: "MIS & Accounts Executive, Mitchell USA Pvt. Ltd.",
  },
  {
    id: 3,
    description: `As a freelancer, I needed a professional image to attract high-paying clients. Easyworks provided me with a prestigious business address and personalized phone answering services that have elevated my brand.`,
    image: avatar3,
    name: "Albert Coalark",
    designation: "CEO of Walmart",
  },
  {
    id: 4,
    description: `As a startup founder, I needed a flexible and cost-effective solution for establishing my business presence. Thanks to Easyworks, I now have a professional address and dedicated phone answering services without the overhead of a traditional office.`,
    image: avatar1,
    name: "Albert Coalark",
    designation: "CEO of Walmart",
  },
  {
    id: 5,
    description: `As a freelancer, I needed a professional image to attract high-paying clients. Easyworks provided me with a prestigious business address and personalized phone answering services that have elevated my brand.`,
    image: avatar1,
    name: "Albert Coalark",
    designation: "CEO of Walmart",
  },
  {
    id: 6,
    description: `As a freelancer, I needed a professional image to attract high-paying clients.`,
    image: avatar2,
    name: "Albert Coalark",
    designation: "CEO of Walmart",
  },
  {
    id: 7,
    description: `I can't say enough good things about Easyworks. Their virtual office services have completely transformed the way I run my business.`,
    image: avatar3,
    name: "Albert Coalark",
    designation: "CEO of Walmart",
  },
  {
    id: 8,
    description: `I've been using [Virtual Office Company] for several years now, and I couldn't be happier with their services. Their virtual office packages are affordable and customizable, allowing me to tailor my plan to fit my specific needs.`,
    image: avatar1,
    name: "Albert Coalark",
    designation: "CEO of Walmart",
  },
];
export const clientSayings2 = [];

export const pricingData = [
  {
    id: 1,
    title: "Virtual Office",
    description: "Thrill Seeker’s Delight",
    pointers: [
      "Agreement and NOC",
      "Utility Bills",
      "Registered by CA/ Lawyer",
      "Temporary Signage",
      "Lifetime Support",
      "Renewal Reminder and Support",
      "Medium Compliance Level",
    ],
    price: "799",
  },
  {
    id: 2,
    title: "Shared Desk",
    description: "Cultural Odyssey",
    pointers: [
      "Agreement and NOC",
      "Utility Bills",
      "Registered by CA/ Lawyer",
      "Standard Permanent Namboard Outside Premises",
      "Lifetime Support",
      "Renewal Reminder and Support",
      "High Compliance Level",
      "Flexible Payment Options",
      "Satisfaction Guarantee",
      "24/7 Customer Support",
    ],
    price: "2000",
  },
  {
    id: 3,
    title: "Dedicated Desk",
    description: "Thrill Seeker’s Delight",
    pointers: [
      "Agreement and NOC",
      "Utility Bills",
      "Registered by CA/ Lawyer",
      "Standard Permanent Namboard Outside Premises",
      "Lifetime Support",
      "Renewal Reminder and Support",
      "Highest Compliance Level",
      "Unique Desk Number",
      "Mail Handling Support",
      "Storage Provision for books",
      "Standard Permanent Name Board on Desk",
      "Authorised Representative",
    ],
    price: "3000",
  },
];

export const faq_Config = [
  {
    id: 1,
    heading: "What is a virtual office?",
    text: "A virtual office provides a business address and office-related services without the need for physical office space.",
  },
  {
    id: 2,
    heading: "What are the benefits of a virtual office?",
    text: "A virtual office offers a professional business address, mail handling, and meeting room access without the overhead costs of a physical office.",
  },
  {
    id: 3,
    heading:
      "Can I use the virtual office address for my business registration?",
    text: "Yes, you can use our virtual office address for your business registration and other official purposes.",
  },
  {
    id: 4,
    heading: "What is a fixed desk?",
    text: "A fixed desk is a dedicated workspace assigned to you in our office, ensuring you have a permanent place to work.",
  },
  {
    id: 5,
    heading: "How does mail handling work?",
    text: "We receive your mail and packages, and you can choose to have them forwarded to your location, scanned and emailed, or held for pickup.",
  },
  {
    id: 6,
    heading: "How can I get started with Easy Works?",
    text: "You can contact us through our website or call our customer service to discuss your needs and get a tailored package.",
  },
  {
    id: 7,
    heading: "What accounting services do you offer?",
    text: "We provide bookkeeping, GST filing, financial reporting, and payroll services to help manage your business finances.",
  },
  {
    id: 8,
    heading: "What compliance services do you offer?",
    text: "Our compliance services include business registration, legal compliance, and regulatory reporting to ensure your business adheres to all necessary regulations.",
  },
  {
    id: 9,
    heading:
      "How can your accounting and compliance services benefit my business?",
    text: "Our services ensure your financial records are accurate and up-to-date, helping you stay compliant with laws and regulations, and allowing you to focus on growing your business.",
  },
  {
    id: 10,
    heading: "What GST advisory services do you offer?",
    text: "We provide comprehensive GST services, including registration, return filing, cancellations, refund, revocations, appeal and annual returns for your business.",
  },
  {
    id: 11,
    heading: "How can GST advisory services benefit my business?",
    text: "Our services ensure timely and accurate GST compliance, reducing the risk of penalties and helping you optimise your tax liabilities.",
  },
  {
    id: 12,
    heading: "What documents are required for GST registration?",
    text: "You’ll need a PAN card, proof of business registration, identity and address proof of promoters/directors, bank account details, and a business address proof.",
  },
  {
    id: 13,
    heading: "How often do I need to file GST returns?",
    text: "GST returns are typically filed monthly, Quarterly and annually. The specific frequency depends on your business's turnover and other factors.",
  },
  {
    id: 14,
    heading: "Can you assist with GST audits?",
    text: "Yes, we provide support and representation during GST audits to ensure compliance and address any issues that may arise.",
  },
  {
    id: 15,
    heading: "What are the major states in which we provide virtual offices?",
    text: "We provide virtual office services across several major states in India, including: Haryana, Karnataka, Telangana, Maharashtra, Gujarat, West Bengal, Assam, Tamil Nadu, Punjab, Rajasthan, Bihar, Delhi, Uttar Pradesh, Madhya Pradesh.",
  },
  {
    id: 16,
    heading: "Do I receive a dedicated phone number?",
    text: "No, our virtual office services do not include a dedicated phone number.",
  },
  {
    id: 17,
    heading: "Who can use a Virtual Office?",
    text: "A virtual office is ideal for e-commerce businesses, remote teams, freelancers, startups, and any business seeking a professional address without the overhead costs of a physical office.",
  },
  {
    id: 18,
    heading: "Can a GST number be applied to a virtual office address?",
    text: "Yes, a GST number can be applied to a virtual office address.",
  },
];
// export const faq_Config2 = [

// ];
export const fixedDesk_Faq = [
  {
    id: 1,
    heading: "What does the shared desk service include?",
    text: "The shared desk service at Easy Works includes a professional workspace with a shared desk number, utility bills, mail handling support, and storage provision for books. We also provide an Agreement and NOC, ensuring all necessary documentation is in place for your business operations.",
  },
  {
    id: 2,
    heading:
      "How does Easy Works ensure compliance with local regulations for shared desks?",
    text: "We maintain high compliance levels by having all registrations done by a Chartered Accountant or Lawyer. This ensures that your business operations are aligned with local laws and regulations, providing you with legal assurance and peace of mind.",
  },
  {
    id: 3,
    heading: "What kind of support does Easy Works offer to shared desk users?",
    text: "We offer lifetime support to shared desk users, including renewal reminders and assistance with any business needs. Our team is committed to helping you with any challenges and ensuring your workspace meets your expectations.",
  },
  {
    id: 4,
    heading: "Can my business name be displayed at the shared desk location?",
    text: "Yes, Easy Works provides a standard permanent name board outside the premises, enhancing your business's visibility and professionalism. This is part of our commitment to help you establish a strong business presence.",
  },
  {
    id: 5,
    heading: "How is mail and package handling managed for shared desk users?",
    text: "Our shared desk service includes comprehensive mail handling support. We ensure that your business correspondence is received and managed efficiently, notifying you of incoming mail and offering options for forwarding or pickup as per your preference.",
  },
  {
    id: 6,
    heading:
      "Is there storage available for my business materials at the shared desk?",
    text: "Yes, we provide storage provisions for books and other business materials, ensuring you have the necessary space to keep your work organised and accessible while using the shared desk.",
  },
  {
    id: 7,
    heading:
      "How do renewal reminders and support work for shared desk services?",
    text: "We send timely reminders before your service agreement expires and offer assistance to renew your contract seamlessly. Our team ensures that you have uninterrupted access to your shared desk and related services.",
  },
  {
    id: 8,
    heading: "What does the dedicated desk service include?",
    text: "The dedicated desk service at Easy Works includes a permanent workspace with a unique desk number, utility bills, mail handling support, storage provision for books, and a standard permanent name board on the desk and outside the premises. We also provide an Agreement and NOC, ensuring all necessary documentation is in place.",
  },
  {
    id: 9,
    heading: "How does Easy Works ensure compliance with local regulations?",
    text: "We maintain the highest compliance levels by having all registrations done by a Chartered Accountant or Lawyer. This ensures that your business operations are aligned with local laws and regulations, providing peace of mind and legal assurance.",
  },
  {
    id: 10,
    heading: "What support does Easy Works offer for dedicated desk users?",
    text: "We provide lifetime support to our dedicated desk users, including renewal reminders and ongoing support for any business needs. Our team is committed to assisting you with any challenges and ensuring your workspace continues to meet your expectations.",
  },
  {
    id: 11,
    heading: "Can I display my business name on the premises?",
    text: "Yes, Easy Works provides a standard permanent name board both on your desk and outside the premises, enhancing your business's visibility and professionalism. This is part of our service to help you establish a strong business presence.",
  },
  {
    id: 12,
    heading: "How is mail and package handling managed?",
    text: "Our dedicated desk service includes comprehensive mail handling support, ensuring that your business correspondence is received and managed efficiently. We notify you of incoming mail and provide options for forwarding or pickup as per your preference.",
  },
  {
    id: 13,
    heading: "Is there storage available for my business materials?",
    text: "Yes, we offer storage provisions for books and other business materials at your dedicated desk, ensuring you have the necessary space to keep your work organised and accessible.",
  },
  {
    id: 14,
    heading: "What is the role of the authorised representative?",
    text: "An authorised representative is available to assist you with any administrative or operational tasks.",
  },
  {
    id: 15,
    heading: "How do renewal reminders and support work?",
    text: "We send out timely reminders before your service agreement expires, along with assistance to renew your contract seamlessly. Our team works with you to ensure uninterrupted access to your dedicated desk and related services.",
  },
  {
    id: 16,
    heading: "What does the virtual office service include?",
    text: "Our virtual office service includes a professional business address, Agreement and NOC, utility bills, temporary signage, and mail handling services. This package provides your business with the essentials needed to establish a credible presence without a physical office space.",
  },
  {
    id: 17,
    heading: "How does Easy Works ensure compliance for virtual offices?",
    text: "We maintain a medium compliance level for our virtual offices, with registrations handled by a Chartered Accountant or Lawyer. This ensures your business operations align with local regulations and provides you with legal assurance.",
  },
  {
    id: 18,
    heading:
      "What kind of support does Easy Works offer for virtual office users?",
    text: "Easy Works offers lifetime support to virtual office users, including renewal reminders and assistance with any business-related needs.",
  },
  {
    id: 19,
    heading:
      "Can I have my business name displayed at the virtual office location?",
    text: "Yes, we provide temporary signage for your business at the virtual office location, enhancing your business's visibility and credibility.",
  },
  {
    id: 20,
    heading: "How are utility bills managed for a virtual office?",
    text: "Our virtual office service includes handling utility bills, so you don't have to worry about managing these details. This allows you to focus on your core business activities while we take care of the administrative aspects.",
  },
  {
    id: 21,
    heading:
      "How do renewal reminders and support work for virtual office services?",
    text: "We provide timely reminders before your service agreement expires and offer support to renew your contract seamlessly. Our team ensures you have continuous access to your virtual office benefits and services.",
  },
  {
    id: 22,
    heading: "Is the Agreement and NOC necessary for a virtual office?",
    text: "Yes, the Agreement and NOC are essential documents for operating a virtual office. They establish the terms of your service and provide the necessary legal framework to support your business operations at our address.",
  },
];
export const our_services_Config = [
  {
    id: 1,
    heading: "Startup and Compliance",
    text: "Embark on your entrepreneurial journey with confidence as we guide you through the labyrinth of regulations and legal requirements. From entity formation to tax obligations, we've got your back every step of the way. Stay ahead of the curve and build a solid foundation for your startup's success.",
    icon: <ReactSVG src={building} width="32px" alt="icon" />,
  },
  {
    id: 2,
    heading: "VPOB & APOB",
    text: "Step into a realm where virtual office convenience meets accounting excellence. With VPOB (Virtual Presence On-the-Go) and APOB (Accounting Prowess on Board), we redefine the way businesses operate. Seamlessly manage your administrative tasks while staying on top of your finances, all from the comfort of your digital domain. Embrace efficiency, flexibility, and innovation – join the VPOB & APOB revolution today!",
    icon: <ReactSVG src={cartCheck} width="32px" alt="icon" />,
  },
  {
    id: 3,
    heading: "GST Advisory",
    text: "Navigating the complexities of Goods and Services Tax (GST) can be daunting for businesses. That's where our GST Advisory services come in. Our team of experts is dedicated to providing tailored guidance and strategic insights to help you navigate the ever-changing GST landscape. From compliance to optimization, we'll work closely with you to ensure your business stays ahead of regulatory requirements while maximizing opportunities for growth. With our comprehensive approach and deep industry knowledge, trust us to be your partner in GST success.",
    icon: <ReactSVG src={headphone} width="32px" alt="icon" />,
  },
  {
    id: 4,
    heading: "Fixed Desk",
    text: "Welcome to the realm where innovation meets stability – the Fixed Deck. Designed to elevate your presentations to new heights, our fixed deck solutions offer a sturdy foundation for your ideas to shine. Say goodbye to last-minute adjustments and hello to consistency and reliability. With Fixed Deck, you can focus on crafting compelling content while we take care of the rest. Step onto the platform of confidence and make your presentations unforgettable with Fixed Deck.",
    icon: <ReactSVG src={pc} width="32px" alt="icon" />,
  },
];

export const footerLinks = [
  {
    id: 1,
    name: "Quick links",
    links: [
      {
        link: "/startup-compliance",
        label: "Startup and Compliance",
      },
      {
        link: "/vpob-apob",
        label: "VPOB APOB",
      },
      {
        link: "/gst-advisory",
        label: "GST Advisory",
      },
      {
        link: "/fixed-desk",
        label: "Fixed Desk",
      },
      {
        link: "/contact-us-basic",
        label: "Contact Us",
      },
    ],
  },
  {
    id: 2,
    name: "GST Advisory",
    links: [
      {
        link: "/contact-us",
        label: "Gst Revocation",
        name: "gstRevocation",
        description: `GST revocation refers to the process of reinstating a cancelled GST registration. When a taxpayer's GST registration is cancelled due to specific reasons, such as non-compliance or irregularities, and the taxpayer subsequently rectifies the issues or fulfils the necessary requirements, they can request the authorities to revoke the cancellation and reinstate their GST registration.`,

        faq_Config: [
          {
            id: 0,
            heading: "What is GST revocation?",
            text: "GST revocation refers to the process of reinstating a cancelled GST registration after rectifying the issues or fulfilling the requirements that led to the cancellation.",
          },
          {
            id: 1,
            heading: "When can I apply for GST revocation?",
            text: "You can apply for GST revocation if your GST registration has been cancelled due to specific reasons, such as non-compliance or irregularities, and you have rectified the issues or fulfilled the necessary requirements.",
          },
          {
            id: 2,
            heading: "How can I apply for GST revocation?",
            text: "To apply for GST revocation, you need to file an application with the appropriate GST authorities, typically through the GST portal. The application should be supported by relevant documents and explanations addressing the reasons for cancellation.",
          },
          {
            id: 3,
            heading: "What documents are required for GST revocation?",
            text: `
            <div>
                <ul>
                    <li>The documents required for GST revocation may vary depending on the reasons for cancellation and any prescribed conditions or requirements.</li>
                    <li>Generally, you may need to provide supporting documents such as an application form for revocation, details of the reasons for cancellation, and any additional documents requested by the GST authorities.</li>
                </ul>
            </div>
        `,
          },
          {
            id: 4,
            heading: "How long does the GST revocation process take?",
            text: "The timeframe for GST revocation can vary depending on factors such as the complexity of the case and the workload of the GST authorities. Generally, it may take several weeks to months for the authorities to review and process your application.",
          },
          {
            id: 5,
            heading:
              "What happens if my application for GST revocation is rejected?",
            text: "If your application for GST revocation is rejected, you have the option to file an appeal with the appropriate appellate authority within the prescribed time frame.",
          },
          {
            id: 6,
            heading:
              "Can I continue business activities while my GST revocation application is pending?",
            text: "If your GST registration has been cancelled, you may not be authorized to conduct business activities under the GST regime until your registration is reinstated. It's important to comply with any post-cancellation requirements and wait for the outcome of your revocation application before resuming business activities.",
          },
          {
            id: 7,
            heading:
              "Why and under what circumstances does the GST department cancel registration?",
            text: `
            <div>
                <ul>
                    <li>If a taxpayer fails to file the GST return for the last 6 months.</li>
                    <li>If a taxpayer fails to pay the due taxes.</li>
                    <li>Doesn’t conduct any business from the Principal place of business.</li>
                </ul>
            </div>
        `,
          },
          {
            id: 8,
            heading:
              "What is the Process if my GST is cancelled by the GST Department?",
            text: "The department initiates the process by issuing a show-cause notice, followed by a response from the taxpayer and adjudication. If cancellation is warranted, an order is issued.",
          },
          {
            id: 9,
            heading: "Can I appeal against the cancellation order?",
            text: "Yes, taxpayers have the option to file an appeal with the appropriate appellate authority within the prescribed time frame.",
          },
          {
            id: 10,
            heading: "Can I register again for the GST after cancellation?",
            text: "Yes, there is no restriction for the same. You can register again for GST after cancellation.",
          },
        ],
      },
      {
        link: "/contact-us",
        label: "GST Refund",
        name: "gstRefund",
        description:
          "It refers to the process by which a registered taxpayer can claim a refund of excess taxes paid under the Goods and services tax regime.",

        faq_Config: [
          {
            id: 0,
            heading: "What is GST refund?",
            text: "GST refund refers to the process by which a registered taxpayer can claim a refund of excess taxes paid under the Goods and Services Tax (GST) regime.",
          },
          {
            id: 1,
            heading: "When can I claim GST refund?",
            text: "You can claim GST refund under various circumstances, such as export of goods or services, inverted duty structure, excess payment of tax, or refund of accumulated input tax credit (ITC).",
          },
          {
            id: 2,
            heading: "How do I claim GST refund?",
            text: "To claim the GST refund, you need to file an application through the GST portal, providing details of the refund claim and supporting documents, such as invoices, export documents, and proof of payment.",
          },
          {
            id: 3,
            heading: "What documents are required for GST refund?",
            text: `
            <div>
                <ul>
                    <li>The documents required for GST refund may vary depending on the nature of the refund claim.</li>
                    <li>You may need to submit invoices, shipping bills, export documents, and any other relevant supporting documents.</li>
                </ul>
            </div>
        `,
          },
          {
            id: 4,
            heading: "How long does it take to process the GST refund?",
            text: "The timeframe for processing GST refund claims depends on various factors, such as the complexity of the case, verification requirements, and workload of the GST authorities. Generally, refunds should be processed within 60 days from the date of receipt of the refund application.",
          },
          {
            id: 5,
            heading: "What happens if my GST refund claim is rejected?",
            text: "If your GST refund claim is rejected by the GST authorities, you have the option to file an appeal with the appropriate appellate authority within the prescribed time frame.",
          },
          {
            id: 6,
            heading: "Can I track the status of my GST refund application?",
            text: "Yes, you can track the status of your GST refund application online through the GST portal using your GSTIN and other relevant details.",
          },
          {
            id: 7,
            heading:
              "Do I need professional help to apply or claim for the GST refund?",
            text: `
            <div>
            <p>Seeking Professional help can be advantageous as:</p>
                <ul>
                    <li>Professionals, such as chartered accountants or GST consultants, possess in-depth knowledge of GST laws and regulations. They can navigate complex transactions, ensuring accurate identification of eligible refund amounts and compliance with regulations.</li>
                    <li>They can help optimize the refund claim by identifying all eligible input tax credit (ITC) and other refundable amounts within the legal framework, potentially increasing your refund amount.</li>
                    <li>They ensure compliance with GST laws and procedures, reducing the risk of errors or rejections in refund claims. They can also assist in maintaining proper records and documentation to support your claim, minimizing delays or rejections.</li>
                    <li>In case of rejected refund claims or disputes with tax authorities, professionals can represent you in appeals and resolution proceedings, advocating for your interests and rights effectively.</li>
                </ul>
            </div>
        `,
          },
        ],
      },
      {
        link: "/contact-us",
        label: "GST Appeal",
        name: "gstAppeal",
        description: `At Easy Works, we provide expert GST appeal services to help taxpayers challenge decisions made by tax authorities. Whether it's disputes over tax assessments, refunds, or penalties, our experienced team is here to guide you through each stage of the appeal process, ensuring your case is handled with the utmost care and precision.`,

        faq_Config: [
          {
            id: 0,
            heading: "What is a GST appeal?",
            text: "A GST appeal is a legal process where a taxpayer challenges a decision made by tax authorities. This could involve disputes over tax assessments, refunds, or penalties.",
          },
          {
            id: 1,
            heading: "When can I file a GST appeal?",
            text: "You can file a GST appeal if you are aggrieved by any decision or order passed by a GST officer. The appeal must be filed within three months from the date of communication of the order.",
          },
          {
            id: 2,
            heading: "What is the process for filing a GST appeal?",
            text: "The process involves assessing the case, preparing necessary documentation, filing the appeal with the appropriate GST authority, and representing your case during the appeal proceedings.",
          },
          {
            id: 3,
            heading: "What documents are required for filing a GST appeal?",
            text: `
            <div>
                <ul>
                    <li>The original order against which the appeal is being filed</li>
                    <li>Grounds of appeal</li>
                    <li>Relevant evidence</li>
                    <li>Any other supporting documents</li>
                </ul>
            </div>
        `,
          },
          {
            id: 4,
            heading: "What are the fees for filing a GST appeal?",
            text: "The fee for filing a GST appeal is 10% of the disputed tax amount for the first appellate authority and an additional 20% for the Appellate Tribunal.",
          },
          {
            id: 5,
            heading: "How long does it take to resolve a GST appeal?",
            text: "The resolution time varies depending on the complexity of the case and the efficiency of the GST appellate authority. It can take several months to over a year.",
          },
          {
            id: 6,
            heading: "What happens if my GST appeal is rejected?",
            text: "If your appeal is rejected, you can escalate the matter to a higher appellate authority, such as the Appellate Tribunal or the High Court.",
          },
          {
            id: 7,
            heading: "Can penalties be waived or reduced through a GST appeal?",
            text: "Yes, penalties can be waived or reduced if the appellate authority finds merit in your appeal and rules in your favor.",
          },
          {
            id: 8,
            heading: "How can Easy Works help with my GST appeal?",
            text: "Easy Works provides end-to-end GST appeal services, including case assessment, documentation, filing, representation, and follow-up. Our expert team ensures that your appeal is handled efficiently and effectively.",
          },
          {
            id: 9,
            heading: "What are the common reasons for filing a GST appeal?",
            text: `
            <div>
                <ul>
                    <li>Disputes over tax assessments</li>
                    <li>Imposition of penalties</li>
                    <li>Denial of input tax credits</li>
                    <li>Incorrect classification of goods or services</li>
                </ul>
            </div>
        `,
          },
        ],
      },
      {
        link: "/contact-us",
        label: "Show Cause Notice",
        name: "showCauseNotice",
        description: `At Easy Works, we provide comprehensive services to help businesses effectively manage and respond to Show Cause Notices (SCNs) issued by tax authorities. An SCN is a legal document issued by the GST authorities demanding an explanation from the taxpayer for a specific issue, such as non-compliance, discrepancies, or suspected fraud. It is crucial to address these notices promptly and accurately to avoid penalties and legal consequences.`,

        faq_Config: [
          {
            id: 0,
            heading: "What is a Show Cause Notice (SCN)?",
            text: "An SCN is a legal document issued by GST authorities demanding an explanation from the taxpayer for non-compliance, discrepancies, or suspected fraud.",
          },
          {
            id: 1,
            heading: "Why is it important to respond to an SCN?",
            text: "Responding to an SCN is crucial to avoid penalties, interest charges, and legal actions. It also helps in maintaining compliance with GST regulations and protecting your business interests.",
          },
          {
            id: 2,
            heading: "What should I do if I receive an SCN?",
            text: "If you receive an SCN, you should immediately review the notice, understand the issues raised, gather relevant documents and evidence, and prepare a comprehensive response. It is advisable to seek professional help to ensure accuracy and completeness.",
          },
          {
            id: 3,
            heading: "What documents are required to respond to an SCN?",
            text: `
            <div>
                <ul>
                    <li>Transaction records</li>
                    <li>Tax invoices</li>
                    <li>Bank statements</li>
                    <li>Purchase and sales records</li>
                    <li>Any other relevant evidence to support your response</li>
                </ul>
            </div>
        `,
          },
          {
            id: 4,
            heading: "What are the common reasons for receiving an SCN?",
            text: `
            <div>
                <ul>
                    <li>Discrepancies in tax returns</li>
                    <li>Non-payment or short payment of GST</li>
                    <li>Incorrect availing of input tax credit</li>
                    <li>Suspected tax evasion</li>
                </ul>
            </div>
        `,
          },
          {
            id: 5,
            heading: "How long do I have to respond to an SCN?",
            text: "The response time is specified in the SCN, usually ranging from 15 to 30 days from the date of receipt. It is essential to adhere to the deadline to avoid penalties.",
          },
          {
            id: 6,
            heading: "What happens if I do not respond to an SCN?",
            text: "Failure to respond can result in penalties, interest charges, and legal actions, including assessment orders and demand notices for the tax amount due.",
          },
          {
            id: 7,
            heading: "Can penalties be waived or reduced?",
            text: "Penalties can be waived or reduced if the taxpayer provides a satisfactory explanation and evidence supporting their case. The decision lies with the tax authorities.",
          },
          {
            id: 8,
            heading: "How can Easy Works help with SCNs?",
            text: "Easy Works provides end-to-end services for handling SCNs, including consultation, documentation, response drafting, filing, and representation before tax authorities. Our expert team ensures timely and accurate handling of your SCN to mitigate risks and penalties.",
          },
          {
            id: 9,
            heading: "Can I appeal against the decision of an SCN?",
            text: "Yes, if you are not satisfied with the decision of the tax authorities after responding to an SCN, you can file an appeal with the appropriate appellate authority.",
          },
        ],
      },
      {
        link: "/contact-us",
        label: "Summons Or Departmental Cases",
        name: "summonsCases",
        description:
          "Our accounting services cover a wide range of tasks, including bookkeeping, financial statement preparation, budgeting, payroll processing, and tax planning. We ensure that your financial records are accurate, up-to-date, and compliant with relevant accounting standards and regulations. By outsourcing your accounting needs to us, you can focus on core business activities while having peace of mind knowing that your finances are in good hands.",

        faq_Config: [
          {
            id: 1,
            heading: "What is a company?",
            text: `A company is a legal entity formed by individuals known as shareholders or members to conduct business activities. It operates as a separate entity from its owners, offering limited liability protection to shareholders. Companies have perpetual existence, centralized management by a board of directors, and ownership represented by shares. Various types of companies exist, including private limited, public limited, one-person company (OPC), and limited liability partnership (LLP).`,
          },
          {
            id: 2,
            heading: "What are the Types of companies?",
            text: `
            <div>
              <p>
                The various types of companies:
              </p>
            <ul>
            <li>Private Limited Company </li>
            <li>Public Limited Company </li>
            <li>Limited Liability Company</li>
            <li>One Person company</li>
            </ul>
            </div>`,
          },
          {
            id: 3,
            heading: "What are the steps to register a company?",
            text: `
            <div>
              <p>
             The steps to register a company:
              </p>
            <ul>
            <li>Choose a Business Structure </li>
            <li>Decide on a Company Name and Check Availability </li>
            <li>Obtain Digital Signature Certificate (DSC)</li>
            <li>Obtain Director Identification Number (DIN)</li>
            <li>Draft Memorandum of Association (MOA) and Articles of Association (AOA)</li>
            <li>Pay Registration Fees and Stamp Duty</li>
            <li>Verification and Approval by ROC</li>
            <li>Receive Certificate of Incorporation</li>
            <li>Obtain PAN and TAN for the Company</li>
            <li>Open a Bank Account</li>
            <li>Start Business Operations</li>
            </ul>
            </div>`,
          },
          {
            id: 4,
            heading: "What is MOA and AOA?",
            text: `
            <div>
              <p>
                  <strong> MOA</strong> stands for Memorandum of Association, while AOA stands for Articles of Association. These are legal documents that define the structure, objectives, and internal rules of a company.
              </p>
              <p>
              MOA outlines the company's main objectives, scope of operations, and its relationship with shareholders and stakeholders. 
              </p>
                <p>AOA, on the other hand, lays down the rules and regulations for the internal management and conduct of the company, including the roles and responsibilities of directors, shareholders, and officers.</p>
                <p>Both MOA and AOA are essential documents required during the company registration process, and they provide a framework for the functioning and governance of the company.
                 </p>
         
            </div>`,
          },
          {
            id: 5,
            heading: "What is DSC?",
            text: `DSC stands for Digital Signature Certificate. It is a secure digital key issued by certifying authorities to verify the authenticity and integrity of electronic documents and transactions. DSCs are used to electronically sign documents and forms during various online transactions, including company registration, income tax filing, and e-filing of documents with government authorities.`,
          },
          {
            id: 6,
            heading: "What is DIN?",
            text: `DIN stands for Director Identification Number. It is a unique identification number assigned to individuals who wish to be appointed as directors of companies registered in India. DIN serves as a means to track and regulate the activities of directors and helps maintain a centralized database of directors' information. It is mandatory for individuals to obtain DIN before being appointed as directors of Indian companies`,
          },
          {
            id: 7,
            heading: "What is DPIN?",
            text: `DPIN is a unique identification number assigned to individuals who wish to become designated partners in a Limited Liability Partnership (LLP) registered in India. It serves a similar purpose to Director Identification Number (DIN) for directors of companies, helping track and regulate the activities of designated partners in LLPs. Obtaining a DPIN is mandatory for individuals before they can be appointed as designated partners of an LLP.`,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "VPOB & APOB by States",
    links: [
      {
        label: "Telangana",
        link: "/contact-us",
        name: "telangana",
        description:
          "Telangana, with Hyderabad as its bustling business hub, is a prime location for setting up virtual offices. The high demand for office space in Hyderabad drives up costs, making a virtual office a more viable option. Easy Works offers comprehensive virtual office solutions, including seamless GST registrations, professional mail handling, compliance services, and call handling to help your business thrive in Telangana. Explore the benefits of a virtual office in Telangana with us!",

        faq_Config: [
          {
            id: 1,
            heading:
              "What is a virtual office, and how does it work in Telangana?",
            text: "A virtual office provides a business address and communication services without requiring a physical office space.",
          },
          {
            id: 2,
            heading:
              "What are the benefits of having a virtual office in Telangana?",
            text: "Benefits include a prestigious business address in Telangana, flexibility, and cost savings on physical office space.",
          },
          {
            id: 3,
            heading:
              "Do I need a physical office to use a virtual office in Telangana?",
            text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
          },
          {
            id: 4,
            heading:
              "What are the compliance requirements for a virtual office in Telangana?",
            text: "Register your business address with relevant authorities and follow local regulations.",
          },
          {
            id: 5,
            heading:
              "Can I use the virtual office address for company registration in Telangana?",
            text: "Yes, our address can be used for company registration in Telangana.",
          },
          {
            id: 6,
            heading:
              "Are there any specific licences or permits required for a virtual office in Telangana?",
            text: "Generally, no extra licences are needed beyond regular business requirements.",
          },
          {
            id: 7,
            heading:
              "How does the GST advisory service work with a virtual office in Telangana?",
            text: "We provide GST compliance support, filing assistance, and tax advice.",
          },
          {
            id: 8,
            heading:
              "Is a registered rent agreement required for GST registration in Telangana?",
            text: "No, a registered rent agreement is not mandatory for GST registration in Telangana.",
          },
        ],
      },
      {
        label: "Delhi",
        link: "/contact-us",
        name: "delhi",
        description: `Delhi stands as a pivotal location where startups and small businesses strive to establish their virtual offices. The cost of renting a physical office in Delhi is notably high, prompting many brands to opt for a virtual address in the nation's capital. With seamless GST registrations, professional mail handling, compliance services, and professional call handling, securing a virtual office in Delhi becomes the ideal solution for establishing a presence. Let Easy Works help you unlock all the advantages of having a virtual office in Delhi!`,

        faq_Config: [
          {
            id: 1,
            heading: "What is a virtual office, and how does it work in Delhi?",
            text: "A virtual office provides a business address and communication services without requiring a physical office space.",
          },
          {
            id: 2,
            heading:
              "What are the benefits of having a virtual office in Delhi?",
            text: "Benefits include a prestigious business address in Delhi, flexibility in working arrangements, cost savings on physical office space.",
          },
          {
            id: 3,
            heading:
              "Do I need a physical office to use a virtual office in Delhi?",
            text: "No, you do not need a physical office. A virtual office provides you with all the benefits of a physical office without the need for a permanent space.",
          },
          {
            id: 4,
            heading:
              "What are the compliance requirements for a virtual office in Delhi?",
            text: "Compliance requirements include registering your business address with the relevant authorities, maintaining proper records, and adhering to local business regulations.",
          },
          {
            id: 5,
            heading:
              "Can I use the virtual office address for company registration in Delhi?",
            text: "Yes, you can use our virtual office address for company registration in Delhi. We provide a legitimate address that meets the requirements for business registration.",
          },
          {
            id: 6,
            heading:
              "Are there any specific licences or permits required for a virtual office in Delhi?",
            text: "No additional licences or permits are needed beyond what is required for your business. However, depending on your business activities, you might need specific permits or registrations.",
          },
          {
            id: 7,
            heading:
              "How does the GST advisory service work with a virtual office in Delhi?",
            text: "Our GST advisory service helps you navigate GST regulations, file returns, and ensure compliance with tax laws.",
          },
          {
            id: 8,
            heading:
              "Is a registered rent agreement required for GST registration in Delhi?",
            text: "No, a registered rent agreement is not mandatory for GST registration in Delhi.",
          },
        ],
      },
      {
        link: "/contact-us",
        name: "haryana",
        description:
          "Haryana, with its burgeoning industrial and commercial growth, offers a strategic location for virtual offices. The high cost of physical office spaces in key cities like Gurgaon makes a virtual address a smart choice for businesses. Easy Works provides seamless GST registrations, professional mail handling, compliance services, and call handling, ensuring your virtual office in Haryana is as efficient as a physical one. Let us help you gain a competitive edge with a virtual office in Haryana!",

        faq_Config: [
          {
            id: 1,
            heading:
              "What is a virtual office, and how does it work in Haryana?",
            text: "A virtual office provides a business address and communication services without requiring a physical office space.",
          },
          {
            id: 2,
            heading:
              "What are the benefits of having a virtual office in Haryana?",
            text: "Benefits include a prestigious business address in Haryana, flexibility, and cost savings on physical office space.",
          },
          {
            id: 3,
            heading:
              "Do I need a physical office to use a virtual office in Haryana?",
            text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
          },
          {
            id: 4,
            heading:
              "What are the compliance requirements for a virtual office in Haryana?",
            text: "Register your business address with relevant authorities and follow local regulations.",
          },
          {
            id: 5,
            heading:
              "Can I use the virtual office address for company registration in Haryana?",
            text: "Yes, our address can be used for company registration in Haryana. We provide a legitimate address that meets the requirements for business registration.",
          },
          {
            id: 6,
            heading:
              "Are there any specific licences or permits required for a virtual office in Haryana?",
            text: "Generally, no extra licences are needed beyond regular business requirements.",
          },
          {
            id: 7,
            heading:
              "How does the GST advisory service work with a virtual office in Haryana?",
            text: "We provide GST compliance support, filing assistance, and tax advice.",
          },
          {
            id: 8,
            heading:
              "Is a registered rent agreement required for GST registration in Haryana?",
            text: "No, a registered rent agreement is not mandatory for GST registration in Haryana.",
          },
        ],
        label: "Haryana",
      },
      {
        label: "Karnataka",
        link: "/contact-us",
        name: "karnataka",
        description:
          "Karnataka, with Bangalore as its tech hub, is a strategic location for virtual offices. The high cost of office spaces in Bangalore makes a virtual office a smart choice for businesses looking to expand. Easy Works provides seamless GST registrations, professional mail handling, compliance services, and call handling to ensure your virtual office in Karnataka operates smoothly. Establish your business presence in Karnataka with our virtual office solutions!",

        faq_Config: [
          {
            id: 9,
            heading:
              "What is a virtual office, and how does it work in Karnataka?",
            text: "A virtual office provides a business address and communication services without requiring a physical office space.",
          },
          {
            id: 10,
            heading:
              "What are the benefits of having a virtual office in Karnataka?",
            text: "Benefits include a prestigious business address in Karnataka, flexibility, and cost savings on physical office space.",
          },
          {
            id: 11,
            heading:
              "Do I need a physical office to use a virtual office in Karnataka?",
            text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
          },
          {
            id: 12,
            heading:
              "What are the compliance requirements for a virtual office in Karnataka?",
            text: "Register your business address with relevant authorities and follow local regulations.",
          },
          {
            id: 13,
            heading:
              "Can I use the virtual office address for company registration in Karnataka?",
            text: "Yes, our address can be used for company registration in Karnataka.",
          },
          {
            id: 14,
            heading:
              "Are there any specific licences or permits required for a virtual office in Karnataka?",
            text: "Generally, no extra licences are needed beyond regular business requirements.",
          },
          {
            id: 15,
            heading:
              "How does the GST advisory service work with a virtual office in Karnataka?",
            text: "We provide GST compliance support, filing assistance, and tax advice.",
          },
          {
            id: 16,
            heading:
              "Is a registered rent agreement required for GST registration in Karnataka?",
            text: "No, a registered rent agreement is not mandatory for GST registration in Karnataka.",
          },
        ],
      },
      {
        label: "Assam",
        link: "/contact-us",
        name: "assam",
        description:
          "Assam, with its rapidly growing economy, offers numerous opportunities for virtual offices. The cost of maintaining a physical office can be high, but a virtual office with Easy Works provides a cost-effective alternative. Our services include seamless GST registrations, professional mail handling, compliance services, and call handling, ensuring your virtual office in Assam operates efficiently. Let us help you establish a strong business presence in Assam with ease!",

        faq_Config: [
          {
            id: 1,
            heading: "What is a virtual office, and how does it work in Assam?",
            text: "A virtual office provides a business address and communication services without requiring a physical office space.",
          },
          {
            id: 2,
            heading:
              "What are the benefits of having a virtual office in Assam?",
            text: "Benefits include a prestigious business address in Assam, flexibility, and cost savings on physical office space.",
          },
          {
            id: 3,
            heading:
              "Do I need a physical office to use a virtual office in Assam?",
            text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
          },
          {
            id: 4,
            heading:
              "What are the compliance requirements for a virtual office in Assam?",
            text: "Register your business address with relevant authorities and follow local regulations.",
          },
          {
            id: 5,
            heading:
              "Can I use the virtual office address for company registration in Assam?",
            text: "Yes, our address can be used for company registration in Assam.",
          },
          {
            id: 6,
            heading:
              "Are there any specific licences or permits required for a virtual office in Assam?",
            text: "Generally, no extra licences are needed beyond regular business requirements.",
          },
          {
            id: 7,
            heading:
              "How does the GST advisory service work with a virtual office in Assam?",
            text: "We provide GST compliance support, filing assistance, and tax advice.",
          },
          {
            id: 8,
            heading:
              "Is a registered rent agreement required for GST registration in Assam?",
            text: "Yes, a registered rent agreement is mandatory for GST registration in Assam.",
          },
        ],
      },
    ],
  },
];

export const complianceServices = [
  {
    id: 1,
    icon: userCheck,
    text: "Startup and Compliance",
    title: "startup",
  },
  {
    id: 2,
    icon: cart,
    text: "VPOB APOB",
    title: "vpob",
  },
  {
    id: 3,
    icon: headphone,
    text: "GST Advisory",
    title: "gst",
  },
  {
    id: 4,
    icon: shield,
    text: "Fixed Desk",
    title: "fixedDesk",
  },
];
export const startupServices = [
  {
    title: "Business registration",
    icon: building2,
    serviceId: 2,
    links: [
      {
        planId: 10,
        label: "Proprietorship",
        link: "/contact-us",
        name: "proprietorship",
        description:
          "A proprietorship, also known as a sole proprietorship, is the simplest form of business entity where a single individual owns and operates the business. In a proprietorship, there is no legal distinction between the owner and the business entity itself. The owner is personally liable for all the debts and obligations of the business.",
        faq_Config: [
          {
            id: 1,
            heading: "What is a proprietorship?",
            text: `A proprietorship is a type of business structure where a single individual owns, manages, and controls the business. The owner is personally liable for all debts and obligations of the business.`,
          },
          {
            id: 2,
            heading: "Is there any Certificate of Incorporation required?",
            text: `No, there is no Certificate of Incorporation required for a proprietorship.`,
          },
          {
            id: 3,
            heading:
              "Am I personally liable for debts and obligations of the business?",
            text: `Yes, as the sole proprietor, you are personally liable for all debts and obligations of the business.`,
          },
          {
            id: 4,
            heading: "Can I hire employees in a proprietorship?",
            text: `Yes, a proprietorship can hire employees. However, the owner remains personally responsible for employment-related matters such as payroll taxes, benefits, and compliance with labour laws.`,
          },
          {
            id: 5,
            heading:
              "Can I convert my proprietorship into another business structure?",
            text: `Yes, it is possible to convert a proprietorship into another business structure such as a partnership or corporation.`,
          },
          {
            id: 6,
            heading: "Can I operate a proprietorship from my house?",
            text: `Yes, you can operate a proprietorship business from your house.`,
          },
          {
            id: 7,
            heading: "What are the compliances for a proprietorship?",
            text: ` <div>
              <p>
                 A proprietorship must file:

              </p>
             <ul>
              <li>GST Return</li>
              <li>Annual Return (Income Tax Return - ITR)</li>
             </div>`,
          },
          {
            id: 8,
            heading:
              "Are there any specific licences required for a proprietorship?",
            text: `Depending on the nature of the business and state regulations, a proprietorship may need to obtain licences such as GST registration, professional tax registration, shops and establishment license, and trade license.`,
          },
          {
            id: 9,
            heading:
              "What are the penalties for non-compliance with tax filing?",
            text: `Failure to file income tax returns, GST returns, or other required filings can result in penalties, interest on unpaid taxes, and legal consequences. For example, late filing of income tax returns can attract a penalty under Section 234F of the Income Tax Act.`,
          },
          {
            id: 10,
            heading: "How can Easy Works help with compliance?",
            text: `Easy Works offers end-to-end compliance services, including income tax return filing, GST registration and return filing, professional tax registration, and obtaining necessary licences. Our expert team ensures timely and accurate compliance, helping you avoid penalties and focus on your business growth.
For more detailed information and assistance with establishing and managing your proprietorship, contact Easy Works today. Our expert team is here to help you navigate the complexities of business compliance and ensure your success.
`,
          },
        ],
      },
      {
        planId: 11,
        label: "Partnership",
        link: "/contact-us",
        name: "partnership",
        description: `A Partnership is a type of business structure where two or more individuals join together to carry on business with the aim of making a profit. Partnerships are governed by the Indian Partnership Act, 1932. Partnerships are formed through a partnership deed, which is a legal document outlining the terms and conditions of the partnership. The deed typically includes details such as the name of the partnership, the names and addresses of the partners, their capital contributions, profit-sharing ratios, decision-making processes, etc. It is advisable to register the partnership deed with the Registrar of Firms to establish legal proof of the partnership's existence.`,
        faq_Config: [
          {
            id: 1,
            heading: "What is a partnership firm?",
            text: `A partnership firm is a business structure where two or more individuals agree to operate and manage a business together with a shared goal of making a profit. Partnerships are governed by the Indian Partnership Act, 1932.`,
          },
          {
            id: 2,
            heading: "How do I form a partnership firm?",
            text: `Forming a partnership firm involves drafting and executing a partnership deed that outlines the rights, duties, and obligations of each partner, profit-sharing ratio, capital contributions, and other relevant terms. The partnership deed is then registered with the Registrar of Firms in the state where the firm is located.
`,
          },
          {
            id: 3,
            heading: "What is a Partnership Deed?",
            text: `A Partnership Deed is an agreement between the partners that highlights the rules of the partnership among the partners.
`,
          },
          {
            id: 4,
            heading:
              "What documents are required to register a Partnership firm?",
            text: `<div>
            <p>The documents required to register a partnership firm include</p>
            <ul>
            <li>PAN card</li>
            <li>Address proof</li>
            <li>Partnership deed (registered and signed by all the partners)</li>
            </ul>
            </div>
            `,
          },
          {
            id: 5,
            heading:
              "5. How are profits and losses distributed in a partnership firm?",
            text: `Profits and losses are typically distributed among partners based on the terms outlined in the partnership deed. The profit-sharing ratio may be based on factors such as capital contributions, effort, expertise, or any other criteria agreed upon by the partners.`,
          },
          {
            id: 6,
            heading: "Can a partnership firm be dissolved?",
            text: `Yes, a partnership firm can be dissolved voluntarily by mutual agreement among the partners or compulsorily by a court order due to various reasons such as insolvency, misconduct, or incapacity of a partner.`,
          },
          {
            id: 7,
            heading:
              "Can a partnership firm be converted into another business structure?",
            text: `Yes, a partnership firm can be converted into another business structure such as a private limited company or LLP, subject to compliance with legal requirements and procedures outlined in relevant laws and regulations.`,
          },
          {
            id: 8,
            heading: "What are the compliances for Partnership?",
            text: `<div>
            <p>A partnership firm must comply with:</p>
            <ul>
            <li>Filing of GST returns</li>
            <li>Filing of Income Tax returns</li>
            <li>Annual compliance as per the Partnership Act</li>
            <li>Compliance with local laws and regulations (e.g., Shops and Establishment Act, Professional Tax, etc.)</li>
            </ul>
            </div>
            `,
          },
          {
            id: 9,
            heading:
              "Are there any specific licences required for a partnership?",
            text: `Depending on the nature of the business and state regulations, a partnership may need to obtain licences such as GST registration, professional tax registration, shops and establishment license, and trade license.`,
          },
          {
            id: 10,
            heading:
              "What are the penalties for non-compliance with tax filing?",
            text: `Failure to file income tax returns, GST returns, or other required filings can result in penalties, interest on unpaid taxes, and legal consequences. For example, late filing of income tax returns can attract a penalty under Section 234F of the Income Tax Act.`,
          },
          {
            id: 11,
            heading: "How can Easy Works help with compliance?",
            text: `Easy Works offers end-to-end compliance services, including income tax return filing, GST registration and return filing, professional tax registration, and obtaining necessary licences. Our expert team ensures timely and accurate compliance, helping you avoid penalties and focus on your business growth.

For more detailed information and assistance with establishing and managing your partnership, contact Easy Works today. Our expert team is here to help you navigate the complexities of business compliance and ensure your success.
`,
          },
        ],
      },

      {
        planId: 12,
        label: "One Person Company",
        link: "/contact-us",
        name: "onePerson",
        description:
          "A One Person Company (OPC) is a type of company that allows a single individual to operate a business, combining the benefits of a sole proprietorship and a private limited company. It limits the liability of the owner to the extent of their shareholding in the company. OPCs offer a suitable business structure for solo entrepreneurs who want limited liability protection while maintaining full control over their business operations.",
        faq_Config: [
          {
            id: 1,
            heading: " What is a One Person Company (OPC)?",
            text: `A One Person Company (OPC) is a type of corporate entity introduced in India to enable solo entrepreneurs to operate and manage their businesses with limited liability. It allows a single individual to establish a company and enjoy the benefits of a corporate structure.`,
          },
          {
            id: 2,
            heading: "Who can form a One Person Company?",
            text: `<div>
            <p>Any individual who is an Indian citizen and resident in India can form a One Person Company. However, certain individuals, such as minors, non-residents, and persons incapacitated by mental illness, are not eligible to incorporate an OPC.</p>
            <ul>
            <li>Section: As per Section 2(62) of the Companies Act, 2013.</li>
            </ul>
            </div>
            `,
          },
          {
            id: 3,
            heading: "What is the minimum capital requirement for an OPC?",
            text: `<div>
            <p>There is no minimum capital requirement for incorporating a One Person Company. The OPC can be registered with any amount of capital as decided by the sole owner.</p>
            <ul>
            <li>Section: Companies (Incorporation) Rules, 2014.</li>
            </ul>
            </div>`,
          },
          {
            id: 4,
            heading: "What is the process for incorporating an OPC?",
            text: `<div>
            <p> The process for incorporating an OPC involves:</p>
           <ul>
            <li>Obtaining Digital Signature Certificates (DSC) for the sole owner and directors.</li>
            <li>Obtaining Director Identification Numbers (DIN).</li>
            <li>Filing the application for incorporation with the Ministry of Corporate Affairs (MCA).</li>
            <li>Obtaining the Certificate of Incorporation (COI) and other necessary documents.</li>
            <li>Section: As per Section 3 and 7 of the Companies Act, 2013.</li>
            </ul>
            </div>`,
          },
          {
            id: 5,
            heading:
              " Can an OPC be converted into another business structure?",
            text: `<div>
            <p> Yes, an OPC can be converted into a private limited company or a public limited company by following the prescribed procedures and fulfilling certain conditions specified under the Companies Act, 2013.</p>
           <ul>
            <li>Section: As per Section 18 of the Companies Act, 2013.</li>
          </ul>
            </div>`,
          },
          {
            id: 6,
            heading: "Can an OPC have more than one director?",
            text: `<div>
            <p>While an OPC is owned and managed by a single individual, it can have a minimum of one director and a maximum of fifteen directors. However, the sole owner is also considered the sole director of the OPC.</p>
              <ul>
            <li>Section: As per Section 149(1) of the Companies Act, 2013.</li>
          </ul>
            </div>`,
          },
          {
            id: 7,
            heading: "Can an OPC issue shares?",
            text: `<div>
            <p>No, an OPC cannot issue shares. Since there is only one owner, there are no shareholders in an OPC. However, the owner's contribution to the OPC is represented by the share capital, similar to other types of companies.</p>
              <ul>
            <li>Section: Companies (Share Capital and Debentures) Rules, 2014.</li>
          </ul>
            </div>`,
          },
          {
            id: 8,
            heading:
              "What is the significance of obtaining a Digital Signature Certificate (DSC)?",
            text: `<div>
            <p>A DSC is used for electronically signing documents, ensuring the authenticity and integrity of the digital documents.</p>
              <ul>
            <li>Section: Information Technology Act, 2000..</li>
          </ul>
            </div>`,
          },
          {
            id: 9,
            heading: "What are the compliances for a One Person Company?",
            text: `<div>
           
              <ul>
            <li>Filing of GST returns.</li>
            <li>Filing of Income Tax returns.</li>
            <li>Annual filing of financial statements and annual returns with the ROC.</li>
            <li>Compliance with local laws and regulations (e.g., Shops and Establishment Act, Professional Tax, etc.).</li>
             </ul>
            </div>`,
          },
          {
            id: 10,
            heading: "Are there any specific licences required for an OPC?",
            text: `Depending on the nature of the business and state regulations, an OPC may need to obtain licences such as GST registration, professional tax registration, shops and establishment licence, and trade licence.`,
          },
          {
            id: 11,
            heading:
              "What are the penalties for non-compliance with tax filing?",
            text: `Failure to file income tax returns, GST returns, or other required filings can result in penalties, interest on unpaid taxes, and legal consequences. For example, late filing of income tax returns can attract a penalty under Section 234F of the Income Tax Act.`,
          },
          {
            id: 12,
            heading: " How can Easy Works help with compliance?",
            text: `Easy Works offers end-to-end compliance services, including annual return filing, financial statement preparation, director KYC, and event-based filings. Our expert team ensures timely and accurate compliance, helping you avoid penalties and focus on your business growth.

For more detailed information and assistance with establishing and managing your One Person Company, contact Easy Works today. Our expert team is here to help you navigate the complexities of business compliance and ensure your success.
`,
          },
        ],
      },
      {
        planId: 13,
        label: "Private Limited Company ",
        link: "/contact-us",
        name: "pvtLtd",
        description:
          "A Private Limited Company is a type of business structure where shares are privately held by a limited number of shareholders. It offers limited liability protection to its shareholders, separating their personal assets from the company’s liabilities. Private limited companies are governed by the Companies Act, 2013 and must adhere to various regulatory requirements set by the Ministry of Corporate Affairs (MCA).",

        faq_Config: [
          {
            id: 1,
            heading: "What is a Private Limited Company?",
            text: `A Private Limited Company (PLC) is a type of corporate entity where the liability of its members or shareholders is limited to the amount of shares held by them. It is a separate legal entity distinct from its owners and is governed by the Companies Act, 2013.`,
          },
          {
            id: 2,
            heading:
              "How many members are required to form a Private Limited Company?",
            text: `<div>
            <p>A Private Limited Company must have a minimum of two shareholders (members) and a maximum of 200 shareholders. There must be at least two directors.
            </p>
            <ul>
            <li>Section: As per Section 3(1)(b) of the Companies Act, 2013.</li>
            </ul>
            </div>`,
          },
          {
            id: 3,
            heading:
              "What is the minimum capital requirement for incorporating a Private Limited Company?",
            text: `<div>
            <p>There is no minimum capital requirement for incorporating a Private Limited Company. The company can be registered with any amount of authorized capital as decided by the promoters.
            </p>
            <ul>
            <li>Section: Companies (Incorporation) Rules, 2014.</li>
            </ul>
            </div>`,
          },
          {
            id: 4,
            heading:
              "How is the management of a Private Limited Company structured?",
            text: `<div>
            <p>The management of a Private Limited Company is structured through the board of directors, who are appointed by the shareholders. The directors are responsible for the day-to-day management and decision-making of the company.
            </p>
            <ul>
            <li>Section: As per Section 149 of the Companies Act, 2013.</li>
            </ul>
            </div>`,
          },
          {
            id: 5,
            heading:
              "Can a Private Limited Company issue shares to the public?",
            text: `<div>
            <p>No, a Private Limited Company cannot issue shares to the public. Shares can only be issued to its existing shareholders, promoters, or through private placements.
            </p>
            <ul>
            <li>Section: As per Section 23(2) of the Companies Act, 2013.</li>
            </ul>
            </div>`,
          },
          {
            id: 6,
            heading:
              "Can a Private Limited Company be converted into another business structure?",
            text: `<div>
            <p>Yes, a Private Limited Company can be converted into other business structures such as a public limited company, LLP, or partnership firm by following the prescribed procedures and fulfilling certain conditions specified under the Companies Act, 2013.

            </p>
            <ul>
            <li>Section: As per Section 18 of the Companies Act, 2013.</li>
            </ul>
            </div>`,
          },
          {
            id: 7,
            heading:
              "What is the process for incorporating a Private Limited Company?",
            text: `<div>
            <p>The process for incorporating a Private Limited Company involves:

            </p>
            <ul>
            <li>Obtaining Digital Signature Certificates (DSC) for the directors.</li>
            <li>Obtaining Director Identification Numbers (DIN).</li>
            <li>Registering the company name.</li>
            <li>Filing incorporation documents with the Registrar of Companies (ROC).</li>
            <li>Obtaining the Certificate of Incorporation (COI) and other necessary documents.</li>
            <li>Section: As per Section 7 of the Companies Act, 2013.</li>
            </ul>
            </div>`,
          },
          {
            id: 8,
            heading:
              "Can NRIs or foreign nationals or foreign entities register a company in India?",
            text: `<div>
            <p>Yes, NRIs, foreign nationals, and foreign entities can register a company and invest in India, subject to the Foreign Direct Investment norms set by the RBI.


            </p>
            <ul>
            <li>Section: As per the Foreign Exchange Management Act, 1999 (FEMA).</li>
            </ul>
            </div>`,
          },
          {
            id: 9,
            heading: "What are the compliances for a Private Limited Company?",
            text: `<div>
            <p>A Private Limited Company must comply with:</p>
            <ul>
            <li>Filing of GST returns.</li>
            <li>Filing of Income Tax returns.</li>
            <li>Annual filing of financial statements and annual returns with the ROC.</li>
            <li>Holding board meetings and general meetings as required.</li>
            <li>Maintaining statutory registers and records.</li>
            <li>Compliance with local laws and regulations (e.g., Shops and Establishment Act, Professional Tax, etc.).</li>
            </ul>
            </div>`,
          },
          {
            id: 10,
            heading:
              "What is the due date for filing annual returns and financial statements?",
            text: `Annual returns (Form MGT-7) must be filed within 60 days of the AGM, and financial statements (Form AOC-4) must be filed within 30 days of the AGM.`,
          },
          {
            id: 11,
            heading: "What are the penalties for non-compliance?",
            text: `Non-compliance with statutory requirements can lead to penalties, fines, and disqualification of directors. For example, failing to file annual returns and financial statements can attract significant penalties per day of default.`,
          },
          {
            id: 12,
            heading: "How can Easy Works help with compliance?",
            text: `Easy Works offers end-to-end compliance services, including annual return filing, financial statement preparation, director KYC, and event-based filings. Our expert team ensures timely and accurate compliance, helping you avoid penalties and focus on your business growth.For more detailed information and assistance with establishing and managing your Private Limited Company, contact Easy Works today. Our expert team is here to help you navigate the complexities of business compliance and ensure your success.`,
          },
        ],
      },
      {
        planId: 15,
        label: "Section 8 Company ",
        link: "/contact-us",
        name: "section8",
        description:
          "A Section 8 Company is a non-profit organization registered under the Companies Act, 2013, in India. These companies are established for promoting commerce, art, science, sports, education, research, social welfare, and religion. The profits of such companies are utilized for promoting their objectives and are not shared among the company’s members.",
        faq_Config: [
          {
            id: 1,
            heading: "What is a Section 8 Company?",
            text: `A Section 8 Company, as per the Companies Act, 2013, is a type of nonprofit organization formed with the objective of promoting charitable causes, social welfare, cultural development, or any other permissible purpose. These companies are incorporated with the intention of applying their profits towards promoting their objectives.`,
          },
          {
            id: 2,
            heading:
              "Can a Section 8 Company generate profits or distribute dividends to its members?",
            text: `No, a Section 8 Company cannot distribute profits or dividends to its members. Any income or surplus generated by the company must be applied solely for promoting its charitable objectives and cannot be distributed among its members.`,
          },
          {
            id: 3,
            heading: "Who can form a Section 8 Company?",
            text: `Any person or association of persons, including individuals, trusts, societies, or other entities dedicated to nonprofit activities, can form a Section 8 Company. The promoters must be committed to advancing social welfare, charitable causes, or other permissible objectives.`,
          },
          {
            id: 4,
            heading: "Can a Section 8 Company operate outside India?",
            text: `Yes, a Section 8 Company can undertake activities outside India, subject to compliance with applicable laws, regulations, and obtaining necessary approvals from regulatory authorities.`,
          },
          {
            id: 5,
            heading:
              "Can a Section 8 Company be converted into another business structure?",
            text: `Yes, a Section 8 Company can be converted into other business structures such as a private limited company, LLP, or partnership firm by following the prescribed procedures and fulfilling certain conditions specified under the Companies Act, 2013.`,
          },
          {
            id: 6,
            heading:
              "Are donations made to a Section 8 Company eligible for tax deductions?",
            text: `Yes, donations made to a Section 8 Company are eligible for tax deductions under Section 80G of the Income Tax Act, 1961, subject to certain conditions and limits specified by the Income Tax Department.`,
          },
          {
            id: 7,
            heading: "What are the compliances for Section 8 Company?",
            text: `<div> 
            <ul>
            <li>Filing of annual financial statements and returns with the ROC.</li>
            <li>Holding board meetings and general meetings as required.</li>
            <li>Maintaining statutory registers and records.</li>
            <li>Compliance with the provisions of the Companies Act, 2013, and other applicable laws.</li>
            </ul>
            </div>`,
          },
          {
            id: 8,
            heading:
              "Are there any specific licences required for a Section 8 Company?",
            text: `Depending on the nature of the activities and state regulations, a Section 8 Company may need to obtain licences such as GST registration, professional tax registration, shops and establishment license, and trade license.`,
          },
          {
            id: 9,
            heading:
              "What are the penalties for non-compliance with tax filing?",
            text: `Failure to file income tax returns, GST returns, or other required filings can result in penalties, interest on unpaid taxes, and legal consequences. For example, late filing of income tax returns can attract a penalty under Section 234F of the Income Tax Act.`,
          },
          {
            id: 10,
            heading: "How can Easy Works help with compliance?",
            text: `Easy Works offers end-to-end compliance services, including annual return filing, financial statement preparation, director KYC, and event-based filings. Our expert team ensures timely and accurate compliance, helping you avoid penalties and focus on your nonprofit goals.`,
          },
        ],
      },
      //       {
      //         label: "Nidhi Company",
      //         link: "/contact-us",
      //         name: "nidhiCompany",
      //         description:
      //           "A Nidhi Company in India is a non-banking financial company (NBFC) recognized under the Companies Act, 2013, and governed by the Ministry of Corporate Affairs (MCA) in India. Nidhi Companies are formed with the primary objective of promoting the habit of thrift and savings among their members and accepting deposits and lending to their members only.",
      //         faq_Config: [
      //           {
      //             id: 1,
      //             heading: "What is a Nidhi Company?",
      //             text: `A Nidhi Company is a type of non-banking financial institution (NBFC) recognized under the Companies Act, 2013, and regulated by the Ministry of Corporate Affairs (MCA) in India. It primarily deals with borrowing and lending money among its members or shareholders, with the aim of cultivating the habit of thrift and savings among its members.
      // `,
      //           },
      //           {
      //             id: 2,
      //             heading: "What are the key objectives of a Nidhi Company?",
      //             text: `The primary objectives include promoting the habit of thrift and savings among its members, receiving deposits and lending to its members only, providing financial assistance for the mutual benefit of its members, and fostering a sense of community and mutual trust.`,
      //           },
      //           {
      //             id: 3,
      //             heading: "Who can form a Nidhi Company?",
      //             text: `Any group of individuals, associations, or trusts desirous of carrying out the activities of a Nidhi Company and complying with the regulatory requirements prescribed under the Companies Act, 2013, can form a Nidhi Company.`,
      //           },
      //           {
      //             id: 4,
      //             heading: "Can a Nidhi Company accept deposits from the public?",
      //             text: `No, a Nidhi Company cannot accept deposits from the public. It can only accept deposits and provide financial assistance to its members, who are also its shareholders.`,
      //           },
      //           {
      //             id: 5,
      //             heading: "What are the restrictions on lending by a Nidhi Company?",
      //             text: `A Nidhi Company can only lend money to its members and cannot extend loans or advances to any other person or entity. The loans provided must be for genuine purposes such as housing, education, or business needs of its members.`,
      //           },
      //           {
      //             id: 6,
      //             heading: "Are deposits made with a Nidhi Company insured?",
      //             text: `No, deposits made with a Nidhi Company are not insured by the Deposit Insurance and Credit Guarantee Corporation (DICGC) of India. However, Nidhi Companies are required to maintain certain reserves and comply with regulatory norms to safeguard the interests of depositors.`,
      //           },
      //           {
      //             id: 7,
      //             heading:
      //               "Can a Nidhi Company be changed into another business structure?",
      //             text: `Yes, a Nidhi Company can be changed into other business structures such as a private limited company, LLP, or partnership firm by following the prescribed procedures and fulfilling certain conditions specified under the Companies Act, 2013.`,
      //           },
      //           {
      //             id: 8,
      //             heading:
      //               "Are dividends distributed to shareholders of a Nidhi Company?",
      //             text: `Yes, profits earned by a Nidhi Company can be distributed to its shareholders in the form of dividends, subject to compliance with regulatory requirements and the company's Articles of Association.`,
      //           },
      //           {
      //             id: 9,
      //             heading: "What are the compliances for Nidhi Company?",
      //             text: `<div>
      //             <ul>
      //             <li>Filing of annual financial statements and returns with the ROC.</li>
      //             <li>Holding board meetings and general meetings as required.</li>
      //             <li>Maintaining statutory registers and records.</li>
      //             <li>Compliance with the provisions of the Companies Act, 2013, and Nidhi Rules, 2014.</li>
      //             </ul>
      //             </div>`,
      //           },
      //           {
      //             id: 10,
      //             heading:
      //               "Are there any specific licences required for a Nidhi Company?",
      //             text: `Depending on the nature of the activities and state regulations, a Nidhi Company may need to obtain licences such as GST registration, professional tax registration, shops and establishment license, and trade license.`,
      //           },
      //           {
      //             id: 11,
      //             heading:
      //               "What are the penalties for non-compliance with tax filing?",
      //             text: `Failure to file income tax returns, GST returns, or other required filings can result in penalties, interest on unpaid taxes, and legal consequences. For example, late filing of income tax returns can attract a penalty under Section 234F of the Income Tax Act.`,
      //           },
      //           {
      //             id: 12,
      //             heading: "How can Easy Works help with compliance?",
      //             text: `Easy Works offers end-to-end compliance services, including annual return filing, financial statement preparation, director KYC, and event-based filings. Our expert team ensures timely and accurate compliance, helping you avoid penalties and focus on your business growth.`,
      //           },
      //         ],
      //       },
      {
        planId: 14,
        label: "Limited Liability Partnership",
        link: "/contact-us",
        name: "ltdLiability",
        description:
          "A Limited Liability Partnership (LLP) is a corporate business structure that combines the features of a partnership and a corporation, offering limited liability protection to its partners.",
        faq_Config: [
          {
            id: 1,
            heading: "What is a Limited Liability Partnership (LLP)?",
            text: `A Limited Liability Partnership (LLP) is a hybrid form of business entity that combines the features of a partnership and a corporation. It offers limited liability protection to its partners while allowing them to participate in management and decision-making.`,
          },
          {
            id: 2,
            heading: "Who can form a Limited Liability Partnership?",
            text: `LLPs can be formed by two or more individuals or entities, known as partners, who wish to carry on a lawful business with a view to profit. Professionals such as lawyers, accountants, consultants, and small business owners often choose LLPs for their business ventures.`,
          },
          {
            id: 3,
            heading: "Can an LLP have more than two partners?",
            text: `Yes, an LLP can have more than two partners. There is no maximum limit on the number of partners in an LLP, although there must be at least two designated partners who are responsible for regulatory compliance.`,
          },
          {
            id: 4,
            heading:
              "Can partners in an LLP actively participate in management?",
            text: `Yes, partners in an LLP can actively participate in management and decision-making. Unlike traditional partnerships where liability is unlimited, partners in an LLP have limited liability protection while still being involved in the day-to-day operations of the business.`,
          },
          {
            id: 5,
            heading: "Can an LLP be changed into another business structure?",
            text: `Yes, an LLP can be changed into other business structures such as a private limited company, public limited company, or partnership firm by following the prescribed procedures and fulfilling certain conditions specified under the Limited Liability Partnership Act, 2008.`,
          },
          {
            id: 6,
            heading: " What are the compliances for LLP?",
            text: `<div> 
            <ul>
            <li>Filing of annual return (Form 11).</li>
            <li>Filing of Statement of Accounts and Solvency (Form 8).</li>
            <li>Holding partners’ meetings as required.</li>
            <li>Maintaining statutory registers and records.</li>
            <li>Compliance with the provisions of the LLP Act, 2008, and other applicable laws.</li>
            </ul>
            </div>`,
          },
          {
            id: 7,
            heading: "How many people are required to incorporate an LLP?",
            text: `A minimum of two partners is required to incorporate an LLP.
`,
          },
          {
            id: 8,
            heading: "Are there any specific licences required for an LLP?",
            text: `Depending on the nature of the business and state regulations, an LLP may need to obtain licences such as GST registration, professional tax registration, shops and establishment licence, and trade licence.
`,
          },
          {
            id: 9,
            heading:
              "What are the penalties for non-compliance with tax filing?",
            text: `Failure to file income tax returns, annual returns, statements of accounts, or other required filings can result in penalties, interest on unpaid taxes, and legal consequences. For example, late filing of income tax returns can attract a penalty under Section 234F of the Income Tax Act.`,
          },
          {
            id: 10,
            heading: "How can Easy Works help with compliance?",
            text: `Easy Works offers end-to-end compliance services, including annual return filing, financial statement preparation, partner KYC, and event-based filings. Our expert team ensures timely and accurate compliance, helping you avoid penalties and focus on your business growth.


For more detailed information and assistance with establishing and managing your business structure, contact Easy Works today. Our expert team is here to help you navigate the complexities of business compliance and ensure your success.
`,
          },
        ],
      },
    ],
  },
  {
    title: "Accounting and Bookkeeping and taxes",
    icon: message,
    serviceId: 6,
    links: [
      {
        label: "Bookkeeping",
        link: "/",
        name: "bookkeeping",
        description:
          "Our comprehensive accounting services include bookkeeping, financial statement preparation, budgeting, payroll processing, and tax planning. We ensure that your financial records are accurate, up-to-date, and compliant with relevant accounting standards and regulations. By outsourcing your accounting needs to us, you can focus on core business activities while having peace of mind knowing that your finances are in good hands.",
        faq_Config: [
          {
            id: 1,
            heading: "What are Bookkeeping services?",
            text: `Bookkeeping services involve recording and organizing financial transactions, maintaining general ledgers, reconciling accounts, generating reports, and facilitating compliance with regulatory requirements.`,
          },
          {
            id: 2,
            heading: "Why is accurate bookkeeping important for businesses?",
            text: `Accurate bookkeeping ensures that financial records are up-to-date and reliable, facilitating better decision-making, financial analysis, and compliance with tax regulations.`,
          },
          {
            id: 3,
            heading:
              "What are the benefits of outsourcing accounting and bookkeeping services?",
            text: `Outsourcing accounting and bookkeeping services can save time and resources, ensure expertise and accuracy, and provide access to advanced technology and financial insights.`,
          },
          {
            id: 4,
            heading: "How often should I reconcile my accounts?",
            text: `It is recommended to reconcile accounts, such as bank statements, credit card statements, and accounts receivable/payable, on a monthly basis to identify discrepancies and errors promptly.`,
          },
          {
            id: 5,
            heading:
              "What documents are needed to provide for bookkeeping services?",
            text: `You'll need to provide invoices, receipts, bank statements, payroll records, tax documents, contracts, and any other financial records relevant to your business operations.`,
          },
          {
            id: 6,
            heading: "How can I ensure compliance with tax regulations?",
            text: `Working with a knowledgeable accountant or bookkeeper can help ensure compliance with tax regulations by accurately recording transactions, identifying deductible expenses, and filing taxes on time.`,
          },
          {
            id: 7,
            heading: "What software can be used for bookkeeping services?",
            text: `We use accounting software such as QuickBooks, Tally, or Zoho Books tailored to your business needs and preferences.`,
          },
        ],
      },
      {
        planId: 38,
        label: "GST Filing",
        link: "/contact-us",
        name: "gstFiling",
        description:
          "Registered businesses are required to file periodic GST returns, including GSTR-1 (for outward supplies), GSTR-3B (for summary of outward and inward supplies), and GSTR-9 (annual return). These returns must be filed within the stipulated due dates, providing accurate details of transactions and tax liabilities.",
        faq_Config: [
          {
            id: 1,
            heading: "Who needs to file GST returns?",
            text: `Registered taxpayers engaged in the supply of goods or services, including businesses, e-commerce operators, and others specified by the government.`,
          },
          {
            id: 2,
            heading: "What are the different types of GST returns?",
            text: `<div>
              
             <ul>
              <li>GSTR-1 for outward supplies</li>
              <li>GSTR-3B for monthly summary</li>
              <li>GSTR-9 for annual return</li>
              <li>GSTR-9C for reconciliation (for taxpayers with turnover exceeding ₹2 crores)</li>
             </div>`,
          },
          {
            id: 3,
            heading: "How often do I need to file GST returns?",
            text: `<div>
             <ul>
              <li>GSTR-1 is generally filed monthly</li>
              <li>GSTR-3B is monthly or quarterly</li>
              <li>GSTR-9 is filed annually</li>
              </ul>
               </div>`,
          },
          {
            id: 4,
            heading: "What documents are required for GST return filing?",
            text: `<div>
             <ul>
              <li>Sales invoices</li>
              <li>Purchase invoices</li>
              <li>Bank statements</li>
              <li>Other relevant financial documents</li>
              <li>Credit/debit notes</li>
              </ul>
               </div>`,
          },
          {
            id: 5,
            heading:
              "What happens if I miss the deadline for filing GST returns?",
            text: `Late filing attracts penalties and interest, ranging from ₹50 to ₹200 per day of delay, depending on the return type and turnover.
`,
          },
          {
            id: 6,
            heading: "Can I revise GST returns after filing?",
            text: `No, currently, there is no provision for revising GST returns after filing. However, corrections can be made in subsequent returns.`,
          },
          {
            id: 7,
            heading: "How do I file GST returns?",
            text: `GST returns can be filed online through the GSTN portal.`,
          },
          {
            id: 8,
            heading: "Do I need to hire a professional for GST return filing?",
            text: `<div>
            <p>Hiring a professional for GST return filing is not mandatory but can be advantageous for businesses due to:</p>
             <ul>
              <li>Handling intricate transactions and ensuring accurate reporting, minimizing the risk of errors.</li>
              <li>Saving time, especially for businesses with high transaction volumes.</li>
              <li>Ensuring compliance with GST laws, reducing the likelihood of penalties.</li>
              <li>Providing support during tax audits or inquiries.
</li>
              <li>Keeping abreast of GST changes, ensuring returns are filed correctly.</li>
 
             
              </ul>
               </div>`,
          },
          {
            id: 9,
            heading:
              "What is the penalty for non-compliance with GST return filing?",
            text: `Non-compliance may result in penalties, fines, and legal consequences, including suspension or cancellation of GST registration.`,
          },
        ],
      },
      {
        label: "Payroll Processing",
        link: "/contact-us",
        name: "payrollProcessing",
        description:
          "Payroll processing is the systematic management of employee financial records, including salaries, wages, bonuses, deductions, and taxes. It involves calculating employee compensation, withholding appropriate taxes, and disbursing payments to employees. Efficient payroll processing ensures timely and accurate payment to employees, compliance with legal requirements, and maintenance of comprehensive records.",
        faq_Config: [
          {
            id: 1,
            heading: "What is payroll processing?",
            text: `Payroll processing involves the management of employee financial records, including the calculation of salaries, wages, bonuses, deductions, and taxes, and the disbursement of payments to employees.`,
          },
          {
            id: 2,
            heading: "Why is payroll processing important for businesses?",
            text: `Payroll processing ensures timely and accurate payment to employees, compliance with legal requirements, and maintenance of comprehensive records. It enhances employee satisfaction and helps manage tax withholdings and filings.`,
          },
          {
            id: 3,
            heading: "What are the benefits of outsourcing payroll processing?",
            text: `Outsourcing payroll processing can save time and resources, ensure expertise and accuracy, and provide access to advanced technology and payroll management systems.`,
          },
          {
            id: 4,
            heading: "How often should payroll be processed?",
            text: `Payroll should be processed based on the company's payroll schedule, which could be weekly, bi-weekly, or monthly.`,
          },
          {
            id: 5,
            heading: "What documents are needed for payroll processing?",
            text: `<div>
            <ul>
            <li>Employee details (name, address, bank account information)</li>
            <li>Salary structure and compensation details</li>
            <li>Attendance and leave records</li>
            <li>Timesheets</li>
            <li>Tax declaration forms</li>
            <li>Any other relevant documents specific to the company's payroll policies</li>
            </ul>
            </div>`,
          },
          {
            id: 6,
            heading: "How can I ensure compliance with payroll regulations?",
            text: `Working with a knowledgeable payroll service provider can help ensure compliance with payroll regulations by accurately calculating and withholding taxes, filing necessary reports, and adhering to employment laws.`,
          },
          {
            id: 7,
            heading: "What software can be used for payroll processing?",
            text: `We use payroll software such as QuickBooks Payroll, Tally Payroll, or Zoho Payroll tailored to your business needs and preferences.`,
          },
          {
            id: 8,
            heading:
              "Can payroll processing be customized to fit my business needs?",
            text: `Yes, payroll processing can be customized to fit your business needs, including tailored salary structures, specific deductions, and compliance with industry-specific regulations.`,
          },
          {
            id: 9,
            heading: "What happens if there are errors in payroll processing?",
            text: `Errors in payroll processing can lead to incorrect payments, compliance issues, and employee dissatisfaction. It's essential to review and reconcile payroll records regularly to prevent and correct errors promptly.`,
          },
          {
            id: 10,
            heading: "Do I need to hire a professional for payroll processing?",
            text: `<div>
            <p>Hiring a professional for payroll processing is not mandatory, but it can be beneficial due to:</p>
            <ul>
            <li>Expertise in handling complex payroll calculations and compliance requirements.</li>
            <li>Time savings, allowing businesses to focus on core activities.</li>
            <li>Reduced risk of errors and penalties associated with payroll mismanagement.</li>
            <p>For more detailed information and assistance with payroll processing, contact Easy Works today. Our expert team is here to help you navigate the complexities of payroll management and ensure your business's success.</p>
        
            </ul>
            </div>`,
          },
        ],
      },
    ],
  },
  {
    title: "E-commerce Payment Reconciliation",
    icon: cart2,
    links: [
      {
        label: "Amazon E-Commerce",
        link: "/contact-us",
        name: "amazonEcommerce",
        description:
          "At Easy Works, we understand the complexities involved in managing and reconciling payments for e-commerce businesses. With the increasing popularity of online marketplaces like Amazon, efficient payment reconciliation has become crucial for maintaining financial accuracy and ensuring smooth business operations. Our specialised Amazon E-commerce Payment Reconciliation services are designed to help you keep track of your transactions, identify discrepancies, and ensure that all payments are accounted for accurately.",
        faq_Config: [
          {
            id: 1,
            heading: "What is payment reconciliation?",
            text: `Payment reconciliation is the process of comparing the transactions recorded in your financial statements with the payments received from your sales channels, such as Amazon, to ensure accuracy and consistency.`,
          },
          {
            id: 2,
            heading:
              "Why is payment reconciliation important for Amazon sellers?",
            text: `Reconciliation is crucial for maintaining accurate financial records, managing cash flow, detecting fraud, resolving disputes, and ensuring compliance with tax regulations.`,
          },
          {
            id: 3,
            heading: "How often should I reconcile my Amazon payments?",
            text: `It is advisable to reconcile your payments regularly, preferably monthly, to ensure timely detection and resolution of discrepancies`,
          },
          {
            id: 4,
            heading:
              "What information do I need to provide for reconciliation?",
            text: `You need to provide access to your Amazon seller account, transaction records, bank statements, and any other relevant financial documents.`,
          },
          {
            id: 5,
            heading:
              " How can Easy Works help with Amazon payment reconciliation?",
            text: `Easy Works offers end-to-end payment reconciliation services, including transaction tracking, payment verification, detailed reporting, dispute management, and data integration. Our expert team ensures accurate and timely reconciliation of your Amazon payments.`,
          },
          {
            id: 6,
            heading:
              "What are the common discrepancies found during reconciliation?",
            text: `Common discrepancies include missing payments, incorrect deductions, duplicate transactions, chargebacks, and refunds not reflected in the payments received.
`,
          },
          {
            id: 7,
            heading: "How do you resolve payment disputes with Amazon?",
            text: `We identify the discrepancies, gather supporting documentation, and communicate with Amazon’s support team to resolve the issues promptly.`,
          },
          {
            id: 8,
            heading:
              "Can you integrate reconciliation data with my accounting system?",
            text: `Yes, we can integrate reconciliation data with your accounting system to ensure seamless updates and accurate financial records.`,
          },
          {
            id: 9,
            heading:
              "What are the costs associated with your reconciliation services?",
            text: `The cost of our services depends on the volume of transactions and the complexity of your business operations. Contact us for a customised quote.`,
          },
          {
            id: 10,
            heading:
              " How do I ensure all transactions are recorded accurately?",
            text: `Regularly download and review Amazon transaction reports, match them with your sales records, and use accounting software to keep your financial records updated.`,
          },
          {
            id: 11,
            heading: "What should I do if I find a fraudulent transaction?",
            text: `Immediately report the fraudulent transaction to Amazon support and your bank. Take necessary steps to secure your account and prevent future fraud.`,
          },
          {
            id: 12,
            heading: "How can I improve my reconciliation process?",
            text: `Use automated tools, maintain detailed records, perform regular reconciliations, and stay updated with Amazon’s policies and fee structures.`,
          },
          {
            id: 13,
            heading:
              "How do I get started with Easy Works' payment reconciliation services?",
            text: `To get started, contact us today. Our team will guide you through the process and set up your account for seamless reconciliation of your Amazon payments.
For more detailed information and to get assistance with your Amazon E-commerce Payment Reconciliation, contact Easy Works today. Our expert team is dedicated to ensuring your business remains financially accurate and compliant.
`,
          },
        ],
      },
      {
        label: "Flipkart E-Commerce",
        link: "/contact-us",
        name: "flipkartEComm",
        description:
          "At Easy Works, we understand the complexities involved in managing and reconciling payments for e-commerce businesses. With the increasing popularity of online marketplaces like Flipkart, efficient payment reconciliation has become crucial for maintaining financial accuracy and ensuring smooth business operations. Our specialized Flipkart E-commerce Payment Reconciliation services are designed to help you keep track of your transactions, identify discrepancies, and ensure that all payments are accounted for accurately.",
        faq_Config: [
          {
            id: 1,
            heading: "What is payment reconciliation?",
            text: `Payment reconciliation is the process of comparing the transactions recorded in your financial statements with the payments received from your sales channels, such as Flipkart, to ensure accuracy and consistency.`,
          },
          {
            id: 2,
            heading:
              " Why is payment reconciliation important for Flipkart sellers?",
            text: `Reconciliation is crucial for maintaining accurate financial records, managing cash flow, detecting fraud, resolving disputes, and ensuring compliance with tax regulations.`,
          },
          {
            id: 3,
            heading: "How often should I reconcile my Flipkart payments?",
            text: `It is advisable to reconcile your payments regularly, preferably monthly, to ensure timely detection and resolution of discrepancies.`,
          },
          {
            id: 4,
            heading:
              "What information do I need to provide for reconciliation?",
            text: `You need to provide access to your Flipkart seller account, transaction records, bank statements, and any other relevant financial documents.`,
          },
          {
            id: 5,
            heading:
              "How can Easy Works help with Flipkart payment reconciliation?",
            text: `Easy Works offers end-to-end payment reconciliation services, including transaction tracking, payment verification, detailed reporting, dispute management, and data integration. Our expert team ensures accurate and timely reconciliation of your Flipkart payments.`,
          },
          {
            id: 6,
            heading:
              "What are the common discrepancies found during reconciliation?",
            text: `Common discrepancies include missing payments, incorrect deductions, duplicate transactions, chargebacks, and refunds not reflected in the payments received.
`,
          },
          {
            id: 7,
            heading: "How do you resolve payment disputes with Flipkart?",
            text: `We identify the discrepancies, gather supporting documentation, and communicate with Flipkart’s support team to resolve the issues promptly.`,
          },
          {
            id: 8,
            heading:
              "Can you integrate reconciliation data with my accounting system?",
            text: `Yes, we can integrate reconciliation data with your accounting system to ensure seamless updates and accurate financial records.
`,
          },
          {
            id: 9,
            heading:
              "What are the costs associated with your reconciliation services?",
            text: `The cost of our services depends on the volume of transactions and the complexity of your business operations. Contact us for a customized quote.
`,
          },
          {
            id: 10,
            heading:
              "How do I get started with Easy Works' payment reconciliation services?",
            text: `To get started, contact us today. Our team will guide you through the process and set up your account for seamless reconciliation of your Flipkart payments.
For more detailed information and to get assistance with your Flipkart E-commerce Payment Reconciliation, contact Easy Works today. Our expert team is dedicated to ensuring your business remains financially accurate and compliant
`,
          },
        ],
      },
    ],
  },
  {
    title: "ROC compliance",
    icon: handShake,
    serviceId: 4,
    links: [
      {
        planId: 24,
        label: "LLP Compliance",
        link: "/contact-us",
        name: "llpCompliance",
        description:
          "Welcome to Easy Works, your trusted partner in ensuring smooth and efficient compliance services for your company. Here, we provide a comprehensive guide on ROC (Registrar of Companies) compliance for Limited Liability Partnerships (LLPs) in India. Understanding and adhering to these compliance requirements is crucial for the lawful and seamless operation of your business.",

        faq_Config: [
          {
            id: 1,
            heading: " What is ROC compliance for LLPs?",
            text: `ROC compliance for LLPs refers to the set of rules and regulations prescribed by the Registrar of Companies that all Limited Liability Partnerships must adhere to, as per the LLP Act, 2008.
`,
          },
          {
            id: 2,
            heading: "Why is ROC compliance important for LLPs?",
            text: `It ensures that the LLP operates legally and transparently, avoiding legal penalties and maintaining its credibility.`,
          },
          {
            id: 3,
            heading:
              "What happens if an LLP fails to comply with ROC regulations?",
            text: `Non-compliance can result in hefty fines, legal actions, and potential disqualification of the designated partners.`,
          },
          {
            id: 4,
            heading:
              "What is the due date for filing the Annual Return (Form LLP-11)?",
            text: `The annual return must be filed within 60 days from the end of the financial year.`,
          },
          {
            id: 5,
            heading:
              "What forms need to be filed annually with the ROC for an LLP?",
            text: `Key forms include LLP-11 (Annual Return) and LLP-8 (Statement of Account & Solvency).`,
          },
          {
            id: 6,
            heading: "What is Form LLP-11?",
            text: `Form LLP-11 is the annual return that contains comprehensive details about the LLP’s partners and other relevant information.`,
          },
          {
            id: 7,
            heading: "When is Form LLP-8 due?",
            text: `Form LLP-8 is due on 30th October of every financial year`,
          },
          {
            id: 8,
            heading: "What is the significance of Form LLP-8?",
            text: `Form LLP-8 includes details of the LLP’s financial position, including the balance sheet and profit and loss account, and must be filed with the ROC.`,
          },
          {
            id: 9,
            heading: "When is the Income Tax Return due for LLPs?",
            text: `The due date is 31st July for LLPs not requiring audit and 30th September for LLPs requiring audit`,
          },
          {
            id: 10,
            heading: "What information is required for Form LLP-8?",
            text: `Form LLP-8 requires details about the LLP’s financial position, including the balance sheet and profit and loss account.`,
          },
          {
            id: 11,
            heading: " Who is required to submit Form DIR-3 KYC?",
            text: `All designated partners of an LLP must submit their KYC details using Form DIR-3 KYC.`,
          },
          {
            id: 12,
            heading: "What are the penalties for late filing of Form LLP-8?",
            text: `Late filing can result in a penalty of ₹100 per day of default.`,
          },
          {
            id: 13,
            heading:
              "What are the penalties for non-filing of the Income Tax Return?",
            text: `Non-filing can attract penalties ranging from ₹1,000 to ₹10,000, depending on the turnover and delay.
For more detailed information and assistance with ROC compliance for your LLP, contact Easy Works today. Our expert team is here to help you navigate the complexities of compliance and ensure your LLP’s success.
`,
          },
          {
            id: 14,
            heading: "Why Choose Easy Works?",
            text: `Easy Works offers expert guidance, document preparation, digital signatures, and certification by professionals to ensure timely and accurate LLP filings. Our services minimize the risk of non-compliance, allowing you to focus on your business.
Get started with Easy Works today to streamline your LLP compliance and avoid penalties. Visit our website for more information and assistance.
`,
          },
          {
            id: 15,
            heading: "What are LLP Annual Filings?",
            text: `LLP Annual Filings are mandatory reports that Limited Liability Partnerships must submit annually to regulatory authorities to maintain compliance and legal status.`,
          },
          {
            id: 16,
            heading: "Why are LLP Annual Filings essential?",
            text: `These filings are vital for maintaining the legal status of an LLP, ensuring transparency, financial accountability, and enhancing credibility.`,
          },
          {
            id: 17,
            heading: "What are the penalties for non-compliance by LLPs?",
            text: `Non-compliance can lead to penalties up to INR 5 lakhs, making it crucial for LLPs to fulfill their annual filing obligations.`,
          },
          {
            id: 18,
            heading: "What are the primary compliance requirements for LLPs?",
            text: `<div>
            <p>Key compliance requirements include:</p>
            <ul>
            <li>Maintaining proper books of accounts</li>
            <li>Filing annual returns (Form 11)</li>
            <li>Filing statements of accounts (Form 8)</li>
            <li>Filing Income Tax Return (ITR-5)</li>
          
            </ul>
            </div>`,
          },
          {
            id: 19,
            heading:
              "What is the deadline for filing Form 11 (Annual Return) by LLPs?",
            text: `Form 11 must be filed within 60 days after the financial year ends, typically by May 30th each year.`,
          },
          {
            id: 20,
            heading:
              "What are the consequences of missing the Form 11 filing deadline?",
            text: `Missing the Form 11 filing deadline results in a penalty of INR 100 per day of delay.`,
          },
          {
            id: 21,
            heading:
              "When must Form 8 (Statement of Accounts) be filed by LLPs?",
            text: `Form 8 must be filed within 30 days from the end of six months after the financial year ends, generally by October 30th.`,
          },
          {
            id: 22,
            heading: "Who can digitally sign Form 8 for LLPs?",
            text: `Form 8 must be digitally signed by two designated partners and certified by a company secretary, chartered accountant, or cost accountant.`,
          },
          {
            id: 23,
            heading: "What is the penalty for late filing of Form 8 by LLPs?",
            text: `Late filing of Form 8 incurs a penalty of INR 100 per day of delay.`,
          },
          {
            id: 24,
            heading: "Is a tax audit required for all LLPs?",
            text: `A tax audit is mandatory for LLPs with an annual turnover exceeding Rs. 40 lakhs or contributions over Rs. 25 lakhs, subject to specific conditions.`,
          },
          {
            id: 25,
            heading:
              "What are the deadlines for LLP tax audits and tax filings?",
            text: `The deadline for a tax audit for applicable LLPs is September 30th, while LLPs not requiring a tax audit must file by July 31st.
`,
          },
          {
            id: 26,
            heading:
              "Do LLPs involved in international transactions have additional filing requirements?",
            text: `Yes, LLPs engaged in international transactions must file Form 3CEB, certified by a chartered accountant, by November 30th.`,
          },
          {
            id: 27,
            heading: "Which Income Tax Return (ITR) form should LLPs use?",
            text: `LLPs must file their income tax return using Form ITR-5.`,
          },
          {
            id: 28,
            heading: "When is the due date for ITR filing by LLPs?",
            text: `The due date for ITR filing is July 31st, or September 30th if a tax audit is required.`,
          },
          {
            id: 29,
            heading: "What are the benefits of LLP Annual Filings?",
            text: `<div>
            <p>Benefits include::</p>
            <ul>
            <li>Enhanced credibility</li>
            <li>A comprehensive financial track record</li>
            <li>Penalty avoidance</li>
            <li>Simplified conversion or closure processes</li>
          
            </ul>
            </div>`,
          },
          {
            id: 30,
            heading: "Why choose Easy Works for LLP annual filings?",
            text: `Easy Works provides expert guidance, timely filings, document preparation, and certification services to streamline the annual filing process and reduce the risk of non-compliance.`,
          },
          {
            id: 31,
            heading:
              "Can Easy Works assist with other compliance services for LLPs?",
            text: `Yes, Easy Works offers a range of compliance services, including GST registration, accounting, and more, to meet all your LLP's needs.`,
          },
          {
            id: 32,
            heading:
              "What happens if an LLP fails to comply with annual filing requirements?",
            text: `Non-compliance can result in substantial penalties, loss of credibility, and potential legal issues.`,
          },
          {
            id: 33,
            heading:
              "How can I get started with LLP annual filings through Easy Works?",
            text: `Visit the Easy Works website and follow the steps outlined to begin your LLP annual filings.`,
          },
          {
            id: 34,
            heading: "How does timely filing of annual returns benefit LLPs?",
            text: `Timely filing helps maintain the LLP's good standing, avoid penalties, and ensure smooth business operations`,
          },
          {
            id: 35,
            heading: "What is the process for filing Form 11 (Annual Return)?",
            text: `Collect all necessary information about the LLP and its partners, complete Form 11, and submit it online via the Ministry of Corporate Affairs portal by May 30th.`,
          },
          {
            id: 36,
            heading:
              "What documents are needed for filing Form 8 (Statement of Accounts)?",
            text: `Required documents include a solvency statement, income and expenditure statement, and a detailed account of assets and liabilities, digitally signed and certified by designated professionals.`,
          },
          {
            id: 37,
            heading:
              "How does maintaining proper books of accounts benefit an LLP?",
            text: `Proper bookkeeping provides a clear financial picture, aids decision-making, and ensures statutory compliance, preventing legal issues and penalties.`,
          },
          {
            id: 38,
            heading:
              "What are the new threshold limits for tax audits from Assessment Year 2021-22?",
            text: `The threshold limit for tax audits has increased to Rs. 5 crore if cash receipts and payments are less than 5% of total receipts and payments.`,
          },
          {
            id: 39,
            heading: "How can Easy Works help with GST registration for LLPs?",
            text: `Easy Works offers comprehensive GST registration services, ensuring your LLP complies with all GST requirements and deadlines, facilitating smooth business operations`,
          },
        ],
      },
      {
        planId: 25,
        label: "Private Limited Company ",
        link: "/contact-us",
        name: "rocPvtLtd",
        description:
          "Welcome to Easy Works, your trusted partner in ensuring smooth and efficient compliance services for your company. Here, we provide a comprehensive guide on ROC (Registrar of Companies) compliance for Private Limited Companies in India. Understanding and adhering to these compliance requirements is crucial for the lawful and seamless operation of your business.",
        faq_Config: [
          {
            id: 1,
            heading: "What is ROC compliance?",
            text: "ROC compliance refers to the set of rules and regulations prescribed by the Registrar of Companies that all Private Limited Companies must adhere to, as per the Companies Act, 2013.",
          },
          {
            id: 2,
            heading: "Why is ROC compliance important?",
            text: "It ensures that the company operates legally and transparently, avoiding legal penalties and maintaining its credibility.",
          },
          {
            id: 3,
            heading:
              "What happens if a company fails to comply with ROC regulations?",
            text: "Non-compliance can result in hefty fines, legal actions, and potential disqualification of the company’s directors.",
          },
          {
            id: 4,
            heading:
              "What is the due date for holding an Annual General Meeting (AGM)?",
            text: "An AGM must be held within six months from the end of the financial year.",
          },
          {
            id: 5,
            heading: "What forms need to be filed annually with the ROC?",
            text: "Key forms include MGT-7 (Annual Return), AOC-4 (Financial Statements), DIR-3 KYC (Director KYC), and others as specified.",
          },
          {
            id: 6,
            heading: "What is Form MGT-7?",
            text: "Form MGT-7 is the annual return that contains comprehensive details about the company’s management, shareholders, and other relevant information.",
          },
          {
            id: 7,
            heading: "When is Form DIR-3 KYC due?",
            text: "Form DIR-3 KYC is due on 30th September of the following financial year.",
          },
          {
            id: 8,
            heading: "What is the significance of Form AOC-4?",
            text: "Form AOC-4 is used to file the company’s financial statements, including the balance sheet, profit and loss account, and cash flow statement, with the ROC.",
          },
          {
            id: 9,
            heading: "How often must Form MSME-1 be filed?",
            text: "Form MSME-1 must be filed twice a year – on 30th April and 31st October.",
          },
          {
            id: 10,
            heading: "What information is required for Form DPT-3?",
            text: "Form DPT-3 requires details about deposits or outstanding loans or money received by the company.",
          },
          {
            id: 11,
            heading: "Who is required to submit Form DIR-3 KYC?",
            text: "All directors of a company must submit their KYC details using Form DIR-3 KYC.",
          },
          {
            id: 12,
            heading: "What is the purpose of Form ADT-1?",
            text: "Form ADT-1 is used to appoint or re-appoint an auditor for the company.",
          },
        ],
      },
      {
        planId: 25,
        label: "Company Registration",
        link: "/contact-us",
        name: "companyRegistration",
        value: "companyRegistration",
        faq_Config: [
          {
            id: 1,
            heading: "What is a company?",
            text: `A company is a legal entity formed by individuals known as shareholders or members to conduct business activities. It operates as a separate entity from its owners, offering limited liability protection to shareholders. Companies have perpetual existence, centralized management by a board of directors, and ownership represented by shares. Various types of companies exist, including private limited, public limited, one-person company (OPC), and limited liability partnership (LLP).`,
          },
          {
            id: 2,
            heading: "What are the Types of companies?",
            text: `
            <div>
              <p>
                The various types of companies:
              </p>
            <ul>
            <li>Private Limited Company </li>
            <li>Public Limited Company </li>
            <li>Limited Liability Company</li>
            <li>One Person company</li>
            </ul>
            </div>`,
          },
          {
            id: 3,
            heading: "What are the steps to register a company?",
            text: `
            <div>
              <p>
             The steps to register a company:
              </p>
            <ul>
            <li>Choose a Business Structure </li>
            <li>Decide on a Company Name and Check Availability </li>
            <li>Obtain Digital Signature Certificate (DSC)</li>
            <li>Obtain Director Identification Number (DIN)</li>
            <li>Draft Memorandum of Association (MOA) and Articles of Association (AOA)</li>
            <li>Pay Registration Fees and Stamp Duty</li>
            <li>Verification and Approval by ROC</li>
            <li>Receive Certificate of Incorporation</li>
            <li>Obtain PAN and TAN for the Company</li>
            <li>Open a Bank Account</li>
            <li>Start Business Operations</li>
            </ul>
            </div>`,
          },
          {
            id: 4,
            heading: "What is MOA and AOA?",
            text: `
            <div>
              <p>
                  <strong> MOA</strong> stands for Memorandum of Association, while AOA stands for Articles of Association. These are legal documents that define the structure, objectives, and internal rules of a company.
              </p>
              <p>
              MOA outlines the company's main objectives, scope of operations, and its relationship with shareholders and stakeholders. 
              </p>
                <p>AOA, on the other hand, lays down the rules and regulations for the internal management and conduct of the company, including the roles and responsibilities of directors, shareholders, and officers.</p>
                <p>Both MOA and AOA are essential documents required during the company registration process, and they provide a framework for the functioning and governance of the company.
                 </p>
         
            </div>`,
          },
          {
            id: 5,
            heading: "What is DSC?",
            text: `DSC stands for Digital Signature Certificate. It is a secure digital key issued by certifying authorities to verify the authenticity and integrity of electronic documents and transactions. DSCs are used to electronically sign documents and forms during various online transactions, including company registration, income tax filing, and e-filing of documents with government authorities.`,
          },
          {
            id: 6,
            heading: "What is DIN?",
            text: `DIN stands for Director Identification Number. It is a unique identification number assigned to individuals who wish to be appointed as directors of companies registered in India. DIN serves as a means to track and regulate the activities of directors and helps maintain a centralized database of directors' information. It is mandatory for individuals to obtain DIN before being appointed as directors of Indian companies`,
          },
          {
            id: 7,
            heading: "What is DPIN?",
            text: `DPIN is a unique identification number assigned to individuals who wish to become designated partners in a Limited Liability Partnership (LLP) registered in India. It serves a similar purpose to Director Identification Number (DIN) for directors of companies, helping track and regulate the activities of designated partners in LLPs. Obtaining a DPIN is mandatory for individuals before they can be appointed as designated partners of an LLP.`,
          },
        ],
        description:
          "Starting a business involves various legal formalities, and company registration is one of the primary steps. We assist entrepreneurs in choosing the right business structure (e.g., private limited company, LLP, sole proprietorship) and guide them through the entire registration process. Our services encompass drafting necessary documents, obtaining Digital Signature Certificates (DSC), filing incorporation forms with the Registrar of Companies (ROC), and obtaining the Certificate of Incorporation.",
      },
      {
        planId: 26,
        label: "DIR 3 KYC",
        link: "/contact-us",
        name: "dirKYC",
        description:
          "DIR-3 KYC (Know Your Customer) is a mandatory annual compliance requirement for all directors holding a Director Identification Number (DIN) in India. Introduced by the Ministry of Corporate Affairs (MCA), this process ensures that the KYC details of directors are up-to-date and accurate. Failure to comply with DIR-3 KYC can result in the deactivation of the DIN and significant penalties.",
        faq_Config: [
          {
            id: 1,
            heading: "What is DIR-3 KYC?",
            text: `DIR-3 KYC is an annual compliance requirement for directors holding a Director Identification Number (DIN). It involves updating and verifying the KYC details of directors with the Ministry of Corporate Affairs (MCA).`,
          },
          {
            id: 2,
            heading: "Who needs to file DIR-3 KYC?",
            text: `All directors holding an active DIN as of March 31st of a financial year must file DIR-3 KYC annually.`,
          },
          {
            id: 3,
            heading: "What happens if DIR-3 KYC is not filed?",
            text: `Failure to file DIR-3 KYC by the due date results in the deactivation of the DIN. To reactivate the DIN, a late fee of Rs. 5,000 must be paid, and the DIR-3 KYC must be filed.`,
          },
          {
            id: 4,
            heading: "What documents are required for DIR-3 KYC?",
            text: `<div>
            <ul>
            <li>PAN card</li>
            <li>Aadhaar card</li>
            <li>Passport (if available)</li>
            <li>Address proof (utility bill, bank statement)</li>
            <li>Latest passport-size photograph</li>
            </ul>
            </div>`,
          },
          {
            id: 5,
            heading: "How can I file DIR-3 KYC for the first time?",
            text: `For first-time filers or those whose DIN is deactivated, download the DIR-3 KYC eForm from the MCA website, fill in the required details, attach the necessary documents, and submit it online through the MCA portal with a digital signature certificate (DSC).`,
          },
          {
            id: 6,
            heading:
              "Can I update my email ID and mobile number while filing DIR-3 KYC?",
            text: `Yes, you can update your email ID and mobile number while filing the DIR-3 KYC form. Ensure that the updated details are unique and not previously used for any other director’s KYC.I update my email ID and mobile number while filing DIR-3 KYC`,
          },
          {
            id: 7,
            heading: "Is it mandatory to file DIR-3 KYC every year?",
            text: `Yes, it is mandatory to file DIR-3 KYC annually for all directors holding an active DIN.`,
          },
          {
            id: 8,
            heading:
              "Can I file DIR-3 KYC myself, or do I need professional help?",
            text: `Directors can file DIR-3 KYC themselves using the web form if they have filed KYC in the previous year. For first-time filers or those requiring certification, it is advisable to seek professional help from a CA, CS, or Cost Accountant.`,
          },
          {
            id: 9,
            heading: "What is the due date for filing DIR-3 KYC?",
            text: `The due date for filing DIR-3 KYC is usually September 30th each year.
`,
          },
        ],
      },
      {
        planId: 27,
        label: "Add/ Remove Directors",
        link: "/contact-us",
        name: "addRemoveDirectors",
        description:
          "Managing the composition of the Board of Directors is a critical aspect of corporate governance. Companies may need to add or remove directors for various reasons such as bringing in new expertise, replacing inactive members, or addressing compliance requirements. This process must comply with the Companies Act, 2013, and related regulations to ensure legal and procedural correctness.",
        faq_Config: [
          {
            id: 1,
            heading: "What is the procedure to add a director to a company?",
            text: `To add a director, obtain their consent, convene a Board Meeting to pass the appointment resolution, ensure the director has a DIN, obtain shareholders’ approval if required, file Form DIR-12 with the ROC, and update company records`,
          },
          {
            id: 2,
            heading: "What forms are needed to add a director?",
            text: `The primary form required is Form DIR-12, along with Form DIR-2 (consent to act as a director), and potentially Form DIR-3 if a new DIN is needed.`,
          },
          {
            id: 3,
            heading: "How long does it take to add a director?",
            text: `The process can take a few days to a few weeks, depending on the time taken to obtain necessary consents, hold meetings, and complete filings.`,
          },
          {
            id: 4,
            heading:
              "Is shareholder approval always required to add a director?",
            text: `Shareholder approval is required if stipulated by the Articles of Association or the Companies Act, 2013, such as in the case of appointing additional directors beyond the limit specified in the AOA.`,
          },
          {
            id: 5,
            heading:
              "What are the compliance requirements after adding a director?",
            text: `After adding a director, the company must file Form DIR-12 with the ROC within 30 days, update the Register of Directors, and ensure the new director’s details are reflected in all relevant company records.`,
          },
          {
            id: 6,
            heading:
              "What is the process for removing a director from a company?",
            text: `To remove a director, convene a Board Meeting to pass a resolution for removal, give special notice to the director if required, hold a General Meeting for shareholder approval, file Form DIR-12 with the ROC, and update company records.`,
          },
          {
            id: 7,
            heading: "Can a director be removed without their consent?",
            text: `Yes, a director can be removed without their consent through a resolution passed at a General Meeting by the shareholders, following the proper notice and opportunity to be heard.`,
          },
          {
            id: 8,
            heading: "What forms are needed to remove a director?",
            text: `The primary form required is Form DIR-12, along with any relevant resolutions and special notices.
`,
          },
          {
            id: 9,
            heading: "What are the grounds for removing a director?",
            text: `Grounds for removing a director include non-performance, conflict of interest, breach of fiduciary duty, legal non-compliance, or resignation by the director.
`,
          },
          {
            id: 10,
            heading: "How long does it take to remove a director?",
            text: `The process can take a few weeks, depending on the time required to convene meetings, pass resolutions, and complete necessary filings.`,
          },
        ],
      },
      {
        planId: 29,
        label: "Registered Office Address Change",
        link: "/contact-us",
        name: "regOfficeAddressChange",
        description:
          "Changing the registered office address of a company or Limited Liability Partnership (LLP) involves a formal procedure that must comply with the Companies Act, 2013, and related regulations. The registered office is the official address where all communications and notices from regulatory authorities are sent. This address is also used for maintaining statutory records.",
        faq_Config: [
          {
            id: 1,
            heading: "What is a registered office address?",
            text: `The registered office address is the official address of the company or LLP where all communications and notices from regulatory authorities are sent. It is also used for maintaining statutory records.`,
          },
          {
            id: 2,
            heading:
              "What are the different types of changes in the registered office address?",
            text: `Changes can be within the same city/town/village, within the same state but different city/town/village, or from one state to another.`,
          },
          {
            id: 3,
            heading:
              "What is the procedure to change the registered office address within the same city/town/village?",
            text: `Convene a Board Meeting to pass a resolution, and file Form INC-22 with the ROC within 15 days of the Board resolution.`,
          },
          {
            id: 4,
            heading:
              "What is the procedure to change the registered office address within the same state but different city/town/village?",
            text: `Convene a Board Meeting, obtain shareholder approval through an EGM, pass a special resolution, file Form MGT-14 with the ROC within 30 days, and file Form INC-22 with the ROC within 15 days of filing Form MGT-14.`,
          },
          {
            id: 5,
            heading:
              "What is the procedure to change the registered office address from one state to another?",
            text: `Convene a Board Meeting, obtain shareholder approval through an EGM, pass a special resolution, file Form MGT-14 with the ROC, file an application with the RD in Form INC-23, publish notices in newspapers, obtain RD approval, and file Form INC-22 and Form INC-28 with the ROC.`,
          },
          {
            id: 6,
            heading:
              "What documents are required for changing the registered office address?",
            text: `Documents include certified copies of the Board and special resolutions, proof of the new address, NOC from the property owner, altered MOA (if required), newspaper advertisements (for inter-state changes), and acknowledgment of notices sent to creditors and stakeholders (for inter-state changes).`,
          },
          {
            id: 7,
            heading:
              "How long does it take to change the registered office address?",
            text: `The time required varies depending on the type of change. Intra-city changes can be completed within a few weeks, while inter-state changes may take several months due to the requirement of RD approval.`,
          },
          {
            id: 8,
            heading:
              "Is shareholder approval always required for changing the registered office address?",
            text: `Shareholder approval is required for changes within the same state but different city/town/village and for inter-state changes. It is not required for changes within the same city/town/village.`,
          },
          {
            id: 9,
            heading: "Can a company operate from multiple addresses?",
            text: `Yes, a company can operate from multiple addresses, but it must have one registered office address. Additional addresses can be designated as branch offices or corporate offices.`,
          },
          {
            id: 10,
            heading:
              "What are the penalties for not updating the registered office address with the ROC?",
            text: `Failure to update the registered office address with the ROC can result in penalties, including fines and legal actions against the company and its officers.`,
          },
        ],
      },
      {
        planId: 30,
        label: "Change In Share Capital",
        link: "/contact-us",
        name: "changeInShareCapital",
        description:
          "Changing the share capital of a company involves altering its authorized, issued, subscribed, or paid-up share capital. This process can be undertaken to accommodate the company’s growth, raise additional funds, restructure the company’s equity base, or comply with regulatory requirements. The change in share capital can be achieved through various methods, such as issuing new shares, consolidating or splitting shares, converting shares, or reducing the share capital.",
        faq_Config: [
          {
            id: 1,
            heading: "What is a change in share capital?",
            text: `A change in share capital refers to any alteration in the company’s authorized, issued, subscribed, or paid-up share capital. This can include increasing or reducing the capital, issuing new shares, consolidating or splitting existing shares, or converting shares from one class to another.`,
          },
          {
            id: 2,
            heading: "Why would a company change its share capital?",
            text: `Companies change their share capital to raise additional funds, accommodate growth, restructure their equity base, comply with regulatory requirements, or return surplus capital to shareholders.`,
          },
          {
            id: 3,
            heading:
              "What is the process to increase the authorized share capital?",
            text: `To increase the authorized share capital, a company must amend its Memorandum of Association, obtain approval from the Board of Directors, pass a special resolution in a general meeting, and file Form SH-7 with the Registrar of Companies.`,
          },
          {
            id: 4,
            heading:
              "Can a company issue new shares without increasing its authorized share capital?",
            text: `No, a company cannot issue new shares beyond its authorized share capital. If the authorized share capital is insufficient, it must first be increased before issuing additional shares.`,
          },
          {
            id: 5,
            heading: "What is the procedure for reducing share capital?",
            text: `To reduce share capital, the company must obtain approval from the Board of Directors, pass a special resolution in a general meeting, seek confirmation from the National Company Law Tribunal (NCLT), and file the necessary forms with the Registrar of Companies.`,
          },
          {
            id: 6,
            heading:
              "What are the documents required for changing share capital?",
            text: `Documents required include the notice of Board and General Meetings, copy of the resolution passed, amended Memorandum of Association (if applicable), Form SH-7 (for increase in authorized capital), Form PAS-3 (for allotment of shares), Form MGT-14 (for special resolutions), and confirmation order from NCLT (for reduction of share capital).`,
          },
          {
            id: 7,
            heading:
              "How long does it take to change the share capital of a company?",
            text: `The time required to change the share capital varies depending on the type of change and compliance with regulatory requirements. Typically, it can take a few weeks to a few months, especially if approval from NCLT is required.`,
          },
          {
            id: 8,
            heading:
              "Is shareholder approval required for all changes in share capital?",
            text: `Yes, most changes in share capital require approval from shareholders through a resolution passed in a general meeting.`,
          },
        ],
      },
      {
        planId: 31,
        label: "Company/LLP Closure",
        link: "/contact-us",
        name: "llpClosure",
        description:
          "Closing a company or Limited Liability Partnership (LLP) involves a formal process to ensure that the entity is legally dissolved and its name is removed from the Registrar of Companies (ROC). This process, also known as winding up or striking off, ensures that the company/LLP ceases operations in compliance with regulatory requirements and settles all its obligations.",
        faq_Config: [
          {
            id: 1,
            heading: "What is the process for voluntarily closing a company?",
            text: `The process involves passing a board resolution, obtaining shareholder approval through a special resolution, submitting a declaration of solvency, obtaining creditor consent, appointing a liquidator, settling liabilities, and filing an application for striking off with the ROC.`,
          },
          {
            id: 2,
            heading: "How can an LLP be closed voluntarily?",
            text: `To close an LLP voluntarily, partners must pass a resolution, obtain creditor consent, submit a declaration of solvency, appoint a liquidator, settle liabilities, and file an application for striking off with the ROC.`,
          },
          {
            id: 3,
            heading: "What is the role of a liquidator in the closure process?",
            text: `A liquidator is appointed to manage the winding-up process, which includes settling liabilities, disposing of assets, and distributing the remaining assets among shareholders or partners.`,
          },
          {
            id: 4,
            heading:
              "What happens if a company/LLP does not respond to the ROC’s notice for compulsory closure?",
            text: `If no satisfactory response is received within 30 days, the ROC publishes a notice in the Official Gazette and proceeds to strike off the company/LLP’s name from the register.`,
          },
          {
            id: 5,
            heading:
              "What are the consequences of not closing a dormant company/LLP?",
            text: `Failure to close a dormant company/LLP can result in penalties, fines, and legal actions against the company/LLP and its directors/partners.`,
          },
          {
            id: 6,
            heading: "Can a closed company/LLP be revived?",
            text: `Yes, a closed company/LLP can be revived by filing an application with the NCLT or appropriate court, demonstrating just cause for revival.`,
          },
          {
            id: 7,
            heading: "What documents are required for the closure process?",
            text: `Documents include board and special resolutions, declaration of solvency, consent of creditors, liquidator’s final accounts, and application forms (STK-2 for companies and LLP-24 for LLPs).`,
          },
          {
            id: 8,
            heading: "How long does the closure process take?",
            text: `The closure process can take several months, depending on the type of closure and compliance with all procedural requirements.`,
          },
          {
            id: 9,
            heading: "Is shareholder/partner approval required for closure?",
            text: `Yes, shareholder approval (for companies) or partner approval (for LLPs) is required for the voluntary closure process.`,
          },
          {
            id: 10,
            heading: "What are the fees associated with the closure process?",
            text: `Fees vary depending on the type of closure and the filings required. Consult with a professional for detailed fee structures.`,
          },
        ],
      },
    ],
  },
  {
    title: "Income Tax Filing",
    icon: messageWrite,
    serviceId: 5,
    links: [
      {
        planId: 32,
        label: "Personal Tax Filing",
        link: "/contact-us",
        name: "personalTaxFiling",
        description:
          "Filing personal income tax returns is an essential responsibility for every taxpayer. It involves reporting your income, deductions, and tax liabilities to the government. Proper tax filing ensures compliance with tax laws, helps avoid penalties, and can provide various benefits such as refunds and deductions.",
        faq_Config: [
          {
            id: 1,
            heading: "Who needs to file an income tax return?",
            text: `Individuals with income above the basic exemption limit, which varies based on age and residential status, must file an income tax return. Additionally, filing is mandatory for those with foreign income/assets, and certain financial transactions.`,
          },
          {
            id: 2,
            heading: "What is the deadline for filing income tax returns?",
            text: `The deadline for filing income tax returns for individuals is usually July 31st of the assessment year. However, this can be extended by the government.`,
          },
          {
            id: 3,
            heading: "What are the consequences of not filing taxes on time?",
            text: `Failure to file taxes on time can result in penalties, interest on unpaid taxes, and loss of certain deductions and carry-forward losses.`,
          },
          {
            id: 4,
            heading: "Can I file my tax return after the deadline?",
            text: `Yes, you can file a belated return before the end of the assessment year. However, penalties and interest may apply.`,
          },
          {
            id: 5,
            heading: "How can I check the status of my tax refund?",
            text: `You can check the status of your tax refund on the e-filing portal of the income tax department by entering your PAN and assessment year.`,
          },
          {
            id: 6,
            heading: "What should I do if I made a mistake in my tax return?",
            text: `If you discover a mistake in your tax return, you can file a revised return before the end of the assessment year or before the completion of the assessment, whichever is earlier.`,
          },
          {
            id: 7,
            heading: " Are there any penalties for late payment of taxes?",
            text: `Yes, late payment of taxes attracts interest under Section 234A, 234B, and 234C of the Income Tax Act`,
          },
          {
            id: 8,
            heading: "How can I verify my income tax return?",
            text: `You can verify your income tax return electronically using Aadhaar OTP, net banking, bank account, demat account, or by sending a signed physical copy to the CPC.`,
          },
          {
            id: 9,
            heading: "Can I claim deductions for donations made to charity?",
            text: `Yes, donations to specified funds and charitable institutions can be claimed under Section 80G of the Income Tax Act.`,
          },
          {
            id: 10,
            heading: "What documents should I keep after filing my tax return?",
            text: `Keep copies of your filed return, acknowledgment, Form 16, Form 26AS, bank statements, investment proofs, and receipts for deductions. These documents should be retained for at least six years`,
          },
        ],
      },
      {
        planId: 33,
        label: "Business Tax Filing",
        link: "/contact-us",
        name: "businessTaxFiling",
        description:
          "Business tax filing is a crucial aspect of running any business, ensuring compliance with legal requirements and optimizing tax obligations. Proper tax filing helps businesses avoid penalties, claim eligible deductions, and maintain accurate financial records. This process involves reporting income, expenses, and other relevant financial information to the tax authorities.",
        faq_Config: [
          {
            id: 1,
            heading: "What is business tax filing?",
            text: `Business tax filing involves reporting a business's income, expenses, and tax liability to the tax authorities, ensuring compliance with tax laws and optimizing tax obligations.`,
          },
          {
            id: 2,
            heading: "Who needs to file business taxes?",
            text: `All businesses, including sole proprietorships, partnerships, LLPs, and companies, need to file tax returns if their income exceeds the basic exemption limit or if they meet other specific conditions.`,
          },
          {
            id: 3,
            heading: "What documents are required for business tax filing?",
            text: `Required documents include financial statements, bank statements, purchase and sales invoices, expense receipts, TDS certificates, and the previous year’s tax return.`,
          },
          {
            id: 4,
            heading:
              " What are the penalties for late filing of business taxes?",
            text: `Penalties include up to Rs. 10,000 for late filing under Section 234F, 1% interest per month on unpaid taxes under Section 234A, and additional penalties for underreporting income or non-payment of advance tax.`,
          },
          {
            id: 5,
            heading: "Can I revise my business tax return after filing?",
            text: `Yes, you can revise your tax return before the end of the assessment year or before the completion of the assessment, whichever is earlier.`,
          },
          {
            id: 6,
            heading: "What is the due date for filing business tax returns?",
            text: `The due date is typically July 31st for individuals and entities not subject to audit and September 30th for those requiring audit. These dates can be extended by the government.`,
          },
          {
            id: 7,
            heading: "How do I pay advance tax?",
            text: `Advance tax can be paid in installments on specified due dates using challan ITNS 280 online through the income tax department's website or through designated banks.`,
          },
          {
            id: 8,
            heading: "What is the presumptive income scheme?",
            text: `The presumptive income scheme allows eligible businesses to declare income at a prescribed rate of turnover, simplifying the tax computation and filing process. It is available under Sections 44AD, 44ADA, and 44AE.`,
          },
          {
            id: 9,
            heading: "How can I verify my filed tax return?",
            text: `You can verify your tax return electronically using Aadhaar OTP, net banking, or by sending a signed physical copy to the Centralized Processing Center (CPC).`,
          },
          {
            id: 10,
            heading: "What happens if I do not file my business tax return?",
            text: `Failure to file can result in penalties, interest on unpaid taxes, and legal consequences, including prosecution for willful default.`,
          },
        ],
      },
      {
        planId: 34,
        label: "TDS Filing",
        link: "/contact-us",
        name: "tdsFiling",
        description:
          "Tax Deducted at Source (TDS) is a mechanism introduced by the Income Tax Department to collect tax at the source of income. The payer deducts tax at a specified rate from the payment and remits it to the government. TDS is applicable to various payments, including salaries, interest, rent, professional fees, and contractual payments. Proper TDS filing ensures compliance with tax laws and helps avoid penalties and interest.",
        faq_Config: [
          {
            id: 1,
            heading: "What is TDS?",
            text: `Tax Deducted at Source (TDS) is a mechanism where tax is deducted at the source of income and remitted to the government by the payer.`,
          },
          {
            id: 2,
            heading: "Who needs to deduct TDS?",
            text: `Entities such as companies, individuals (under certain conditions), HUFs, firms, LLPs, and others making specified payments are required to deduct TDS.`,
          },
          {
            id: 3,
            heading: "What is TAN, and how is it obtained?",
            text: `TAN (Tax Deduction and Collection Account Number) is a unique identifier required for entities deducting TDS. It can be obtained by applying online through the NSDL website.`,
          },
          {
            id: 4,
            heading: "What are the due dates for depositing TDS?",
            text: `TDS must be deposited by the 7th of the following month. For March, the due date is April 30th.`,
          },
          {
            id: 5,
            heading: "What are the forms used for filing TDS returns?",
            text: `
            <div>
            <ul>Form 24Q: TDS on salaries</ul>
            <ul>Form 26Q: TDS on all payments other than salaries</ul>
            <ul>Form 27Q: TDS on payments to non-residents</ul>
            <ul>Form 27EQ: TCS (Tax Collected at Source)</ul>
            </div>`,
          },
          {
            id: 6,
            heading:
              "What happens if TDS is not deducted or deposited on time?",
            text: `Interest is charged for late deduction or deposit, and penalties may be imposed for non-compliance, including late filing of TDS returns and non-issuance of TDS certificates.`,
          },
          {
            id: 7,
            heading: " How can I file TDS returns?",
            text: `TDS returns can be filed online through the TRACES website or through authorized TIN-FCs. Ensure accurate details and timely submission to avoid penalties.`,
          },
          {
            id: 8,
            heading:
              "What are TDS certificates, and when should they be issued?",
            text: `TDS certificates (Form 16 and Form 16A) are issued to deductees as proof of TDS deducted and deposited. Form 16 is issued annually for salaries by June 15th, and Form 16A is issued quarterly for other payments within 15 days from the due date of filing TDS returns.`,
          },
          {
            id: 9,
            heading: "How can I correct errors in TDS returns?",
            text: `Errors in TDS returns can be corrected by filing a revised return. Use the correction facility on the TRACES website to update and resubmit the return.`,
          },
          {
            id: 10,
            heading:
              "What are the consequences of not issuing TDS certificates?",
            text: `A penalty of Rs. 100 per day is levied for each day of delay in issuing TDS certificates, subject to a maximum of the TDS amount.`,
          },
        ],
      },
      {
        planId: 35,
        label: "Form 16",
        link: "/contactUs",
        name: "form16",
        description:
          "Form 16 is a crucial document for salaried employees, issued by employers as proof of tax deducted at source (TDS) on salary income. It serves as a certificate of the TDS deducted and deposited with the government on behalf of the employee. Form 16 is essential for employees to file their income tax returns and claim any refunds due.",
        faq_Config: [
          {
            id: 1,
            heading: "What is Form 16?",
            text: `Form 16 is a certificate issued by employers to their employees, detailing the TDS deducted on salary income and deposited with the government.`,
          },
          {
            id: 2,
            heading: "Who is eligible to receive Form 16?",
            text: `All salaried employees whose employer has deducted TDS from their salary are eligible to receive Form 16.`,
          },
          {
            id: 3,
            heading: "When is Form 16 issued?",
            text: `Employers typically issue Form 16 by June 15th of the assessment year.`,
          },
          {
            id: 4,
            heading:
              " What should I do if I do not receive Form 16 from my employer?",
            text: `If you do not receive Form 16, contact your employer to request the document. Employers are legally required to provide Form 16 if TDS has been deducted.`,
          },
          {
            id: 5,
            heading: "Can I file my income tax return without Form 16?",
            text: `Yes, you can file your income tax return without Form 16 by using salary slips and other relevant documents to report your income and deductions. However, Form 16 simplifies the process.`,
          },
          {
            id: 6,
            heading: "What is the difference between Form 16 and Form 16A?",
            text: `Form 16 is issued for TDS on salary income, while Form 16A is issued for TDS on other incomes such as interest, rent, professional fees, etc.`,
          },
          {
            id: 7,
            heading: "How can I verify the accuracy of Form 16?",
            text: `Verify the accuracy of Form 16 by cross-checking the details with your salary slips, bank statements, and Form 26AS (tax credit statement).`,
          },
          {
            id: 8,
            heading: "What should I do if there are errors in Form 16?",
            text: `If there are errors in Form 16, contact your employer immediately to get the corrections made. Employers can issue a revised Form 16 if needed.`,
          },
          {
            id: 9,
            heading:
              "How can I use Form 16 to claim deductions and exemptions?",
            text: `Form 16 provides a detailed breakup of your salary, exemptions, and deductions, which can be used to accurately claim deductions and exemptions while filing your income tax return.`,
          },
          {
            id: 10,
            heading: "Is Form 16 required for applying for loans?",
            text: `Yes, Form 16 is often required when applying for loans as it serves as proof of income and tax compliance.
For more detailed information and assistance with Form 16 and filing your income tax return, contact us today. Our expert team is here to guide you through the process and ensure compliance with all tax regulations.
`,
          },
        ],
      },
    ],
  },
  {
    title: "Additional registration",
    icon: penWrite,
    serviceId: 3,
    links: [
      {
        planId: 17,
        label: "Professional Tax",
        link: "/contact-us",
        name: "additionalProTax",
        description:
          "Professional tax is a state-level tax imposed on individuals and entities engaged in professional or trade activities. Our professional tax registration services help businesses comply with professional tax laws by obtaining the required registrations and licences from the appropriate authorities. We guide you through the registration process, assist with tax calculations and filings, and ensure that your business remains compliant with professional tax regulations.",
        faq_Config: [
          {
            id: 1,
            heading: "What is Professional Tax?",
            text: `Professional Tax is a state-level tax imposed on individuals and entities engaged in professional or trade activities. It is levied by state governments in India, and the tax rates vary from state to state. The maximum tax that can be imposed is Rs. 2500/-.`,
          },
          {
            id: 2,
            heading: "Who needs to pay Professional Tax?",
            text: `Individuals engaged in professions, trades, callings, or employment are liable to pay Professional Tax. Employers are responsible for deducting Professional Tax from the salaries of their employees and remitting it to the state government.`,
          },
          {
            id: 3,
            heading: "Is Professional Tax Registration mandatory?",
            text: `Yes, Professional Tax Registration is mandatory for individuals and entities liable to pay Professional Tax. Employers must obtain registration and deduct Professional Tax from the salaries of their employees.`,
          },
          {
            id: 4,
            heading: "How do I register for Professional Tax?",
            text: `To register for Professional Tax, you need to apply to the appropriate state government authority responsible for administering Professional Tax. The registration process may vary from state to state but typically involves filling out an application form and submitting required documents such as PAN card, address proof, and incorporation certificate (for companies).
`,
          },
          {
            id: 5,
            heading:
              "What are the documents required for Professional Tax Registration?",
            text: `Common documents include PAN card, address proof, identity proof, and incorporation certificate (for companies). Employers may also need to provide details of employees and their salaries.`,
          },
          {
            id: 6,
            heading: "Is there a deadline for Professional Tax Registration?",
            text: `Deadlines for obtaining Professional Tax Registration vary depending on the state. It is advisable to check with the respective state government authority for specific deadlines and compliance requirements.`,
          },
          {
            id: 7,
            heading:
              "What are the consequences of non-compliance with Professional Tax regulations?",
            text: `Non-compliance with Professional Tax regulations may result in penalties and legal consequences, including fines and prosecution. Employers who fail to deduct and remit Professional Tax from the salaries of their employees may be liable for penalties imposed by the state government.`,
          },
          {
            id: 8,
            heading:
              "Do freelancers and self-employed individuals need to pay Professional Tax?",
            text: `Yes, freelancers and self-employed individuals engaged in professional or trade activities are liable to pay Professional Tax. They are required to obtain Professional Tax Registration and pay the tax directly to the state government.`,
          },
          {
            id: 9,
            heading: "Can Professional Tax Registration be cancelled?",
            text: `Yes, Professional Tax Registration can be cancelled under certain circumstances, such as cessation of business or closure of operations. Employers must apply for cancellation of registration to the appropriate state government authority and comply with any related requirements.`,
          },
          {
            id: 10,
            heading: "Is Professional Tax deductible as a business expense?",
            text: `Yes, Professional Tax paid by employers on behalf of their employees is generally treated as a deductible business expense for income tax purposes. However, it is advisable to consult with a tax advisor or accountant for specific guidance on tax`,
          },
        ],
      },
      {
        planId: 19,
        label: "FSSAI",
        link: "/contact-us",
        name: "additionalfssaiReg",
        description:
          "The Food Safety and Standards Authority of India (FSSAI) registration is mandatory for businesses involved in the manufacturing, processing, packaging, distribution, and sale of food products. We assist food businesses in obtaining FSSAI licences or registrations by guiding them through the application process, preparing required documents, and liaising with regulatory authorities. Our goal is to ensure compliance with food safety standards and regulations, thereby enhancing consumer trust and confidence in your products.",
        faq_Config: [
          {
            id: 1,
            heading: "What is FSSAI Registration?",
            text: `FSSAI registration is a mandatory requirement for all food businesses in India, as per the Food Safety and Standards Act, 2006. The Food Safety and Standards Authority of India (FSSAI) is the regulatory body responsible for ensuring the safety and quality of food products in the country.`,
          },
          {
            id: 2,
            heading: "Who needs to obtain FSSAI registration?",
            text: `Any individual or entity involved in the manufacturing, processing, packaging, distribution, sale, import, export, or storage of food products in India needs to obtain FSSAI registration.`,
          },
          {
            id: 3,
            heading: "What are the different types of FSSAI registration?",
            text: `FSSAI registration is available in three categories: Basic Registration, State FSSAI License, and Central FSSAI License, based on the size and scale of the food business.`,
          },
          {
            id: 4,
            heading: "Is an FSSAI license required for businesses?",
            text: `Yes, an FSSAI license is mandatory for all businesses involved in the production of food and food products. It's a legal requirement enforced by the government to ensure food safety standards are met.`,
          },
          {
            id: 5,
            heading: "How to renew your license after expiry?",
            text: `<div>
            <ul>
            <li>Fee Payment: Pay renewal fees online</li>
            <li>Verification and Approval: Wait for verification and approval by FSSAI authorities.
</li>
            <li>Compliance: Ensure ongoing compliance to maintain your renewed license</li>
            <li>Online Renewal: Log in to the FSSAI website, fill the renewal form, and upload documents.
</li>
            </ul>
            </div>`,
          },
          {
            id: 6,
            heading: "What are the different categories of licences?",
            text: ` <ul>
            <li>FSSAI Basic Registration</li>
            <li>State FSSAI License</li>
            <li>Central FSSAI License</li>
            </ul>`,
          },
          {
            id: 7,
            heading: "What is the fee for FSSAI License?",
            text: `<ul>
            <li>FSSAI Basic Registration: Fee of Rs 100.</li>
            <li>State FSSAI License: Fees between Rs 2,000 to 5,000 (depending on the type of business).</li>
            <li>Central FSSAI License: Fee of Rs 7,500.</li>
            </ul>`,
          },
          {
            id: 8,
            heading: "How to check the status of FSSAI registration?",
            text: `<ul>
            <li>Go to the official FSSAI website (www.fssai.gov.in).</li>
            <li>Find the "Check Application Status" option.</li>
            <li>Enter your application or registration number.</li>
            <li>Click "Submit" or "Check Status.</li>
            <li>Review the displayed status.</li>
            </ul>`,
          },
          {
            id: 9,
            heading: "Is FSSAI License mandatory for e-commerce sellers?",
            text: `Yes, FSSAI license is mandatory for e-commerce sellers who are engaged in the sale of food products. This includes online platforms that sell food items such as groceries, packaged foods, beverages, and other consumables. E-commerce sellers need to obtain FSSAI registration or license to ensure compliance with food safety regulations and maintain the quality and safety standards of the food products they sell. Failure to obtain an FSSAI license can lead to penalties and legal consequences.`,
          },
        ],
      },
      {
        planId: 21,
        label: "Udyam Registration",
        link: "/contact-us",
        name: "udyamRegistration",
        description:
          "Udyam Registration is an initiative introduced by the Government of India to facilitate the ease of doing business for Micro, Small, and Medium Enterprises (MSMEs). It is an online registration process designed to simplify the registration of businesses involved in manufacturing, production, processing, preservation, or service activities.",
        faq_Config: [
          {
            id: 1,
            heading: "What is Udyam Registration?",
            text: `Udyam Registration is an online registration process introduced by the Government of India to simplify the registration of Micro, Small, and Medium Enterprises (MSMEs). It replaced the earlier Udyog Aadhaar Registration process.`,
          },
          {
            id: 2,
            heading: "Who is eligible for Udyam registration?",
            text: `<div>
            <ul>
            <p>Eligible entities include:</p>
            <li>Proprietorships.</li>
            <li>Hindu Undivided Families (HUF)</li>
            <li>Partnerships</li>
            <li>One Person Companies</li>
            <li>Private Limited Companies</li>
            <li>Public Limited Companies</li>
            <li>Limited Liability Partnerships (LLP)</li>
            <li>Co-operative Societies</li>
            
            </ul>
            </div>`,
          },
          {
            id: 3,
            heading: "What are the benefits of Udyam registration?",
            text: `<div>
            <ul> 
            <li>Priority access to government tenders</li>
            <li>Easy access to collateral-free bank loans</li>
            <li>1% interest rate exemption on bank overdrafts</li>
            <li>Eligibility for tax rebates</li>
            <li>Priority consideration for licensing and certification</li>
            <li>Tariff and capital subsidies</li>
            <li>Discounts on electricity bills</li>
            <li>50% discount on government fees for trademarks and patent filing</li>
            </ul>
            </div>`,
          },
          {
            id: 4,
            heading: "What are the documents required for Udyam registration?",
            text: `<div>
            <ul> 
            <li>PAN Card</li>
            <li>Aadhaar Card</li>
            <li>Business address proof</li>
            <li>Bank account details</li>
          
            </ul>
            </div>`,
          },
        ],
      },
      {
        planId: 18,
        label: "Trade License",
        link: "/contact-us",
        name: "additionalTradeLicense",
        description:
          "A trade license is a mandatory requirement for conducting certain types of business activities in India. Our trade license services assist businesses in obtaining the necessary permits and licences from local authorities to operate legally. We help you navigate the complex process of trade license application, ensure compliance with local regulations, and obtain approvals efficiently, allowing you to focus on running your business.",
        faq_Config: [
          {
            id: 1,
            heading: "Which businesses need a trade license?",
            text: `All trading and manufacturing businesses require a trade license.`,
          },
          {
            id: 2,
            heading: "Where can I obtain a trade license?",
            text: `Trade licences can be obtained from the Municipal Corporation of the area where the business is located.`,
          },
          {
            id: 3,
            heading: "Is a trade license mandatory?",
            text: `Yes, businesses covered under the State Municipal Corporation Act must apply for a trade license.`,
          },
          {
            id: 4,
            heading:
              "Can I apply for a trade license if I am running my business from home?",
            text: `Yes, a trade license is required even for businesses operated from home.`,
          },
        ],
      },
      {
        planId: 20,
        label: "Import Export Code",
        link: "/contact-us",
        name: "importExportCode",
        description:
          "The Import Export Code (IEC), also known as the Importer Exporter Code, is a unique 10-digit code issued by the Directorate General of Foreign Trade (DGFT), Ministry of Commerce and Industry, Government of India. It is a mandatory requirement for individuals or businesses engaged in importing or exporting goods and services from India. An importer must ensure that the importing entity has both GST registration and IEC to clear customs.",
        faq_Config: [
          {
            id: 1,
            heading: "What is an Import Export Code (IEC)?",
            text: `The Import Export Code (IEC) is a 10-digit unique identification number issued by the Directorate General of Foreign Trade (DGFT), Ministry of Commerce and Industry, Government of India. It is mandatory for individuals or businesses engaged in importing or exporting goods and services from India.`,
          },
          {
            id: 2,
            heading: "Who needs to obtain an IEC?",
            text: `Any individual or entity involved in import-export activities, including manufacturers, traders, exporters, importers, service providers, and e-commerce operators, needs to obtain an IEC.`,
          },
          {
            id: 3,
            heading: "What is the validity period of an IEC?",
            text: `Once issued, the Import Export Code has a lifetime validity. There is no need for renewal as long as the business exists and the registration is not revoked or surrendered.`,
          },
          {
            id: 4,
            heading:
              "Is it mandatory to have an IEC for all import-export transactions?",
            text: `Yes, obtaining an IEC is mandatory for all import-export transactions. It is required for customs clearance, bank transactions, and availing various benefits and concessions under export promotion schemes.`,
          },
          {
            id: 5,
            heading: "How can I apply for an IEC?",
            text: `You can apply for an IEC online through the DGFT's official website or through authorized service providers. The application process involves filling out the prescribed form (ANF 2A), uploading required documents, and paying the requisite fee.`,
          },
          {
            id: 6,
            heading: "What documents are required for IEC application?",
            text: `Commonly required documents include PAN card, identity proof, address proof, bank certificate, and photographs of the applicant. Additional documents may be required based on the type of entity applying for IEC.
`,
          },
          {
            id: 7,
            heading: "Is there any fee for obtaining an IEC?",
            text: `Yes, there is a prescribed fee for obtaining an IEC, which can be paid online during the application process.`,
          },
          {
            id: 8,
            heading: "Can an IEC be suspended or cancelled?",
            text: `Yes, the DGFT reserves the right to suspend or cancel an IEC in case of non-compliance, submission of false information, or violation of import-export laws. Businesses whose IEC is suspended or cancelled are barred from engaging in import-export activities until the issue is resolved.`,
          },
        ],
      },
      {
        planId: 23,
        label: "Digital Signature",
        link: "/contact-us",
        name: "digitalSignature",
        description:
          "A digital signature is a cryptographic technique used to validate the authenticity and integrity of a digital message or document. It serves as a secure and reliable equivalent to a handwritten signature or a stamped seal, but it offers far greater security. Digital signatures are essential for secure online transactions, document signing, and ensuring the authenticity of electronic communications.",
        faq_Config: [
          {
            id: 1,
            heading: "What is a digital signature?",
            text: `A digital signature is a cryptographic technique used to validate the authenticity and integrity of a digital message or document, providing a secure and reliable equivalent to a handwritten signature or a stamped seal.`,
          },
          {
            id: 2,
            heading: "Why are digital signatures important?",
            text: `Digital signatures are important because they provide security, authenticity, integrity, and non-repudiation for digital documents and transactions, ensuring that they are secure and verifiable.`,
          },
          {
            id: 3,
            heading: "What are the types of digital signatures?",
            text: `<div>
            <ul>
            <li>Class 1: Basic level security, used for securing email communications.</li>
            <li>Class 2: Used for filing documents with government agencies.</li>
            <li>Class 3: High-level security, used for e-tendering, e-procurement, and other high-value transactions.</li>
            </ul>
            </div>
            `,
          },
          {
            id: 4,
            heading:
              "How do I apply for a Digital Signature Certificate (DSC)?",
            text: `To apply for a DSC, fill out the application form online or offline, submit the required documents, pay the applicable fees, and the DSC will be issued after verification.`,
          },
          {
            id: 5,
            heading: "What documents are required for a DSC?",
            text: `Commonly required documents include identity proof (PAN card, Aadhaar card, passport, etc.), address proof (Electricity bill, telephone bill, etc.), and a passport-size photograph.`,
          },
          {
            id: 6,
            heading: "Can digital signatures be used for legal documents?",
            text: `Yes, digital signatures can be used for legal documents, court submissions, affidavits, and other legal processes, ensuring their authenticity and integrity.`,
          },
          {
            id: 7,
            heading: "Is a digital signature secure?",
            text: `Yes, digital signatures provide a high level of security for online transactions and communications, protecting against tampering and fraud.`,
          },
          {
            id: 8,
            heading:
              "What is the validity period of a Digital Signature Certificate?",
            text: `The validity period of a DSC varies and can range from one to three years, depending on the issuing authority and the type of certificate.`,
          },
          {
            id: 9,
            heading: "Can I renew my Digital Signature Certificate?",
            text: `Yes, you can renew your DSC by following the renewal process, which typically involves submitting a renewal application, providing the necessary documents, and paying the renewal fee.`,
          },
        ],
      },
      {
        planId: 22,
        label: "Startup India Registration ",
        link: "/contact-us",
        name: "startupIndia",
        description:
          "Startup India is a flagship initiative launched by the Government of India to build a strong ecosystem for nurturing innovation and startups in the country. The initiative aims to drive sustainable economic growth and generate large-scale employment opportunities. Through this initiative, startups can gain official recognition, access various benefits, and integrate into the national startup ecosystem.",
        faq_Config: [
          {
            id: 1,
            heading: "What is Startup India Registration?",
            text: `Startup India Registration is an online registration process introduced by the Government of India to identify and support startups, promote innovation, and drive sustainable economic growth and job creation.`,
          },
          {
            id: 2,
            heading: "Who is eligible for Startup India Registration?",
            text: `Eligible entities include Private Limited Companies, Limited Liability Partnerships, and Registered Partnership Firms that are not older than 10 years and have an annual turnover not exceeding Rs. 100 crore.`,
          },
          {
            id: 3,
            heading: "What are the benefits of Startup India Registration?",
            text: `<div>
            <ul>
            <li>Priority access to government tenders</li>
            <li>Easy access to collateral-free bank loans</li>
            <li>1% interest rate exemption on bank overdrafts</li>
            <li>Eligibility for tax rebates</li>
            <li>Priority consideration for licensing and certification</li>
            <li>Tariff and capital subsidies</li>
            <li>Discounts on electricity bills</li>
            <li>50% discount on government fees for trademarks and patent filing</li>
            </ul>
            </div>
            `,
          },
          {
            id: 4,
            heading:
              "What documents are required for Startup India Registration?",
            text: `<div>
            <ul>
            <li>Certificate of Incorporation</li>
            <li>PAN Card of the business</li>
            <li>Aadhaar Card of the founder</li>
            <li>Business plan or pitch deck</li>
            <li>Proof of concept or prototype development (if applicable)</li>
            <li>Financial statements (if applicable)</li>
            <li>Details of patents or trademarks (if applicable)</li>
           
            </ul>
            </div>`,
          },
          {
            id: 5,
            heading: "How do I apply for Startup India Registration?",
            text: `To apply, visit the Startup India website, fill out the registration form, submit the required documents, and wait for verification. Upon successful verification, a Certificate of Recognition will be issued.
`,
          },
          {
            id: 6,
            heading:
              "Can an existing business apply for Startup India Registration?",
            text: `Yes, an existing business can apply for Startup India Registration, provided it meets the eligibility criteria regarding age, turnover, and innovation.`,
          },
          {
            id: 7,
            heading: "Is there a fee for Startup India Registration?",
            text: `No, there is no fee for registering under the Startup India initiative.`,
          },
          {
            id: 8,
            heading:
              "What happens after my startup is recognized under Startup India?",
            text: `After recognition, the startup can access various benefits, including funding opportunities, tax exemptions, and support from government schemes designed to promote growth and innovation.`,
          },
        ],
      },
    ],
  },
];
export const vpobServices = [
  {
    stateId: 7,
    label: "Assam",
    icon: assam,
    link: "/contact-us",
    name: "assam",
    description:
      "Assam, with its rapidly growing economy, offers numerous opportunities for virtual offices. The cost of maintaining a physical office can be high, but a virtual office with Easy Works provides a cost-effective alternative. Our services include seamless GST registrations, professional mail handling, compliance services, and call handling, ensuring your virtual office in Assam operates efficiently. Let us help you establish a strong business presence in Assam with ease!",

    faq_Config: [
      {
        id: 1,
        heading: "What is a virtual office, and how does it work in Assam?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 2,
        heading: "What are the benefits of having a virtual office in Assam?",
        text: "Benefits include a prestigious business address in Assam, flexibility, and cost savings on physical office space.",
      },
      {
        id: 3,
        heading:
          "Do I need a physical office to use a virtual office in Assam?",
        text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
      },
      {
        id: 4,
        heading:
          "What are the compliance requirements for a virtual office in Assam?",
        text: "Register your business address with relevant authorities and follow local regulations.",
      },
      {
        id: 5,
        heading:
          "Can I use the virtual office address for company registration in Assam?",
        text: "Yes, our address can be used for company registration in Assam.",
      },
      {
        id: 6,
        heading:
          "Are there any specific licences or permits required for a virtual office in Assam?",
        text: "Generally, no extra licences are needed beyond regular business requirements.",
      },
      {
        id: 7,
        heading:
          "How does the GST advisory service work with a virtual office in Assam?",
        text: "We provide GST compliance support, filing assistance, and tax advice.",
      },
      {
        id: 8,
        heading:
          "Is a registered rent agreement required for GST registration in Assam?",
        text: "Yes, a registered rent agreement is mandatory for GST registration in Assam.",
      },
    ],
  },
  {
    stateId: 1,
    label: "Haryana",
    icon: haryana,
    link: "/contact-us",
    name: "haryana",
    description:
      "Haryana, with its burgeoning industrial and commercial growth, offers a strategic location for virtual offices. The high cost of physical office spaces in key cities like Gurgaon makes a virtual address a smart choice for businesses. Easy Works provides seamless GST registrations, professional mail handling, compliance services, and call handling, ensuring your virtual office in Haryana is as efficient as a physical one. Let us help you gain a competitive edge with a virtual office in Haryana!",

    faq_Config: [
      {
        id: 1,
        heading: "What is a virtual office, and how does it work in Haryana?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 2,
        heading: "What are the benefits of having a virtual office in Haryana?",
        text: "Benefits include a prestigious business address in Haryana, flexibility, and cost savings on physical office space.",
      },
      {
        id: 3,
        heading:
          "Do I need a physical office to use a virtual office in Haryana?",
        text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
      },
      {
        id: 4,
        heading:
          "What are the compliance requirements for a virtual office in Haryana?",
        text: "Register your business address with relevant authorities and follow local regulations.",
      },
      {
        id: 5,
        heading:
          "Can I use the virtual office address for company registration in Haryana?",
        text: "Yes, our address can be used for company registration in Haryana. We provide a legitimate address that meets the requirements for business registration.",
      },
      {
        id: 6,
        heading:
          "Are there any specific licences or permits required for a virtual office in Haryana?",
        text: "Generally, no extra licences are needed beyond regular business requirements.",
      },
      {
        id: 7,
        heading:
          "How does the GST advisory service work with a virtual office in Haryana?",
        text: "We provide GST compliance support, filing assistance, and tax advice.",
      },
      {
        id: 8,
        heading:
          "Is a registered rent agreement required for GST registration in Haryana?",
        text: "No, a registered rent agreement is not mandatory for GST registration in Haryana.",
      },
    ],
  },
  {
    stateId: 2,
    label: "Karnataka",
    icon: Karnataka,
    link: "/contact-us",
    name: "karnataka",
    description:
      "Karnataka, with Bangalore as its tech hub, is a strategic location for virtual offices. The high cost of office spaces in Bangalore makes a virtual office a smart choice for businesses looking to expand. Easy Works provides seamless GST registrations, professional mail handling, compliance services, and call handling to ensure your virtual office in Karnataka operates smoothly. Establish your business presence in Karnataka with our virtual office solutions!",

    faq_Config: [
      {
        id: 9,
        heading: "What is a virtual office, and how does it work in Karnataka?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 10,
        heading:
          "What are the benefits of having a virtual office in Karnataka?",
        text: "Benefits include a prestigious business address in Karnataka, flexibility, and cost savings on physical office space.",
      },
      {
        id: 11,
        heading:
          "Do I need a physical office to use a virtual office in Karnataka?",
        text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
      },
      {
        id: 12,
        heading:
          "What are the compliance requirements for a virtual office in Karnataka?",
        text: "Register your business address with relevant authorities and follow local regulations.",
      },
      {
        id: 13,
        heading:
          "Can I use the virtual office address for company registration in Karnataka?",
        text: "Yes, our address can be used for company registration in Karnataka.",
      },
      {
        id: 14,
        heading:
          "Are there any specific licences or permits required for a virtual office in Karnataka?",
        text: "Generally, no extra licences are needed beyond regular business requirements.",
      },
      {
        id: 15,
        heading:
          "How does the GST advisory service work with a virtual office in Karnataka?",
        text: "We provide GST compliance support, filing assistance, and tax advice.",
      },
      {
        id: 16,
        heading:
          "Is a registered rent agreement required for GST registration in Karnataka?",
        text: "No, a registered rent agreement is not mandatory for GST registration in Karnataka.",
      },
    ],
  },
  {
    stateId: 12,
    label: "Delhi",
    icon: Delhi,
    link: "/contact-us",
    name: "delhi",
    description: `Delhi stands as a pivotal location where startups and small businesses strive to establish their virtual offices. The cost of renting a physical office in Delhi is notably high, prompting many brands to opt for a virtual address in the nation's capital. With seamless GST registrations, professional mail handling, compliance services, and professional call handling, securing a virtual office in Delhi becomes the ideal solution for establishing a presence. Let Easy Works help you unlock all the advantages of having a virtual office in Delhi!`,

    faq_Config: [
      {
        id: 1,
        heading: "What is a virtual office, and how does it work in Delhi?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 2,
        heading: "What are the benefits of having a virtual office in Delhi?",
        text: "Benefits include a prestigious business address in Delhi, flexibility in working arrangements, cost savings on physical office space.",
      },
      {
        id: 3,
        heading:
          "Do I need a physical office to use a virtual office in Delhi?",
        text: "No, you do not need a physical office. A virtual office provides you with all the benefits of a physical office without the need for a permanent space.",
      },
      {
        id: 4,
        heading:
          "What are the compliance requirements for a virtual office in Delhi?",
        text: "Compliance requirements include registering your business address with the relevant authorities, maintaining proper records, and adhering to local business regulations.",
      },
      {
        id: 5,
        heading:
          "Can I use the virtual office address for company registration in Delhi?",
        text: "Yes, you can use our virtual office address for company registration in Delhi. We provide a legitimate address that meets the requirements for business registration.",
      },
      {
        id: 6,
        heading:
          "Are there any specific licences or permits required for a virtual office in Delhi?",
        text: "No additional licences or permits are needed beyond what is required for your business. However, depending on your business activities, you might need specific permits or registrations.",
      },
      {
        id: 7,
        heading:
          "How does the GST advisory service work with a virtual office in Delhi?",
        text: "Our GST advisory service helps you navigate GST regulations, file returns, and ensure compliance with tax laws.",
      },
      {
        id: 8,
        heading:
          "Is a registered rent agreement required for GST registration in Delhi?",
        text: "No, a registered rent agreement is not mandatory for GST registration in Delhi.",
      },
    ],
  },
  {
    stateId: 13,
    label: "Uttar Pradesh",
    icon: UP,
    link: "/contact-us",
    name: "up",
    description:
      "Uttar Pradesh, being one of the largest states in India, is a hotspot for business expansion. The cost of physical office spaces can be prohibitive, especially in major cities like Noida and Lucknow. A virtual office with Easy Works offers a cost-effective solution, complete with seamless GST registrations, professional mail handling, compliance services, and call handling. Establish your business presence in Uttar Pradesh effortlessly with our virtual office services!",

    faq_Config: [
      {
        id: 1,
        heading:
          "What is a virtual office, and how does it work in Uttar Pradesh?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 2,
        heading:
          "What are the benefits of having a virtual office in Uttar Pradesh?",
        text: "Benefits include a prestigious business address in Uttar Pradesh, flexibility, and cost savings on physical office space.",
      },
      {
        id: 3,
        heading:
          "Do I need a physical office to use a virtual office in Uttar Pradesh?",
        text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
      },
      {
        id: 4,
        heading:
          "What are the compliance requirements for a virtual office in Uttar Pradesh?",
        text: "Register your business address with relevant authorities and follow local regulations.",
      },
      {
        id: 5,
        heading:
          "Can I use the virtual office address for company registration in Uttar Pradesh?",
        text: "Yes, our address can be used for company registration in Uttar Pradesh.",
      },
      {
        id: 6,
        heading:
          "Are there any specific licences or permits required for a virtual office in Uttar Pradesh?",
        text: "Generally, no extra licences are needed beyond regular business requirements.",
      },
      {
        id: 7,
        heading:
          "How does the GST advisory service work with a virtual office in Uttar Pradesh?",
        text: "We provide GST compliance support, filing assistance, and tax advice.",
      },
      {
        id: 8,
        heading:
          "Is a registered rent agreement required for GST registration in Uttar Pradesh?",
        text: "No, a registered rent agreement is not mandatory for GST registration in Uttar Pradesh.",
      },
    ],
  },
  {
    stateId: 14,
    label: "Madhya Pradesh",
    icon: MP,
    link: "/contact-us",
    name: "mp",
    description:
      "Madhya Pradesh, located in the heart of India, is an emerging business destination. The expenses associated with physical office spaces can be significant, but a virtual office provides a cost-effective solution. Easy Works offers seamless GST registrations, professional mail handling, compliance services, and call handling to make your virtual office in Madhya Pradesh efficient and effective. Establish your business presence in Madhya Pradesh with our virtual office services!",

    faq_Config: [
      {
        id: 1,
        heading:
          "What is a virtual office, and how does it work in Madhya Pradesh?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 2,
        heading:
          "What are the benefits of having a virtual office in Madhya Pradesh?",
        text: "Benefits include a prestigious business address in Madhya Pradesh, flexibility, and cost savings on physical office space.",
      },
      {
        id: 3,
        heading:
          "Do I need a physical office to use a virtual office in Madhya Pradesh?",
        text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
      },
      {
        id: 4,
        heading:
          "What are the compliance requirements for a virtual office in Madhya Pradesh?",
        text: "Register your business address with relevant authorities and follow local regulations.",
      },
      {
        id: 5,
        heading:
          "Can I use the virtual office address for company registration in Madhya Pradesh?",
        text: "Yes, our address can be used for company registration in Madhya Pradesh.",
      },
      {
        id: 6,
        heading:
          "Are there any specific licences or permits required for a virtual office in Madhya Pradesh?",
        text: "Generally, no extra licences are needed beyond regular business requirements.",
      },
      {
        id: 7,
        heading:
          "How does the GST advisory service work with a virtual office in Madhya Pradesh?",
        text: "We provide GST compliance support, filing assistance, and tax advice.",
      },
      {
        id: 8,
        heading:
          "Is a registered rent agreement required for GST registration in Madhya Pradesh?",
        text: "No, a registered rent agreement is not mandatory for GST registration in Madhya Pradesh.",
      },
    ],
  },
  {
    stateId: 5,
    label: "Gujarat",
    icon: Gujarat,
    link: "/contact-us",
    name: "gujarat",
    description:
      "Gujarat, known for its business-friendly environment, is an ideal place for virtual offices. The high cost of physical office spaces in cities like Ahmedabad can be a challenge for many businesses. Easy Works provides virtual office solutions, including seamless GST registrations, professional mail handling, compliance services, and call handling, to help you establish a strong business presence in Gujarat. Explore the benefits of a virtual office in Gujarat with us!",

    faq_Config: [
      {
        id: 1,
        heading: "What is a virtual office, and how does it work in Gujarat?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 2,
        heading: "What are the benefits of having a virtual office in Gujarat?",
        text: "Benefits include a prestigious business address in Gujarat, flexibility, and cost savings on physical office space.",
      },
      {
        id: 3,
        heading:
          "Do I need a physical office to use a virtual office in Gujarat?",
        text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
      },
      {
        id: 4,
        heading:
          "What are the compliance requirements for a virtual office in Gujarat?",
        text: "Register your business address with relevant authorities and follow local regulations.",
      },
      {
        id: 5,
        heading:
          "Can I use the virtual office address for company registration in Gujarat?",
        text: "Yes, our address can be used for company registration in Gujarat.",
      },
      {
        id: 6,
        heading:
          "Are there any specific licences or permits required for a virtual office in Gujarat?",
        text: "Generally, no extra licences are needed beyond regular business requirements.",
      },
      {
        id: 7,
        heading:
          "How does the GST advisory service work with a virtual office in Gujarat?",
        text: "Our GST advisory service helps you navigate GST regulations, file returns, and ensure compliance with tax laws.",
      },
      {
        id: 8,
        heading:
          "Is a registered rent agreement required for GST registration in Gujarat?",
        text: "No, a registered rent agreement is not mandatory for GST registration in Gujarat.",
      },
      {
        id: 9,
        heading:
          "Is biometric verification mandatory for GST Registration in Gujarat?",
        text: "Yes, biometric verification is mandatory for GST registration in Gujarat.",
      },
    ],
  },
  {
    stateId: 3,
    label: "Telangana",
    icon: Telangana,
    link: "/contact-us",
    name: "telangana",
    description:
      "Telangana, with Hyderabad as its bustling business hub, is a prime location for setting up virtual offices. The high demand for office space in Hyderabad drives up costs, making a virtual office a more viable option. Easy Works offers comprehensive virtual office solutions, including seamless GST registrations, professional mail handling, compliance services, and call handling to help your business thrive in Telangana. Explore the benefits of a virtual office in Telangana with us!",

    faq_Config: [
      {
        id: 1,
        heading: "What is a virtual office, and how does it work in Telangana?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 2,
        heading:
          "What are the benefits of having a virtual office in Telangana?",
        text: "Benefits include a prestigious business address in Telangana, flexibility, and cost savings on physical office space.",
      },
      {
        id: 3,
        heading:
          "Do I need a physical office to use a virtual office in Telangana?",
        text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
      },
      {
        id: 4,
        heading:
          "What are the compliance requirements for a virtual office in Telangana?",
        text: "Register your business address with relevant authorities and follow local regulations.",
      },
      {
        id: 5,
        heading:
          "Can I use the virtual office address for company registration in Telangana?",
        text: "Yes, our address can be used for company registration in Telangana.",
      },
      {
        id: 6,
        heading:
          "Are there any specific licences or permits required for a virtual office in Telangana?",
        text: "Generally, no extra licences are needed beyond regular business requirements.",
      },
      {
        id: 7,
        heading:
          "How does the GST advisory service work with a virtual office in Telangana?",
        text: "We provide GST compliance support, filing assistance, and tax advice.",
      },
      {
        id: 8,
        heading:
          "Is a registered rent agreement required for GST registration in Telangana?",
        text: "No, a registered rent agreement is not mandatory for GST registration in Telangana.",
      },
    ],
  },
  {
    stateId: 4,
    label: "Maharashtra",
    icon: Maharashtra,
    link: "/contact-us",
    name: "maharashtra",
    description:
      "Maharashtra, with Mumbai as its financial hub, is a prime location for virtual offices. The cost of renting a physical office in Mumbai is significantly high, making a virtual office an attractive alternative. Easy Works offers seamless GST registrations, professional mail handling, compliance services, and call handling to ensure your virtual office in Maharashtra operates smoothly. Establish your business presence in Maharashtra with our virtual office solutions!",

    faq_Config: [
      {
        id: 1,
        heading:
          "What is a virtual office, and how does it work in Maharashtra?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 2,
        heading:
          "What are the benefits of having a virtual office in Maharashtra?",
        text: "Benefits include a prestigious business address in Maharashtra, flexibility, and cost savings on physical office space.",
      },
      {
        id: 3,
        heading:
          "Do I need a physical office to use a virtual office in Maharashtra?",
        text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
      },
      {
        id: 4,
        heading:
          "What are the compliance requirements for a virtual office in Maharashtra?",
        text: "Register your business address with relevant authorities and follow local regulations.",
      },
      {
        id: 5,
        heading:
          "Can I use the virtual office address for company registration in Maharashtra?",
        text: "Yes, our address can be used for company registration in Maharashtra.",
      },
      {
        id: 6,
        heading:
          "Are there any specific licences or permits required for a virtual office in Maharashtra?",
        text: "Generally, no extra licences are needed beyond regular business requirements.",
      },
      {
        id: 7,
        heading:
          "How does the GST advisory service work with a virtual office in Maharashtra?",
        text: "We provide GST compliance support, filing assistance, and tax advice.",
      },
      {
        id: 8,
        heading:
          "Is a registered rent agreement required for GST registration in Maharashtra?",
        text: "Yes, a registered rent agreement is mandatory for GST registration in Maharashtra.",
      },
    ],
  },
  {
    stateId: 9,
    label: "Punjab",
    icon: Punjab,
    link: "/contact-us",
    name: "punjab",
    description:
      "Punjab, with its thriving business environment, is an excellent location for virtual offices. The high cost of physical office spaces in cities like Chandigarh makes a virtual office a practical choice. Easy Works offers comprehensive virtual office solutions, including seamless GST registrations, professional mail handling, compliance services, and call handling, to help your business grow in Punjab. Discover the benefits of a virtual office in Punjab with us!",

    faq_Config: [
      {
        id: 1,
        heading: "What is a virtual office, and how does it work in Punjab?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 2,
        heading: "What are the benefits of having a virtual office in Punjab?",
        text: "Benefits include a prestigious business address in Punjab, flexibility, and cost savings on physical office space.",
      },
      {
        id: 3,
        heading:
          "Do I need a physical office to use a virtual office in Punjab?",
        text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
      },
      {
        id: 4,
        heading:
          "What are the compliance requirements for a virtual office in Punjab?",
        text: "Compliance requirements include registering your business address with relevant authorities and adhering to local business regulations.",
      },
      {
        id: 5,
        heading:
          "Can I use the virtual office address for company registration in Punjab?",
        text: "Yes, you can use our virtual office address for company registration in Punjab.",
      },
      {
        id: 6,
        heading:
          "Are there any specific licences or permits required for a virtual office in Punjab?",
        text: "No additional licences are needed beyond your business requirements. Specific permits might be required based on your business activities.",
      },
      {
        id: 7,
        heading:
          "How does the GST advisory service work with a virtual office in Punjab?",
        text: "Our GST advisory service helps you navigate GST regulations, file returns, and ensure compliance with tax laws.",
      },
      {
        id: 8,
        heading:
          "Is a registered rent agreement required for GST registration in Punjab?",
        text: "No, a registered rent agreement is not mandatory for GST registration in Punjab.",
      },
    ],
  },
  {
    stateId: 10,
    label: "Rajasthan",
    icon: Rajasthan,
    link: "/contact-us",
    name: "rajasthan",
    description:
      "Rajasthan, known for its vibrant economy, is an excellent place to set up a virtual office. The high costs of physical office spaces in cities like Jaipur can be a barrier for many businesses. A virtual office with Easy Works offers a practical solution, complete with seamless GST registrations, professional mail handling, compliance services, and call handling. Let us help you establish a strong foothold in Rajasthan with our virtual office solutions!",

    faq_Config: [
      {
        id: 1,
        heading: "What is a virtual office, and how does it work in Rajasthan?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 2,
        heading:
          "What are the benefits of having a virtual office in Rajasthan?",
        text: "Benefits include a prestigious business address in Rajasthan, flexibility, and cost savings on physical office space.",
      },
      {
        id: 3,
        heading:
          "Do I need a physical office to use a virtual office in Rajasthan?",
        text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
      },
      {
        id: 4,
        heading:
          "What are the compliance requirements for a virtual office in Rajasthan?",
        text: "Register your business address with relevant authorities and follow local regulations.",
      },
      {
        id: 5,
        heading:
          "Can I use the virtual office address for company registration in Rajasthan?",
        text: "Yes, our address can be used for company registration in Rajasthan.",
      },
      {
        id: 6,
        heading:
          "Are there any specific licences or permits required for a virtual office in Rajasthan?",
        text: "Generally, no extra licences are needed beyond regular business requirements.",
      },
      {
        id: 7,
        heading:
          "How does the GST advisory service work with a virtual office in Rajasthan?",
        text: "We provide GST compliance support, filing assistance, and tax advice.",
      },
      {
        id: 8,
        heading:
          "Is a registered rent agreement required for GST registration in Rajasthan?",
        text: "No, a registered rent agreement is not mandatory for GST registration in Rajasthan.",
      },
    ],
  },
  {
    stateId: 8,
    label: "Tamil Nadu",
    icon: TN,
    link: "/contact-us",
    name: "tn",
    description:
      "Tamil Nadu, with Chennai as its economic powerhouse, is a strategic location for virtual offices. The high cost of office spaces in Chennai makes a virtual office a smart choice for businesses looking to expand. Easy Works provides comprehensive virtual office solutions, including seamless GST registrations, professional mail handling, compliance services, and call handling, to help your business flourish in Tamil Nadu. Discover the benefits of a virtual office in Tamil Nadu with Easy Works!",

    faq_Config: [
      {
        id: 1,
        heading:
          "What is a virtual office, and how does it work in Tamil Nadu?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 2,
        heading:
          "What are the benefits of having a virtual office in Tamil Nadu?",
        text: "Benefits include a prestigious business address in Tamil Nadu, flexibility, and cost savings on physical office space.",
      },
      {
        id: 3,
        heading:
          "Do I need a physical office to use a virtual office in Tamil Nadu?",
        text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
      },
      {
        id: 4,
        heading:
          "What are the compliance requirements for a virtual office in Tamil Nadu?",
        text: "Register your business address with relevant authorities and follow local regulations.",
      },
      {
        id: 5,
        heading:
          "Can I use the virtual office address for company registration in Tamil Nadu?",
        text: "Yes, our address can be used for company registration in Tamil Nadu.",
      },
      {
        id: 6,
        heading:
          "Are there any specific licences or permits required for a virtual office in Tamil Nadu?",
        text: "Generally, no extra licences are needed beyond regular business requirements.",
      },
      {
        id: 7,
        heading:
          "How does the GST advisory service work with a virtual office in Tamil Nadu?",
        text: "We provide GST compliance support, filing assistance, and tax advice.",
      },
      {
        id: 8,
        heading:
          "Is a registered rent agreement required for GST registration in Tamil Nadu?",
        text: "No, a registered rent agreement is not mandatory for GST registration in Tamil Nadu.",
      },
    ],
  },
  {
    stateId: 6,
    label: "West Bengal",
    icon: WB,
    link: "/contact-us",
    name: "wb",
    description:
      "West Bengal, with Kolkata as its commercial center, offers numerous opportunities for virtual offices. The cost of physical office spaces in Kolkata can be prohibitive, making a virtual office a cost-effective solution. Easy Works provides seamless GST registrations, professional mail handling, compliance services, and call handling to ensure your virtual office in West Bengal is efficient and effective. Let us help you establish a strong business presence in West Bengal with our virtual office solutions!",

    faq_Config: [
      {
        id: 1,
        heading:
          "What is a virtual office, and how does it work in West Bengal?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 2,
        heading:
          "What are the benefits of having a virtual office in West Bengal?",
        text: "Benefits include a prestigious business address in West Bengal, flexibility, and cost savings on physical office space.",
      },
      {
        id: 3,
        heading:
          "Do I need a physical office to use a virtual office in West Bengal?",
        text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
      },
      {
        id: 4,
        heading:
          "What are the compliance requirements for a virtual office in West Bengal?",
        text: "Register your business address with relevant authorities and follow local regulations.",
      },
      {
        id: 5,
        heading:
          "Can I use the virtual office address for company registration in West Bengal?",
        text: "Yes, our address can be used for company registration in West Bengal.",
      },
      {
        id: 6,
        heading:
          "Are there any specific licences or permits required for a virtual office in West Bengal?",
        text: "Generally, no extra licences are needed beyond regular business requirements.",
      },
      {
        id: 7,
        heading:
          "How does the GST advisory service work with a virtual office in West Bengal?",
        text: "We provide GST compliance support, filing assistance, and tax advice.",
      },
      {
        id: 8,
        heading:
          "Is a registered rent agreement required for GST registration in West Bengal?",
        text: "No, a registered rent agreement is not mandatory for GST registration in West Bengal.",
      },
    ],
  },
  {
    stateId: 11,
    label: "Bihar",
    icon: Bihar,
    link: "/contact-us",
    name: "bihar",
    description:
      "Bihar, with its rapidly growing economy, presents numerous opportunities for businesses. The cost of maintaining a physical office can be high, but a virtual office with Easy Works offers a practical alternative. Our services include seamless GST registrations, professional mail handling, compliance services, and call handling, ensuring your virtual office in Bihar operates smoothly. Let us help you establish a strong business presence in Bihar with ease!",

    faq_Config: [
      {
        id: 1,
        heading: "What is a virtual office, and how does it work in Bihar?",
        text: "A virtual office provides a business address and communication services without requiring a physical office space.",
      },
      {
        id: 2,
        heading: "What are the benefits of having a virtual office in Bihar?",
        text: "Benefits include a prestigious business address in Bihar, flexibility, and cost savings on physical office space.",
      },
      {
        id: 3,
        heading:
          "Do I need a physical office to use a virtual office in Bihar?",
        text: "No, you do not need a physical office. A virtual office offers all the benefits without a permanent space.",
      },
      {
        id: 4,
        heading:
          "What are the compliance requirements for a virtual office in Bihar?",
        text: "Register your business address with relevant authorities and follow local regulations.",
      },
      {
        id: 5,
        heading:
          "Can I use the virtual office address for company registration in Bihar?",
        text: "Yes, our address can be used for company registration in Bihar.",
      },
      {
        id: 6,
        heading:
          "Are there any specific licences or permits required for a virtual office in Bihar?",
        text: "Generally, no extra licences are needed beyond regular business requirements.",
      },
      {
        id: 7,
        heading:
          "How does the GST advisory service work with a virtual office in Bihar?",
        text: "We provide GST compliance support, filing assistance, and tax advice.",
      },
      {
        id: 8,
        heading:
          "Is a registered rent agreement required for GST registration in Bihar?",
        text: "No, a registered rent agreement is not mandatory for GST registration in Bihar.",
      },
    ],
  },
];
export const gstAdvisoryServices = [
  {
    serviceId: 6,
    planId: 36,
    label: "GST Registration",
    name: "gstRegistration",
    link: "/contact-us",
    description:
      "GST registration is the process by which businesses or individuals register themselves under the Goods and Services Tax (GST) regime. It is mandatory for entities involved in the supply of goods or services with an aggregate turnover above the threshold limit specified by the government.",

    faq_Config: [
      {
        id: 1,
        heading: "What is GST registration?",
        text: `GST registration is the process by which businesses or individuals register themselves under the Goods and Services Tax (GST) regime. It is mandatory for entities involved in the supply of goods or services with an aggregate turnover above the threshold limit specified by the government.`,
      },
      {
        id: 2,
        heading: "Who needs to register for GST?",
        text: `
            <div>
             
            <ul>
            <li>Businesses engaged in taxable supplies with turnover exceeding the threshold limit. </li>
            <li>E-commerce operators, inter-state suppliers, and certain other categories mandated by the government. </li>
          
            </ul>
            </div>`,
      },
      {
        id: 3,
        heading: "What is the threshold turnover for GST registration?",
        text: `
            <div>
              
            <ul>
            <li>The threshold limit is Rs. 40 lacs or higher for the manufacturing sector.</li>
            <li>The service sector has a threshold of Rs. 20 lacs or higher. </li>
            <li>The threshold limit is Rs. 10 lacs or more for particular category states.</li>
            </ul>
            </div>`,
      },
      {
        id: 4,
        heading:
          "Can businesses below the threshold turnover voluntarily register for GST?",
        text: `Yes, businesses below the threshold turnover can opt for voluntary registration to avail benefits like input tax credit and interstate trade participation.`,
      },
      {
        id: 5,
        heading: "What documents are required for GST registration?",
        text: `PAN card, identity/address proof, business registration documents, bank account details, and digital signature (for certain entities).`,
      },
      {
        id: 6,
        heading: "How long does it take to get GST registration?",
        text: `The processing time varies, but typically it takes 7 to 14 working days for GST registration.`,
      },
      {
        id: 7,
        heading: "What happens if I don't register for GST?",
        text: `Non-registration or non-compliance with GST regulations may result in penalties and legal consequences.`,
      },
      {
        id: 8,
        heading: "What is GSTIN, and how is it obtained?",
        text: `GSTIN is the unique 15-digit Goods and Services Tax Identification Number assigned to registered taxpayers. It is obtained upon successful GST registration.`,
      },
      {
        id: 9,
        heading: "Is there a fee for GST registration?",
        text: `No, there is no fee for GST registration as of now.`,
      },
      {
        id: 10,
        heading: "Do I need separate GST registration for each state?",
        text: `Yes, businesses operating in multiple states need to obtain separate GST registration for each state they operate in.`,
      },
      {
        id: 11,
        heading: "Can I amend my GST registration details after registration?",
        text: `Yes, you can amend certain details like address, contact information, etc., after registration through the GST portal.`,
      },
    ],
  },
  {
    serviceId: 6,
    planId: 37,
    label: "GST APOB",
    name: "gstAPOB",
    description:
      "At Easy Works, we offer comprehensive GST Additional Place of Business (APOB) services to help you efficiently manage and register multiple business locations. Registering all your business locations under GST is crucial for compliance, seamless operations, and avoiding penalties. Our expert team ensures that your APOB registrations are handled efficiently, ensuring full compliance with GST regulations.",

    faq_Config: [
      {
        id: 1,
        heading: "What is an Additional Place of Business (APOB)?",
        text: `An Additional Place of Business (APOB) refers to any location, other than the principal place of business, where a taxpayer carries out business activities. This includes branches, warehouses, and storage facilities.`,
      },
      {
        id: 2,
        heading: "Why is it important to register an APOB under GST?",
        text: `Registering an APOB ensures compliance with GST regulations, allows for seamless business operations, and enables the claiming of input tax credits for expenses incurred at these locations.`,
      },
      {
        id: 3,
        heading: "What documents are required for registering an APOB?",
        text: `
            <div>
              <p>
            Common documents include:
              </p>
            <ul>
            <li>Proof of ownership or lease agreement for the additional location.</li>
            <li>No Objection Certificate (NOC) from the property owner.</li>
            <li>Electricity bill or other utility bills for address verification.</li>
            <li>GST registration certificate of the principal place of business.</li>         
            </ul>
            </div>`,
      },
      {
        id: 4,
        heading: "How long does it take to register an APOB under GST?",
        text: `The registration process typically takes 5-7 working days, depending on the accuracy of the documentation and the response from the GST authorities.`,
      },
      {
        id: 5,
        heading:
          " Can I operate from an additional place of business without registering it under GST?",
        text: `No, operating from an unregistered location is a violation of GST laws and can attract penalties and legal consequences.`,
      },
      {
        id: 6,
        heading: "How do I update my GST registration with a new APOB?",
        text: `You can update your GST registration by filing Form GST REG-14 through the GST portal. This form allows you to add, delete, or modify the details of your business locations.`,
      },
      {
        id: 7,
        heading: "Is there a fee for registering an APOB under GST?",
        text: `There is no government fee for registering an APOB under GST. However, professional fees may apply if you engage a consultant for the registration process.`,
      },
      {
        id: 8,
        heading: " What happens if I close an additional place of business?",
        text: `If you close an additional place of business, you need to update your GST registration by filing Form GST REG-14 to remove the location from your registration details.`,
      },
      {
        id: 9,
        heading: "Can I register multiple APOBs under a single GSTIN?",
        text: `Yes, you can register multiple additional places of business under a single GSTIN. Each location must be listed and documented in your GST registration.`,
      },

      {
        id: 10,
        heading: "How can Easy Works help with my APOB registration?",
        text: `Easy Works provides end-to-end GST APOB services, including consultation, documentation, filing, and follow-up. Our expert team ensures that your additional places of business are registered efficiently and in compliance with GST regulations.`,
      },
    ],
  },
  {
    serviceId: 6,
    planId: 38,
    label: "GST Return Filing",
    name: "gstReturnFiling",
    description:
      "Registered businesses are required to file periodic GST returns, including GSTR-1 (for outward supplies), GSTR-3B (for summary of outward and inward supplies), and GSTR-9 (annual return). These returns must be filed within the stipulated due dates, providing accurate details of transactions and tax liabilities.",

    faq_Config: [
      {
        id: 1,
        heading: "Who needs to file GST returns?",
        text: `Registered taxpayers engaged in the supply of goods or services, including businesses, e-commerce operators, and others specified by the government.`,
      },
      {
        id: 2,
        heading: "What are the different types of GST returns?",
        text: `
            <div>
               
            <ul>
            <li>GSTR-1 for outward supplies </li>
            <li>GSTR-3B for monthly summary</li>
            <li>GSTR-9 for annual return</li>
            <li>GSTR-9C for reconciliation (for taxpayers with turnover exceeding ₹2 crores).</li>
            </ul>
            </div>`,
      },
      {
        id: 3,
        heading: "How often do I need to file GST returns?",
        text: `
            <div>
              <p>
             The frequency of filing varies as-
              </p>
            <ul>
            <li>GSTR-1 is generally filed monthly.</li>
            <li>GSTR-3B is monthly or quarterly.</li>
            <li>GSTR-9 is filed annually.</li>
           
            </div>`,
      },
      {
        id: 4,
        heading: "What documents are required for GST return filing?",
        text: `
             <div>
               
            <ul>
            <li>Sales Invoices.</li> 
            <li>Purchase invoices.</li>
            <li>Credit/debit notes.</li>
            <li>Other relevant financial documents.</li>
           
            </div>`,
      },
      {
        id: 5,
        heading: "What happens if I miss the deadline for filing GST returns?",
        text: `Late filing attracts penalties and interest, ranging from ₹50 to ₹200 per day of delay, depending on the return type and turnover.`,
      },
      {
        id: 6,
        heading: "Can I revise GST returns after filing?",
        text: `No, currently, there is no provision for revising GST returns after filing. However, corrections can be made in subsequent returns.`,
      },
      {
        id: 7,
        heading: "How do I file GST returns?",
        text: `GST returns can be filed online through the GSTN portal.`,
      },
      {
        id: 8,
        heading: "Do I need to hire a professional for GST return filing?",
        text: `
        <div>
              <p>
             Hiring a professional for GST return filing is not mandatory, but it can be beneficial and can be advantageous for businesses due to:
              </p>
            <ul>
            <li>Professionals can handle intricate transactions and ensure accurate reporting, minimising the risk of errors.</li>
            <li>Outsourcing to a professional saves time, especially for businesses with high transaction volumes..</li>
            <li>Tax experts ensure compliance with GST laws, reducing the likelihood of penalties.</li>
            <li>Professionals provide support during tax audits or inquiries.</li>
            <li>Professionals stay abreast of GST changes, ensuring returns are filed correctly</li>
           
            </div>
`,
      },
      {
        id: 9,
        heading:
          "What is the penalty for non-compliance with GST return filing?",
        text: `Non-compliance may result in penalties, fines, and legal consequences, including suspension or cancellation of GST registration.`,
      },
    ],
  },
  {
    serviceId: 6,
    planId: 39,
    label: "GST Cancellation",
    name: "gstCancellation",
    description:
      "Cancellation of GST registration is a formal process through which a registered taxpayer requests the authorities to cancel their GST registration.",

    faq_Config: [
      {
        id: 0,
        heading: "How can I cancel my GST voluntarily?",
        text: "Taxpayers can cancel their GST registration voluntarily through the GST portal by submitting GST REG-16.",
      },
      {
        id: 1,
        heading: "What are the documents required for voluntary cancellation?",
        text: `
            <div>
                <ul>
                    <li>PAN Card</li>
                    <li>Aadhar Card</li>
                    <li>Last return filed</li>
                    <li>Proof of closure of business (if Any)</li>
                </ul>
            </div>
        `,
      },
      {
        id: 2,
        heading:
          "Can I cancel my GST if my turnover falls below the threshold limit?",
        text: "Yes, If your turnover falls below the threshold limit, you can apply for voluntary cancellation of GST.",
      },
      {
        id: 3,
        heading: "Can I cancel GST registration without filing returns?",
        text: "No. GST registrations cannot be cancelled without filing returns. Taxpayers must first complete the filing of GST returns. Then, the taxpayer must submit form GSTR-10 (also known as Final Return) to apply for cancellation of GST registration.",
      },
      {
        id: 4,
        heading: "How long does it take to cancel GST registration?",
        text: "It takes around 10 to 15 working days for the cancellation to be processed.",
      },
    ],
  },
  {
    label: "GST Revocation",
    name: "gstRevocation",
    description: `GST revocation refers to the process of reinstating a cancelled GST registration. When a taxpayer's GST registration is cancelled due to specific reasons, such as non-compliance or irregularities, and the taxpayer subsequently rectifies the issues or fulfils the necessary requirements, they can request the authorities to revoke the cancellation and reinstate their GST registration.`,

    faq_Config: [
      {
        id: 0,
        heading: "What is GST revocation?",
        text: "GST revocation refers to the process of reinstating a cancelled GST registration after rectifying the issues or fulfilling the requirements that led to the cancellation.",
      },
      {
        id: 1,
        heading: "When can I apply for GST revocation?",
        text: "You can apply for GST revocation if your GST registration has been cancelled due to specific reasons, such as non-compliance or irregularities, and you have rectified the issues or fulfilled the necessary requirements.",
      },
      {
        id: 2,
        heading: "How can I apply for GST revocation?",
        text: "To apply for GST revocation, you need to file an application with the appropriate GST authorities, typically through the GST portal. The application should be supported by relevant documents and explanations addressing the reasons for cancellation.",
      },
      {
        id: 3,
        heading: "What documents are required for GST revocation?",
        text: `
            <div>
                <ul>
                    <li>The documents required for GST revocation may vary depending on the reasons for cancellation and any prescribed conditions or requirements.</li>
                    <li>Generally, you may need to provide supporting documents such as an application form for revocation, details of the reasons for cancellation, and any additional documents requested by the GST authorities.</li>
                </ul>
            </div>
        `,
      },
      {
        id: 4,
        heading: "How long does the GST revocation process take?",
        text: "The timeframe for GST revocation can vary depending on factors such as the complexity of the case and the workload of the GST authorities. Generally, it may take several weeks to months for the authorities to review and process your application.",
      },
      {
        id: 5,
        heading:
          "What happens if my application for GST revocation is rejected?",
        text: "If your application for GST revocation is rejected, you have the option to file an appeal with the appropriate appellate authority within the prescribed time frame.",
      },
      {
        id: 6,
        heading:
          "Can I continue business activities while my GST revocation application is pending?",
        text: "If your GST registration has been cancelled, you may not be authorized to conduct business activities under the GST regime until your registration is reinstated. It's important to comply with any post-cancellation requirements and wait for the outcome of your revocation application before resuming business activities.",
      },
      {
        id: 7,
        heading:
          "Why and under what circumstances does the GST department cancel registration?",
        text: `
            <div>
                <ul>
                    <li>If a taxpayer fails to file the GST return for the last 6 months.</li>
                    <li>If a taxpayer fails to pay the due taxes.</li>
                    <li>Doesn’t conduct any business from the Principal place of business.</li>
                </ul>
            </div>
        `,
      },
      {
        id: 8,
        heading:
          "What is the Process if my GST is cancelled by the GST Department?",
        text: "The department initiates the process by issuing a show-cause notice, followed by a response from the taxpayer and adjudication. If cancellation is warranted, an order is issued.",
      },
      {
        id: 9,
        heading: "Can I appeal against the cancellation order?",
        text: "Yes, taxpayers have the option to file an appeal with the appropriate appellate authority within the prescribed time frame.",
      },
      {
        id: 10,
        heading: "Can I register again for the GST after cancellation?",
        text: "Yes, there is no restriction for the same. You can register again for GST after cancellation.",
      },
    ],
  },
  {
    label: "GST Refund",
    name: "gstRefund",
    description:
      "It refers to the process by which a registered taxpayer can claim a refund of excess taxes paid under the Goods and services tax regime.",

    faq_Config: [
      {
        id: 0,
        heading: "What is GST refund?",
        text: "GST refund refers to the process by which a registered taxpayer can claim a refund of excess taxes paid under the Goods and Services Tax (GST) regime.",
      },
      {
        id: 1,
        heading: "When can I claim GST refund?",
        text: "You can claim GST refund under various circumstances, such as export of goods or services, inverted duty structure, excess payment of tax, or refund of accumulated input tax credit (ITC).",
      },
      {
        id: 2,
        heading: "How do I claim GST refund?",
        text: "To claim the GST refund, you need to file an application through the GST portal, providing details of the refund claim and supporting documents, such as invoices, export documents, and proof of payment.",
      },
      {
        id: 3,
        heading: "What documents are required for GST refund?",
        text: `
            <div>
                <ul>
                    <li>The documents required for GST refund may vary depending on the nature of the refund claim.</li>
                    <li>You may need to submit invoices, shipping bills, export documents, and any other relevant supporting documents.</li>
                </ul>
            </div>
        `,
      },
      {
        id: 4,
        heading: "How long does it take to process the GST refund?",
        text: "The timeframe for processing GST refund claims depends on various factors, such as the complexity of the case, verification requirements, and workload of the GST authorities. Generally, refunds should be processed within 60 days from the date of receipt of the refund application.",
      },
      {
        id: 5,
        heading: "What happens if my GST refund claim is rejected?",
        text: "If your GST refund claim is rejected by the GST authorities, you have the option to file an appeal with the appropriate appellate authority within the prescribed time frame.",
      },
      {
        id: 6,
        heading: "Can I track the status of my GST refund application?",
        text: "Yes, you can track the status of your GST refund application online through the GST portal using your GSTIN and other relevant details.",
      },
      {
        id: 7,
        heading:
          "Do I need professional help to apply or claim for the GST refund?",
        text: `
            <div>
            <p>Seeking Professional help can be advantageous as:</p>
                <ul>
                    <li>Professionals, such as chartered accountants or GST consultants, possess in-depth knowledge of GST laws and regulations. They can navigate complex transactions, ensuring accurate identification of eligible refund amounts and compliance with regulations.</li>
                    <li>They can help optimize the refund claim by identifying all eligible input tax credit (ITC) and other refundable amounts within the legal framework, potentially increasing your refund amount.</li>
                    <li>They ensure compliance with GST laws and procedures, reducing the risk of errors or rejections in refund claims. They can also assist in maintaining proper records and documentation to support your claim, minimizing delays or rejections.</li>
                    <li>In case of rejected refund claims or disputes with tax authorities, professionals can represent you in appeals and resolution proceedings, advocating for your interests and rights effectively.</li>
                </ul>
            </div>
        `,
      },
    ],
  },
  {
    label: "GST Appeal",
    name: "gstAppeal",
    description: `At Easy Works, we provide expert GST appeal services to help taxpayers challenge decisions made by tax authorities. Whether it's disputes over tax assessments, refunds, or penalties, our experienced team is here to guide you through each stage of the appeal process, ensuring your case is handled with the utmost care and precision.`,

    faq_Config: [
      {
        id: 0,
        heading: "What is a GST appeal?",
        text: "A GST appeal is a legal process where a taxpayer challenges a decision made by tax authorities. This could involve disputes over tax assessments, refunds, or penalties.",
      },
      {
        id: 1,
        heading: "When can I file a GST appeal?",
        text: "You can file a GST appeal if you are aggrieved by any decision or order passed by a GST officer. The appeal must be filed within three months from the date of communication of the order.",
      },
      {
        id: 2,
        heading: "What is the process for filing a GST appeal?",
        text: "The process involves assessing the case, preparing necessary documentation, filing the appeal with the appropriate GST authority, and representing your case during the appeal proceedings.",
      },
      {
        id: 3,
        heading: "What documents are required for filing a GST appeal?",
        text: `
            <div>
                <ul>
                    <li>The original order against which the appeal is being filed</li>
                    <li>Grounds of appeal</li>
                    <li>Relevant evidence</li>
                    <li>Any other supporting documents</li>
                </ul>
            </div>
        `,
      },
      {
        id: 4,
        heading: "What are the fees for filing a GST appeal?",
        text: "The fee for filing a GST appeal is 10% of the disputed tax amount for the first appellate authority and an additional 20% for the Appellate Tribunal.",
      },
      {
        id: 5,
        heading: "How long does it take to resolve a GST appeal?",
        text: "The resolution time varies depending on the complexity of the case and the efficiency of the GST appellate authority. It can take several months to over a year.",
      },
      {
        id: 6,
        heading: "What happens if my GST appeal is rejected?",
        text: "If your appeal is rejected, you can escalate the matter to a higher appellate authority, such as the Appellate Tribunal or the High Court.",
      },
      {
        id: 7,
        heading: "Can penalties be waived or reduced through a GST appeal?",
        text: "Yes, penalties can be waived or reduced if the appellate authority finds merit in your appeal and rules in your favor.",
      },
      {
        id: 8,
        heading: "How can Easy Works help with my GST appeal?",
        text: "Easy Works provides end-to-end GST appeal services, including case assessment, documentation, filing, representation, and follow-up. Our expert team ensures that your appeal is handled efficiently and effectively.",
      },
      {
        id: 9,
        heading: "What are the common reasons for filing a GST appeal?",
        text: `
            <div>
                <ul>
                    <li>Disputes over tax assessments</li>
                    <li>Imposition of penalties</li>
                    <li>Denial of input tax credits</li>
                    <li>Incorrect classification of goods or services</li>
                </ul>
            </div>
        `,
      },
    ],
  },
  {
    serviceId: 6,
    planId: 40,
    label: "GST Annual Returns",
    name: "gstAnnualReturns",
    description: `At Easy Works, we specialize in providing comprehensive GST annual return filing services to ensure your business remains compliant with all statutory requirements. The GST annual return is a crucial document that consolidates all the information provided in the monthly or quarterly returns filed during the financial year. Filing this return accurately and on time is essential to avoid penalties and ensure your business's financial health.`,

    faq_Config: [
      {
        id: 10,
        heading: "What is a GST annual return?",
        text: "A GST annual return is a comprehensive return that summarizes all the monthly or quarterly returns filed during a financial year, including details of sales, purchases, input tax credits, and tax paid.",
      },
      {
        id: 11,
        heading: "Who needs to file a GST annual return?",
        text: "All registered taxpayers under GST, including regular taxpayers, composition dealers, and e-commerce operators, need to file annual returns.",
      },
      {
        id: 12,
        heading: "What forms are used for GST annual returns?",
        text: "The main forms used for GST annual returns are GSTR-9 (for regular taxpayers) and GSTR-9C (for taxpayers whose turnover exceeds ₹2 crores, requiring reconciliation and audit).",
      },
      {
        id: 13,
        heading: "What is the due date for filing GST annual returns?",
        text: "The due date for filing GST annual returns is December 31st of the subsequent financial year, unless extended by the government.",
      },
      {
        id: 14,
        heading:
          "What happens if I miss the deadline for filing the GST annual return?",
        text: "Missing the deadline attracts a late fee of ₹200 per day, up to a maximum of 0.25% of the turnover in the state or union territory. Additionally, interest at 18% per annum is charged on the outstanding tax amount.",
      },
      {
        id: 15,
        heading: "Can I revise the GST annual return after filing?",
        text: "No, currently there is no provision to revise the GST annual return once it is filed. It is crucial to ensure all details are accurate before submission.",
      },
      {
        id: 16,
        heading: "What details are required for filing the GST annual return?",
        text: "Details required include sales, purchases, input tax credits, tax paid, amendments made during the year, and any advances received or paid.",
      },
      {
        id: 17,
        heading: "What is GSTR-9C?",
        text: "GSTR-9C is a reconciliation statement that must be filed by taxpayers with an annual turnover exceeding ₹2 crores. It includes a reconciliation of the annual return with the audited financial statements and requires a certification by a Chartered Accountant or Cost Accountant.",
      },
      {
        id: 18,
        heading: "How can Easy Works help with GST annual return filing?",
        text: "Easy Works provides end-to-end GST annual return filing services, including data compilation, form preparation, reconciliation, filing, and post-filing support. Our expert team ensures timely and accurate filing, helping you avoid penalties and interest charges.",
      },
      {
        id: 19,
        heading: "What are the benefits of filing GST annual returns on time?",
        text: "Timely filing of GST annual returns helps in maintaining accurate financial records, avoiding penalties and interest charges, ensuring compliance with GST regulations, and providing a clear picture of your business’s financial health.",
      },
    ],
  },
  {
    label: "Show Cause Notice ",
    name: "showCauseNotice",
    description: `At Easy Works, we provide comprehensive services to help businesses effectively manage and respond to Show Cause Notices (SCNs) issued by tax authorities. An SCN is a legal document issued by the GST authorities demanding an explanation from the taxpayer for a specific issue, such as non-compliance, discrepancies, or suspected fraud. It is crucial to address these notices promptly and accurately to avoid penalties and legal consequences.`,

    faq_Config: [
      {
        id: 0,
        heading: "What is a Show Cause Notice (SCN)?",
        text: "An SCN is a legal document issued by GST authorities demanding an explanation from the taxpayer for non-compliance, discrepancies, or suspected fraud.",
      },
      {
        id: 1,
        heading: "Why is it important to respond to an SCN?",
        text: "Responding to an SCN is crucial to avoid penalties, interest charges, and legal actions. It also helps in maintaining compliance with GST regulations and protecting your business interests.",
      },
      {
        id: 2,
        heading: "What should I do if I receive an SCN?",
        text: "If you receive an SCN, you should immediately review the notice, understand the issues raised, gather relevant documents and evidence, and prepare a comprehensive response. It is advisable to seek professional help to ensure accuracy and completeness.",
      },
      {
        id: 3,
        heading: "What documents are required to respond to an SCN?",
        text: `
            <div>
                <ul>
                    <li>Transaction records</li>
                    <li>Tax invoices</li>
                    <li>Bank statements</li>
                    <li>Purchase and sales records</li>
                    <li>Any other relevant evidence to support your response</li>
                </ul>
            </div>
        `,
      },
      {
        id: 4,
        heading: "What are the common reasons for receiving an SCN?",
        text: `
            <div>
                <ul>
                    <li>Discrepancies in tax returns</li>
                    <li>Non-payment or short payment of GST</li>
                    <li>Incorrect availing of input tax credit</li>
                    <li>Suspected tax evasion</li>
                </ul>
            </div>
        `,
      },
      {
        id: 5,
        heading: "How long do I have to respond to an SCN?",
        text: "The response time is specified in the SCN, usually ranging from 15 to 30 days from the date of receipt. It is essential to adhere to the deadline to avoid penalties.",
      },
      {
        id: 6,
        heading: "What happens if I do not respond to an SCN?",
        text: "Failure to respond can result in penalties, interest charges, and legal actions, including assessment orders and demand notices for the tax amount due.",
      },
      {
        id: 7,
        heading: "Can penalties be waived or reduced?",
        text: "Penalties can be waived or reduced if the taxpayer provides a satisfactory explanation and evidence supporting their case. The decision lies with the tax authorities.",
      },
      {
        id: 8,
        heading: "How can Easy Works help with SCNs?",
        text: "Easy Works provides end-to-end services for handling SCNs, including consultation, documentation, response drafting, filing, and representation before tax authorities. Our expert team ensures timely and accurate handling of your SCN to mitigate risks and penalties.",
      },
      {
        id: 9,
        heading: "Can I appeal against the decision of an SCN?",
        text: "Yes, if you are not satisfied with the decision of the tax authorities after responding to an SCN, you can file an appeal with the appropriate appellate authority.",
      },
    ],
  },
  {
    label: "Summons Or Departmental Cases",
    name: "summonsCases",
    description:
      "Our accounting services cover a wide range of tasks, including bookkeeping, financial statement preparation, budgeting, payroll processing, and tax planning. We ensure that your financial records are accurate, up-to-date, and compliant with relevant accounting standards and regulations. By outsourcing your accounting needs to us, you can focus on core business activities while having peace of mind knowing that your finances are in good hands.",

    faq_Config: [
      {
        id: 1,
        heading: "What is a company?",
        text: `A company is a legal entity formed by individuals known as shareholders or members to conduct business activities. It operates as a separate entity from its owners, offering limited liability protection to shareholders. Companies have perpetual existence, centralized management by a board of directors, and ownership represented by shares. Various types of companies exist, including private limited, public limited, one-person company (OPC), and limited liability partnership (LLP).`,
      },
      {
        id: 2,
        heading: "What are the Types of companies?",
        text: `
            <div>
              <p>
                The various types of companies:
              </p>
            <ul>
            <li>Private Limited Company </li>
            <li>Public Limited Company </li>
            <li>Limited Liability Company</li>
            <li>One Person company</li>
            </ul>
            </div>`,
      },
      {
        id: 3,
        heading: "What are the steps to register a company?",
        text: `
            <div>
              <p>
             The steps to register a company:
              </p>
            <ul>
            <li>Choose a Business Structure </li>
            <li>Decide on a Company Name and Check Availability </li>
            <li>Obtain Digital Signature Certificate (DSC)</li>
            <li>Obtain Director Identification Number (DIN)</li>
            <li>Draft Memorandum of Association (MOA) and Articles of Association (AOA)</li>
            <li>Pay Registration Fees and Stamp Duty</li>
            <li>Verification and Approval by ROC</li>
            <li>Receive Certificate of Incorporation</li>
            <li>Obtain PAN and TAN for the Company</li>
            <li>Open a Bank Account</li>
            <li>Start Business Operations</li>
            </ul>
            </div>`,
      },
      {
        id: 4,
        heading: "What is MOA and AOA?",
        text: `
            <div>
              <p>
                  <strong> MOA</strong> stands for Memorandum of Association, while AOA stands for Articles of Association. These are legal documents that define the structure, objectives, and internal rules of a company.
              </p>
              <p>
              MOA outlines the company's main objectives, scope of operations, and its relationship with shareholders and stakeholders. 
              </p>
                <p>AOA, on the other hand, lays down the rules and regulations for the internal management and conduct of the company, including the roles and responsibilities of directors, shareholders, and officers.</p>
                <p>Both MOA and AOA are essential documents required during the company registration process, and they provide a framework for the functioning and governance of the company.
                 </p>
         
            </div>`,
      },
      {
        id: 5,
        heading: "What is DSC?",
        text: `DSC stands for Digital Signature Certificate. It is a secure digital key issued by certifying authorities to verify the authenticity and integrity of electronic documents and transactions. DSCs are used to electronically sign documents and forms during various online transactions, including company registration, income tax filing, and e-filing of documents with government authorities.`,
      },
      {
        id: 6,
        heading: "What is DIN?",
        text: `DIN stands for Director Identification Number. It is a unique identification number assigned to individuals who wish to be appointed as directors of companies registered in India. DIN serves as a means to track and regulate the activities of directors and helps maintain a centralized database of directors' information. It is mandatory for individuals to obtain DIN before being appointed as directors of Indian companies`,
      },
      {
        id: 7,
        heading: "What is DPIN?",
        text: `DPIN is a unique identification number assigned to individuals who wish to become designated partners in a Limited Liability Partnership (LLP) registered in India. It serves a similar purpose to Director Identification Number (DIN) for directors of companies, helping track and regulate the activities of designated partners in LLPs. Obtaining a DPIN is mandatory for individuals before they can be appointed as designated partners of an LLP.`,
      },
    ],
  },
];

export const fixedDeskAdvantage = [
  {
    label: "Demarcated Space",
    description:
      "Secure, clearly defined workspaces tailored for optimal productivity and privacy in a professional environment.",
    icon: demoAdvant,
  },
  {
    label: "Premium Signage ",
    description:
      "High-quality, visible signage solutions designed to enhance brand presence and guide clients effortlessly.",
    icon: premiumAdvant,
  },
  {
    label: "Superior Compliance",
    description:
      "Ensuring all regulatory and legal standards are met with precision and efficiency, fostering trust and reliability.",
    icon: superiorAdvant,
  },
  {
    label: "Authorised representative",
    description:
      "Professional representatives officially designated to act on your behalf, ensuring seamless communication.",
    icon: authorisedAdvant,
  },
  {
    label: "GST certificate Display ",
    description:
      "Prominent and compliant display of GST certificates to instill confidence and meet regulatory requirements.",
    icon: certiAdvant,
  },
  {
    label: "Faster GST approval",
    description:
      "Faster and prioritise agreement processing and GST application",
    icon: fasterAdvant,
  },
];

export const startupServiceOptions = {
  startup: [
    {
      value: "companyRegistration",
      label: "Company Registration",
      faq_Config: [
        {
          id: 1,
          heading: "What is a company?",
          text: `A company is a legal entity formed by individuals known as shareholders or members to conduct business activities. It operates as a separate entity from its owners, offering limited liability protection to shareholders. Companies have perpetual existence, centralized management by a board of directors, and ownership represented by shares. Various types of companies exist, including private limited, public limited, one-person company (OPC), and limited liability partnership (LLP).`,
        },
        {
          id: 2,
          heading: "What are the Types of companies?",
          text: `
            <div>
              <p>
                The various types of companies:
              </p>
            <ul>
            <li>Private Limited Company </li>
            <li>Public Limited Company </li>
            <li>Limited Liability Company</li>
            <li>One Person company</li>
            </ul>
            </div>`,
        },
        {
          id: 3,
          heading: "What are the steps to register a company?",
          text: `
            <div>
              <p>
             The steps to register a company:
              </p>
            <ul>
            <li>Choose a Business Structure </li>
            <li>Decide on a Company Name and Check Availability </li>
            <li>Obtain Digital Signature Certificate (DSC)</li>
            <li>Obtain Director Identification Number (DIN)</li>
            <li>Draft Memorandum of Association (MOA) and Articles of Association (AOA)</li>
            <li>Pay Registration Fees and Stamp Duty</li>
            <li>Verification and Approval by ROC</li>
            <li>Receive Certificate of Incorporation</li>
            <li>Obtain PAN and TAN for the Company</li>
            <li>Open a Bank Account</li>
            <li>Start Business Operations</li>
            </ul>
            </div>`,
        },
        {
          id: 4,
          heading: "What is MOA and AOA?",
          text: `
            <div>
              <p>
                  <strong> MOA</strong> stands for Memorandum of Association, while AOA stands for Articles of Association. These are legal documents that define the structure, objectives, and internal rules of a company.
              </p>
              <p>
              MOA outlines the company's main objectives, scope of operations, and its relationship with shareholders and stakeholders. 
              </p>
                <p>AOA, on the other hand, lays down the rules and regulations for the internal management and conduct of the company, including the roles and responsibilities of directors, shareholders, and officers.</p>
                <p>Both MOA and AOA are essential documents required during the company registration process, and they provide a framework for the functioning and governance of the company.
                 </p>
         
            </div>`,
        },
        {
          id: 5,
          heading: "What is DSC?",
          text: `DSC stands for Digital Signature Certificate. It is a secure digital key issued by certifying authorities to verify the authenticity and integrity of electronic documents and transactions. DSCs are used to electronically sign documents and forms during various online transactions, including company registration, income tax filing, and e-filing of documents with government authorities.`,
        },
        {
          id: 6,
          heading: "What is DIN?",
          text: `DIN stands for Director Identification Number. It is a unique identification number assigned to individuals who wish to be appointed as directors of companies registered in India. DIN serves as a means to track and regulate the activities of directors and helps maintain a centralized database of directors' information. It is mandatory for individuals to obtain DIN before being appointed as directors of Indian companies`,
        },
        {
          id: 7,
          heading: "What is DPIN?",
          text: `DPIN is a unique identification number assigned to individuals who wish to become designated partners in a Limited Liability Partnership (LLP) registered in India. It serves a similar purpose to Director Identification Number (DIN) for directors of companies, helping track and regulate the activities of designated partners in LLPs. Obtaining a DPIN is mandatory for individuals before they can be appointed as designated partners of an LLP.`,
        },
      ],
      description:
        "Starting a business involves various legal formalities, and company registration is one of the primary steps. We assist entrepreneurs in choosing the right business structure (e.g., private limited company, LLP, sole proprietorship) and guide them through the entire registration process. Our services encompass drafting necessary documents, obtaining Digital Signature Certificates (DSC), filing incorporation forms with the Registrar of Companies (ROC), and obtaining the Certificate of Incorporation.",
    },
    {
      value: "accounting",
      label: "Accounting and Bookkeeping Services",
      faq_Config: [
        {
          id: 1,
          heading: "What are Accounting and Bookkeeping services?",
          text: `
            <div>
              <p>
                <strong>Accounting Services:</strong> Analysing and interpreting
                financial data to provide insights into a company's financial
                health, including preparing financial statements, budgeting, tax
                planning, and auditing.
              </p>
              <p>
                <strong>Bookkeeping Services:</strong> Recording and organising
                financial transactions, maintaining general ledgers, reconciling
                accounts, generating reports, and facilitating compliance with
                regulatory requirements.
              </p>
            </div>`,
        },
        {
          id: 2,
          heading: "Why is accurate bookkeeping important for businesses?",
          text: "Accurate bookkeeping ensures that financial records are up-to-date and reliable, facilitating better decision-making, financial analysis, and compliance with tax regulations.",
        },
        {
          id: 3,
          heading:
            "What are the benefits of outsourcing accounting and bookkeeping services?",
          text: "Outsourcing accounting and bookkeeping services can save time and resources, ensure expertise and accuracy, and provide access to advanced technology and financial insights.",
        },
        {
          id: 4,
          heading: "How often should I reconcile my accounts?",
          text: "It is recommended to reconcile accounts, such as bank statements, credit card statements, and accounts receivable/payable, on a monthly basis to identify discrepancies and errors promptly.",
        },
        {
          id: 5,
          heading:
            "What documents are needed to provide for accounting and bookkeeping services?",
          text: "You'll need to provide invoices, receipts, bank statements, payroll records, tax documents, contracts, and any other financial records relevant to your business operations",
        },
        {
          id: 6,
          heading: "How can I ensure compliance with tax regulations?",
          text: "Working with a knowledgeable accountant or bookkeeper can help ensure compliance with tax regulations by accurately recording transactions, identifying deductible expenses, and filing taxes on time.",
        },
        {
          id: 7,
          heading:
            "What software can be used for accounting and bookkeeping services?",
          text: "We use accounting software such as QuickBooks, Tally or Zoho books tailored to your business needs and preferences.",
        },
      ],
      description:
        "Our accounting services cover a wide range of tasks, including bookkeeping, financial statement preparation, budgeting, payroll processing, and tax planning. We ensure that your financial records are accurate, up-to-date, and compliant with relevant accounting standards and regulations. By outsourcing your accounting needs to us, you can focus on core business activities while having peace of mind knowing that your finances are in good hands.",
    },

    {
      value: "paymentReconciliation",
      label: "E-Commerce Payment Reconciliation",
      description:
        " In the fast-paced world of e-commerce, accurate payment reconciliation is essential for maintaining financial integrity and optimizing revenue. Our e-commerce payment reconciliation services automate the process of matching transactions, identifying discrepancies, and reconciling accounts. We help you track payments from multiple channels, ascertain business and item wise profitability and ensuring accurate financial reporting and minimizing the risk of revenue leakage.",
      faq_Config: [],
    },
    {
      value: "rocCompliance",
      label: "ROC Compliance",
      description:
        " Every registered company in India is required to comply with various statutory requirements prescribed by the Registrar of Companies (ROC). Our ROC compliance services cover annual filing of financial statements and annual returns, maintenance of statutory registers and records, convening and conducting board meetings and general meetings, and ensuring compliance with provisions of the Companies Act, 2013. We keep track of filing deadlines and regulatory changes, allowing you to focus on running your business smoothly.",
      faq_Config: [
        {
          id: 1,
          heading: "What is ROC compliance?",
          text: `ROC (Registrar of Companies) compliance refers to adhering to statutory requirements and regulations outlined in the Companies Act or relevant corporate laws. It involves maintaining accurate records, filing necessary documents, and fulfilling obligations mandated by the ROC to ensure legal validity, transparency, and accountability in business operations.
`,
        },
        {
          id: 2,
          heading: "Is ROC Compliance important for Business or a Company?",
          text: `ROC compliance is important for business or companies to fulfil their legal obligations, maintain transparency, protect stakeholders interests and uphold good corporate governance standards. It contributes to the credibility, legality, and sustainability of the company's operations in the long run.
`,
        },
        {
          id: 3,
          heading: "What are the benefits of ROC compliance?",
          text: `
          <>
          <p>The benefits of ROC (Registrar of Companies) compliance include:
          </p>
          <ul>
          <li>Legal Standing: Maintaining compliance ensures the company's legal validity and standing, enhancing its credibility and reliability.</li>
          <li>Avoiding Penalties: Compliance helps avoid penalties, fines, or legal consequences associated with non-compliance, protecting the company's financial interests.</li>
          <li>Transparency: Compliance fosters transparency and accountability in business operations, building trust among stakeholders such as investors, creditors, and customers.
           </li>
          <li>Good Governance: Adhering to regulatory requirements promotes good corporate governance practices, contributing to long-term sustainability and ethical conduct.
         </li>
          <li>Facilitating Transactions: Compliance facilitates smoother business transactions, mergers, acquisitions, or partnerships, enhancing the company's growth opportunities.
          </li>
          <li>Protecting Stakeholder Interests: Compliance safeguards the interests of shareholders, creditors, employees, and other stakeholders by ensuring accurate disclosure and adherence to legal standards.
           </li>
          <li>Legal Security: Compliant businesses are less susceptible to legal disputes, ensuring smooth operations and mitigating risks associated with regulatory non-compliance.
          </li>
          </ul>
          </>`,
        },
        {
          id: 4,
          heading:
            "What are the consequences of non-compliance with ROC regulations?",
          text: `Non-compliance with ROC (Registrar of Companies) regulations can result in penalties, fines, legal proceedings, loss of goodwill, disqualification of directors, business disruption, deregistration, and financial losses.
`,
        },
        {
          id: 5,
          heading:
            "Can I handle ROC compliance internally, or should I seek professional assistance?",
          text: `While handling ROC (Registrar of Companies) compliance internally is possible, seeking professional assistance is often advisable. Professional firms or chartered accountants specialise in navigating complex regulatory frameworks, ensuring accurate adherence to ROC regulations, and mitigating risks associated with non-compliance. They provide expert guidance, streamline compliance processes, and offer strategic advice tailored to the company's needs. Professional assistance can help businesses maintain transparency, avoid penalties, and safeguard their legal standing and reputation.`,
        },
      ],
    },
    {
      value: "proTaxReg",
      label: "Professional Tax Registration (PTEC or PTRC)",
      description:
        "Professional tax is a state-level tax imposed on individuals and entities engaged in professional or trade activities. Our professional tax registration services help businesses comply with professional tax laws by obtaining the required registrations and licences from the appropriate authorities. We guide you through the registration process, assist with tax calculations and filings, and ensure that your business remains compliant with professional tax regulations",
      faq_Config: [
        {
          id: 1,
          heading: " What is Professional Tax?",
          text: `Professional Tax is a state-level tax imposed on individuals and entities engaged in professional or trade activities. It is levied by state governments in India and the tax rates vary from state to state. At present the maximum tax that can be imposed is restricted to is Rs. 2500/-.`,
        },
        {
          id: 2,
          heading: "Who needs to pay Professional Tax?",
          text: `Individuals engaged in professions, trades, callings, or employment are liable to pay Professional Tax. Employers are responsible for deducting Professional Tax from the salaries of their employees and remitting it to the state government.`,
        },
        {
          id: 3,
          heading: "Is Professional Tax Registration mandatory?",
          text: `Yes, Professional Tax Registration is mandatory for individuals and entities liable to pay Professional Tax. Employers must obtain registration and deduct Professional Tax from the salaries of their employees.`,
        },
        {
          id: 4,
          heading: "How do I register for Professional Tax?",
          text: `To register for Professional Tax, you need to apply to the appropriate state government authority responsible for administering Professional Tax. The registration process may vary from state to state but typically involves filling out an application form and submitting required documents such as PAN card, address proof, and incorporation certificate (for companies).`,
        },
        {
          id: 5,
          heading:
            "What are the documents required for Professional Tax Registration?",
          text: `The documents required for Professional Tax Registration may vary depending on the state. However, common documents include PAN card, address proof (such as Aadhaar card or utility bill), identity proof, and incorporation certificate (for companies). Employers may also need to provide details of employees and their salaries.`,
        },
        {
          id: 6,
          heading: "Is there a deadline for Professional Tax Registration?",
          text: `There may be deadlines for obtaining Professional Tax Registration, which vary depending on the state. It is advisable to check with the respective state government authority for the specific deadlines and compliance requirements.`,
        },
        {
          id: 7,
          heading:
            "What are the consequences of non-compliance with Professional Tax regulations?",
          text: `Non-compliance with Professional Tax regulations may result in penalties and legal consequences, including fines and prosecution. Employers who fail to deduct and remit Professional Tax from the salaries of their employees may be liable for penalties imposed by the state government.`,
        },
        {
          id: 8,
          heading:
            "Do freelancers and self-employed individuals need to pay Professional Tax?",
          text: `Yes, freelancers and self-employed individuals engaged in professional or trade activities are liable to pay Professional Tax. They are required to obtain Professional Tax Registration and pay the tax directly to the state government.`,
        },
        {
          id: 9,
          heading: "Can Professional Tax Registration be cancelled?",
          text: `Yes, Professional Tax Registration can be cancelled under certain circumstances, such as cessation of business or closure of operations. Employers must apply for cancellation of registration to the appropriate state government authority and comply with any related requirements.`,
        },
        {
          id: 10,
          heading: "Is Professional Tax deductible as a business expense?",
          text: `Yes, Professional Tax paid by employers on behalf of their employees is generally treated as a deductible business expense for income tax purposes. However, it is advisable to consult with a tax advisor or accountant for specific guidance on tax deductions.`,
        },
      ],
    },
    {
      value: "fssaiRegistration",
      label: "FSSAI Registration",
      description:
        "The Food Safety and Standards Authority of India (FSSAI) registration is mandatory for businesses involved in the manufacturing, processing, packaging, distribution, and sale of food products. We assist food businesses in obtaining FSSAI licences or registrations by guiding them through the application process, preparing required documents, and liaising with regulatory authorities. Our goal is to ensure compliance with food safety standards and regulations, thereby enhancing consumer trust and confidence in your products.",
      faq_Config: [
        {
          id: 1,
          heading: "What is FSSAI Registration?",
          text: `FSSAI registration is a mandatory requirement for all food businesses in India, as per the Food Safety and Standards Act, 2006. The Food Safety and Standards Authority of India (FSSAI) is the regulatory body responsible for ensuring the safety and quality of food products in the country. FSSAI registration is necessary to ensure compliance with food safety standards and regulations.
`,
        },
        {
          id: 2,
          heading: "Who issues FSSAI?",
          text: `Any individual or entity involved in the manufacturing, processing, packaging, distribution, sale, import, export, or storage of food products in India needs to obtain FSSAI registration`,
        },
        {
          id: 3,
          heading: "What are the different types of FSSAI registration?",
          text: `FSSAI registration is available in three categories: Basic Registration, State FSSAI License, and Central FSSAI License, based on the size and scale of the food business.`,
        },
        {
          id: 4,
          heading: "Is an FSSAI licence required for businesses?",
          text: `Yes, an FSSAI license is mandatory for all businesses involved in the production of food and food products. It's a legal requirement enforced by the government to ensure food safety standards are met.
`,
        },
        {
          id: 5,
          heading: "How to renew your licence after expiry?",
          text: `
          <ul>
          <li>Online Renewal: Log in to the FSSAI website, fill the renewal form, and upload documents.</li>
          <li>Fee Payment: Pay renewal fees online.</li>
          <li>Verification and Approval: Wait for verification and approval by FSSAI authorities.
          </li>
          <li>Compliance: Ensure ongoing compliance to maintain your renewed license.</li>
          </ul>
`,
        },
        {
          id: 6,
          heading: "What are the different categories of license?",
          text: `
          <ul>
          <li>FSSAI Basic Registration.</li>
          <li>State FSSAI  License</li>
          <li>Central FSSAI  License </li>
`,
        },
        {
          id: 7,
          heading: "What is the Fees of FSSAI License?",
          text: `
          <p>The FSSAI registration fee for different types of registration are as follows:
          </p>
          <ul>
          <li>FSSAI Basic Registration - Fee of Rs 100.
          </li>
          <li>State FSSAI License - Fees is between Rs 2000 to 5000 (Depend on the type of business)
            </li>
          <li>Central FSSAI License - Fee of Rs 7500. </li>
`,
        },
        {
          id: 8,
          heading: "How to check the status of FSSAI registration?",
          text: `
          <p>To check your FSSAI registration status:
          </p>
          <ul>
          <li>Go to the official FSSAI website <a href='https://www.fssai.gov.in/' target='_blank'>(www.fssai.gov.in)</a>.</li>
          <li>Find the "Check Application Status".</li>
          <li>Enter your application or registration number. </li>
          <li>Click "Submit" or "Check Status."
            </li>
          <li>Review the displayed status.
            </li>
`,
        },
        {
          id: 9,
          heading: "Is FSSAI License mandatory for E-commerce sellers?",
          text: `Yes, FSSAI license is mandatory for e-commerce sellers who are engaged in the sale of food products. This includes online platforms that sell food items such as groceries, packaged foods, beverages, and other consumables. E-commerce sellers need to obtain FSSAI registration or license to ensure compliance with food safety regulations and to maintain the quality and safety standards of the food products they sell. Failure to obtain an FSSAI license can lead to penalties and legal consequences.

`,
        },
      ],
    },
    {
      value: "tradeLicense",
      label: "Trade License",
      description:
        "A trade license is a mandatory requirement for conducting certain types of business activities in India. Our trade license services assist businesses in obtaining the necessary permits and licences from local authorities to operate legally. We help you navigate the complex process of trade license application, ensure compliance with local regulations, and obtain approvals efficiently, allowing you to focus on running your business.",
      faq_Config: [
        {
          id: 1,
          heading: "Which businesses need a trade license?",
          text: `All trading and manufacturing businesses require a trade license.`,
        },
        {
          id: 2,
          heading: "Where can I obtain a trade license?",
          text: `Trade licences can be obtained from the Municipal Corporation of the area where the business is located.`,
        },
        {
          id: 3,
          heading: "Is a trade license mandatory?",
          text: `Yes, businesses covered under the State Municipal Corporation Act must apply for a trade license.`,
        },
        {
          id: 4,
          heading:
            "Can I apply for a trade license if I am running my business from home?",
          text: `Yes, a trade license is required even for businesses operated from home.`,
        },
      ],
    },
    {
      value: "gstCompliance",
      label: "GST Compliance",
      faq_Config: [
        {
          id: 1,
          heading: "What is GST Compliance?",
          text: `GST compliance refers to the adherence of businesses to the rules, regulations, and requirements set forth by the Goods and Services Tax (GST) regime. It encompasses various activities, including timely filing of GST returns, accurate invoicing, proper maintenance of records, and compliance with GST laws and regulations.
`,
        },
        {
          id: 2,
          heading: "What is the importance of GST Compliance?",
          text: `
            <div>
              <p>
                <strong>Legal Requirement:</strong> GST compliance is a legal obligation for all registered businesses under the GST regime. Non-compliance can lead to penalties, fines, and legal repercussions.
              </p>
              <p>
                <strong>Business Continuity:</strong> Compliance with GST regulations ensures smooth business operations and continuity by avoiding disruptions, penalties, and legal actions.
              </p>
              <p>
                <strong>Credibility and Trust:</strong> GST compliance enhances the credibility and trustworthiness of businesses in the eyes of customers, suppliers, and stakeholders, leading to stronger business relationships and brand reputation.
              </p>
              <p>
                <strong>Avoidance of Penalties:</strong> Compliance with GST laws helps businesses avoid penalties, fines, and interest charges imposed for non-compliance, saving costs and resources in the long run.
              </p>
              <p>
                <strong>Input Tax Credit (ITC) Benefits:</strong>  GST compliance enables businesses to claim Input Tax Credit (ITC) on GST paid on inputs, reducing the overall tax liability and enhancing cash flow management.
              </p>
            </div>`,
        },
        {
          id: 3,
          heading: "Why is the GST Compliance important for a Business?",
          text: `GST compliance is essential for businesses to meet their legal obligations, maintain credibility, avoid penalties, and leverage benefits such as Input Tax Credit (ITC). By understanding the key aspects of GST compliance, addressing challenges, and implementing best practices, businesses can ensure smooth compliance with GST regulations and contribute to the overall efficiency and effectiveness of the GST regime.`,
        },
      ],
    },
  ],
  vpob: [
    {
      value: "vpobSeller",
      label: "Amazon IXD Onboarding",
    },
    {
      value: "flipkartOnboarding",
      label: "Flipkart FBF Onboarding",
    },
    {
      value: "amazonCore",
      label: "Amazon Core VPPOB State",
    },
    {
      value: "mhVpob",
      label: "Maharashtra VPOB",
    },
    {
      value: "karnatakaVpob",
      label: "Karnataka VPOB",
    },
    {
      value: "wbVpob",
      label: "West Bengal VPOB",
    },
    {
      value: "amazonVpob",
      label: "Amazon VPOB",
    },
    {
      value: "fkVpob",
      label: "Flipkart VPOB",
    },
    {
      value: "myntraVpob",
      label: "Myntra VPOB",
    },
  ],
  gstAdvisory: [
    {
      value: "gstReg",
      label: "GST Registration",
      faq_Config: [
        {
          id: 1,
          heading: "What is GST registration?",
          text: `GST registration is the process by which businesses or individuals register themselves under the Goods and Services Tax (GST) regime. It is mandatory for entities involved in the supply of goods or services with an aggregate turnover above the threshold limit specified by the government.`,
        },
        {
          id: 2,
          heading: "Who needs to register for GST?",
          text: ` <ul>
                  <li>Businesses engaged in taxable supplies with turnover exceeding the threshold limit.</li>
                  <li>E-commerce operators, inter-state suppliers, and certain other categories mandated by the government.</li>
            </ul>`,
        },
        {
          id: 3,
          heading: "What is the threshold turnover for GST registration?",
          text: ` <ul>
                  <li>The threshold limit is Rs. 40 lacs or higher for the manufacturing sector.</li>
                  <li>The service sector has a threshold of Rs. 20 lacs or higher.</li>
                  <li>The threshold limit is Rs. 10 lacs or more for particular category states.</li>
            </ul>`,
        },
        {
          id: 4,
          heading:
            "Can businesses below the threshold turnover voluntarily register for GST?",
          text: "Yes, businesses below the threshold turnover can opt for voluntary registration to avail benefits like input tax credit and interstate trade participation.",
        },
        {
          id: 5,
          heading: "What documents are required for GST registration?",
          text: "PAN card, identity/address proof, business registration documents, bank account details, and digital signature (for certain entities).",
        },
        {
          id: 6,
          heading: "How long does it take to get GST registration?",
          text: "The processing time varies, but typically it takes 7 to 14 working days for GST registration.",
        },
        {
          id: 7,
          heading: "What is GSTIN, and how is it obtained?",
          text: "GSTIN is the unique 15-digit Goods and Services Tax Identification Number assigned to registered taxpayers. It is obtained upon successful GST registration.",
        },
        {
          id: 8,
          heading: "Is there a fee for GST registration?",
          text: "No, there is no fee for GST registration as of now.",
        },
        {
          id: 9,
          heading: "Do I need separate GST registration for each state?",
          text: "Yes, businesses operating in multiple states need to obtain separate GST registration for each state they operate in.",
        },
        {
          id: 10,
          heading:
            "Can I amend my GST registration details after registration?",
          text: "Yes, you can amend certain details like address, contact information, etc., after registration through the GST portal",
        },
        {
          id: 11,
          heading: "What happens if I don't register for GST?",
          text: "Non-registration or non-compliance with GST regulations may result in penalties and legal consequences.",
        },
      ],
      description:
        "GST registration is the process by which businesses or individuals register themselves under the Goods and Services Tax (GST) regime. It is mandatory for entities involved in the supply of goods or services with an aggregate turnover above the threshold limit specified by the government.",
    },
    {
      value: "gstCancellation",
      label: "GST Cancellation",
    },
    {
      value: "gstReactivate",
      label: "Reactivate Cancelled GST",
    },
    {
      value: "gstAppeal",
      label: "GST Appeal Services",
    },
    {
      value: "gstAdvisory",
      label: "GST Advisory Services",
    },
    {
      value: "gstNotice",
      label: "GST Notice Reply",
    },
    {
      value: "obtainGst",
      label: "Obtain GST Refund",
    },
    {
      value: "vpobApob",
      label: "VPOB & APOB Services",
    },
    {
      value: "gstReturnFiling",
      label: "GST Return Filing",
    },
  ],
};

export const gstServices = [
  {
    image: gst1,
    label: "Expert GST Appeal Services",
    link: "",
    color: "#434CE5",
    name: "gstAppeal",
    description: `At Easy Works, we provide expert GST appeal services to help taxpayers challenge decisions made by tax authorities. Whether it's disputes over tax assessments, refunds, or penalties, our experienced team is here to guide you through each stage of the appeal process, ensuring your case is handled with the utmost care and precision.`,

    faq_Config: [
      {
        id: 0,
        heading: "What is a GST appeal?",
        text: "A GST appeal is a legal process where a taxpayer challenges a decision made by tax authorities. This could involve disputes over tax assessments, refunds, or penalties.",
      },
      {
        id: 1,
        heading: "When can I file a GST appeal?",
        text: "You can file a GST appeal if you are aggrieved by any decision or order passed by a GST officer. The appeal must be filed within three months from the date of communication of the order.",
      },
      {
        id: 2,
        heading: "What is the process for filing a GST appeal?",
        text: "The process involves assessing the case, preparing necessary documentation, filing the appeal with the appropriate GST authority, and representing your case during the appeal proceedings.",
      },
      {
        id: 3,
        heading: "What documents are required for filing a GST appeal?",
        text: `
            <div>
                <ul>
                    <li>The original order against which the appeal is being filed</li>
                    <li>Grounds of appeal</li>
                    <li>Relevant evidence</li>
                    <li>Any other supporting documents</li>
                </ul>
            </div>
        `,
      },
      {
        id: 4,
        heading: "What are the fees for filing a GST appeal?",
        text: "The fee for filing a GST appeal is 10% of the disputed tax amount for the first appellate authority and an additional 20% for the Appellate Tribunal.",
      },
      {
        id: 5,
        heading: "How long does it take to resolve a GST appeal?",
        text: "The resolution time varies depending on the complexity of the case and the efficiency of the GST appellate authority. It can take several months to over a year.",
      },
      {
        id: 6,
        heading: "What happens if my GST appeal is rejected?",
        text: "If your appeal is rejected, you can escalate the matter to a higher appellate authority, such as the Appellate Tribunal or the High Court.",
      },
      {
        id: 7,
        heading: "Can penalties be waived or reduced through a GST appeal?",
        text: "Yes, penalties can be waived or reduced if the appellate authority finds merit in your appeal and rules in your favor.",
      },
      {
        id: 8,
        heading: "How can Easy Works help with my GST appeal?",
        text: "Easy Works provides end-to-end GST appeal services, including case assessment, documentation, filing, representation, and follow-up. Our expert team ensures that your appeal is handled efficiently and effectively.",
      },
      {
        id: 9,
        heading: "What are the common reasons for filing a GST appeal?",
        text: `
            <div>
                <ul>
                    <li>Disputes over tax assessments</li>
                    <li>Imposition of penalties</li>
                    <li>Denial of input tax credits</li>
                    <li>Incorrect classification of goods or services</li>
                </ul>
            </div>
        `,
      },
    ],
  },
  {
    image: gst2,
    label: "GST Annual Returns",
    name: "gstAnnualReturns",
    description: `At Easy Works, we specialize in providing comprehensive GST annual return filing services to ensure your business remains compliant with all statutory requirements. The GST annual return is a crucial document that consolidates all the information provided in the monthly or quarterly returns filed during the financial year. Filing this return accurately and on time is essential to avoid penalties and ensure your business's financial health.`,
    link: "contact-us",
    faq_Config: [
      {
        id: 10,
        heading: "What is a GST annual return?",
        text: "A GST annual return is a comprehensive return that summarizes all the monthly or quarterly returns filed during a financial year, including details of sales, purchases, input tax credits, and tax paid.",
      },
      {
        id: 11,
        heading: "Who needs to file a GST annual return?",
        text: "All registered taxpayers under GST, including regular taxpayers, composition dealers, and e-commerce operators, need to file annual returns.",
      },
      {
        id: 12,
        heading: "What forms are used for GST annual returns?",
        text: "The main forms used for GST annual returns are GSTR-9 (for regular taxpayers) and GSTR-9C (for taxpayers whose turnover exceeds ₹2 crores, requiring reconciliation and audit).",
      },
      {
        id: 13,
        heading: "What is the due date for filing GST annual returns?",
        text: "The due date for filing GST annual returns is December 31st of the subsequent financial year, unless extended by the government.",
      },
      {
        id: 14,
        heading:
          "What happens if I miss the deadline for filing the GST annual return?",
        text: "Missing the deadline attracts a late fee of ₹200 per day, up to a maximum of 0.25% of the turnover in the state or union territory. Additionally, interest at 18% per annum is charged on the outstanding tax amount.",
      },
      {
        id: 15,
        heading: "Can I revise the GST annual return after filing?",
        text: "No, currently there is no provision to revise the GST annual return once it is filed. It is crucial to ensure all details are accurate before submission.",
      },
      {
        id: 16,
        heading: "What details are required for filing the GST annual return?",
        text: "Details required include sales, purchases, input tax credits, tax paid, amendments made during the year, and any advances received or paid.",
      },
      {
        id: 17,
        heading: "What is GSTR-9C?",
        text: "GSTR-9C is a reconciliation statement that must be filed by taxpayers with an annual turnover exceeding ₹2 crores. It includes a reconciliation of the annual return with the audited financial statements and requires a certification by a Chartered Accountant or Cost Accountant.",
      },
      {
        id: 18,
        heading: "How can Easy Works help with GST annual return filing?",
        text: "Easy Works provides end-to-end GST annual return filing services, including data compilation, form preparation, reconciliation, filing, and post-filing support. Our expert team ensures timely and accurate filing, helping you avoid penalties and interest charges.",
      },
      {
        id: 19,
        heading: "What are the benefits of filing GST annual returns on time?",
        text: "Timely filing of GST annual returns helps in maintaining accurate financial records, avoiding penalties and interest charges, ensuring compliance with GST regulations, and providing a clear picture of your business’s financial health.",
      },
    ],
    color: "#EC754C",
  },
  {
    image: gst3,
    label: "GST Refund Process",
    name: "gstRefund",
    description:
      "It refers to the process by which a registered taxpayer can claim a refund of excess taxes paid under the Goods and services tax regime.",

    faq_Config: [
      {
        id: 0,
        heading: "What is GST refund?",
        text: "GST refund refers to the process by which a registered taxpayer can claim a refund of excess taxes paid under the Goods and Services Tax (GST) regime.",
      },
      {
        id: 1,
        heading: "When can I claim GST refund?",
        text: "You can claim GST refund under various circumstances, such as export of goods or services, inverted duty structure, excess payment of tax, or refund of accumulated input tax credit (ITC).",
      },
      {
        id: 2,
        heading: "How do I claim GST refund?",
        text: "To claim the GST refund, you need to file an application through the GST portal, providing details of the refund claim and supporting documents, such as invoices, export documents, and proof of payment.",
      },
      {
        id: 3,
        heading: "What documents are required for GST refund?",
        text: `
            <div>
                <ul>
                    <li>The documents required for GST refund may vary depending on the nature of the refund claim.</li>
                    <li>You may need to submit invoices, shipping bills, export documents, and any other relevant supporting documents.</li>
                </ul>
            </div>
        `,
      },
      {
        id: 4,
        heading: "How long does it take to process the GST refund?",
        text: "The timeframe for processing GST refund claims depends on various factors, such as the complexity of the case, verification requirements, and workload of the GST authorities. Generally, refunds should be processed within 60 days from the date of receipt of the refund application.",
      },
      {
        id: 5,
        heading: "What happens if my GST refund claim is rejected?",
        text: "If your GST refund claim is rejected by the GST authorities, you have the option to file an appeal with the appropriate appellate authority within the prescribed time frame.",
      },
      {
        id: 6,
        heading: "Can I track the status of my GST refund application?",
        text: "Yes, you can track the status of your GST refund application online through the GST portal using your GSTIN and other relevant details.",
      },
      {
        id: 7,
        heading:
          "Do I need professional help to apply or claim for the GST refund?",
        text: `
            <div>
            <p>Seeking Professional help can be advantageous as:</p>
                <ul>
                    <li>Professionals, such as chartered accountants or GST consultants, possess in-depth knowledge of GST laws and regulations. They can navigate complex transactions, ensuring accurate identification of eligible refund amounts and compliance with regulations.</li>
                    <li>They can help optimize the refund claim by identifying all eligible input tax credit (ITC) and other refundable amounts within the legal framework, potentially increasing your refund amount.</li>
                    <li>They ensure compliance with GST laws and procedures, reducing the risk of errors or rejections in refund claims. They can also assist in maintaining proper records and documentation to support your claim, minimizing delays or rejections.</li>
                    <li>In case of rejected refund claims or disputes with tax authorities, professionals can represent you in appeals and resolution proceedings, advocating for your interests and rights effectively.</li>
                </ul>
            </div>
        `,
      },
    ],
    link: "/contact-us",
    color: "#469291",
  },
  {
    image: gst4,
    label: "GST Cancellation",
    link: "/contact-us",
    name: "gstCancellation",
    description:
      "Cancellation of GST registration is a formal process through which a registered taxpayer requests the authorities to cancel their GST registration.",

    faq_Config: [
      {
        id: 0,
        heading: "How can I cancel my GST voluntarily?",
        text: "Taxpayers can cancel their GST registration voluntarily through the GST portal by submitting GST REG-16.",
      },
      {
        id: 1,
        heading: "What are the documents required for voluntary cancellation?",
        text: `
            <div>
                <ul>
                    <li>PAN Card</li>
                    <li>Aadhar Card</li>
                    <li>Last return filed</li>
                    <li>Proof of closure of business (if Any)</li>
                </ul>
            </div>
        `,
      },
      {
        id: 2,
        heading:
          "Can I cancel my GST if my turnover falls below the threshold limit?",
        text: "Yes, If your turnover falls below the threshold limit, you can apply for voluntary cancellation of GST.",
      },
      {
        id: 3,
        heading: "Can I cancel GST registration without filing returns?",
        text: "No. GST registrations cannot be cancelled without filing returns. Taxpayers must first complete the filing of GST returns. Then, the taxpayer must submit form GSTR-10 (also known as Final Return) to apply for cancellation of GST registration.",
      },
      {
        id: 4,
        heading: "How long does it take to cancel GST registration?",
        text: "It takes around 10 to 15 working days for the cancellation to be processed.",
      },
    ],
    color: "#EC4C4C",
  },
  {
    image: gst5,
    label: "Revocation of Cancellation",
    link: "/contact-us",
    name: "gstRevocation",
    description: `GST revocation refers to the process of reinstating a cancelled GST registration. When a taxpayer's GST registration is cancelled due to specific reasons, such as non-compliance or irregularities, and the taxpayer subsequently rectifies the issues or fulfils the necessary requirements, they can request the authorities to revoke the cancellation and reinstate their GST registration.`,

    faq_Config: [
      {
        id: 0,
        heading: "What is GST revocation?",
        text: "GST revocation refers to the process of reinstating a cancelled GST registration after rectifying the issues or fulfilling the requirements that led to the cancellation.",
      },
      {
        id: 1,
        heading: "When can I apply for GST revocation?",
        text: "You can apply for GST revocation if your GST registration has been cancelled due to specific reasons, such as non-compliance or irregularities, and you have rectified the issues or fulfilled the necessary requirements.",
      },
      {
        id: 2,
        heading: "How can I apply for GST revocation?",
        text: "To apply for GST revocation, you need to file an application with the appropriate GST authorities, typically through the GST portal. The application should be supported by relevant documents and explanations addressing the reasons for cancellation.",
      },
      {
        id: 3,
        heading: "What documents are required for GST revocation?",
        text: `
            <div>
                <ul>
                    <li>The documents required for GST revocation may vary depending on the reasons for cancellation and any prescribed conditions or requirements.</li>
                    <li>Generally, you may need to provide supporting documents such as an application form for revocation, details of the reasons for cancellation, and any additional documents requested by the GST authorities.</li>
                </ul>
            </div>
        `,
      },
      {
        id: 4,
        heading: "How long does the GST revocation process take?",
        text: "The timeframe for GST revocation can vary depending on factors such as the complexity of the case and the workload of the GST authorities. Generally, it may take several weeks to months for the authorities to review and process your application.",
      },
      {
        id: 5,
        heading:
          "What happens if my application for GST revocation is rejected?",
        text: "If your application for GST revocation is rejected, you have the option to file an appeal with the appropriate appellate authority within the prescribed time frame.",
      },
      {
        id: 6,
        heading:
          "Can I continue business activities while my GST revocation application is pending?",
        text: "If your GST registration has been cancelled, you may not be authorized to conduct business activities under the GST regime until your registration is reinstated. It's important to comply with any post-cancellation requirements and wait for the outcome of your revocation application before resuming business activities.",
      },
      {
        id: 7,
        heading:
          "Why and under what circumstances does the GST department cancel registration?",
        text: `
            <div>
                <ul>
                    <li>If a taxpayer fails to file the GST return for the last 6 months.</li>
                    <li>If a taxpayer fails to pay the due taxes.</li>
                    <li>Doesn’t conduct any business from the Principal place of business.</li>
                </ul>
            </div>
        `,
      },
      {
        id: 8,
        heading:
          "What is the Process if my GST is cancelled by the GST Department?",
        text: "The department initiates the process by issuing a show-cause notice, followed by a response from the taxpayer and adjudication. If cancellation is warranted, an order is issued.",
      },
      {
        id: 9,
        heading: "Can I appeal against the cancellation order?",
        text: "Yes, taxpayers have the option to file an appeal with the appropriate appellate authority within the prescribed time frame.",
      },
      {
        id: 10,
        heading: "Can I register again for the GST after cancellation?",
        text: "Yes, there is no restriction for the same. You can register again for GST after cancellation.",
      },
    ],
    color: "#43BEE5",
  },
  {
    image: gst6,
    label: "Online GST Registration",
    name: "gstRegistration",
    link: "/contact-us",
    description:
      "GST registration is the process by which businesses or individuals register themselves under the Goods and Services Tax (GST) regime. It is mandatory for entities involved in the supply of goods or services with an aggregate turnover above the threshold limit specified by the government.",

    faq_Config: [
      {
        id: 1,
        heading: "What is GST registration?",
        text: `GST registration is the process by which businesses or individuals register themselves under the Goods and Services Tax (GST) regime. It is mandatory for entities involved in the supply of goods or services with an aggregate turnover above the threshold limit specified by the government.`,
      },
      {
        id: 2,
        heading: "Who needs to register for GST?",
        text: `
            <div>
             
            <ul>
            <li>Businesses engaged in taxable supplies with turnover exceeding the threshold limit. </li>
            <li>E-commerce operators, inter-state suppliers, and certain other categories mandated by the government. </li>
          
            </ul>
            </div>`,
      },
      {
        id: 3,
        heading: "What is the threshold turnover for GST registration?",
        text: `
            <div>
              
            <ul>
            <li>The threshold limit is Rs. 40 lacs or higher for the manufacturing sector.</li>
            <li>The service sector has a threshold of Rs. 20 lacs or higher. </li>
            <li>The threshold limit is Rs. 10 lacs or more for particular category states.</li>
            </ul>
            </div>`,
      },
      {
        id: 4,
        heading:
          "Can businesses below the threshold turnover voluntarily register for GST?",
        text: `Yes, businesses below the threshold turnover can opt for voluntary registration to avail benefits like input tax credit and interstate trade participation.`,
      },
      {
        id: 5,
        heading: "What documents are required for GST registration?",
        text: `PAN card, identity/address proof, business registration documents, bank account details, and digital signature (for certain entities).`,
      },
      {
        id: 6,
        heading: "How long does it take to get GST registration?",
        text: `The processing time varies, but typically it takes 7 to 14 working days for GST registration.`,
      },
      {
        id: 7,
        heading: "What happens if I don't register for GST?",
        text: `Non-registration or non-compliance with GST regulations may result in penalties and legal consequences.`,
      },
      {
        id: 8,
        heading: "What is GSTIN, and how is it obtained?",
        text: `GSTIN is the unique 15-digit Goods and Services Tax Identification Number assigned to registered taxpayers. It is obtained upon successful GST registration.`,
      },
      {
        id: 9,
        heading: "Is there a fee for GST registration?",
        text: `No, there is no fee for GST registration as of now.`,
      },
      {
        id: 10,
        heading: "Do I need separate GST registration for each state?",
        text: `Yes, businesses operating in multiple states need to obtain separate GST registration for each state they operate in.`,
      },
      {
        id: 11,
        heading: "Can I amend my GST registration details after registration?",
        text: `Yes, you can amend certain details like address, contact information, etc., after registration through the GST portal.`,
      },
    ],
    color: "#4B8DF0",
  },
];

export const contactInfos = [
  {
    id: 1,
    heading: "LEGAL NAME",
    line1: "EASYWORKS",
    line2: "",
    line3:
      "3198, Basement, Palam Vihar, Sector - 23, Gurguram, Haryana - 122017",
    lastLine: "",
  },
  {
    id: 2,
    heading: "GST IDENTIFICATION NUMBER",
    line1: "06AAIFE5081H1ZF",
    line2: "",
    line3: "",
    lastLine: "",
  },
];
export const mobileLinks = [
  {
    key: "1",
    label: "Startup and Compliance",
    children: (
      <div>
        {startupServices?.map(({ title, serviceId, links }) => (
          <div className="mobile-nav-inner-section">
            <Typography className="text-primary mobile-nav-sub-heading">
              {title}
            </Typography>
            {links.map(
              ({ label, description, faq_Config, planId, stateId, name }) => (
                <div>
                  {" "}
                  <Link
                    className="service-card-link"
                    to="/contact-us"
                    state={{
                      label,
                      faq: JSON.stringify(faq_Config),
                      description,
                      name,
                      serviceId,
                      planId,
                      stateId,
                    }}
                  >
                    {label}
                  </Link>
                </div>
              )
            )}
          </div>
        ))}
      </div>
    ),
  },
  {
    key: "2",
    label: "VPOB APOB",
    children: (
      <div>
        {vpobServices?.map(
          ({
            label,
            description,
            faq_Config,
            serviceId,
            planId,
            stateId,
            name,
          }) => (
            <div>
              {" "}
              <Link
                className="service-card-link"
                to="/contact-us"
                state={{
                  label,
                  faq: JSON.stringify(faq_Config),
                  description,
                  name,
                  serviceId,
                  planId,
                  stateId,
                }}
              >
                {label}
              </Link>
            </div>
          )
        )}
      </div>
    ),
  },
  {
    key: "3",
    label: "GST Advisory",
    children: (
      <div>
        {gstAdvisoryServices?.map(
          ({
            label,
            description,
            faq_Config,
            serviceId,
            planId,
            stateId,
            name,
          }) => (
            <div>
              {" "}
              <Link
                className="service-card-link"
                to="/contact-us"
                state={{
                  label,
                  faq: JSON.stringify(faq_Config),
                  description,
                  name,
                  serviceId,
                  planId,
                  stateId,
                }}
              >
                {label}
              </Link>
            </div>
          )
        )}
      </div>
    ),
  },
  // {
  //   key: "4",
  //   label: "Fixed Desk",
  // },
];
export const contactUsNumbers = [
  {
    id: 1,
    label: "WHATSAPP",
    value: "+91 9717174320",
  },
  {
    id: 2,
    label: "SUPPORT EMAIL",
    value: "Bookings@easyworks.in",
  },
  {
    id: 3,
    label: "TIMINGS",
    value: "10:00am to 07:00pm (Mon - Sat)",
  },
];

export const handleDefaultService = (route, setSelectedService, setOptions) => {
  switch (route) {
    case "/gst-advisory":
      setOptions(startupServiceOptions.gstAdvisory);
      setSelectedService("gst");
      break;
    case "/vpob-apob":
      setOptions(startupServiceOptions.vpob);
      setSelectedService("vpob");
      break;
    case "/fixed-desk":
      setSelectedService("fixedDesk");
      break;
    default:
      setOptions(startupServiceOptions.startup);
      setSelectedService("startup");
      break;
  }
};

export const landingServiceData = {
  startup: [
    {
      id: 1,
      label: "Jiomart Seller Registration",
      position: {
        xs: {
          x: 42,
          y: 36,
        },
        md: {
          x: 42,
          y: 36,
        },
      },
    },
    {
      id: 2,
      label: "Amazon Seller Registration",
      position: {
        xs: {
          x: -4,
          y: 136,
        },
        md: {
          x: -4,
          y: 136,
        },
      },
    },
    {
      id: 3,
      label: "Flipkart Seller Registration",
      position: {
        xs: {
          x: 182,
          y: 262,
        },
        md: {
          x: -42,
          y: 236,
        },
      },
    },
    {
      id: 4,
      label: "GST Registration Ecommerce",
      position: {
        xs: {
          x: 228,
          y: 61,
        },
        md: {
          x: -87,
          y: 336,
        },
      },
    },
    {
      id: 5,
      label: "GST Filing Services",
      position: {
        xs: {
          x: 9,
          y: 48,
        },
        md: {
          x: -132,
          y: 436,
        },
      },
    },
  ],
  apob: [
    {
      id: 1,
      label: "Amazon IXD Onboarding",
      position: {
        xs: {
          x: 42,
          y: 36,
        },
        md: {
          x: 42,
          y: 36,
        },
      },
    },
    {
      id: 2,
      label: "Flipkart FBF Onboarding",
      position: {
        xs: {
          x: -4,
          y: 136,
        },
        md: {
          x: -4,
          y: 136,
        },
      },
    },
    {
      id: 3,
      label: "Karnataka VPOB",
      position: {
        xs: {
          x: 182,
          y: 262,
        },
        md: {
          x: -42,
          y: 236,
        },
      },
    },

    {
      id: 4,
      label: "Maharashtra VPOB",
      position: {
        xs: {
          x: 228,
          y: 61,
        },
        md: {
          x: -87,
          y: 336,
        },
      },
    },
    {
      id: 5,
      label: "Amazon Core VPPOB State",
      position: {
        xs: {
          x: 9,
          y: 48,
        },
        md: {
          x: -132,
          y: 436,
        },
      },
    },
  ],
  gst: [
    {
      id: 1,
      label: "GST Registration",
      position: {
        xs: {
          x: 42,
          y: 36,
        },
        md: {
          x: 42,
          y: 36,
        },
      },
    },
    {
      id: 2,
      label: "GST Cancellation",
      position: {
        xs: {
          x: -4,
          y: 136,
        },
        md: {
          x: -4,
          y: 136,
        },
      },
    },
    {
      id: 3,
      label: "Reactivate Cancelled GST",
      position: {
        xs: {
          x: 182,
          y: 262,
        },
        md: {
          x: -42,
          y: 236,
        },
      },
    },
    {
      id: 4,
      label: "GST Appeal Services",
      position: {
        xs: {
          x: 228,
          y: 61,
        },
        md: {
          x: -87,
          y: 336,
        },
      },
    },
    {
      id: 5,
      label: "GST Advisory Services",
      position: {
        xs: {
          x: 9,
          y: 48,
        },
        md: {
          x: -132,
          y: 436,
        },
      },
    },
  ],
  fixedDesk: [
    {
      id: 1,
      label: "Demarcated Space",
      position: {
        xs: {
          x: 0,
          y: 0,
        },
        md: {
          x: 0,
          y: 0,
        },
      },
    },
    {
      id: 2,
      label: "Superior Compliance",
      position: {
        xs: {
          x: -4,
          y: 136,
        },
        md: {
          x: -4,
          y: 136,
        },
      },
    },
    {
      id: 3,
      label: "GST certificate Display ",
      position: {
        xs: {
          x: 182,
          y: 262,
        },
        md: {
          x: -42,
          y: 236,
        },
      },
    },
    {
      id: 4,
      label: "Premium Signage ",
      position: {
        xs: {
          x: 228,
          y: 61,
        },
        md: {
          x: -87,
          y: 336,
        },
      },
    },
    {
      id: 5,
      label: "Authorised representative",
      position: {
        xs: {
          x: 9,
          y: 48,
        },
        md: {
          x: -132,
          y: 436,
        },
      },
    },
  ],
};

export const allLocations = [
  {
    name: "Delhi",
  },
  {
    name: "Telangana",
  },
  {
    name: "Andhra Pradesh",
  },
  {
    name: "Madhya Pradesh",
  },
  {
    name: "Rajasthan",
  },
  {
    name: "Haryana",
  },
  {
    name: "Punjab",
  },
  {
    name: "West Bengal",
  },
  {
    name: "Tamil Nadu",
  },
  {
    name: "Maharashtra",
  },
  {
    name: "Gujrat",
  },
  {
    name: "Uttar Pradesh",
  },
  {
    name: "Assam",
  },
  {
    name: "Bihar",
  },
];

export const businessRegistrationLines = {
  typeOfCompanies: [
    {
      type: "headed",
      heading: "Private Limited Company",
      text: "A privately held business entity with limited liability for its shareholders",
    },
    {
      type: "headed",
      heading: "Public Limited Company",
      text: "A company whose shares are traded publicly on a stock exchange and can be owned by anyone.",
    },
    {
      type: "headed",
      heading: "One Person Company (OPC)",
      text: "A type of private company with a single shareholder who has limited liability.",
    },
    {
      type: "headed",
      heading: "Limited Liability Partnership (LLP)",
      text: "A hybrid structure combining features of a partnership and a corporation, providing limited liability to its partners.",
    },
  ],
  regRequirements: [
    {
      type: "non-headed",
      heading: "",
      text: "Selection of Company Name",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Memorandum of Association (MOA) and Articles of Association (AOA)",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Director Identification Number (DIN)",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Digital Signature Certificate (DSC)",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Registered Office Address",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Share Capital",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Director and Shareholder Details",
    },
    {
      type: "non-headed",
      heading: "",
      text: "No Objection Certificate (NOC)",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Payment of Registration Fees",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Compliance with Legal Requirements",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Certificate of Incorporation",
    },
  ],
  regProcess: [
    {
      type: "non-headed",
      heading: "",
      text: "Obtain Digital Signature Certificate (DSC) and Director Identification Number (DIN) for all directors.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Fill Part-A of the SPICe+ form and reserve the company name on the MCA website.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Provide company details in Part-B of the SPICe+ form.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Prepare and file the eMOA and eAOA.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Upload the required documents.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Submit the filled SPICe+ form on the MCA website, attaching the DSC of proposed directors and a declaration by a professional",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Pay the company registration fees and ROC stamp duty on the MCA website and generate challans",
    },
    {
      type: "non-headed",
      heading: "",
      text: "The CRC will process the application and forward it to the ROC once all documents are in order.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "The ROC will register the company and issue:",
      hasList: true,
      list: [
        "Company Incorporation Certificate",
        "Company Permanent Account Number (PAN)",
        "Company Tax Collection and Deduction Number (TAN)",
      ],
    },
  ],
};

export const accountingLines = {
  benefits: [
    {
      type: "headed",
      heading: "Financial Management",
      text: "Accounting and bookkeeping provide accurate records of financial transactions, enabling effective financial management and decision-making.",
    },
    {
      type: "headed",
      heading: "Compliance",
      text: "Proper accounting and bookkeeping ensure compliance with tax regulations, accounting standards, and reporting requirements, minimising the risk of penalties or legal consequences.",
    },
    {
      type: "headed",
      heading: "Performance Evaluation",
      text: "Financial statements generated through accounting processes allow businesses to assess their financial performance, identify trends, and make informed decisions to improve efficiency and profitability.",
    },
    {
      type: "headed",
      heading: "Planning and Forecasting",
      text: " Historical financial data helps businesses forecast future trends, set realistic goals, and develop strategic plans for growth and expansion.",
    },
    {
      type: "headed",
      heading: "Investor Confidence",
      text: "Transparent financial reporting builds trust and confidence among investors, lenders, and stakeholders, facilitating access to capital and investment opportunities.",
    },
    {
      type: "headed",
      heading: "Tax Optimization",
      text: "Accurate records enable businesses to identify tax deductions, credits, and incentives, optimising tax planning strategies and minimising tax liabilities.",
    },
    {
      type: "headed",
      heading: "Business Insights",
      text: "Accounting and bookkeeping provide valuable insights into business operations, expenses, and revenue sources, helping identify areas for cost reduction, revenue enhancement, and operational improvements",
    },
  ],
  requiredDocs: [
    {
      type: "non-headed",
      heading: "",
      text: "Invoice and receipts",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Purchase-Sales invoices",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Payroll records",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Bank statement of a financial year or monthly statement",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Expense Records",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Financial statements",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Any other relevant financial or business documents specific to the company's operations.",
    },
  ],
};
export const rocLines = {
  importance: [
    {
      type: "headed",
      heading: "Legal Obligation",
      text: "Compliance with ROC regulations is mandated by law under the Companies Act, 2013, or relevant corporate laws in different jurisdictions. Companies must adhere to statutory requirements to ensure legal validity and avoid penalties or legal consequences.",
    },
    {
      type: "headed",
      heading: "Maintaining Legal Status",
      text: "ROC compliance ensures that the company maintains its legal standing and recognition as a registered entity. Failure to comply with statutory obligations may result in penalties, fines, or even deregistration of the company, affecting its ability to operate and conduct business.",
    },
    {
      type: "headed",
      heading: "Transparency and Accountability",
      text: " ROC compliance promotes transparency and accountability in the management and operations of the company. By maintaining accurate records, filing annual returns, and disclosing relevant information to regulatory authorities, companies demonstrate their commitment to transparency and integrity.",
    },
    {
      type: "headed",
      heading: "Protection of Stakeholder Interests",
      text: "Compliance with ROC regulations helps protect the interests of various stakeholders, including shareholders, creditors, employees, and the public. Timely and accurate disclosure of financial information and corporate governance practices enhances stakeholders' confidence in the company.",
    },
    {
      type: "headed",
      heading: "Facilitation of Business Transactions",
      text: " ROC compliance facilitates business transactions, such as mergers, acquisitions, or raising capital through public offerings or loans. Potential investors, lenders, or business partners often rely on ROC filings and disclosures to assess the company's financial health, governance practices, and legal standing.",
    },
    {
      type: "headed",
      heading: "Maintaining Good Corporate Governance",
      text: "ROC compliance is an integral part of good corporate governance practices. It promotes ethical conduct, accountability, and responsible management of company affairs, fostering trust and confidence among stakeholders.",
    },
  ],
  guidelines: [
    {
      type: "headed",
      heading: "Annual Compliance",
      text: "Involves regular, yearly filings such as annual returns and financial statements.",
    },
    {
      type: "headed",
      heading: "Event-Based Compliance",
      text: "Addresses specific compliances triggered by events like changes in management, share capital, or registered office.",
    },
    {
      type: "headed",
      heading: "Other Compliances",
      text: "Encompasses various regulatory obligations essential for maintaining the company's legal status, including director KYC updates and maintenance of statutory registers.",
    },
    {
      type: "headed",
      heading: "Key Annual Compliances for Private Limited Companies",
      text: "",
    },
    {
      type: "headed",
      heading: "INC-20A: Declaration for Commencement of Business",
      text: "Required for companies with a share capital, must be obtained within 180 days of incorporation to initiate business activities.",
    },
    {
      type: "headed",
      heading: "Appointment of Auditor and Filing E-form ADT-1",
      text: "First auditor must be appointed within 30 days of incorporation and ratified by shareholders during the first AGM. Form ADT-1 confirming the appointment must be filed within 15 days post-AGM.",
    },
    {
      type: "headed",
      heading: "Board Meetings",
      text: "First board meeting within 30 days of incorporation and at least four meetings annually with intervals not exceeding 120 days.",
    },
    {
      type: "headed",
      heading: "Annual General Meeting (AGM)",
      text: "First AGM within nine months from the closure of the first financial year, subsequently held annually within six months from the end of the financial year.",
    },
    {
      type: "headed",
      heading: "Annual ROC Filings",
      text: "Submission of AOC-4 (Financial Statements) within 30 days post-AGM, and MGT-7 (Annual Returns) within 60 days of the AGM.",
    },
    {
      type: "headed",
      heading: "DIR-12: Appointment/Resignation of Directors",
      text: "Changes in directorship must be filed within 30 days of such changes.DIR-3 KYC: Director KYC Submission.",
    },
    {
      type: "headed",
      heading: "DIR-3 KYC: Director KYC Submission",
      text: "Directors to submit KYC details annually by September 30th, failing which incurs a penalty.",
    },
    {
      type: "headed",
      heading: "DPT-3: Return of Deposits",
      text: "Annual reporting of deposit details by June 30th.",
    },
    {
      type: "headed",
      heading: "Directors’ Report",
      text: "An abridged version covering required information for small companies under Section 134 must be prepared and authorized.",
    },
    {
      type: "headed",
      heading: "Maintenance of Statutory Registers and Books of Accounts",
      text: "Regular maintenance and updating of statutory registers, books of accounts, and records.",
    },
  ],
};

export const ltdLiabilityLines = {
  keyFeatures: [
    {
      type: "headed",
      heading: "Key Features:",
      text: "",
      hasList: true,
      list: [
        "Limited Liability: Partners' liability is limited to their contribution to the LLP.",
        "Separate Legal Entity: The LLP has its own legal identity, separate from its partners.",
        "Flexible Management: Partners can manage the LLP directly.",
        "Perpetual Succession: The LLP continues to exist regardless of changes in partnership.",
      ],
    },
  ],
  essentialLicense: [
    {
      type: "headed",
      heading: "Incorporation of LLP",
      text: "",
      hasList: true,
      list: [
        "Requirement: Register the LLP with the Ministry of Corporate Affairs (MCA) by filing the necessary documents, including the LLP agreement, Form 1 (Application for reservation or change of name), and Form 2 (Incorporation Document and Statement).",
        "Outcome: Obtain a Certificate of Incorporation (COI) and a unique LLP Identification Number (LLPIN).",
      ],
    },
    {
      type: "headed",
      heading: " LLP Agreement",
      text: "",
      hasList: true,
      list: [
        "Requirement: Draft and execute an LLP agreement that outlines the rights, duties, and obligations of partners, profit-sharing ratio, decision-making processes, admission of new partners, and procedures for dissolution.",
      ],
    },
    {
      type: "headed",
      heading: " Bank Account",
      text: "",
      hasList: true,
      list: [
        "Requirement: Operate a separate bank account for the company to manage finances.",
      ],
    },
    {
      type: "headed",
      heading: "GST Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory if the LLP's annual turnover exceeds the threshold limit set by the government.",
        "Benefits: Voluntary registration is advisable for input tax credit benefits.",
        "Penalty: Failure to register can result in a penalty of 10% of the tax amount due or ₹10,000, whichever is higher.",
      ],
    },
    {
      type: "headed",
      heading: "Professional Tax Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Depending on the state of operation, professional tax registration may be required. This tax is levied by state governments on income earned from professional activities.",
        "Penalty: Penalties vary by state but typically include fines and interest on late payments.",
      ],
    },
    {
      type: "headed",
      heading: "Shops and Establishment Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Obtain this registration from the local municipal corporation or panchayat. It regulates working conditions, hours of operation, and employment-related matters.",
        "Penalty: Operating without a license can result in fines and closure of the business by local authorities.",
      ],
    },
    {
      type: "headed",
      heading: "MSME Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Not mandatory but recommended.",
        "Benefits: Provides benefits such as easier access to credit and government support schemes.",
      ],
    },
    {
      type: "headed",
      heading: "Trade License",
      text: "",
      hasList: true,
      list: [
        "Requirement: Required from the local municipal corporation or panchayat, ensuring compliance with local laws and regulations related to business activities.",
        "Penalty: Non-compliance can lead to fines and legal action from local municipal authorities.",
      ],
    },
    {
      type: "headed",
      heading: "FSSAI Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory for LLPs involved in food-related activities to ensure compliance with food safety standards.",
        "Penalty: Failure to obtain FSSAI registration can result in a penalty of up to ₹5 lakhs and/or imprisonment for up to six months",
      ],
    },
  ],
};
export const llpClosureLines = {
  types: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: ["Voluntary Closure", "Compulsory Closure"],
    },
  ],
  reasons: [
    {
      type: "headed",
      heading: "Reasons for Voluntary Closure",
      text: "",
      hasList: true,
      list: [
        "The company is not carrying on any business or operation for a period of one year or more.",
        "The company has failed to commence its business within one year of incorporation.",
        "Shareholders decide to wind up the company due to financial difficulties or other reasons.",
      ],
    },
  ],
  stepsCompanyClosure: [
    {
      type: "headed",
      heading: "Steps to Close a Company",
      text: "",
      hasList: true,
      list: [
        "Board Meeting: Conduct a Board Meeting to pass a resolution for winding up the company. The resolution must be approved by a majority of directors.",
        "Special Resolution: Convene an Extraordinary General Meeting (EGM) to pass a special resolution for winding up the company. The resolution must be approved by at least 75% of shareholders.",
        "Declaration of Solvency: Submit a declaration of solvency by directors, stating that the company can pay its debts in full within three years from the commencement of winding up.",
        "Creditors’ Meeting: Call a meeting of creditors to approve the winding-up resolution.",
        "Appointment of Liquidator: Appoint a liquidator to manage the winding-up process.",
        "Notice of Resolution: File the special resolution with the ROC in Form MGT-14 within 30 days of passing the resolution.",
        "Winding Up Petition: File a petition for winding up with the National Company Law Tribunal (NCLT).",
        "Liquidation Process: The liquidator settles the company’s liabilities, disposes of assets, and distributes the remaining assets among shareholders.",
        "Final Meeting: Convene a final meeting of shareholders to approve the liquidator’s final accounts.",
        "Application for Striking Off: File an application for striking off the company’s name from the ROC in Form STK-2.",
      ],
    },
  ],
  llpClosureReasons: [
    {
      type: "headed",
      heading: "Reasons for Voluntary Closure",
      text: "",
      hasList: true,
      list: [
        "The LLP is not carrying on any business or operation for a period of one year or more.",
        "Partners decide to dissolve the LLP due to financial difficulties or other reasons.",
      ],
    },
  ],
  llpClosureSteps: [
    {
      type: "headed",
      heading: "Steps to Close an LLP",
      text: "",
      hasList: true,
      list: [
        "Partners’ Meeting: Convene a meeting of partners to pass a resolution for winding up the LLP. The resolution must be approved by a majority of partners.",
        "Consent of Creditors: Obtain consent from creditors to wind up the LLP.",
        "Declaration of Solvency: Submit a declaration of solvency by partners, stating that the LLP can pay its debts in full within one year from the commencement of winding up.",
        "Appointment of Liquidator: Appoint a liquidator to manage the winding-up process.",
        "Notice of Resolution: File the resolution with the ROC in Form LLP-3 within 30 days of passing the resolution.",
        "Liquidation Process: The liquidator settles the LLP’s liabilities, disposes of assets, and distributes the remaining assets among partners.",
        "Final Meeting: Convene a final meeting of partners to approve the liquidator’s final accounts.",
        "Application for Striking Off: File an application for striking off the LLP’s name from the ROC in Form LLP-24.",
      ],
    },
  ],
  compulsoryClosureReason: [
    {
      type: "headed",
      heading: "The ROC can initiate the closure of a company/LLP if:",
      text: "",
      hasList: true,
      list: [
        "The company/LLP has not commenced business within one year of incorporation.",
        "The company/LLP is not carrying on any business or operation for a period of two immediately preceding financial years and has not applied for dormant status.",
      ],
    },
  ],
  process: [
    {
      type: "headed",
      heading: "Process",
      text: "",
      hasList: true,
      list: [
        "Notice by ROC: The ROC issues a notice to the company/LLP and its directors/partners, informing them of its intention to strike off the name.",
        "Response to Notice: The company/LLP must respond to the notice within 30 days, providing reasons for not being struck off.",
        "Publication of Notice: If no satisfactory response is received, the ROC publishes a notice in the Official Gazette, stating its intention to strike off the company/LLP.",
        "Striking Off: After the publication, the ROC strikes off the company/LLP’s name from the register.",
      ],
    },
  ],
};
export const form16Lines = {
  importance: [
    {
      type: "headed",
      heading: "Proof of Income",
      text: "",
      hasList: true,
      list: [
        "Form 16 provides a comprehensive summary of an employee’s salary, including all components such as basic pay, allowances, perquisites, and deductions.",
      ],
    },
    {
      type: "headed",
      heading: "Tax Deduction Details",
      text: "",
      hasList: true,
      list: [
        "It details the TDS deducted by the employer and deposited with the government, ensuring transparency and accuracy in tax payments.",
      ],
    },
    {
      type: "headed",
      heading: " Filing Income Tax Returns",
      text: "",
      hasList: true,
      list: [
        "Form 16 simplifies the process of filing income tax returns, as it contains all the necessary information about the employee’s income and tax deducted.",
      ],
    },
    {
      type: "headed",
      heading: "Claiming Deductions and Exemptions",
      text: "",
      hasList: true,
      list: [
        "It helps employees claim various deductions and exemptions under the Income Tax Act, reducing their overall tax liability.",
      ],
    },
    {
      type: "headed",
      heading: "Proof for Financial Transactions",
      text: "",
      hasList: true,
      list: [
        "Form 16 is often required for various financial transactions, such as applying for loans or visas, as it serves as proof of income and tax compliance.",
      ],
    },
  ],
  partA: [
    {
      type: "headed",
      heading: "Part A",
      text: "Part A of Form 16 includes the following details:",
      hasList: true,
      list: [
        "Employer Details: Name, address, and TAN of the employer.",
        "Employee Details: Name, address, and PAN of the employee.",
        "Period of Employment: Duration for which the employee was employed with the organization.",
        "Summary of TDS: Quarterly details of TDS deducted and deposited with the government.",
      ],
    },
  ],
  partB: [
    {
      type: "headed",
      heading: "Part B",
      text: "Part B of Form 16 is an annexure containing details of the employee’s salary and deductions, including:",
      hasList: true,
      list: [
        "Gross Salary: Detailed breakup of salary components.",
        "Exemptions: Details of exemptions under Section 10, such as HRA, LTA, etc.",
        "Deductions: Information on deductions under Chapter VI-A, such as Section 80C (investments in PPF, LIC, etc.), Section 80D (health insurance), and others.",
        "Tax Payable and Paid: Computation of tax payable, TDS deducted, and any balance tax payable or refund due.",
      ],
    },
  ],
  howToUse: [
    {
      type: "headed",
      heading: "How to Use Form 16 for Filing Income Tax Returns",
      text: "",
      hasList: true,
      list: [
        "Obtain Form 16: Ensure you receive Form 16 from your employer, typically by June 15th of the assessment year.",
        "Verify Details: Check the details mentioned in Form 16 for accuracy, including personal information, salary details, and TDS amounts.",
        "Login to the e-Filing Portal: Log in to the Income Tax Department’s e-filing portal.",
        "Select the Appropriate ITR Form: Choose the correct ITR form based on your income sources.",
        "Fill in Income Details: Use the information in Form 16 to fill in the income details in the ITR form.",
        "Claim Deductions: Enter the deductions claimed as per Part B of Form 16.",
        "Compute Tax Payable: Calculate the tax payable based on the information provided.",
        "Pay Additional Tax, if Any: If there is any additional tax payable, pay it online through the e-filing portal.",
        "Submit and Verify: Submit the ITR form and verify it using one of the available methods (Aadhaar OTP, net banking, etc.).",
      ],
    },
  ],
};
export const tdsFilingLines = {
  importance: [
    {
      type: "headed",
      heading: "Legal Compliance",
      text: "",
      hasList: true,
      list: [
        "TDS filing is mandatory for all entities required to deduct tax at source. It ensures that the tax deducted is deposited with the government and reported accurately.",
      ],
    },
    {
      type: "headed",
      heading: "Avoiding Penalties",
      text: "",
      hasList: true,
      list: [
        "Timely and accurate TDS filing helps avoid penalties and interest charges imposed for late or incorrect filings.",
      ],
    },
    {
      type: "headed",
      heading: "Accurate Financial Reporting",
      text: "",
      hasList: true,
      list: [
        "TDS returns provide a clear record of the tax deducted and deposited, aiding in accurate financial reporting and reconciliation.",
      ],
    },
    {
      type: "headed",
      heading: "Facilitating Tax Credits",
      text: "",
      hasList: true,
      list: [
        "Proper TDS filing ensures that the deducted tax is credited to the recipient's account, allowing them to claim tax credits while filing their income tax returns.",
      ],
    },
  ],
  steps: [
    {
      type: "headed",
      heading: "Obtain TAN",
      text: "",
      hasList: true,
      list: [
        "Ensure that your business has a valid Tax Deduction and Collection Account Number (TAN). TAN is mandatory for deducting and depositing TDS.",
      ],
    },
    {
      type: "headed",
      heading: "Deduct TDS",
      text: "",
      hasList: true,
      list: [
        "Deduct TDS at the applicable rates while making payments such as salaries, interest, rent, professional fees, and other specified payments.",
      ],
    },
    {
      type: "headed",
      heading: "Deposit TDS",
      text: "",
      hasList: true,
      list: [
        "Deposit the deducted TDS with the government using Challan ITNS 281. TDS must be deposited by the 7th of the following month. For March, the deadline is April 30th.",
      ],
    },
    {
      type: "headed",
      heading: " File TDS Returns",
      text: "File quarterly TDS returns in the prescribed forms:",
      hasList: true,
      list: [
        "Form 24Q: For TDS on salaries",
        "Form 26Q: For TDS on all payments other than salaries",
        "Form 27Q: For TDS on payments to non-residents",
        "Form 27EQ: For TCS (Tax Collected at Source)",
      ],
    },
    {
      type: "headed",
      heading: "Issue TDS Certificates",
      text: "",
      hasList: true,
      list: [
        "Issue TDS certificates to the deductees. Form 16 is issued for TDS on salaries, and Form 16A for TDS on other payments. These forms must be issued within the specified timelines.",
      ],
    },
  ],
  penalties: [
    {
      type: "headed",
      heading: "Late Deduction (Section 201)",
      text: "",
      hasList: true,
      list: [
        "Interest is charged at 1% per month or part of the month from the date the tax was deductible until the actual date of deduction.",
      ],
    },
    {
      type: "headed",
      heading: "Late Deposit (Section 201)",
      text: "",
      hasList: true,
      list: [
        "Interest is charged at 1.5% per month or part of the month from the date of deduction until the date of deposit.",
      ],
    },
    {
      type: "headed",
      heading: "Late Filing of TDS Returns (Section 234E)",
      text: "",
      hasList: true,
      list: [
        "A penalty of Rs. 200 per day is levied for each day the return is delayed, subject to a maximum of the TDS amount.",
      ],
    },
    {
      type: "headed",
      heading: "Non-Filing or Incorrect Filing of TDS Returns (Section 271H)",
      text: "",
      hasList: true,
      list: [
        "A penalty ranging from Rs. 10,000 to Rs. 1,00,000 can be imposed for non-filing or incorrect filing of TDS returns.",
      ],
    },
    {
      type: "headed",
      heading: "Non-Issuance of TDS Certificates",
      text: "",
      hasList: true,
      list: [
        "A penalty of Rs. 100 per day is levied for each day of delay in issuing TDS certificates, subject to a maximum of the TDS amount.",
      ],
    },
  ],
};
export const businessTaxFilingLines = {
  importance: [
    {
      type: "headed",
      heading: " Legal Compliance",
      text: "",
      hasList: true,
      list: [
        "Filing taxes ensures that the business complies with the Income Tax Act, 1961, and other relevant tax laws. Non-compliance can result in legal consequences and damage the business's reputation.",
      ],
    },
    {
      type: "headed",
      heading: "Claiming Deductions and Credits",
      text: "",
      hasList: true,
      list: [
        "Proper tax filing allows businesses to claim deductions, credits, and exemptions, reducing overall tax liability and optimizing financial performance.",
      ],
    },
    {
      type: "headed",
      heading: "Avoiding Penalties",
      text: "",
      hasList: true,
      list: [
        "Timely and accurate tax filing helps avoid penalties, interest, and fines imposed by the tax authorities for late or incorrect filings",
      ],
    },
    {
      type: "headed",
      heading: "Financial Management",
      text: "",
      hasList: true,
      list: [
        "Regular tax filing provides a clear picture of the business’s financial health, aiding in better financial planning and decision-making.",
      ],
    },
  ],
  penalties: [
    {
      type: "headed",
      heading: " Late Filing Penalty (Section 234F)",
      text: "",
      hasList: true,
      list: [
        "A penalty of up to Rs. 10,000 is levied for filing the tax return after the due date. For small taxpayers with an income of up to Rs. 5 lakh, the maximum penalty is Rs. 1,000.",
      ],
    },
    {
      type: "headed",
      heading: "Interest on Late Payment (Section 234A)",
      text: "",
      hasList: true,
      list: [
        "Interest at the rate of 1% per month or part of the month is charged on the unpaid tax amount from the due date of filing until the date of actual filing.",
      ],
    },
    {
      type: "headed",
      heading:
        "Interest on Default in Payment of Advance Tax (Section 234B and 234C)",
      text: "",
      hasList: true,
      list: [
        "Interest is charged for default or shortfall in payment of advance tax. Section 234B applies if advance tax paid is less than 90% of the assessed tax, and Section 234C applies to deferment of instalment payments.",
      ],
    },
    {
      type: "headed",
      heading: " Penalty for Concealment of Income (Section 270A)",
      text: "",
      hasList: true,
      list: [
        "A penalty ranging from 50% to 200% of the tax evaded is imposed for underreporting or misreporting of income.",
      ],
    },
    {
      type: "headed",
      heading: "Fee for Default in Furnishing Return (Section 271F)",
      text: "",
      hasList: true,
      list: [
        "A fee of Rs. 5,000 may be levied for failure to furnish the return of income by the due date.",
      ],
    },
  ],
  steps: [
    {
      type: "headed",
      heading: "Gather Required Documents",
      text: "Collect all necessary documents, including:",
      hasList: true,
      list: [
        "Financial statements (balance sheet, profit and loss statement)",
        "Bank statements",
        "Purchase and sales invoices",
        "Expense receipts",
        "Previous year’s tax return",
        "TDS (Tax Deducted at Source) certificates",
      ],
    },
    {
      type: "headed",
      heading: "Choose the Appropriate ITR Form",
      text: "Select the correct Income Tax Return (ITR) form based on the type of business:",
      hasList: true,
      list: [
        "ITR-3: For individuals and HUFs with income from business or profession.",
        "ITR-4 (Sugam): For individuals, HUFs, and firms opting for presumptive income scheme under Sections 44AD, 44ADA, or 44AE.",
        "ITR-5: For firms, LLPs, AOPs, BOIs, and other entities.",
        "ITR-6: For companies other than those claiming exemption under Section 11.",
      ],
    },
    {
      type: "headed",
      heading: "Compute Total Income",
      text: "",
      hasList: true,
      list: [
        "Calculate the total income by adding income from all sources, such as business income, capital gains, rental income, and other sources.",
      ],
    },
    {
      type: "headed",
      heading: "Claim Deductions",
      text: "Claim eligible deductions under various sections, such as:",
      hasList: true,
      list: [
        "Section 80C: Investments in PPF, NSC, ELSS, etc.",
        "Section 80D: Health insurance premiums.",
        "Section 80G: Donations to specified funds and charitable institutions.",
      ],
    },
    {
      type: "headed",
      heading: "Compute Tax Liability",
      text: "",
      hasList: true,
      list: [
        "Compute the tax liability based on the applicable tax rates and total income after deductions.",
      ],
    },
    {
      type: "headed",
      heading: " Pay Advance Tax",
      text: "",
      hasList: true,
      list: [
        "If the tax liability exceeds Rs. 10,000, pay advance tax in installments as per the due dates to avoid interest under Section 234B and 234C.",
      ],
    },
    {
      type: "headed",
      heading: "File the Return",
      text: "",
      hasList: true,
      list: [
        "File the tax return online through the income tax department’s e-filing portal. Verify the return using one of the available methods (Aadhaar OTP, net banking, etc.).",
      ],
    },
    {
      type: "headed",
      heading: "Verify the Return",
      text: "",
      hasList: true,
      list: [
        "After filing, verify the return within 120 days. This can be done electronically or by sending a signed physical copy to the Centralized Processing Center (CPC).",
      ],
    },
  ],
};
export const additionalTradeLines = {
  whatIs: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "A trade license is an official document issued by the Municipal Corporation of a state, granting permission to conduct a specific trade or business within a designated area or location. It ensures that businesses adhere to safety standards set by the State Municipal Corporation, safeguarding residents from potential health hazards. All businesses falling under the purview of the State Municipal Corporation Act must acquire a trade license.",
      ],
    },
  ],
  whyNecassary: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Introduced in India four decades ago, trade licences are governed by the Municipal Corporation Acts of respective state governments. They aim to prevent the negative impact of trade or business activities on public health and maintain ethical business practices. By regulating trade activities, trade licences promote societal harmony, ensuring businesses comply with rules, guidelines, and safety measures.",
      ],
    },
  ],
  mainObjectives: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Regulate and monitor business activities within a specific area or municipality.",
        "Prevent anti-competitive practices among businesses.",
        "Ensure compliance with corporation or government regulations.",
        "Prevent businesses from breaching laws or causing nuisance.",
        "Regulate the use of hazardous substances to protect people and the environment.",
        "Promote the use of environmentally friendly practices.",
      ],
    },
  ],
  whoIssues: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Trade licences are issued by the licensing department of the Municipal Corporation, covering various departments such as industries, engineering, and health. The issuance process varies from state to state, depending on the rules and regulations of local government agencies.",
      ],
    },
  ],
  differentCategories: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Industries License: For small, medium, and large-scale manufacturing factories.",
        "Shop License: For businesses including dangerous and offensive trades, barber shops, clinics, and medical stores.",
        "Food Establishment License: For restaurants, hotels, food stalls, and bakeries",
      ],
    },
  ],
  eligibility: [
    {
      type: "headed",
      heading: "Eligibility:",
      text: "",
      hasList: true,
      list: [
        "Applicants must be over 18 years old and have no criminal records.",
        "The business must be legally permissible.",
      ],
    },
  ],
  applicationProcess: [
    {
      type: "headed",
      heading: "Application Process:",
      text: "",
      hasList: true,
      list: [
        "Applicants can apply online or offline, depending on the municipal corporation's guidelines.",
        "The process generally involves filling out an application form, submitting required documents, and paying the applicable fees.",
      ],
    },
  ],
  docsRequired: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Address proof of the business",
        "ID proof of the applicant",
        "PAN card",
        "Memorandum of Association (MOA) and Certificate of Incorporation (for companies)",
        "Certificate of registration (for partnership firms and LLPs)",
        "Municipal property tax receipt or lease document",
      ],
    },
  ],
  licenseFeenTime: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Fees vary by state and may be charged annually or based on business turnover.",
        "The processing time typically ranges from 7 to 30 days, depending on the municipality.",
      ],
    },
  ],
  renewalCheck: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Trade licences must be renewed annually.",
        "Applicants can check the status of their application and download the license certificate from the municipal corporation's website.",
        "Renewal can also be done online by following the prescribed steps.",
      ],
    },
  ],
};
export const startupIndiaLines = {
  features: [
    {
      type: "headed",
      heading: "Official Recognition",
      text: "",
      hasList: true,
      list: [
        "Startups receive a Certificate of Recognition from the Department for Promotion of Industry and Internal Trade (DPIIT). This recognition opens up a plethora of benefits and opportunities for startups.",
      ],
    },
    {
      type: "headed",
      heading: "Support and Incentives",
      text: "",
      hasList: true,
      list: [
        "Recognized startups benefit from various government schemes, tax exemptions, and simplified compliance processes. This support helps startups focus on innovation and growth.",
      ],
    },
    {
      type: "headed",
      heading: "Funding Opportunities",
      text: "",
      hasList: true,
      list: [
        "Startups get increased access to funding through government programs, venture capital, angel investors, and other financial incentives. The initiative also facilitates connections with potential investors.",
      ],
    },
    {
      type: "headed",
      heading: "Networking and Mentorship",
      text: "",
      hasList: true,
      list: [
        "Startups can access a vast network of industry experts, mentors, and other stakeholders who provide valuable guidance and support to help startups grow and succeed.",
      ],
    },
    {
      type: "headed",
      heading: "IPR Benefits",
      text: "",
      hasList: true,
      list: [
        "Startups can avail themselves of significant cost reductions in patent and trademark filings. The initiative provides easier facilitation for Intellectual Property Rights (IPR) protection, ensuring startups can safeguard their innovations.",
      ],
    },
  ],
  eligibility: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Age of Entity: Must not exceed 10 years from the date of incorporation.",
        "Type of Entity: Must be registered as a Private Limited Company, Limited Liability Partnership, or a Registered Partnership Firm.",
        "Annual Turnover: Must not exceed Rs. 100 crore in any of the financial years since incorporation.",
        "Innovative and Scalable: The entity must be working towards innovation, development, or improvement of products or processes, and must have the potential to generate employment or create wealth.",
      ],
    },
  ],
  benefits: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Priority Access to Government Tenders: Startups receive priority access to various government tenders and contracts.",
        "Easy Access to Collateral-Free Bank Loans: Startups can access collateral-free loans under various government schemes.",
        "Interest Rate Exemption on Bank Overdrafts: Startups can avail a 1% interest rate exemption on bank overdrafts.",
        "Eligibility for Tax Rebates: Startups can benefit from tax exemptions for three consecutive years out of the first ten years since incorporation.",
        "Priority Consideration for Licensing and Certification: Startups receive priority consideration for various licences and certifications.",
        "Tariff and Capital Subsidies: Startups can avail tariff and capital subsidies under various government schemes.",
        "Discounts on Electricity Bills: Startups can avail discounts on electricity bills under certain conditions.",
        "50% Discount on Government Fees for Trademarks and Patent Filing: Startups can avail significant cost reductions in trademark and patent filings.",
      ],
    },
  ],
  regProcess: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Online Application: Visit the Startup India website and fill out the registration form with accurate details about the startup.",
        "Document Submission: Submit the required documents, including a Certificate of Incorporation, detailed description of the business, and supporting documents for the innovation claim.",
        "Verification: The submitted application and documents are verified by the concerned authorities.",
        "Recognition: Upon successful verification, a Certificate of Recognition is issued, granting the startup access to various benefits under the Startup India initiative.",
      ],
    },
  ],
  docsRequired: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Certificate of Incorporation",
        "PAN Card of the business",
        "Aadhaar Card of the founder",
        "Business plan or pitch deck",
        "Proof of concept or prototype development (if applicable)",
        "Financial statements (if applicable)",
        "Details of patents or trademarks (if applicable)",
      ],
    },
  ],
};
export const digitalSignatureLines = {
  importance: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Security: Digital signatures provide a high level of security for online transactions and communications, protecting against tampering and fraud.",
        "Authenticity: Ensures that the document or message comes from a verified source.",
        "Integrity: Confirms that the content of the document has not been altered since it was signed.",
        "Non-repudiation: Prevents the signer from denying their signature on a document or transaction.",
      ],
    },
  ],
  uses: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "E-Governance: Filing income tax returns, applying for licences, and other government-related processes.",
        "Business Transactions: Signing contracts, agreements, and invoices.",
        "Legal Documentation: Signing legal documents, court submissions, and affidavits.",
        "Email Encryption: Ensuring secure email communications.",
        "Banking: Authorizing financial transactions and bank-related processes.",
      ],
    },
  ],
  types: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Class 1: Basic level security, used for securing email communications.",
        "Class 2: Used for filing documents with the Registrar of Companies (ROC), Income Tax Department, and other government agencies.",
        "Class 3: High-level security, used for e-tendering, e-procurement, and other high-value transactions.",
      ],
    },
  ],
  docsRequired: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Identity proof (PAN card, Aadhaar card, passport, etc.)",
        "Address proof (Electricity bill, telephone bill, etc.)",
        "Passport-size photograph",
      ],
    },
  ],
  applicationProcess: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Application Submission: Fill out the application form for DSC online or offline.",
        "Document Verification: Submit the required documents for verification.",
        "Payment of Fees: Pay the applicable fees for the DSC.",
        "Issuance of DSC: Once verified, the digital signature certificate is issued and can be used for various purposes.",
      ],
    },
  ],
};
export const importExportCodeLines = {
  importance: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Unlock International Markets: The IEC is a mandatory requirement for any individual or entity engaged in importing and exporting goods and services from India. It facilitates the entry of Indian companies into international markets, promoting growth and expansion.",
        "Effortless Online Registration: IEC registration is a hassle-free process conducted entirely online, ensuring a smooth and efficient registration process.",
        "Minimal Document Requirements: The required documents include proof of registration/incorporation certificate, proof of address, PAN card, and a cancelled cheque.",
        "Lifetime Validity: The IEC registration offers lifetime validity, eliminating the need for periodic updates or renewals as long as the business remains operational.",
        "Prevention of Illegal Goods Transportation: IEC ensures that only authentic and verified information is provided, making the transportation of illegal goods impossible.",
      ],
    },
  ],
  prerequisite: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Business Entity Registration",
        "Valid PAN Card",
        "Bank Account",
        "Business Address Proof",
        "Constitution of Business",
        "Digital Signature Certificate (DSC)",
        "GST Registration (if applicable)",
      ],
    },
  ],
  natureOfFirms: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Proprietorship Firm",
        "Partnership Firm",
        "Limited Liability Partnership",
        "Private Limited Company",
        "Trust",
        "Hindu Undivided Family (HUF)",
        "Society",
      ],
    },
  ],
  validity: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Once issued, the Import Export Code is valid for the lifetime of the entity and does not require renewal. However, any changes in details such as address, constitution, or ownership of the business must be updated with the DGFT.",
      ],
    },
  ],
  applicationProcess: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "The application for IEC can be submitted online through the DGFT's official website. Applicants need to fill out the prescribed application form (Aayaat Niryaat Form - ANF 2A) and submit the required documents electronically. Once submitted, the application is processed by the DGFT, and if all requirements are met, the IEC is issued within a few working days.",
      ],
    },
  ],
};
export const udyamLines = {
  benefits: [
    {
      type: "headed",
      heading: "Benefits of Udyam Registration",
      text: "",
      hasList: true,
      list: [
        "Priority Access to Government Tenders: Registered MSMEs receive priority access to various government tenders, helping them secure contracts more easily.",
        "Easy Access to Collateral-Free Bank Loans: MSMEs can access collateral-free loans under various government schemes, promoting financial inclusion and growth.",
        "1% Interest Rate Exemption on Bank Overdrafts: Businesses can benefit from a 1% interest rate exemption on bank overdrafts, reducing their financial burden.",
        "Eligibility for Tax Rebates: Registered MSMEs can avail tax rebates, enhancing their profitability and competitiveness.",
        "Priority Consideration for Licensing and Certification: MSMEs receive priority consideration for various licences and certifications, streamlining their operations.",
        "Tariff and Capital Subsidies: Registered MSMEs can access tariff and capital subsidies, reducing their operational costs.",
        "Discounts on Electricity Bills: MSMEs can avail discounts on electricity bills, lowering their utility expenses.",
        "50% Discount on Government Fees for Trademarks and Patent Filing: Registered MSMEs can avail significant cost reductions in trademark and patent filings.",
      ],
    },
  ],
  renewal: [
    {
      type: "headed",
      heading: "Renewal of Udyam Registration",
      text: "",
      hasList: true,
      list: [
        "The Udyam Registration Number is a permanent identification number that is valid for a lifetime. No renewal is required, ensuring uninterrupted benefits for registered MSMEs.",
      ],
    },
  ],
  whoCanApply: [
    {
      type: "headed",
      heading: "Who Can Apply for Udyam Registration",
      text: "",
      hasList: true,
      list: [
        "Proprietorships",
        "Hindu Undivided Families (HUF)",
        "Partnerships",
        "One Person Companies",
        "Private Limited Companies",
        "Public Limited Companies",
        "Limited Liability Partnerships (LLP)",
        "Co-operative Societies",
      ],
    },
  ],
  docsRequired: [
    {
      type: "headed",
      heading: "Documents Required",
      text: "",
      hasList: true,
      list: [
        "PAN Card",
        "Aadhaar Card",
        "Business address proof",
        "Bank account details",
      ],
    },
  ],
};
export const additionalfssaiLines = {
  whoNeeds: [
    {
      type: "headed",
      heading: "Who Needs FSSAI Registration?",
      text: "",
      hasList: true,
      list: [
        "Any individual or entity involved in the manufacturing, processing, packaging, storage, transportation, distribution, or sale of food products must obtain FSSAI registration. This includes food manufacturers, processors, wholesalers, retailers, distributors, exporters, importers, restaurants, caterers, food service providers, and online food businesses.",
      ],
    },
  ],
  types: [
    {
      type: "headed",
      heading: "Types of FSSAI Registration",
      text: "",
      hasList: true,
      list: [
        "FSSAI Basic Registration: For small food businesses with an annual turnover of up to Rs. 12 lakh. Suitable for street vendors, hawkers, small-scale manufacturers, and petty retailers.",
        "State FSSAI License: For food businesses with an annual turnover ranging from more than Rs. 12 lakh to Rs. 20 crore. Suitable for medium-sized food enterprises operating within a single state.",
        "Central FSSAI License: For food businesses with an annual turnover exceeding Rs. 20 crore. Mandatory for large-scale food establishments operating in multiple states or involved in import/export operations.",
      ],
    },
  ],
  docsRequired: [
    {
      type: "headed",
      heading: "Documents Required for FSSAI Registration",
      text: "",
      hasList: true,
      list: [
        "Identity proof (PAN card, Aadhaar card, Voter ID, etc.)",
        "Address proof (Electricity bill, Rent agreement, etc.)",
        "Business entity proof (Certificate of incorporation, Partnership deed, etc.)",
        "Food safety management plan (for manufacturers)",
        "List of food products to be manufactured or traded",
        "Authority letter or nominee details",
        "Declaration form",
      ],
    },
  ],
  regProcess: [
    {
      type: "headed",
      heading: "FSSAI Registration Process",
      text: "",
      hasList: true,
      list: [
        "Application Preparation: Gather all necessary documents and information required for FSSAI registration.",
        "Online Application Submission: Visit the FSSAI website and fill out the online registration form with accurate details.",
        "Document Verification: FSSAI authorities will verify the submitted documents and information.",
        "Approval and Issuance: Once verification is complete and all requirements are met, FSSAI registration is approved, and a unique FSSAI license number is issued.",
        "License Renewal: FSSAI registration/license must be renewed periodically as per the prescribed schedule.",
      ],
    },
  ],
};
export const additionalRegProTaxLines = {
  whoNeeds: [
    {
      type: "headed",
      heading: "Who Needs to Register for Professional Tax",
      text: "",
      hasList: true,
      list: [
        "E-Commerce sellers registered under GST in every state.",
        "Self-employed individuals such as doctors, lawyers, architects, and chartered accountants.",
        "Employers who have employed staff members paid salaries.",
        "Business owners engaged in trades or professions.",
        "Freelancers providing services to clients.",
        "Directors of companies receiving a salary.",
      ],
    },
  ],
  docsRequired: [
    {
      type: "headed",
      heading: "Documents Required",
      text: "",
      hasList: true,
      list: [
        "Address proof of establishment",
        "PAN of the business",
        "KYC of proprietor, partner, or directors",
        "Photograph of proprietor, partner, or directors",
        "Salary details of employees (if applicable)",
        "CIN certificate in case of company or LLP",
      ],
    },
  ],
  regProcess: [
    {
      type: "headed",
      heading: "Application Preparation",
      text: "Gather all necessary documents and information required for FSSAI registration.",
    },
    {
      type: "headed",
      heading: "Online Application Submission",
      text: "Visit the FSSAI website and fill out the online registration form with accurate details.",
    },
    {
      type: "headed",
      heading: "Document Verification",
      text: "The FSSAI authorities will verify the submitted documents and information.",
    },
    {
      type: "headed",
      heading: "Approval and Issuance",
      text: "Once verification is complete and all requirements are met, FSSAI registration is approved, and a unique FSSAI license number is issued.",
    },
    {
      type: "headed",
      heading: "License Renewal",
      text: "FSSAI registration/license must be renewed periodically as per the prescribed schedule.",
    },
  ],
  states: [
    {
      type: "headed",
      heading: "States Where Professional Tax is Imposed",
      text: " Professional tax is imposed in the following states:",
      hasList: true,
      list: [
        " Karnataka",
        "Bihar",
        "West Bengal",
        " Andhra Pradesh",
        " Telangana",
        " Maharashtra",
        " Tamil Nadu",
        " Gujarat",
        " Assam",
        " Kerala",
        "Meghalaya",
        "Odisha",
        " Tripura",
        " Madhya Pradesh",
        "and Sikkim",
      ],
    },
  ],
};
export const personalTaxFilingLines = {
  importance: [
    {
      type: "headed",
      heading: "Legal Compliance",
      text: "",
      hasList: true,
      list: [
        "Filing your taxes ensures you are complying with the tax laws of your country. Non-compliance can result in penalties, interest, and legal consequences.",
      ],
    },
    {
      type: "headed",
      heading: "Claiming Deductions and Exemptions",
      text: "",
      hasList: true,
      list: [
        "Proper tax filing allows you to claim deductions, exemptions, and credits, which can reduce your overall tax liability.",
      ],
    },
    {
      type: "headed",
      heading: "Avoiding Penalties",
      text: "",
      hasList: true,
      list: [
        "Filing your taxes on time helps avoid late filing penalties and interest on unpaid taxes.",
      ],
    },
    {
      type: "headed",
      heading: "Income Proof",
      text: "",
      hasList: true,
      list: [
        "A filed tax return acts as proof of income for various financial transactions, such as applying for loans, visas, and credit cards.",
      ],
    },
    {
      type: "headed",
      heading: "Carry Forward Losses",
      text: "",
      hasList: true,
      list: [
        "Filing your tax returns allows you to carry forward certain losses to future years, which can be set off against future income.",
      ],
    },
  ],
  steps: [
    {
      type: "headed",
      heading: "Gather Required Documents",
      text: "",
      hasList: true,
      list: [
        "Before filing your tax return, gather all necessary documents, including:",
        "Form 16 (for salaried individuals)",
        "Form 26AS (tax credit statement)",
        "Bank statements",
        "Investment proofs",
        "Receipts for deductions (e.g., insurance premiums, home loan interest)",
        "Proof of other income (e.g., rental income, interest income)",
        "Proof of other income (e.g., rental income, interest income)",
      ],
    },
    {
      type: "headed",
      heading: "Choose the Right Tax Form",
      text: "",
      hasList: true,
      list: [
        "Select the appropriate Income Tax Return (ITR) form based on your income sources:",
        "ITR-1 (Sahaj): For individuals with income from salary, one house property, other sources (interest, etc.), and having total income up to Rs. 50 lakh.",
        "ITR-2: For individuals and HUFs not having income from business or profession.",
        "ITR-3: For individuals and HUFs having income from business or profession.",
        "ITR-4 (Sugam): For individuals, HUFs, and firms opting for presumptive income scheme.",
      ],
    },
    {
      type: "headed",
      heading: "Calculate Your Total Income",
      text: "",
      hasList: true,
      list: [
        "Calculate your total income by adding all income sources, such as salary, house property, capital gains, business or profession, and other sources.",
      ],
    },
    {
      type: "headed",
      heading: "Claim Deductions",
      text: "",
      hasList: true,
      list: [
        "Claim deductions under various sections of the Income Tax Act, such as:",
        "Section 80C: Deductions for investments in PPF, EPF, NSC, etc.",
        "Section 80D: Deductions for health insurance premiums.",
        "Section 24: Deductions for home loan interest.",
        "Section 80E: Deductions for education loan interest",
      ],
    },
    {
      type: "headed",
      heading: "Compute Tax Liability",
      text: "",
      hasList: true,
      list: [
        "After claiming deductions, compute your tax liability based on the applicable tax slabs.",
      ],
    },
    {
      type: "headed",
      heading: "Pay Taxes",
      text: "",
      hasList: true,
      list: [
        "If you have any outstanding tax liability, pay it before filing your return. This can be done online through the income tax department's website.",
      ],
    },
    {
      type: "headed",
      heading: "File Your Return",
      text: "",
      hasList: true,
      list: [
        "File your income tax return online through the e-filing portal of the income tax department. Verify the return using one of the available methods (Aadhaar OTP, net banking, etc.).",
      ],
    },
    {
      type: "headed",
      heading: "Verify Your Return",
      text: "",
      hasList: true,
      list: [
        "After filing, verify your return within 120 days. This can be done online through the e-filing portal or by sending a physical copy to the Centralized Processing Center (CPC).",
      ],
    },
  ],
  deductions: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Section 80C: Deductions up to Rs. 1.5 lakh for investments in PPF, EPF, NSC, ELSS, etc",
        `Section 80D: Deductions for health insurance premiums up to Rs. 25,000 for individuals and Rs. 50,000 for senior citizens.`,
        `Section 24: Deductions up to Rs. 2 lakh for home loan interest.`,
        `Section 80E: Deductions for interest on education loans with no upper limit.`,
        `House Rent Allowance (HRA): Exemption for house rent paid, subject to certain conditions.`,
      ],
    },
  ],
};
export const bookkeepingLines = {
  benefits: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Financial Management: Accurate bookkeeping provides precise records of financial transactions, enabling effective financial management and decision-making.",
        "Compliance: Proper accounting ensures compliance with tax regulations, accounting standards, and reporting requirements, minimizing the risk of penalties or legal consequences.",
        "Performance Evaluation: Financial statements generated through accounting processes allow businesses to assess their financial performance, identify trends, and make informed decisions to improve efficiency and profitability.",
        "Planning and Forecasting: Historical financial data helps businesses forecast future trends, set realistic goals, and develop strategic plans for growth and expansion.",
        "Investor Confidence: Transparent financial reporting builds trust and confidence among investors, lenders, and stakeholders, facilitating access to capital and investment opportunities.",
        "Tax Optimization: Accurate records enable businesses to identify tax deductions, credits, and incentives, optimizing tax planning strategies and minimizing tax liabilities.",
        "Business Insights: Bookkeeping provides valuable insights into business operations, expenses, and revenue sources, helping identify areas for cost reduction, revenue enhancement, and operational improvements.",
      ],
    },
  ],
  docsRequired: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Invoices and receipts",
        "Purchase-sales invoices",
        "Payroll records",
        "Bank statements of a financial year or monthly statement",
        "Expense records",
        "Financial statements",
        "Any other relevant financial or business documents specific to the company's operations",
      ],
    },
  ],
};
export const payrollLines = {
  benefits: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Accuracy: Ensures precise calculation of employee salaries, wages, deductions, and taxes, reducing the risk of errors.",
        "Compliance: Adheres to employment laws and tax regulations, minimizing the risk of penalties and legal issues.",
        "Efficiency: Streamlines payroll processes, saving time and resources for the business.",
        "Employee Satisfaction: Timely and accurate payments enhance employee morale and trust in the company.",
        "Record Keeping: Maintains detailed records of employee compensation, facilitating audits and financial planning.",
        "Tax Management: Proper payroll processing helps in managing tax withholdings, filings, and compliance.",
      ],
    },
  ],
  docsRequired: [
    {
      type: "headed",
      heading:
        "To provide payroll processing services, we require the following documents:",
      text: "",
      hasList: true,
      list: [
        "Employee details (name, address, bank account information)",
        "Salary structure and compensation details",
        "Attendance and leave records",
        "Timesheets",
        "Tax declaration forms",
        "Any other relevant documents specific to the company's payroll policies",
      ],
    },
  ],
};
export const rocDIRLines = {
  importance: [
    {
      type: "headed",
      heading:
        "DIR-3 KYC is essential for maintaining the integrity and transparency of corporate governance in India. It helps in:",
      text: "",
      hasList: true,
      list: [
        "Verifying the identity of directors",
        "Preventing the misuse of DINs",
        "Maintaining an updated database of active directors",
        "Ensuring compliance with regulatory requirements",
      ],
    },
  ],
  steps: [
    {
      type: "headed",
      heading: "Preparation",
      text: " Before filing the DIR-3 KYC, gather the following documents and information:",
      hasList: true,
      list: [
        "Director Identification Number (DIN)",
        "Personal Mobile Number and Email Address: Unique for each director",
        "Personal Identification Documents:",
        "PAN card",
        "Aadhaar card",
        "Passport (if available)",
        "Voter ID",
        "Address Proof: Utility bills, bank statements, etc.",
        "Photograph: Latest passport-size photograph",
      ],
    },
    {
      type: "headed",
      heading: "Form Filing",
      text: "The DIR-3 KYC form can be filed online through the MCA portal. There are two types of forms based on the director’s past compliance status:",
      hasList: true,
      list: [],
    },
    {
      type: "headed",
      heading: "DIR-3 KYC eForm",
      text: "For directors filing their KYC for the first time or those whose DIN status is 'Deactivated due to non-filing of DIR-3 KYC':",
      hasList: true,
      list: [
        "Download the DIR-3 KYC eForm from the MCA website.",
        "Fill in the required details, including personal, contact, and address information.",
        "Attach the necessary documents (self-attested copies of PAN, Aadhaar, etc.).",
        "Attach a digital signature certificate (DSC) of the director.",
        "Get the form certified by a practicing Chartered Accountant (CA), Company Secretary (CS), or Cost Accountant.",
        "Upload the form on the MCA portal.",
      ],
    },
    {
      type: "headed",
      heading: "DIR-3 KYC Web Form",
      text: "",
      hasList: true,
      list: [
        "Log in to the MCA portal.",
        "Select the DIR-3 KYC web form option.",
        "Verify and update the pre-filled details (if necessary).",
        "Submit the form using OTP verification (sent to the registered mobile number and email ID).",
      ],
    },
    {
      type: "headed",
      heading: "Verification",
      text: "After submission, the details are verified by the ROC. Upon successful verification, an acknowledgment is sent to the registered email ID, confirming the completion of the DIR-3 KYC process.",
      hasList: false,
      list: [],
    },
  ],
  penalties: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Deactivation of DIN: If DIR-3 KYC is not filed by the due date (usually September 30th each year), the DIN is deactivated.",
        "Late Fee: To reactivate the DIN, a late fee of Rs. 5,000 is applicable. The director must file the DIR-3 KYC along with the penalty to restore the DIN status.",
        "Select the DIR-3 KYC web form option.",
        "Verify and update the pre-filled details (if necessary).",
        "Submit the form using OTP verification (sent to the registered mobile number and email ID).",
        "Get the form certified by a practicing Chartered Accountant (CA), Company Secretary (CS), or Cost Accountant.",
        "Upload the form on the MCA portal.",
      ],
    },
  ],
};
export const changeInShareCapitalLines = {
  types: [
    {
      type: "headed",
      heading: "Increase in Authorized Share Capital",
      text: "",
      hasList: true,
      list: [
        "Purpose: To enable the company to issue additional shares in the future.",
        "Procedure: Requires an amendment to the company’s Memorandum of Association (MOA), approval by the Board of Directors, and passing a special resolution in a general meeting.",
      ],
    },
    {
      type: "headed",
      heading: "Issuance of New Shares",
      text: "",
      hasList: true,
      list: [
        "Purpose: To raise additional funds for the company.",
        "Procedure: Approval by the Board of Directors, allotment of shares, filing necessary forms with the Registrar of Companies (ROC).",
      ],
    },
    {
      type: "headed",
      heading: "Consolidation or Sub-Division of Shares",
      text: "",
      hasList: true,
      list: [
        "Purpose: To change the nominal value of shares without altering the total capital.",
        "Procedure: Approval by the Board of Directors, passing a resolution in a general meeting, and filing necessary forms with the ROC.",
      ],
    },
    {
      type: "headed",
      heading: "Conversion of Shares",
      text: "",
      hasList: true,
      list: [
        "Purpose: To convert shares from one class to another, such as from preference shares to equity shares.",
        "Procedure: Approval by the Board of Directors, special resolution in a general meeting, and necessary filings with the ROC.",
      ],
    },
    {
      type: "headed",
      heading: "Reduction of Share Capital",
      text: "",
      hasList: true,
      list: [
        "Purpose: To write off accumulated losses or return surplus capital to shareholders.",
        "Procedure: Approval by the Board of Directors, special resolution in a general meeting, confirmation by the National Company Law Tribunal (NCLT), and filing necessary forms with the ROC.",
      ],
    },
  ],
  steps: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Board Meeting: Convene a meeting of the Board of Directors to discuss and approve the proposed change in share capital.",
        "General Meeting: Hold a general meeting of shareholders to pass the required resolution (ordinary or special, depending on the nature of the change).",
        "Amendment of MOA: If increasing the authorized share capital, amend the company’s Memorandum of Association.",
        "Filing with ROC: File the necessary forms and resolutions with the Registrar of Companies within the prescribed time frame.",
        "Issuance of New Shares or Other Changes: If issuing new shares, proceed with the allotment and ensure compliance with relevant laws and regulations.",
      ],
    },
  ],
  docsRequired: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Notice of Board Meeting and General Meeting",
        "Copy of the resolution passed",
        "Amended Memorandum of Association (if applicable)",
        "Form SH-7 (for increase in authorized capital)",
        "Form PAS-3 (for allotment of shares)",
        "Form MGT-14 (for special resolutions)",
        "Confirmation order from NCLT (for reduction of share capital)",
      ],
    },
  ],
};

export const regOfficeAddressChangeLines = {
  types: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Within the Same City/Town/Village",
        "Within the Same State but Different City/Town/Village",
        "From One State to Another",
      ],
    },
  ],
  addressSteps: [
    {
      type: "headed",
      heading: "Steps",
      text: "",
      hasList: true,
      list: [
        "Board Meeting: Convene a Board Meeting to pass a resolution approving the change of address.",
        "Form INC-22: File Form INC-22 with the Registrar of Companies (ROC) within 15 days of the Board resolution.",
      ],
    },
  ],
  sameCityDocs: [
    {
      type: "headed",
      heading: "Documents Required",
      text: "",
      hasList: true,
      list: [
        "Certified copy of the Board resolution",
        "Proof of the new address (e.g., utility bill, rent agreement)",
        "No Objection Certificate (NOC) from the owner (if the property is rented)",
      ],
    },
  ],
  sameCityDifferentTownSteps: [
    {
      type: "headed",
      heading: "Steps",
      text: "",
      hasList: true,
      list: [
        "Board Meeting: Convene a Board Meeting to pass a resolution approving the change of address.",
        "Special Resolution: Obtain shareholder approval through an Extraordinary General Meeting (EGM) and pass a special resolution.",
        "Form MGT-14: File Form MGT-14 with the ROC within 30 days of passing the special resolution.",
        "Form INC-22: File Form INC-22 with the ROC within 15 days of filing Form MGT-14.",
      ],
    },
  ],
  sameCityDifferentTownDocs: [
    {
      type: "headed",
      heading: "Documents Required",
      text: "",
      hasList: true,
      list: [
        "Certified copy of the Board resolution",
        "Copy of the special resolution",
        "Proof of the new address",
        "NOC from the owner (if the property is rented)",
        "Altered Memorandum of Association (MOA) reflecting the new address (if required)",
      ],
    },
  ],
  oneStateToAnotherSteps: [
    {
      type: "headed",
      heading: "Steps",
      text: "",
      hasList: true,
      list: [
        "Board Meeting: Convene a Board Meeting to pass a resolution approving the change of address.",
        "Special Resolution: Obtain shareholder approval through an EGM and pass a special resolution.",
        "Form MGT-14: File Form MGT-14 with the ROC within 30 days of passing the special resolution.",
        "Application to Regional Director (RD): File an application with the RD in Form INC-23, seeking approval for the address change.",
        "Notice of Application: Publish a notice of the application in two newspapers (one English and one vernacular) and send individual notices to creditors and other stakeholders.",
        "Obtain RD Approval: Attend the hearing (if required) and obtain the RD's approval.",
        "Form INC-22: File Form INC-22 with the ROC within 30 days of receiving RD approval.",
        "Form INC-28: File Form INC-28 with the ROC, along with a certified copy of the RD’s order.",
      ],
    },
  ],
  oneStateToAnotherDocs: [
    {
      type: "headed",
      heading: "Documents Required:",
      text: "",
      hasList: true,
      list: [
        "Certified copy of the Board resolution",
        "Copy of the special resolution",
        "Proof of the new address",
        "NOC from the owner (if the property is rented)",
        "Copy of newspaper advertisements",
        "Acknowledgment of notice sent to creditors and other stakeholders",
        "Altered MOA reflecting the new address",
      ],
    },
  ],
};
export const addRemoveDirectorLines = {
  reasons: [
    {
      type: "headed",
      heading: "Reasons for Adding Directors",
      text: "",
      hasList: true,
      list: [
        "Bringing in specialized expertise or experience",
        "Increasing the number of directors to meet statutory requirements",
        "Replacing a retiring or resigning director",
        "Strengthening the board’s decision-making capacity",
      ],
    },
  ],
  steps: [
    {
      type: "headed",
      heading: "Steps to Add a Director",
      text: "",
      hasList: true,
      list: [
        "Obtain Consent: The proposed director must provide written consent to act as a director in Form DIR-2.",
        "Board Meeting: Convene a Board Meeting to pass a resolution for the appointment of the director. The notice of the meeting should be sent to all directors.",
        "Director Identification Number (DIN): Ensure the proposed director has a valid Director Identification Number (DIN). If not, apply for a DIN through Form DIR-3.",
        "Shareholders’ Approval: If required by the Articles of Association (AOA) or the Companies Act, 2013, convene a General Meeting to obtain shareholders’ approval for the appointment.",
        "Form Filing: File Form DIR-12 with the Registrar of Companies (ROC) within 30 days of the appointment. Attach necessary documents such as the Board resolution, consent letter (DIR-2), and notice of the meeting.",
        "Update Registers: Update the Register of Directors and Key Managerial Personnel, and make necessary entries in the company’s records.",
      ],
    },
  ],
  docsRequired: [
    {
      type: "headed",
      heading: "Documents Required",
      text: "",
      hasList: true,
      list: [
        "Notice of Board Meeting",
        "Board resolution for appointment",
        "Consent to act as a director (Form DIR-2)",
        "Copy of DIN allotment letter (if applicable)",
        "Shareholders’ resolution (if required)",
        "Form DIR-12",
      ],
    },
  ],
  removeReasons: [
    {
      type: "headed",
      heading: "Reasons for Removing Directors",
      text: "",
      hasList: true,
      list: [
        "Non-performance or inactivity",
        "Conflict of interest or breach of fiduciary duty",
        "Legal or regulatory non-compliance",
        "Resignation submitted by the director",
        "Other reasons specified by the board or shareholders",
      ],
    },
  ],
  removeSteps: [
    {
      type: "headed",
      heading: "Steps to Remove a Director",
      text: "",
      hasList: true,
      list: [
        "Board Meeting: Convene a Board Meeting to discuss the removal of the director and pass a resolution to call for a General Meeting. Send the notice to all directors.",
        "Special Notice: If required, a special notice must be given to the director proposed to be removed, giving them an opportunity to be heard at the General Meeting.",
        "General Meeting: Convene a General Meeting where the shareholders vote on the resolution to remove the director. The director has the right to present their case at the meeting.",
        "Form Filing: File Form DIR-12 with the ROC within 30 days of the resolution being passed. Attach necessary documents such as the Board resolution, special notice, and shareholders’ resolution.",
        "Update Registers: Update the Register of Directors and Key Managerial Personnel, and make necessary entries in the company’s records.",
      ],
    },
  ],
  removeDocs: [
    {
      type: "headed",
      heading: "Documents Required",
      text: "",
      hasList: true,
      list: [
        "Notice of Board Meeting",
        "Board resolution for removal",
        "Special notice to the director (if applicable)",
        "Shareholders’ resolution",
        "Form DIR-12",
      ],
    },
  ],
};
export const rocCompliancePVTLTDLines = {
  requirements: [
    {
      type: "headed",
      heading: " Annual General Meeting (AGM)",
      text: "",
      hasList: true,
      list: [
        "Due Date: Within six months from the end of the financial year.",
        "Description: Every Private Limited Company must hold an AGM every year to discuss the company’s financial statements and other significant matters.",
        "Penalties: Failure to hold an AGM can result in a fine of up to ₹1 lakh for the company and up to ₹5,000 per day for continuing default for each officer in default.",
      ],
    },
    {
      type: "headed",
      heading: " Annual Return (Form MGT-7)",
      text: "",
      hasList: true,
      list: [
        "Due Date: Within 60 days from the date of the AGM.",
        "Description: The annual return is a comprehensive document that contains details about the company’s management, shareholders, and other relevant information.",
        "Penalties: Late filing can attract a penalty of ₹100 per day of default. Additionally, the company and every officer in default may face fines ranging from ₹50,000 to ₹5 lakhs.",
      ],
    },
    {
      type: "headed",
      heading: "Financial Statements (Form AOC-4)",
      text: "",
      hasList: true,
      list: [
        "Due Date: Within 30 days from the date of the AGM.",
        "Description: Financial statements, including the balance sheet, profit and loss account, and cash flow statement, must be filed with the ROC.",
        "Penalties: Late filing can result in a penalty of ₹100 per day of default. The company and officers in default may also face additional fines.",
      ],
    },
    {
      type: "headed",
      heading: "Director KYC (Form DIR-3 KYC)",
      text: "",
      hasList: true,
      list: [
        "Due Date: 30th September of the following financial year.",
        "Description: Directors of the company must submit their KYC details to ensure their information is up-to-date in the MCA records.",
        "Penalties: Non-filing can result in the deactivation of the DIN (Director Identification Number) and a penalty of ₹5,000 upon later filing",
      ],
    },
    {
      type: "headed",
      heading: "Form ADT-1",
      text: "",
      hasList: true,
      list: [
        "Due Date: Within 15 days of the AGM.",
        "Description: This form is used to appoint or re-appoint an auditor for the company.",
        "Penalties: Late filing can attract a penalty of ₹100 per day of default.",
      ],
    },
    {
      type: "headed",
      heading: "Form MBP-1",
      text: "",
      hasList: true,
      list: [
        "Due Date: During the first board meeting of the financial year.",
        "Description: Directors must disclose their interest in other entities to avoid conflicts of interest.",
        "Penalties: Failure to disclose can result in fines ranging from ₹50,000 to ₹1 lakh for the director.",
      ],
    },
    {
      type: "headed",
      heading: "Form DPT-3",
      text: "",
      hasList: true,
      list: [
        "Due Date: 30th June every year.",
        "Description: This form is used to furnish details about deposits or outstanding loans or money received by the company.",
        "Penalties: Non-compliance can result in fines up to ₹5,000 and additional penalties of ₹500 per day for continuing default.",
      ],
    },
    {
      type: "headed",
      heading: "Form MSME-1",
      text: "",
      hasList: true,
      list: [
        "Due Date: 30th April (for October to March) and 31st October (for April to September).",
        "Description: This form is used to report dues to Micro or Small Enterprises.",
        "Penalties: Non-compliance can lead to fines that may extend to ₹25,000 for the company and up to ₹3,000 per day for continuing default for each officer in default.",
      ],
    },
  ],
};
export const gstRevocationLines = {
  reasons: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Non-Filing of Returns: If a registered taxpayer fails to file GST returns for a specified period, the GST department may initiate cancellation proceedings.",
        "Non-Payment of Taxes: Failure to pay taxes due within the prescribed time frame can lead to cancellation of GST registration.",
        "Non-Compliance with Law: Violation of GST laws, such as fraudulent activities, suppression of sales, or misrepresentation of facts, can result in cancellation.",
        "Invalid Registration: GST registrations obtained through misrepresentation of facts or fraud may be cancelled by the department (doesn’t conduct any business from the declared principal place of business).",
        "Other Statutory Reasons: The GST department may cancel registrations for reasons stipulated under the GST law or based on specific provisions.",
      ],
    },
  ],
  processCancellation: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Show cause notice (SCN)- The GST department initiates the process by issuing a Show cause notice to the taxpayer and it provides the taxpayer an opportunity to respond to the notices issued.",
        "Response submission - The taxpayer must submit the response to the show- cause notice in stipulated time frame, addressing the issue and providing proper explanation and evidence to support.",
        "Adjudication- The response received from the taxpayer, the department may conduct further inquiry. This may involve the physical verification of the Principal place of business, verification of the documents and records.",
        "Cancellation order - If the department finds the sufficient grounds for cancellation (Physical verification failed or finds fraud in the records). It issues an order for the cancellation of GSTIN. It specifies the effective date of cancellation and other relevant details.",
        "GSTIN Deactivation - The GSTIN allotted to the taxpayer is deactivated and the taxpayer is no longer authorized to conduct business activities.",
        "Appeal Process- If the application for revocation is rejected by the GST authorities, the taxpayer has the option to file an appeal with the appropriate authority within the prescribed time frame.",
      ],
    },
  ],
  processRevocation: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Eligibility: Determine if you are eligible for revocation of GST cancellation based on the reasons for cancellation and any prescribed conditions or requirements.",
        "Prepare Application: Gather necessary documents and information to support your application for revocation. This may include:",
        "Application form for revocation of cancellation (if available).",
        "Details of the reasons for cancellation and steps taken to rectify them.",
        "Any additional documents requested by the GST authorities.",
        "Submit Application: File an application for revocation of GST cancellation with the appropriate GST authorities. ",
        "Review and Verification: The GST authorities will review your application and supporting documents. They may conduct physical verification of the Principal place of business or scrutiny to ensure compliance with GST laws and regulations.",
        "Decision: Based on the assessment, the GST authorities will decide whether to approve or reject your application for revocation of cancellation. If approved, they will issue an order for revocation.",
        "GSTIN Reactivation: Upon issuance of the revocation order, your GST Identification Number (GSTIN) will be reactivated, allowing you to resume your business activities under the GST regime.",
      ],
    },
  ],
};
export const gstAppealLines = {
  keyStage: [
    {
      type: "headed",
      heading: "Appeal to the First Appellate Authority",
      text: "",
      hasList: true,
      list: [
        "Initial Stage: This is the first step where a taxpayer can appeal against the decision of the GST officer.",
        "Timeline: The appeal must be filed within three months from the date of communication of the decision or order.",
        "Pre-Deposit Requirement: The taxpayer must pay a pre-deposit of 10% of the disputed amount before filing the appeal",
      ],
    },
    {
      type: "headed",
      heading: "Appeal to the Appellate Tribunal",
      text: "",
      hasList: true,
      list: [
        "Escalation: If dissatisfied with the decision of the First Appellate Authority, the taxpayer can escalate the matter to the Appellate Tribunal.",
        "Timeline: This appeal must be filed within three months from the date of the order of the First Appellate Authority.",
        "Additional Pre-Deposit: An additional pre-deposit of 20% of the remaining disputed amount is required.",
      ],
    },
    {
      type: "headed",
      heading: "Further Appeals",
      text: "",
      hasList: true,
      list: [
        "Higher Forums: Appeals against the decisions of the Appellate Tribunal can be made to the High Court and eventually the Supreme Court.",
        "Legal Expertise: These appeals typically involve substantial questions of law and require comprehensive legal expertise.",
      ],
    },
  ],
  penalties: [
    {
      type: "headed",
      heading: "Late Filing Penalties",
      text: "",
      hasList: true,
      list: [
        "Penalty: ₹100 per day per Act (CGST and SGST) up to a maximum of ₹5,000 for delayed filing of GST returns",
      ],
    },
    {
      type: "headed",
      heading: "Interest on Delayed Payment",
      text: "",
      hasList: true,
      list: ["Interest Rate: 18% per annum charged on delayed payment of GST."],
    },
    {
      type: "headed",
      heading: "Penalty for Tax Evasion",
      text: "",
      hasList: true,
      list: [
        "Tax Evasion: A penalty equivalent to 100% of the tax amount due or ₹10,000, whichever is higher, may be imposed.",
      ],
    },
    {
      type: "headed",
      heading: "Other Specific Penalties",
      text: "",
      hasList: true,
      list: [
        "Incorrect Invoices: Penalties for issuance of incorrect invoices.",
        "Record Maintenance: Penalties for not maintaining proper records.",
        "Obstruction: Penalties for obstructing the duties of a GST officer.",
      ],
    },
  ],
  ourServices: [
    {
      type: "headed",
      heading: "Initial Consultation and Assessment",
      text: "",
      hasList: true,
      list: [
        "Case Review: Detailed review of your case to assess the potential for a successful appeal.",
        "Strategic Planning: Strategic advice on the best course of action.",
      ],
    },
    {
      type: "headed",
      heading: "Preparation and Filing of Appeals",
      text: "",
      hasList: true,
      list: [
        "Drafting Appeals: Drafting and filing appeals at all levels, including the First Appellate Authority and the Appellate Tribunal.",
        "Documentation: Ensuring all documentation is complete and submitted within prescribed timelines.",
      ],
    },
    {
      type: "headed",
      heading: " Representation and Advocacy",
      text: "",
      hasList: true,
      list: [
        "Representation: Representing clients before tax authorities and appellate forums.",
        "Advocacy: Providing robust advocacy to defend the taxpayer's position",
      ],
    },
    {
      type: "headed",
      heading: "Compliance and Advisory Services",
      text: "",
      hasList: true,
      list: [
        "Ongoing Advisory: Ongoing advisory services to ensure continued compliance with GST regulations.",
        "Updates: Regular updates on changes in GST laws and their implications for your business",
      ],
    },
  ],
};
export const showCauseLines = {
  importance: [
    {
      type: "headed",
      heading: "Legal Obligation",
      text: "",
      hasList: true,
      list: [
        "Responding to an SCN is a legal requirement. Failure to respond can lead to adverse actions, including penalties, interest, and even prosecution.",
      ],
    },
    {
      type: "headed",
      heading: "Avoid Penalties",
      text: "",
      hasList: true,
      list: [
        "Timely and accurate responses can help mitigate or avoid hefty penalties and interest charges that may be levied for non-compliance or discrepancies.",
      ],
    },
    {
      type: "headed",
      heading: "Protecting Business Interests",
      text: "",
      hasList: true,
      list: [
        "Proper handling of SCNs helps protect your business interests, maintain your reputation, and ensure smooth operations without interruptions from legal actions.",
      ],
    },
    {
      type: "headed",
      heading: "Ensuring Compliance",
      text: "",
      hasList: true,
      list: [
        "Responding to SCNs ensures that your business remains compliant with GST laws and regulations, reducing the risk of future issues.",
      ],
    },
  ],
  ourServices: [
    {
      type: "headed",
      heading: "Initial Consultation and Assessment",
      text: "",
      hasList: true,
      list: [
        "We begin by thoroughly reviewing the SCN and assessing the potential implications for your business. This includes understanding the nature of the issue, the evidence required, and the legal provisions involved.",
      ],
    },
    {
      type: "headed",
      heading: " Documentation and Preparation",
      text: "",
      hasList: true,
      list: [
        "Our team assists in gathering all necessary documents and evidence required to support your response. We ensure that all information is accurate and complete.",
      ],
    },
    {
      type: "headed",
      heading: "Drafting the Response",
      text: "",
      hasList: true,
      list: [
        "We draft a comprehensive and legally sound response to the SCN, addressing each point raised by the authorities and providing the necessary explanations and evidence.",
      ],
    },
    {
      type: "headed",
      heading: " Filing and Submission",
      text: "",
      hasList: true,
      list: [
        "We handle the filing and submission of the response to the appropriate tax authorities, ensuring that all procedural requirements are met and deadlines are adhered to.",
      ],
    },
    {
      type: "headed",
      heading: "Representation and Follow-Up",
      text: "",
      hasList: true,
      list: [
        "Our team represents your case before the tax authorities and follows up to ensure that the issue is resolved promptly and favourably.",
      ],
    },
  ],
  penalties: [
    {
      type: "headed",
      heading: "Late Response Penalty",
      text: "",
      hasList: true,
      list: [
        "Penalty: Delays in responding to an SCN can attract penalties as specified by the GST authorities.",
      ],
    },
    {
      type: "headed",
      heading: "Interest on Outstanding Tax",
      text: "",
      hasList: true,
      list: [
        "Interest: Interest at the rate of 18% per annum is charged on the outstanding tax liability if the SCN relates to tax dues.",
      ],
    },
    {
      type: "headed",
      heading: "Penalty for Non-Compliance",
      text: "",
      hasList: true,
      list: [
        "General Penalty: Under Section 125 of the CGST Act, a general penalty of up to ₹25,000 can be imposed for any contravention of GST provisions.",
      ],
    },
  ],
};
export const gstAnnualReturnsLines = {
  keyComponents: [
    {
      type: "headed",
      heading: "Total Turnover",
      text: "",
      hasList: true,
      list: [
        "The total value of sales made during the year, including both taxable and exempt supplies",
      ],
    },
    {
      type: "headed",
      heading: "Tax Paid",
      text: "",
      hasList: true,
      list: [
        "Details of the GST paid, including Central GST (CGST), State GST (SGST), Integrated GST (IGST), and Cess.",
      ],
    },
    {
      type: "headed",
      heading: "Input Tax Credit (ITC)",
      text: "",
      hasList: true,
      list: [
        "Information on the amount of ITC claimed and utilized, as well as any reversals.",
      ],
    },
    {
      type: "headed",
      heading: "Supplies and Taxes Declared",
      text: "",
      hasList: true,
      list: [
        "Comprehensive data on inward and outward supplies, amendments made, and the taxes paid on them.",
        "The due date for filing the GSTR-9 is generally December 31st of the subsequent financial year, although the government may extend this deadline to accommodate various challenges businesses might face.",
      ],
    },
  ],
  importance: [
    {
      type: "headed",
      heading: " Compliance",
      text: "",
      hasList: true,
      list: [
        "Ensures that the business complies with GST laws, reducing the risk of penalties and legal issues.",
        "Helps in identifying any discrepancies between the books of accounts and the returns filed.",
      ],
    },
    {
      type: "headed",
      heading: " Reconciliation",
      text: "",
      hasList: true,
      list: [
        "Provides a consolidated view of the entire year's transactions, aiding in the reconciliation of tax data.",
        "Assists in correcting any errors or omissions made in the monthly or quarterly returns.",
      ],
    },
    {
      type: "headed",
      heading: "Transparency",
      text: "",
      hasList: true,
      list: [
        "Enhances transparency in the business’s tax affairs, building trust with stakeholders and tax authorities.",
        "Demonstrates the business’s commitment to adhering to tax regulations and maintaining accurate financial records.",
      ],
    },
    {
      type: "headed",
      heading: "Financial Analysis",
      text: "",
      hasList: true,
      list: [
        "Offers valuable insights into the business’s financial performance over the year.",
        "Helps in making informed decisions regarding tax planning and financial management.",
      ],
    },
  ],
  keyComponents2: [
    {
      type: "headed",
      heading: "Basic Information",
      text: "",
      hasList: true,
      list: [
        "GSTIN, legal name, and trade name of the taxpayer.",
        "Details of the financial year for which the return is being filed.",
      ],
    },
    {
      type: "headed",
      heading: "Details of Outward and Inward Supplies",
      text: "",
      hasList: true,
      list: [
        "Consolidates all outward supplies (sales) and inward supplies (purchases) declared in the returns during the financial year.",
        "Includes details of amendments made to the supplies and the taxes paid thereon.",
      ],
    },
    {
      type: "headed",
      heading: " Input Tax Credit (ITC)",
      text: "",
      hasList: true,
      list: [
        "Summarizes the ITC availed and utilized during the year.",
        "Provides details of ITC reversed, if any, and the reasons for the reversal",
      ],
    },
    {
      type: "headed",
      heading: "Tax Paid and Refunds Claimed",
      text: "",
      hasList: true,
      list: [
        "Details of the taxes paid, including CGST, SGST, IGST, and Cess.",
        "Information on refunds claimed and sanctioned during the financial year.",
      ],
    },
    {
      type: "headed",
      heading: " Other Information",
      text: "",
      hasList: true,
      list: [
        "Any additional information that might be relevant, such as late fees paid or demands raised.",
      ],
    },
  ],
  penalties: [
    {
      type: "headed",
      heading: " Late Fees",
      text: "",
      hasList: true,
      list: [
        "₹200 per day of delay (₹100 each for CGST and SGST) up to a maximum of 0.25% of the taxpayer’s turnover in the state or union territory.",
      ],
    },
    {
      type: "headed",
      heading: " Interest",
      text: "",
      hasList: true,
      list: [
        "Interest at the rate of 18% per annum on the outstanding tax liability.",
      ],
    },
  ],
  annualReturnServices: [
    {
      type: "headed",
      heading: "Comprehensive Review and Preparation",
      text: "",
      hasList: true,
      list: [
        "Detailed review of your financial records and monthly returns.",
        "Accurate preparation and filing of the GSTR-9 form.",
      ],
    },
    {
      type: "headed",
      heading: "Reconciliation and Corrections",
      text: "",
      hasList: true,
      list: [
        "Thorough reconciliation of data to ensure consistency and accuracy.",
        "Identification and correction of discrepancies.",
      ],
    },
    {
      type: "headed",
      heading: "Advisory and Support",
      text: "",
      hasList: true,
      list: [
        "Expert advice on GST compliance and annual return filing.",
        "Ongoing support to address any queries or issues that may arise.",
      ],
    },
    {
      type: "headed",
      heading: "Timely Filing",
      text: "",
      hasList: true,
      list: [
        "Ensuring that your GST annual return is filed well before the deadline to avoid penalties and interest.",
      ],
    },
  ],
};
export const gstRefundLines = {
  process: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Application Filing: The taxpayer files an application for GST refund through the GST portal, providing details of the refund claim, such as tax paid, input tax credit availed, and reasons for refund.",
        "Document Submission: Supporting documents, such as invoices, shipping bills, and export documents, are submitted along with the refund application to substantiate the claim.",
        "Verification and Processing: The GST authorities verify the refund application and supporting documents to ensure compliance with GST laws and regulations. If the application is found to be in order, the refund is processed.",
        "Refund Payment: Upon approval of the refund claim, the refund amount is credited to the taxpayer's bank account through the electronic credit ledger.",
      ],
    },
  ],
  timeFrame: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "The GST law mandates a time limit for processing refund claims. Generally, refunds should be processed within 60 days from the date of receipt of the refund application.",
        "In some cases of export refunds, the timeframe for processing may be longer due to additional verification requirements.",
      ],
    },
  ],
};
export const gstCancellationLines = {
  reasons: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Closure of Business: If the business ceases operations or is no longer liable to be registered under GST.",
        "Transfer of Business: When the ownership or control of the business is transferred to another entity, necessitating cancellation of the old registration.",
        "Change in Constitution: If there's a change in the constitution of the business, such as conversion from a partnership to a company or vice versa.",
        "Non-Requirement of Registration: In case the turnover of the business falls below the threshold limit, making GST registration no longer mandatory.",
      ],
    },
  ],
  steps: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Application Filing: The taxpayer needs to submit an application for cancellation of GST registration through the GST portal. The application should be filed in FORM GST REG-16.",
        "Document Submission: Along with the application, the taxpayer needs to provide supporting documents depending on the reason for cancellation. These may include:",
        "For closure of business: Proof of closure such as final GST returns, surrender of registrations under other laws, etc.",
        "For transfer of business: Documents related to the transfer, such as sale deed, partnership deed, etc.",
        "For change in constitution: Documents showing the change, like amended partnership deed, incorporation certificate, etc.",
        "Verification: The GST authorities will verify the application and documents submitted. They may request additional information or documents if necessary.",
        "Cancellation Order: Upon successful verification, the GST officer will issue an order for cancellation of GST registration in FORM GST REG-19.",
        "Final Return: The taxpayer needs to file a final return (GSTR-10), including details of stocks, inputs, and other liabilities, up to the date of cancellation.",
        "GSTIN Deactivation: Once the cancellation order is issued, the GSTIN allotted to the taxpayer will be deactivated.",
        "Cancellation Certificate: After completion of the cancellation process, the taxpayer will receive a cancellation certificate electronically.",
      ],
    },
  ],
};
export const llcComplianceLines = {
  importance: [
    {
      type: "headed",
      heading: "Annual Return (Form LLP-11)",
      text: "",
      hasList: true,
      list: [
        "Due Date: Within 60 days from the end of the financial year.",
        "Description: The annual return is a comprehensive document that contains details about the LLP’s partners and other relevant information.",
        "Penalties: Late filing can attract a penalty of ₹100 per day of default.",
      ],
    },
    {
      type: "headed",
      heading: "Statement of Account & Solvency (Form LLP-8)",
      text: "",
      hasList: true,
      list: [
        "Due Date: 30th October of every financial year.",
        "Description: The statement includes details of the LLP’s financial position, including the balance sheet and profit and loss account.",
        "Penalties: Late filing can result in a penalty of ₹100 per day of default.",
      ],
    },
    {
      type: "headed",
      heading: "Income Tax Return",
      text: "",
      hasList: true,
      list: [
        "Due Date: 31st July for LLPs not requiring audit and 30th September for LLPs requiring audit.",
        "Description: LLPs must file their income tax returns annually.",
        "Penalties: Non-filing can attract penalties ranging from ₹1,000 to ₹10,000, depending on the turnover and delay.",
      ],
    },
    {
      type: "headed",
      heading: "DIN KYC (DIR-3 KYC)",
      text: "",
      hasList: true,
      list: [
        "Due Date: 30th September of every year.",
        "Description: Designated partners of the LLP must submit their KYC details to ensure their information is up-to-date in the MCA records.",
        "Penalties: Non-filing can result in the deactivation of the DIN (Designated Partner Identification Number) and a penalty of ₹5,000 upon later filing.",
      ],
    },
  ],
};
export const flipkartEcommerceLines = {
  importance: [
    {
      type: "headed",
      heading: "Importance of Payment Reconciliation",
      text: "",
      hasList: true,
      list: [
        "Accurate Financial Record: Reconciliation helps maintain precise financial records, ensuring that all transactions are accurately recorded. This is essential for financial reporting, tax filings, and overall business transparency.",

        "Cash Flow Management: By regularly reconciling payments, businesses can track their cash flow more effectively. This helps in managing working capital, planning expenses, and making informed financial decisions.",

        "Identifying Discrepancies: Reconciliation helps identify and resolve discrepancies between the payments received and the amounts expected. This includes handling issues like chargebacks, refunds, and incorrect payments.",

        "Dispute Resolution: Reconciliation helps in identifying and resolving payment disputes with Flipkart. Whether it's a missing payment, incorrect charge, or any other issue, having accurate records facilitates quicker resolution.",

        "Compliance:Maintaining accurate financial records is essential for compliance with tax regulations and financial reporting requirements. Proper reconciliation ensures that you meet all statutory obligations.",
      ],
    },
  ],
  services: [
    {
      type: "headed",
      heading: "Our Flipkart E-Commerce Payment Reconciliation Services",
      text: "",
      hasList: true,
      list: [
        "Transaction Tracking: We track all transactions from your Flipkart account, including sales, refunds, cancellations, and chargebacks. Our system ensures that every transaction is accounted for..",

        "Payment Verification: We verify all payments received from Flipkart against the sales made. This includes checking for discrepancies in amounts, missing payments, and incorrect deductions.",

        "Detailed Reporting: Our service includes providing detailed reports that highlight reconciled transactions, pending payments, and any discrepancies. These reports help you stay on top of your finances.",

        "Dispute Management: We assist in managing and resolving disputes with Flipkart. Our team ensures that any discrepancies are promptly addressed and resolved.",

        "Data Integration: We integrate data from your Flipkart account with your accounting system, ensuring seamless updates and accurate financial records.",
      ],
    },
  ],
  benefits: [
    {
      type: "headed",
      heading: "Benefits of Using Easy Works for Payment Reconciliation",
      text: "",
      hasList: true,
      list: [
        "Expertise: Our team of experts has extensive experience in e-commerce payment reconciliation. We understand the specific challenges faced by sellers on platforms like Flipkart and provide tailored solutions.",

        "Accuracy: We use advanced tools and techniques to ensure accurate reconciliation. Our meticulous approach ensures that all transactions are accurately recorded and discrepancies are promptly identified.",

        "Time-Saving: Outsourcing payment reconciliation to us saves you valuable time. You can focus on growing your business while we handle the financial details.",

        "Cost-Effective: Our services are cost-effective, helping you save money on hiring in-house accountants or managing reconciliation manually.",

        "Peace of Mind: With Easy Works handling your payment reconciliation, you can have peace of mind knowing that your finances are in good hands. We ensure that your financial records are accurate and up-to-date.",
      ],
    },
  ],
};
export const amazonEcommerceLines = {
  importance: [
    {
      type: "headed",
      heading: "Importance of Payment Reconciliation",
      text: "",
      hasList: true,
      list: [
        "Accurate Financial Record: Reconciliation helps maintain precise financial records, ensuring that all transactions are accurately recorded. This is essential for financial reporting, tax filings, and overall business transparency.",

        "Cash Flow Management: By regularly reconciling payments, businesses can track their cash flow more effectively. This helps in managing working capital, planning expenses, and making informed financial decisions.",

        "Identifying Discrepancies: Reconciliation helps identify and resolve discrepancies between the payments received and the amounts expected. This includes handling issues like chargebacks, refunds, and incorrect payments.",

        "Fraud Detection: Regular reconciliation can help detect fraudulent activities or errors early, enabling businesses to take corrective actions promptly.",

        "Customer Relationship Management: Accurate reconciliation ensures that customer orders are processed and payments are applied correctly, leading to better customer satisfaction and trust.",
      ],
    },
  ],

  steps: [
    {
      type: "headed",
      heading: "Steps for Amazon E-commerce Payment Reconciliation",
      text: "",
      hasList: true,
      list: [
        "Download Transaction Reports: Amazon provides detailed transaction reports that include all sales, refunds, fees, and other transactions. These reports can be downloaded from the Amazon Seller Central account.",

        "Verify Sales Orders: Match each sales order in your accounting system with the transaction report from Amazon. Ensure that the quantities, prices, and product details align.",

        "Match Payments: Reconcile the payments received in your bank account with the payments recorded in Amazon’s transaction reports. This includes sales proceeds, refunds, and any other adjustments.",

        "Reconcile Fees and Deductions: Amazon deducts various fees such as referral fees, FBA fees, and advertising fees. Ensure these deductions match with the amounts in your financial records.",

        "Handle Discrepancies: Investigate and resolve any discrepancies between your records and Amazon’s reports. This may involve contacting Amazon support for clarification or adjusting your records to reflect accurate amounts.",

        "Update Financial Records: Once all transactions are reconciled, update your financial records to reflect the reconciled amounts. This ensures your books are accurate and up-to-date",
      ],
    },
  ],
  tools: [
    {
      type: "headed",
      heading: "Tools for Payment Reconciliation",
      text: "",
      hasList: true,
      list: [
        "Amazon Seller Central: Provides detailed transaction reports and payment summaries.",
        "Accounting Software: Tools like QuickBooks, Xero, or Zoho Books can integrate with Amazon to streamline reconciliation.",
        "Reconciliation Software: Specialized tools like A2X or Payability can automate the reconciliation process and provide detailed reports.",
        "Match each sales order in your accounting system with the transaction report from Amazon. Ensure that the quantities, prices, and product details align.",

        "Match Payments: Reconcile the payments received in your bank account with the payments recorded in Amazon’s transaction reports. This includes sales proceeds, refunds, and any other adjustments.",

        "Reconcile Fees and Deductions: Amazon deducts various fees such as referral fees, FBA fees, and advertising fees. Ensure these deductions match with the amounts in your financial records.",

        "Handle Discrepancies: Investigate and resolve any discrepancies between your records and Amazon’s reports. This may involve contacting Amazon support for clarification or adjusting your records to reflect accurate amounts.",

        "Update Financial Records: Once all transactions are reconciled, update your financial records to reflect the reconciled amounts. This ensures your books are accurate and up-to-date",
      ],
    },
  ],
  services: [
    {
      type: "headed",
      heading: "Our Amazon E-Commerce Payment Reconciliation Services",
      text: "",
      hasList: true,
      list: [
        "Transaction Tracking: We track all transactions from your Amazon account, including sales, refunds, cancellations, and chargebacks. Our system ensures that every transaction is accounted for.",
        "Payment Verification: We verify all payments received from Amazon against the sales made. This includes checking for discrepancies in amounts, missing payments, and incorrect deductions.",
        "Detailed Reporting: Our service includes providing detailed reports that highlight reconciled transactions, pending payments, and any discrepancies. These reports help you stay on top of your finances.",
        "Dispute Management: We assist in managing and resolving disputes with Amazon. Our team ensures that any discrepancies are promptly addressed and resolved.",
        "Data Integration: We integrate data from your Amazon account with your accounting system, ensuring seamless updates and accurate financial records.",
        "Update Financial Records: Once all transactions are reconciled, update your financial records to reflect the reconciled amounts. This ensures your books are accurate and up-to-date",
      ],
    },
  ],
  benefits: [
    {
      type: "headed",
      heading: "Benefits of Using Easy Works for Payment Reconciliation",
      text: "",
      hasList: true,
      list: [
        "Expertise: Our team of experts has extensive experience in e-commerce payment reconciliation. We understand the specific challenges faced by sellers on platforms like Amazon and provide tailored solutions.",

        "Accuracy: We use advanced tools and techniques to ensure accurate reconciliation. Our meticulous approach ensures that all transactions are accurately recorded and discrepancies are promptly identified.",

        "Time-Saving: Outsourcing payment reconciliation to us saves you valuable time. You can focus on growing your business while we handle the financial details.",
        "Cost-Effective :Our services are cost-effective, helping you save money on hiring in-house accountants or managing reconciliation manually.",

        "Peace of Mind: With Easy Works handling your payment reconciliation, you can have peace of mind knowing that your finances are in good hands. We ensure that your financial records are accurate and up-to-date.",

        "Update Financial Records: Once all transactions are reconciled, update your financial records to reflect the reconciled amounts. This ensures your books are accurate and up-to-date",
      ],
    },
  ],
};
export const gstReturnsLines = {
  types: [
    {
      type: "headed",
      heading: "GSTR -1 ",
      text: "",
      hasList: true,
      list: [
        "GSTR-1 is filed by taxpayers to report outward supplies, including B2B and B2C transactions, exports, credit/debit notes and HSN/SAC wise summary. It’s filed monthly by regular taxpayers or quarterly for those under the QRMP scheme.",
      ],
    },
    {
      type: "headed",
      heading: "GSTR- 3B ",
      text: "",
      hasList: true,
      list: [
        "GSTR-3B is a summary return filed monthly by registered taxpayers to declare summary details of their outward and inward supplies, along with the input tax credit (ITC) claimed and tax liability payable for the tax period.",
      ],
    },
    {
      type: "headed",
      heading: "GSTR-9",
      text: "",
      hasList: true,
      list: [
        "GSTR -9 is an annual return filed by registered taxpayers under GST, providing a consolidated summary of all inward and outward supplies, tax paid, and input tax credit claimed during the financial year. It helps ensure compliance with GST regulations and facilitates reconciliation of tax-related transactions.",
      ],
    },
    {
      type: "headed",
      heading: "GSTR -9C ",
      text: "",
      hasList: true,
      list: [
        "GSTR-9C is an annual reconciliation statement filed by taxpayers with a turnover exceeding ₹2 crores. It's accompanied by audited financial statements and reconciles the figures reported in GSTR-9 with audited financials. This ensures accuracy and compliance with GST regulations.",
      ],
    },
  ],
  whoNeeds: [
    {
      type: "non-headed",
      heading: "",
      text: "Various entities registered under the Goods and Services Tax (GST) regime are required to file GST returns such as-",
      hasList: true,
      list: [
        "Regular Taxpayer ",
        "E-commerce operators",
        "Non- Resident Taxable persons ",
        "Casual Taxable persons ",
        "Composition scheme Dealers",
      ],
    },
  ],
};
export const gstApobLines = {
  importance: [
    {
      type: "headed",
      heading: "Compliance",
      text: "",
      hasList: true,
      list: [
        "Registering all business locations under GST ensures that you comply with GST laws and avoid penalties for non-compliance. This is essential for maintaining a clean record with tax authorities.",
      ],
    },
    {
      type: "headed",
      heading: "Seamless Operations",
      text: "",
      hasList: true,
      list: [
        "Having all business locations registered facilitates smooth operations, including invoicing, claiming input tax credits, and managing logistics.",
      ],
    },
    {
      type: "headed",
      heading: " Legal Protection",
      text: "",
      hasList: true,
      list: [
        "Proper registration of APOBs provides legal protection and ensures that your business operations are recognized by law.",
      ],
    },
    {
      type: "headed",
      heading: "Input Tax Credit (ITC)",
      text: "",
      hasList: true,
      list: [
        "Registering additional locations allows you to claim input tax credits on purchases and expenses incurred at those locations, optimizing your tax liabilities.",
      ],
    },
  ],
  services: [
    {
      type: "headed",
      heading: "Initial Consultation and Assessment",
      text: "",
      hasList: true,
      list: [
        "We begin by understanding your business structure and identifying all additional places of business that need to be registered under GST.",
      ],
    },
    {
      type: "headed",
      heading: "Documentation and Preparation",
      text: "",
      hasList: true,
      list: [
        "Our team assists in gathering all necessary documents required for the registration process, ensuring accuracy and completeness.",
      ],
    },
    {
      type: "headed",
      heading: "Filing and Submission",
      text: "",
      hasList: true,
      list: [
        "We handle the filing of the GST APOB registration application, ensuring that all details are correctly filled and submitted within the prescribed timelines.",
      ],
    },
    {
      type: "headed",
      heading: "Follow-Up and Approval",
      text: "",
      hasList: true,
      list: [
        "We continuously follow up with the GST authorities to track the progress of your application and ensure timely approval.",
      ],
    },
    {
      type: "headed",
      heading: "Post-Registration Support",
      text: "",
      hasList: true,
      list: [
        "After successful registration, we provide ongoing support to address any queries or issues related to your APOB registration.",
      ],
    },
  ],
  penalties: [
    {
      type: "headed",
      heading: "Penalty for Incorrect Information",
      text: "",
      hasList: true,
      list: [
        "Penalty: Providing incorrect information during registration can attract a penalty of up to ₹25,000 under Section 125 of the CGST Act.",
      ],
    },
  ],
};
export const gstRegistrationLines = {
  limit: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "The threshold limit is Rs. 40 lacs or higher for the manufacturing sector.",
        "The service sector has a threshold of Rs. 20 lacs or higher.",
        "The threshold limit is Rs. 10 lacs or more for particular category states.",
      ],
    },
  ],
  whoRegister: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Individuals and Business Entities: Those involved in taxable supplies of goods or services are required to register for GST.",
        "Turnover Threshold: Businesses with an annual turnover exceeding the prescribed limit.",
        "E-commerce Operators: Those who sell goods and services on E-commerce Platforms such as Amazon and Flipkart.",
        "Non-Resident Taxable Persons: Individuals or entities not residing in India but supplying goods or services in the country are required to register for GST registration. Their registration is necessary to comply with Indian tax laws regarding their transactions in the country.",
        "Casual Taxable Persons: Those supplying goods or services in India are required to register for GST.",
        "Inter-state Suppliers: Businesses engaged in the inter-state supply of goods or services are mandated to register for GST.",
        "Migration from Old Tax Regime: Businesses previously registered under the old tax regime (e.g., VAT, Service Tax) and transitioning to GST must also register under the new system.",
      ],
    },
  ],
  docsRequired: [
    {
      type: "headed",
      heading: "For Individual/ Sole Proprietor",
      text: "",
      hasList: true,
      list: [
        `E-Aadhaar card downloaded from this <a href='https://myaadhaar.uidai.gov.in/genricDownloadAadhaar' target='_blank' rel='noreferer noopener'> link </a>`,
        `Email and Mobile Number of "Proprietor"`,
        `PAN Card and Photograph of the "Proprietor"`,
        `GST Certificate of "Home State" (If any)`,
        "Any Recent Amazon Customer Sale Invoice",
        "HSN Codes/ Products Sold",
        "Bank Statement Showing Address",
        "Cancelled Cheque",
      ],
    },
    {
      type: "headed",
      heading: "For Partnership Firm",
      text: "",
      hasList: true,
      list: [
        `E-Aadhaar card of "All Partners" downloaded from this <a href='https://myaadhaar.uidai.gov.in/genricDownloadAadhaar' target='_blank' rel='noreferer noopener'> link </a>`,
        `Email and Mobile Number “All Partners"`,
        "Photographs and PAN Card of All Partners",
        "PAN Card of the Firm",
        `GST Certificate of "Home State" (If any)`,
        "Partnership Deed",
        "Cancelled cheque from the firm",
        "Board Resolution for Authorised Signatory",
        "HSN Codes/ Products Sold ",
        "Bank Statement Showing Address",
      ],
    },
    {
      type: "headed",
      heading: "For Limited Liability Partnership",
      text: "",
      hasList: true,
      list: [
        `E-Aadhaar card of "All Partners" downloaded from this <a href='https://myaadhaar.uidai.gov.in/genricDownloadAadhaar' target='_blank' rel='noreferer noopener'> link </a>`,
        `Email and Mobile Number “All Partners"`,
        "Photographs and PAN Card of All Partners",
        `GST Certificate of "Home State" (If any)`,
        "Partnership Deed",
        "CIN Certificate of the Firm",
        "PAN Card of the Firm",
        "Board Resolution for Authorised Signatory",
        "HSN Codes/ Products Sold ",
        "Bank Statement Showing Address",
        "DIN number of All partners",
      ],
    },
    {
      type: "headed",
      heading: "For Private Limited Company",
      text: "",
      hasList: true,
      list: [
        `E-Aadhaar card of "All Partners" downloaded from this <a href='https://myaadhaar.uidai.gov.in/genricDownloadAadhaar' target='_blank' rel='noreferer noopener'> link </a>`,
        `Email and Mobile Number “All Directors"`,
        "Photographs and PAN Card of All Directors",
        `PAN Card of the "Company"`,
        `GST Certificate of "Home State" (If any)`,
        "Certificate of Incorporation",
        "Cancelled cheque from the firm.",
        " Board Resolution for Authorised Signatory",
        "HSN Codes/ Products Sold ",
        "Bank Statement Showing Address",
        "DIN number of all Directors",
      ],
    },
    {
      type: "headed",
      heading: "Post-Registration Requirements",
      text: "",
      hasList: true,
      list: [
        "Compliance: After obtaining GST registration, businesses need to comply with various GST provisions, including filing of GST returns, payment of taxes, maintaining records, etc.",
        "Update of Information: Any changes in the business details provided during registration, such as change in address, contact details, addition or deletion of partners/directors, etc., need to be updated on the GST portal.",
        "Cancellation: In case of closure of business or any other reason necessitating cancellation of GST registration, the taxpayer needs to apply for cancellation of registration on the GST portal.",
      ],
    },
  ],
  benefits: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "Legal Compliance: Ensures compliance with tax laws and regulations, avoiding penalties and legal consequences.",
        "Input Tax Credit (ITC): Allows businesses to claim ITC on GST paid on purchases, reducing overall tax liability.",
        "Interstate Trade: Facilitates participation in interstate trade, enhancing market reach and business opportunities.",
        "Competitive Advantage: Builds trust with customers and suppliers, enhancing business credibility and competitiveness.",
        "Government Contracts: Enables eligibility for government tenders and contracts, expanding business opportunities.",
        "Smooth Business Operations: Streamlines tax processes, reduces paperwork, and fosters efficient business operations.",
        "Improved Cash Flow: Availability of input tax credit leads to better cash flow management for businesses.",
      ],
    },
  ],
};
export const nidhiLines = {
  keyFeatures: [
    {
      type: "headed",
      heading: "Key Features:",
      text: "",
      hasList: true,
      list: [
        "Mutual Benefit Society: Formed to cultivate the habit of saving and thrift among its members.",
        "Limited Liability: Members' liability is limited to their shareholding.",
        "Separate Legal Entity: The company has its own legal identity, separate from its members.",
        "Member-Only Transactions: Accepts deposits and lends only to its members.",
      ],
    },
  ],
  essentialLicense: [
    {
      type: "headed",
      heading: " Incorporation of Nidhi Company",
      text: "",
      hasList: true,
      list: [
        "Requirement: Register the company with the Ministry of Corporate Affairs (MCA) under the Companies Act, 2013.",
        "Outcome: Obtain a Certificate of Incorporation (COI) and a unique Corporate Identity Number (CIN).",
        "Separate Legal Entity: The company has its own legal identity, separate from its members.",
        "Member-Only Transactions: Accepts deposits and lends only to its members.",
      ],
    },
    {
      type: "headed",
      heading: "Bank Account",
      text: "",
      hasList: true,
      list: [
        "Requirement: Operate a separate bank account for the company to manage finances",
        "Outcome: Obtain a Certificate of Incorporation (COI) and a unique Corporate Identity Number (CIN).",
        "Separate Legal Entity: The company has its own legal identity, separate from its members.",
        "Member-Only Transactions: Accepts deposits and lends only to its members.",
      ],
    },
    {
      type: "headed",
      heading: "GST Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory if the company's annual turnover exceeds the threshold limit set by the government.",
        "Benefits: Voluntary registration is advisable for input tax credit benefits.",
        "Penalty: Failure to register can result in a penalty of 10% of the tax amount due or ₹10,000, whichever is higher.",
        "Member-Only Transactions: Accepts deposits and lends only to its members.",
      ],
    },
    {
      type: "headed",
      heading: " Professional Tax Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Depending on the state of operation, professional tax registration may be required. This tax is levied by state governments on income earned from professional activities.",
        "Penalty: Penalties vary by state but typically include fines and interest on late payments.",
        "Penalty: Failure to register can result in a penalty of 10% of the tax amount due or ₹10,000, whichever is higher.",
        "Member-Only Transactions: Accepts deposits and lends only to its members.",
      ],
    },
    {
      type: "headed",
      heading: "Shops and Establishment License",
      text: "",
      hasList: true,
      list: [
        "Requirement: Obtain this licence from the local municipal corporation or panchayat. It regulates working conditions, hours of operation, and employment-related matters.",
        "Penalty: Operating without a license can result in fines and closure of the business by local authorities.",
        "Penalty: Failure to register can result in a penalty of 10% of the tax amount due or ₹10,000, whichever is higher.",
        "Member-Only Transactions: Accepts deposits and lends only to its members.",
      ],
    },
    {
      type: "headed",
      heading: "MSME Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Not mandatory but recommended.",
        "Benefits: Provides benefits such as easier access to credit and government support schemes.",
        "Penalty: Failure to register can result in a penalty of 10% of the tax amount due or ₹10,000, whichever is higher.",
        "Member-Only Transactions: Accepts deposits and lends only to its members.",
      ],
    },
    {
      type: "headed",
      heading: "Trade License",
      text: "",
      hasList: true,
      list: [
        "Requirement: Required from the local municipal corporation or panchayat, ensuring compliance with local laws and regulations related to business activities.",
        "Penalty: Non-compliance can lead to fines and legal action from local municipal authorities",
        "Penalty: Failure to register can result in a penalty of 10% of the tax amount due or ₹10,000, whichever is higher.",
        "Member-Only Transactions: Accepts deposits and lends only to its members.",
      ],
    },
    {
      type: "headed",
      heading: " FSSAI Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory for businesses involved in food-related activities to ensure compliance with food safety standards.",
        "Penalty: Failure to obtain FSSAI registration can result in a penalty of up to ₹5 lakhs and/or imprisonment for up to six months.",
        "Penalty: Failure to register can result in a penalty of 10% of the tax amount due or ₹10,000, whichever is higher.",
        "Member-Only Transactions: Accepts deposits and lends only to its members.",
      ],
    },
  ],
};
export const section8Lines = {
  keyFeatures: [
    {
      type: "headed",
      heading: "Key Features:",
      text: "",
      hasList: true,
      list: [
        "Nonprofit Objective: Established for the promotion of commerce, art, science, sports, education, research, social welfare, religion, charity, or other useful objectives.",
        "Limited Liability: The members' liability is limited to their shareholding.",
        "Separate Legal Entity: The company has its own legal identity, separate from its members.",
        "Perpetual Succession: The company continues to exist regardless of changes in membership.",
      ],
    },
  ],
  essentialLicense: [
    {
      type: "headed",
      heading: "Incorporation of Section 8 Company",
      text: "",
      hasList: true,
      list: [
        "Requirement: Register the company with the Ministry of Corporate Affairs (MCA) under Section 8 of the Companies Act, 2013.",
        "Outcome: Obtain a Certificate of Incorporation (COI) and a unique Corporate Identity Number (CIN).",
      ],
    },
    {
      type: "headed",
      heading: "Bank Account",
      text: "",
      hasList: true,
      list: [
        "Requirement: Operate a separate bank account for the company to manage finances.",
      ],
    },
    {
      type: "headed",
      heading: "GST Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory if the company's annual turnover exceeds the threshold limit set by the government.",
        "Benefits: Voluntary registration is advisable for input tax credit benefits.",
        "Penalty: Failure to register can result in a penalty of 10% of the tax amount due or ₹10,000, whichever is higher.",
      ],
    },
    {
      type: "headed",
      heading: "Professional Tax Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Depending on the state of operation, professional tax registration may be required. This tax is levied by state governments on income earned from professional activities.",
        "Penalty: Penalties vary by state but typically include fines and interest on late payments.",
      ],
    },
    {
      type: "headed",
      heading: "Shops and Establishment License",
      text: "",
      hasList: true,
      list: [
        "Requirement: Obtain this licence from the local municipal corporation or panchayat. It regulates working conditions, hours of operation, and employment-related matters.",
        "Penalty: Operating without a license can result in fines and closure of the business by local authorities.",
      ],
    },
    {
      type: "headed",
      heading: "MSME Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Not mandatory but recommended.",
        "Benefits: Provides benefits such as easier access to credit and government support schemes.",
      ],
    },
    {
      type: "headed",
      heading: "Trade License",
      text: "",
      hasList: true,
      list: [
        "Requirement: Required from the local municipal corporation or panchayat, ensuring compliance with local laws and regulations related to business activities.",
        "Penalty: Non-compliance can lead to fines and legal action from local municipal authorities.",
      ],
    },
    {
      type: "headed",
      heading: "FSSAI Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory for businesses involved in food-related activities to ensure compliance with food safety standards.",
        "Penalty: Failure to obtain FSSAI registration can result in a penalty of up to ₹5 lakhs and/or imprisonment for up to six months.",
      ],
    },
  ],
};
export const proTaxLines = {
  whoNeeds: [
    {
      type: "non-headed",
      heading: "",
      text: "E-Commerce Sellers for Every State Registered under GST",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Self-employed individuals such as doctors, lawyers, architects, and chartered accountants.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Employers who have employed staff members are paid salaries.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Business owners who are engaged in trades or professions.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Freelancers who are providing services to clients.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Directors of companies who are receiving a salary.",
    },
  ],
  states: [
    {
      type: "non-headed",
      heading: "",
      text: "Karnataka",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Bihar",
    },
    {
      type: "non-headed",
      heading: "",
      text: "West Bengal",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Andhra Pradesh",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Telangana",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Maharashtra",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Tamil Nadu",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Gujarat",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Assam",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Kerala",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Meghalaya",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Odisha",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Tripura",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Madhya Pradesh",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Sikkim",
    },
  ],
  docsRequired: [
    {
      type: "non-headed",
      heading: "",
      text: "Address proof of establishment",
    },
    {
      type: "non-headed",
      heading: "",
      text: "PAN of Business",
    },

    {
      type: "non-headed",
      heading: "",
      text: "KYC of Proprietor, Partner or Directors",
    },

    {
      type: "non-headed",
      heading: "",
      text: "Photograph of Proprietor, Partner or Directors",
    },

    {
      type: "non-headed",
      heading: "",
      text: "Salary details of employees (if applicable)",
    },
    {
      type: "non-headed",
      heading: "",
      text: "CIN Certificate in Case of Company or LLP.",
    },
  ],
};

export const fssaiRegLines = {
  whoNeeds:
    "Any individual or entity involved in the manufacturing, processing, packaging, storage, transportation, distribution, or sale of food products must obtain FSSAI registration. This includes food manufacturers, processors, wholesalers, retailers, distributors, exporters, importers, restaurants, caterers, food service providers, and online food businesses.",
  whoIssues:
    "The Food Safety and Standards Authority of India (FSSAI) is the regulatory body responsible for issuing FSSAI registration and licences in India. FSSAI was established under the Food Safety and Standards Act, 2006, with the mandate to regulate and oversee the safety and quality of food products across the country. FSSAI sets standards for food safety, hygiene, and quality control, monitors compliance with these standards, and issues FSSAI registration and licences to food businesses that meet the prescribed criteria. FSSAI plays a crucial role in ensuring the safety and quality of food products consumed by the public in India.",
  types: [
    {
      type: "headed",
      heading: "FSSAI Basic Registration",
      text: "Designed for small food businesses with an annual turnover of up to Rs. 12 lakh. This category suits street vendors, hawkers, small-scale manufacturers, and petty retailers.",
    },
    {
      type: "headed",
      heading: "State FSSAI License",
      text: "Applicable to food businesses with an annual turnover ranging from more than Rs. 12 lakh to Rs. 20 crore. This licence is suitable for medium-sized food enterprises operating within a single state.",
    },
    {
      type: "headed",
      heading: "Central FSSAI License",
      text: "Required for food businesses with an annual turnover exceeding Rs. 20 crore. It is mandatory for large-scale food establishments operating in multiple states or involved in import/export operations.",
    },
  ],
  docsRequired: [
    {
      type: "non-headed",
      heading: "",
      text: "Identity proof (PAN card, Aadhaar card, Voter ID, etc.)",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Address proof (Electricity bill, Rent agreement, etc.)",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Business entity proof (Certificate of incorporation, Partnership deed, etc.)",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Food safety management plan (for manufacturers)",
    },
    {
      type: "non-headed",
      heading: "",
      text: "List of food products to be manufactured or traded",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Authority letter or Nominee details",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Declaration form",
    },
  ],
  regProcess: [
    {
      type: "headed",
      heading: "Application Preparation",
      text: "Gather all necessary documents and information required for FSSAI registration.",
    },
    {
      type: "headed",
      heading: "Online Application Submission",
      text: "Visit the FSSAI website and fill out the online registration form with accurate details.",
    },
    {
      type: "headed",
      heading: "Document Verification",
      text: "The FSSAI authorities will verify the submitted documents and information.",
    },
    {
      type: "headed",
      heading: "Approval and Issuance",
      text: "Once verification is complete and all requirements are met, FSSAI registration is approved, and a unique FSSAI license number is issued.",
    },
    {
      type: "headed",
      heading: "License Renewal",
      text: "FSSAI registration/license must be renewed periodically as per the prescribed schedule.",
    },
  ],
};
export const pvtLtdLines = {
  keyFeatures: [
    {
      type: "headed",
      heading: "Limited Liability",
      text: "Shareholders' liability is limited to their shareholding.",
    },
    {
      type: "headed",
      heading: "Separate Legal Entity",
      text: "The company has its own legal identity, separate from its owners",
    },
    {
      type: "headed",
      heading: "Perpetual Succession",
      text: "The company continues to exist regardless of changes in ownership.",
    },
    {
      type: "headed",
      heading: "Ease of Fundraising:",
      text: "Ability to raise capital through equity shares, private equity, and venture capital.",
    },
  ],
  incorporationEssential: [
    {
      type: "headed",
      heading: "Incorporation of Private Limited Company",
      text: "",
      hasList: true,
      list: [
        "Requirement: Register the company with the Ministry of Corporate Affairs (MCA) under the Companies Act, 2013.",
        "Outcome: Obtain a Certificate of Incorporation (COI) and a unique Corporate Identity Number (CIN).",
        "Section: As per Section 7 of the Companies Act, 2013.",
      ],
    },
  ],
  bankEssential: [
    {
      type: "headed",
      heading: "Bank Account",
      text: "",
      hasList: true,
      list: [
        "Requirement: Operate a separate bank account for the company to manage finances.",
        "Section: Companies (Management and Administration) Rules, 2014.",
      ],
    },
  ],
  gstEssential: [
    {
      type: "headed",
      heading: "Goods and Services Tax (GST) Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory if the company's annual turnover exceeds the threshold limit set by the government.",
        "Benefits: Voluntary registration is advisable for input tax credit benefits.",
        "Penalty: Failure to register can result in a penalty of 10% of the tax amount due or ₹10,000, whichever is higher.",
        "Section: As per Section 22 of the CGST Act, 2017.",
      ],
    },
  ],
  proTaxEssential: [
    {
      type: "headed",
      heading: "Professional Tax Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Depending on the state of operation, professional tax registration may be required. This tax is levied by state governments on income earned from professional activities.",
        "Penalty: Penalties vary by state but typically include fines and interest on late payments.",
      ],
    },
  ],
  shopsEssential: [
    {
      type: "headed",
      heading: "Shops and Establishment License",
      text: "",
      hasList: true,
      list: [
        "Requirement: Obtain this licence from the local municipal corporation or panchayat. It regulates working conditions, hours of operation, and employment-related matters.",
        "Penalty: Operating without a license can result in fines and closure of the business by local authorities.",
      ],
    },
  ],
  msmeEssential: [
    {
      type: "headed",
      heading: "MSME Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Not mandatory but recommended.",
        "Benefits: Provides benefits such as easier access to credit and government support schemes.",
        "Section: MSME Development Act, 2006.",
      ],
    },
  ],
  tradeEssential: [
    {
      type: "headed",
      heading: "Trade License",
      text: "",
      hasList: true,
      list: [
        "Requirement: Required from the local municipal corporation or panchayat, ensuring compliance with local laws and regulations related to business activities.",
        "Penalty: Non-compliance can lead to fines and legal action from local municipal authorities.",
      ],
    },
  ],
  fssaiEssential: [
    {
      type: "headed",
      heading: "FSSAI Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory for businesses involved in food-related activities to ensure compliance with food safety standards.",
        "Penalty: Failure to obtain FSSAI registration can result in a penalty of up to ₹5 lakhs and/or imprisonment for up to six months.",
        "Section: Food Safety and Standards Act, 2006.",
      ],
    },
  ],
  importEssential: [
    {
      type: "headed",
      heading: "Import-Export Code (IEC)",
      text: "",
      hasList: true,
      list: [
        "Requirement: Needed for companies engaged in import or export activities. Obtained from the Directorate General of Foreign Trade (DGFT).",
        "Penalty: Operating without an IEC can result in penalties and seizure of goods by customs authorities.",
        "Section: Foreign Trade (Development and Regulation) Act, 1992.",
      ],
    },
  ],
};
export const onePersonLines = {
  keyFeatures: [
    {
      type: "headed",
      heading: "Single Ownership",
      text: "Owned and managed by one individual.",
    },
    {
      type: "headed",
      heading: "Limited Liability",
      text: "The owner's liability is limited to their shareholding.",
    },
    {
      type: "headed",
      heading: "Separate Legal Entity",
      text: "The company has its own legal identity, separate from its owner.",
    },
    {
      type: "headed",
      heading: "Perpetual Succession",
      text: "The company continues to exist regardless of changes in ownership.",
    },
  ],
  incorporationEssential: [
    {
      type: "headed",
      heading: "Incorporation of OPC",
      text: "",
      hasList: true,
      list: [
        "Requirement: Register the OPC with the Ministry of Corporate Affairs (MCA) under the Companies Act, 2013",
        "Outcome: Obtain a Certificate of Incorporation (COI) and a unique Corporate Identity Number (CIN).",
        "Section: As per Section 3 of the Companies Act, 2013.",
      ],
    },
  ],
  bankEssential: [
    {
      type: "headed",
      heading: "Bank Account",
      text: "",
      hasList: true,
      list: [
        "Requirement: Operate a separate bank account for the company to manage finances.",
        "Section: Companies (Management and Administration) Rules, 2014.",
      ],
    },
  ],
  gstEssential: [
    {
      type: "headed",
      heading: "GST Registration",
      text: "",
      hasList: true,
      list: [
        `Requirement: Mandatory if the OPC's annual turnover exceeds the threshold limit set by the government.`,
        `Benefits: Voluntary registration is advisable to avail of input tax credit benefits.`,
        `Penalty: Failure to register can result in a penalty of 10% of the tax amount due or ₹10,000, whichever is higher.`,
        `Section: As per Section 22 of the CGST Act, 2017.`,
      ],
    },
  ],
  proTaxEssential: [
    {
      type: "headed",
      heading: "Professional Tax Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Depending on the state where the OPC operates, professional tax registration may be required.",
        "Description: Professional tax is a state-level tax imposed on income earned by practising a profession, trade, or employment.",
        "Penalty: Penalties vary by state but typically include fines and interest on late payments.",
      ],
    },
  ],
  shopEssential: [
    {
      type: "headed",
      heading: "Shops and Establishment License",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory for all businesses, including OPCs, operating within a state.",
        "Description: Regulates working conditions, hours of operation, and other employment-related matters.",
        "Penalty: Operating without a license can result in fines and closure of the business by local authorities.",
      ],
    },
  ],
  msmeEssential: [
    {
      type: "headed",
      heading: "MSME Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Not mandatory but recommended.",
        "Benefits: Provides various benefits such as easier access to credit, subsidies, and government support schemes.",
        "Section: MSME Development Act, 2006.",
      ],
    },
  ],
  tradeEssential: [
    {
      type: "headed",
      heading: "Trade License",
      text: "",
      hasList: true,
      list: [
        "Requirement: Depending on the nature of the OPC's business activities, a trade licence may be required from the local municipal corporation or panchayat.",
        "Description: Ensures compliance with local laws and regulations.",
        "Penalty: Non-compliance can lead to fines and legal action from local municipal authorities.",
      ],
    },
  ],
  fssaiEssential: [
    {
      type: "headed",
      heading: "FSSAI Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Necessary if the OPC deals with food-related activities such as manufacturing, processing, packaging, storage, transportation, distribution, or sale.",
        "Description: Ensures food safety standards.",
        "Penalty: Failure to obtain FSSAI registration can result in a penalty of up to ₹5 lakhs and/or imprisonment for up to six months.",
        "Section: Food Safety and Standards Act, 2006.",
      ],
    },
  ],
  importExportEssential: [
    {
      type: "headed",
      heading: "Import-Export Code (IEC)",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory if the OPC engages in import or export activities.",
        "Description: Obtained from the Directorate General of Foreign Trade (DGFT).",
        "Penalty: Operating without an IEC can result in penalties and seizure of goods by customs authorities.",
        "Section: Foreign Trade (Development and Regulation) Act, 1992.",
      ],
    },
  ],
};
export const partnershipLines = {
  keyFeatures: [
    {
      type: "headed",
      heading: "Shared Ownership",
      text: "Owned and managed by two or more individuals.",
    },
    {
      type: "headed",
      heading: "Unlimited Liability",
      text: "Partners are personally liable for all business debts.",
    },
    {
      type: "headed",
      heading: "Minimal Compliance",
      text: "Fewer regulatory requirements compared to other business structures.",
    },
    {
      type: "headed",
      heading: "Flexible Management",
      text: "Decisions are made collectively by the partners.",
    },
  ],
  deedEssential: [
    {
      type: "headed",
      heading: "Partnership Deed",
      text: "",
      hasList: true,
      list: [
        "Requirement: A partnership deed is a crucial document that outlines the terms and conditions of the partnership, including profit-sharing ratios, capital contributions, roles and responsibilities of partners, etc.",
        "Registration: While not mandatory, it is advisable to register the partnership deed with the Registrar of Firms to establish legal proof of the partnership’s existence.",
      ],
    },
  ],
  panEssential: [
    {
      type: "headed",
      heading: "PAN & TAN",
      text: "",
      hasList: true,
      list: [
        "Requirement: Apply for Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) from the Income Tax Department.",
        "Penalty: Failure to obtain PAN can result in a penalty of ₹10,000 under Section 272B of the Income Tax Act.",
      ],
    },
  ],
  bankEssential: [
    {
      type: "headed",
      heading: "Bank Account",
      text: "",
      hasList: true,
      list: [
        "Requirement: Operate a separate bank account for the business to manage the finances.",
      ],
    },
  ],
  gstEssential: [
    {
      type: "headed",
      heading: "GST Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: If the partnership's annual turnover exceeds the threshold limit set by the government, GST registration is mandatory.",
        "Penalty: Failure to register for GST can result in a penalty of 10% of the tax amount due or ₹10,000, whichever is higher.",
      ],
    },
  ],
  proTaxEssential: [
    {
      type: "headed",
      heading: "Professional Tax Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Depending on the state where the partnership operates, professional tax registration may be required. Professional tax is a state-level tax imposed on income earned by practising a profession, trade, or employment",
        "Penalty: Penalties vary by state but typically include fines and interest on late payments.",
      ],
    },
  ],
  shopsEssentials: [
    {
      type: "headed",
      heading: "Shops and Establishment License",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory for all businesses, including partnerships, operating within a state. It regulates working conditions, hours of operation, and other employment-related matters.",
        "Penalty: Operating without a license can result in fines and closure of the business by the local authorities.",
      ],
    },
  ],
  msmeEssentials: [
    {
      type: "headed",
      heading: "MSME Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: While not mandatory, registering the partnership under the Micro, Small, and Medium Enterprises (MSME) Act can provide various benefits such as easier access to credit, subsidies, and government support schemes.",
      ],
    },
  ],
  licenseEssentials: [
    {
      type: "headed",
      heading: "Trade License",
      text: "",
      hasList: true,
      list: [
        `Requirement: Depending on the nature of the partnership's business activities, a trade licence may be required from the local municipal corporation or panchayat. It ensures compliance with local laws and regulations.`,
        "Penalty: Non-compliance can lead to fines and legal action from the local municipal authorities.",
      ],
    },
  ],
  fssaiEssentials: [
    {
      type: "headed",
      heading: "FSSAI Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: If the partnership deals with food-related activities such as manufacturing, processing, packaging, storage, transportation, distribution, or sale, obtaining an FSSAI registration or licence is necessary to ensure food safety standards.",
        "Penalty: Failure to obtain FSSAI registration can result in a penalty of up to ₹5 lakhs and/or imprisonment for up to six months.",
      ],
    },
  ],
  importEssentials: [
    {
      type: "headed",
      heading: "Import-Export Code (IEC)",
      text: "",
      hasList: true,
      list: [
        "Requirement: If the partnership engages in import or export activities, an Import-Export Code (IEC) from the Directorate General of Foreign Trade (DGFT) is required.",
        "Penalty: Operating without an IEC can result in penalties and seizure of goods by customs authorities.",
      ],
    },
  ],
};
export const proprietorshipLines = {
  keyFeatures: [
    {
      type: "headed",
      heading: "Single Ownership",
      text: "Owned and managed by one individual.",
    },
    {
      type: "headed",
      heading: "Unlimited Liability",
      text: "The owner is personally liable for all business debts.",
    },
    {
      type: "headed",
      heading: "Minimal Compliance",
      text: "Fewer regulatory requirements compared to other business structures.",
    },
    {
      type: "headed",
      heading: "Direct Control",
      text: "The proprietor has complete control over all business operations..",
    },
  ],
  gstRegEssential: [
    {
      type: "headed",
      heading: "GST Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory if your annual turnover exceeds the threshold limit set by the government.",
        "Benefits: Voluntary registration is advisable to avail of input tax credit benefits, even if your turnover is below the threshold.",
      ],
    },
  ],

  bankAccountEssential: [
    {
      type: "headed",
      heading: "Bank Account",
      text: "",
      hasList: true,
      list: [
        "Requirement: Operate a separate bank account for the business to manage the finances efficiently.",
      ],
    },
  ],
  proTaxEssential: [
    {
      type: "headed",
      heading: "Professional Tax Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: May be required depending on the state where your business operates.",
        "Description: A state-level tax imposed on income earned by practising a profession, trade, or employment.",
      ],
    },
  ],
  shopsEstablishment: [
    {
      type: "headed",
      heading: "Shops and Establishment License",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory for all businesses, including proprietorships, operating within a state.",
        "Description: Regulates working conditions, hours of operation, and other employment-related matters.",
      ],
    },
  ],
  msmeEssential: [
    {
      type: "headed",
      heading: "MSME Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Not mandatory but recommended.",
        "Benefits: Provides various benefits such as easier access to credit, subsidies, and government support schemes.",
      ],
    },
  ],
  tradeLicenseEssential: [
    {
      type: "headed",
      heading: "Trade License",
      text: "",
      hasList: true,
      list: [
        "Requirement: Depending on the nature of your business, you may need to obtain a trade licence from the local municipal corporation or panchayat..",
        "Description: Ensures that your business complies with local laws and regulations.",
      ],
    },
  ],
  fssaiLicenseEssential: [
    {
      type: "headed",
      heading: "FSSAI Registration",
      text: "",
      hasList: true,
      list: [
        "Requirement: Necessary if your proprietorship deals with food-related activities such as manufacturing, processing, packaging, storage, transportation, distribution, or sale.",
        "Description: Ensures food safety standards.",
      ],
    },
  ],
  importEssentials: [
    {
      type: "headed",
      heading: "Import-Export Code (IEC)",
      text: "",
      hasList: true,
      list: [
        "Requirement: Mandatory if your proprietorship engages in import or export activities.",
        "Description: Obtained from the Directorate General of Foreign Trade (DGFT).",
      ],
    },
  ],
};
export const gstFilingLines = {
  types: [
    {
      type: "non-headed",
      heading: "",
      text: "",
      hasList: true,
      list: [
        "GSTR-1: Filed by taxpayers to report outward supplies, including B2B and B2C transactions, exports, credit/debit notes, and HSN/SAC wise summary. It’s filed monthly by regular taxpayers or quarterly for those under the QRMP scheme.",
        "GSTR-3B: A summary return filed monthly by registered taxpayers to declare summary details of their outward and inward supplies, along with the input tax credit (ITC) claimed and tax liability payable for the tax period.",
        "GSTR-9: An annual return filed by registered taxpayers under GST, providing a consolidated summary of all inward and outward supplies, tax paid, and input tax credit claimed during the financial year. It helps ensure compliance with GST regulations and facilitates reconciliation of tax-related transactions.",
        "GSTR-9C: An annual reconciliation statement filed by taxpayers with a turnover exceeding ₹2 crores. It's accompanied by audited financial statements and reconciles the figures reported in GSTR-9 with audited financials, ensuring accuracy and compliance with GST regulations.",
      ],
    },
  ],
  whoNeeds: [
    {
      type: "headed",
      heading:
        "Various entities registered under the Goods and Services Tax (GST) regime are required to file GST returns, including:",
      text: "",
      hasList: true,
      list: [
        "Regular taxpayers",
        "E-commerce operators",
        "Non-resident taxable persons",
        "Casual taxable persons",
        "Composition scheme dealers",
      ],
    },
  ],
};
export const tradeLicenseLines = {
  whatIs:
    "A trade license is an official document issued by the Municipal Corporation of a state, granting permission to conduct a specific trade or business within a designated area or location. It ensures that businesses adhere to safety standards set by the State Municipal Corporation, safeguarding residents from potential health hazards. All businesses falling under the purview of the State Municipal Corporation Act must acquire a trade license.",
  whyTradeLicense:
    "Introduced in India four decades ago, trade licences are governed by the Municipal Corporation Acts of respective state governments. They aim to prevent the negative impact of trade or business activities on public health and maintain ethical business practices. By regulating trade activities, trade licences promote societal harmony, ensuring businesses comply with rules, guidelines, and safety measures.",
  objectives: [
    {
      type: "non-headed",
      heading: "",
      text: "Regulate and monitor business activities within a specific area or municipality.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Prevent anti-competitive practices among businesses.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Ensure compliance with corporation or government regulations.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Prevent businesses from breaching laws or causing nuisance.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Regulate the use of hazardous substances to protect people and the environment.",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Promote the use of environmentally friendly practices.",
    },
  ],
  whoIssues:
    "Trade licences are issued by the licensing department of the Municipal Corporation, covering various departments such as industries, engineering, and health. The issuance process varies from state to state, depending on the rules and regulations of local government agencies.",
  categories: [
    {
      type: "headed",
      heading: "Industries license",
      text: "Small, medium, and large-scale manufacturing factories.Shop license: Including dangerous and offensive trades, barber shops, clinics, and medical stores.",
    },
    {
      type: "headed",
      heading: "Food establishment license",
      text: "For restaurants, hotels, food stalls, and bakeries.",
    },
    {
      type: "headed",
      heading: "Shop license",
      text: "Including dangerous and offensive trades, barber shops, clinics, and medical stores.",
    },
  ],
  eligibility:
    "To apply for a trade license, applicants must be over 18 years old and have no criminal records.The business must be legally permissible.",
  howToApply: `Applicants can apply online or offline, depending on the municipal corporation's guidelines. The process generally involves filling out an application form, submitting required documents, and paying the applicable fees.`,

  docsRequired: [
    {
      type: "non-headed",
      heading: "",
      text: "Address proof of the business",
    },
    {
      type: "non-headed",
      heading: "",
      text: "ID proof of the applicant",
    },
    {
      type: "non-headed",
      heading: "",
      text: "PAN card",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Memorandum of Association (MOA) and Certificate of Incorporation (for companies)",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Certificate of registration (for partnership firms and LLPs)",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Municipal property tax receipt or lease document",
    },
    {
      type: "non-headed",
      heading: "",
      text: "Trade License Fees and Processing Time",
    },
  ],
  feesVaryText:
    "Fees vary by state and may be charged annually or based on business turnover. The processing time typically ranges from 7 to 30 days, depending on the municipality.",
  renewalCheck: `Trade licences must be renewed annually. Applicants can check the status of their application and download the license certificate from the municipal corporation's website. Renewal can also be done online by following the prescribed steps.`,
};

export const navPoppers = {
  startupCompliance: [
    "Company Registration",
    "Accounting and Bookkeeping Services",
    "E-Commerce Payment Reconciliation",
    "ROC Compliance",
    "Professional Tax Registration (PTEC or PTRC)",
    "FSSAI Registration",
    "Trade License",
    "GST Compliance",
  ],
};

export const bannerChipTexts = [
  "Proprietorship",
  "Amazon E-Commerce",
  "LLP Compliance",
  "APoB & VPoB",
  "Company Registration",
  "GST Registration",
  "Private Limited Company ",
];

export const officeLcoations = [
  "Delhi",
  "Haryana",
  "Uttar Pradesh",
  "Telangana",
  "Madhya Pradesh",
  "Punjab",
  "Rajasthan",
  "West Bengal",
  "Maharashtra",
  "Tamil Nadu",
  "Gujarat",
  "Bihar",
  "Assam",
  "...and growing more",
];

export const locationData = [
  {
    icon: userCheckOrange,
    data: "17,000+",
    label: "GST Queries Solved",
  },
  {
    icon: shieldOrange,
    data: "96%",
    label: "GST Approval Rate",
  },
  {
    icon: headphonesOrange,
    data: "Lifetime",
    label: "VPOB Support",
  },
  {
    icon: cartOrange,
    data: "15,000+",
    label: "Ecommerce Sellers",
  },
];

export const easyProcessData = [
  { label: "Free Consultation", text: "Get in Touch with our Experts." },
  { label: "Share Documents", text: "Send all your legal documents required" },
  { label: "Document Verification", text: "Our team will complete the KYC" },
  { label: "Order Complete", text: "Yes, Its Done! just 4 Simple steps" },
];

export const vpobadvantageText = [
  {
    label: "Reducing Returns by 50%",
    text: "You can store inventory in Amazon/Flipkart FCs, get orders from Prime customers, and eliminate logistics and delivery issues.",
  },
  {
    label: "28X Sales",
    text: "Get Seller Priority, Better Reach, Fast Delivery & Increase Sales by Selling Your Inventory in 28 States.",
  },
  {
    label: "28X Reach",
    text: "With Amazon/Flipkart's Algorithm, sellers with more FC are automatically preferred, resulting in same-day delivery.",
  },
  {
    label: "Access 45+ FCs",
    text: "Take advantage of major distribution centres in 28 states. Take your business to the next level!",
  },
];

export const homeBannerChipsData = [
  {
    serviceId: 2,
    planId: 10,
    label: "Proprietorship",
    link: "/contact-us",
    name: "proprietorship",
    description:
      "A proprietorship, also known as a sole proprietorship, is the simplest form of business entity where a single individual owns and operates the business. In a proprietorship, there is no legal distinction between the owner and the business entity itself. The owner is personally liable for all the debts and obligations of the business.",
    faq_Config: [
      {
        id: 1,
        heading: "What is a proprietorship?",
        text: `A proprietorship is a type of business structure where a single individual owns, manages, and controls the business. The owner is personally liable for all debts and obligations of the business.`,
      },
      {
        id: 2,
        heading: "Is there any Certificate of Incorporation required?",
        text: `No, there is no Certificate of Incorporation required for a proprietorship.`,
      },
      {
        id: 3,
        heading:
          "Am I personally liable for debts and obligations of the business?",
        text: `Yes, as the sole proprietor, you are personally liable for all debts and obligations of the business.`,
      },
      {
        id: 4,
        heading: "Can I hire employees in a proprietorship?",
        text: `Yes, a proprietorship can hire employees. However, the owner remains personally responsible for employment-related matters such as payroll taxes, benefits, and compliance with labour laws.`,
      },
      {
        id: 5,
        heading:
          "Can I convert my proprietorship into another business structure?",
        text: `Yes, it is possible to convert a proprietorship into another business structure such as a partnership or corporation.`,
      },
      {
        id: 6,
        heading: "Can I operate a proprietorship from my house?",
        text: `Yes, you can operate a proprietorship business from your house.`,
      },
      {
        id: 7,
        heading: "What are the compliances for a proprietorship?",
        text: ` <div>
              <p>
                 A proprietorship must file:

              </p>
             <ul>
              <li>GST Return</li>
              <li>Annual Return (Income Tax Return - ITR)</li>
             </div>`,
      },
      {
        id: 8,
        heading:
          "Are there any specific licences required for a proprietorship?",
        text: `Depending on the nature of the business and state regulations, a proprietorship may need to obtain licences such as GST registration, professional tax registration, shops and establishment license, and trade license.`,
      },
      {
        id: 9,
        heading: "What are the penalties for non-compliance with tax filing?",
        text: `Failure to file income tax returns, GST returns, or other required filings can result in penalties, interest on unpaid taxes, and legal consequences. For example, late filing of income tax returns can attract a penalty under Section 234F of the Income Tax Act.`,
      },
      {
        id: 10,
        heading: "How can Easy Works help with compliance?",
        text: `Easy Works offers end-to-end compliance services, including income tax return filing, GST registration and return filing, professional tax registration, and obtaining necessary licences. Our expert team ensures timely and accurate compliance, helping you avoid penalties and focus on your business growth.
For more detailed information and assistance with establishing and managing your proprietorship, contact Easy Works today. Our expert team is here to help you navigate the complexities of business compliance and ensure your success.
`,
      },
    ],
  },
  {
    label: "Amazon E-Commerce",
    link: "/contact-us",
    name: "amazonEcommerce",
    description:
      "At Easy Works, we understand the complexities involved in managing and reconciling payments for e-commerce businesses. With the increasing popularity of online marketplaces like Amazon, efficient payment reconciliation has become crucial for maintaining financial accuracy and ensuring smooth business operations. Our specialised Amazon E-commerce Payment Reconciliation services are designed to help you keep track of your transactions, identify discrepancies, and ensure that all payments are accounted for accurately.",
    faq_Config: [
      {
        id: 1,
        heading: "What is payment reconciliation?",
        text: `Payment reconciliation is the process of comparing the transactions recorded in your financial statements with the payments received from your sales channels, such as Amazon, to ensure accuracy and consistency.`,
      },
      {
        id: 2,
        heading: "Why is payment reconciliation important for Amazon sellers?",
        text: `Reconciliation is crucial for maintaining accurate financial records, managing cash flow, detecting fraud, resolving disputes, and ensuring compliance with tax regulations.`,
      },
      {
        id: 3,
        heading: "How often should I reconcile my Amazon payments?",
        text: `It is advisable to reconcile your payments regularly, preferably monthly, to ensure timely detection and resolution of discrepancies`,
      },
      {
        id: 4,
        heading: "What information do I need to provide for reconciliation?",
        text: `You need to provide access to your Amazon seller account, transaction records, bank statements, and any other relevant financial documents.`,
      },
      {
        id: 5,
        heading: " How can Easy Works help with Amazon payment reconciliation?",
        text: `Easy Works offers end-to-end payment reconciliation services, including transaction tracking, payment verification, detailed reporting, dispute management, and data integration. Our expert team ensures accurate and timely reconciliation of your Amazon payments.`,
      },
      {
        id: 6,
        heading:
          "What are the common discrepancies found during reconciliation?",
        text: `Common discrepancies include missing payments, incorrect deductions, duplicate transactions, chargebacks, and refunds not reflected in the payments received.
`,
      },
      {
        id: 7,
        heading: "How do you resolve payment disputes with Amazon?",
        text: `We identify the discrepancies, gather supporting documentation, and communicate with Amazon’s support team to resolve the issues promptly.`,
      },
      {
        id: 8,
        heading:
          "Can you integrate reconciliation data with my accounting system?",
        text: `Yes, we can integrate reconciliation data with your accounting system to ensure seamless updates and accurate financial records.`,
      },
      {
        id: 9,
        heading:
          "What are the costs associated with your reconciliation services?",
        text: `The cost of our services depends on the volume of transactions and the complexity of your business operations. Contact us for a customised quote.`,
      },
      {
        id: 10,
        heading: " How do I ensure all transactions are recorded accurately?",
        text: `Regularly download and review Amazon transaction reports, match them with your sales records, and use accounting software to keep your financial records updated.`,
      },
      {
        id: 11,
        heading: "What should I do if I find a fraudulent transaction?",
        text: `Immediately report the fraudulent transaction to Amazon support and your bank. Take necessary steps to secure your account and prevent future fraud.`,
      },
      {
        id: 12,
        heading: "How can I improve my reconciliation process?",
        text: `Use automated tools, maintain detailed records, perform regular reconciliations, and stay updated with Amazon’s policies and fee structures.`,
      },
      {
        id: 13,
        heading:
          "How do I get started with Easy Works' payment reconciliation services?",
        text: `To get started, contact us today. Our team will guide you through the process and set up your account for seamless reconciliation of your Amazon payments.
For more detailed information and to get assistance with your Amazon E-commerce Payment Reconciliation, contact Easy Works today. Our expert team is dedicated to ensuring your business remains financially accurate and compliant.
`,
      },
    ],
  },
  {
    serviceId: 4,
    planId: 24,
    label: "LLP Compliance",
    link: "/contact-us",
    name: "llpCompliance",
    description:
      "Welcome to Easy Works, your trusted partner in ensuring smooth and efficient compliance services for your company. Here, we provide a comprehensive guide on ROC (Registrar of Companies) compliance for Limited Liability Partnerships (LLPs) in India. Understanding and adhering to these compliance requirements is crucial for the lawful and seamless operation of your business.",

    faq_Config: [
      {
        id: 1,
        heading: " What is ROC compliance for LLPs?",
        text: `ROC compliance for LLPs refers to the set of rules and regulations prescribed by the Registrar of Companies that all Limited Liability Partnerships must adhere to, as per the LLP Act, 2008.
`,
      },
      {
        id: 2,
        heading: "Why is ROC compliance important for LLPs?",
        text: `It ensures that the LLP operates legally and transparently, avoiding legal penalties and maintaining its credibility.`,
      },
      {
        id: 3,
        heading: "What happens if an LLP fails to comply with ROC regulations?",
        text: `Non-compliance can result in hefty fines, legal actions, and potential disqualification of the designated partners.`,
      },
      {
        id: 4,
        heading:
          "What is the due date for filing the Annual Return (Form LLP-11)?",
        text: `The annual return must be filed within 60 days from the end of the financial year.`,
      },
      {
        id: 5,
        heading:
          "What forms need to be filed annually with the ROC for an LLP?",
        text: `Key forms include LLP-11 (Annual Return) and LLP-8 (Statement of Account & Solvency).`,
      },
      {
        id: 6,
        heading: "What is Form LLP-11?",
        text: `Form LLP-11 is the annual return that contains comprehensive details about the LLP’s partners and other relevant information.`,
      },
      {
        id: 7,
        heading: "When is Form LLP-8 due?",
        text: `Form LLP-8 is due on 30th October of every financial year`,
      },
      {
        id: 8,
        heading: "What is the significance of Form LLP-8?",
        text: `Form LLP-8 includes details of the LLP’s financial position, including the balance sheet and profit and loss account, and must be filed with the ROC.`,
      },
      {
        id: 9,
        heading: "When is the Income Tax Return due for LLPs?",
        text: `The due date is 31st July for LLPs not requiring audit and 30th September for LLPs requiring audit`,
      },
      {
        id: 10,
        heading: "What information is required for Form LLP-8?",
        text: `Form LLP-8 requires details about the LLP’s financial position, including the balance sheet and profit and loss account.`,
      },
      {
        id: 11,
        heading: " Who is required to submit Form DIR-3 KYC?",
        text: `All designated partners of an LLP must submit their KYC details using Form DIR-3 KYC.`,
      },
      {
        id: 12,
        heading: "What are the penalties for late filing of Form LLP-8?",
        text: `Late filing can result in a penalty of ₹100 per day of default.`,
      },
      {
        id: 13,
        heading:
          "What are the penalties for non-filing of the Income Tax Return?",
        text: `Non-filing can attract penalties ranging from ₹1,000 to ₹10,000, depending on the turnover and delay.
For more detailed information and assistance with ROC compliance for your LLP, contact Easy Works today. Our expert team is here to help you navigate the complexities of compliance and ensure your LLP’s success.
`,
      },
      {
        id: 14,
        heading: "Why Choose Easy Works?",
        text: `Easy Works offers expert guidance, document preparation, digital signatures, and certification by professionals to ensure timely and accurate LLP filings. Our services minimize the risk of non-compliance, allowing you to focus on your business.
Get started with Easy Works today to streamline your LLP compliance and avoid penalties. Visit our website for more information and assistance.
`,
      },
      {
        id: 15,
        heading: "What are LLP Annual Filings?",
        text: `LLP Annual Filings are mandatory reports that Limited Liability Partnerships must submit annually to regulatory authorities to maintain compliance and legal status.`,
      },
      {
        id: 16,
        heading: "Why are LLP Annual Filings essential?",
        text: `These filings are vital for maintaining the legal status of an LLP, ensuring transparency, financial accountability, and enhancing credibility.`,
      },
      {
        id: 17,
        heading: "What are the penalties for non-compliance by LLPs?",
        text: `Non-compliance can lead to penalties up to INR 5 lakhs, making it crucial for LLPs to fulfill their annual filing obligations.`,
      },
      {
        id: 18,
        heading: "What are the primary compliance requirements for LLPs?",
        text: `<div>
            <p>Key compliance requirements include:</p>
            <ul>
            <li>Maintaining proper books of accounts</li>
            <li>Filing annual returns (Form 11)</li>
            <li>Filing statements of accounts (Form 8)</li>
            <li>Filing Income Tax Return (ITR-5)</li>
          
            </ul>
            </div>`,
      },
      {
        id: 19,
        heading:
          "What is the deadline for filing Form 11 (Annual Return) by LLPs?",
        text: `Form 11 must be filed within 60 days after the financial year ends, typically by May 30th each year.`,
      },
      {
        id: 20,
        heading:
          "What are the consequences of missing the Form 11 filing deadline?",
        text: `Missing the Form 11 filing deadline results in a penalty of INR 100 per day of delay.`,
      },
      {
        id: 21,
        heading: "When must Form 8 (Statement of Accounts) be filed by LLPs?",
        text: `Form 8 must be filed within 30 days from the end of six months after the financial year ends, generally by October 30th.`,
      },
      {
        id: 22,
        heading: "Who can digitally sign Form 8 for LLPs?",
        text: `Form 8 must be digitally signed by two designated partners and certified by a company secretary, chartered accountant, or cost accountant.`,
      },
      {
        id: 23,
        heading: "What is the penalty for late filing of Form 8 by LLPs?",
        text: `Late filing of Form 8 incurs a penalty of INR 100 per day of delay.`,
      },
      {
        id: 24,
        heading: "Is a tax audit required for all LLPs?",
        text: `A tax audit is mandatory for LLPs with an annual turnover exceeding Rs. 40 lakhs or contributions over Rs. 25 lakhs, subject to specific conditions.`,
      },
      {
        id: 25,
        heading: "What are the deadlines for LLP tax audits and tax filings?",
        text: `The deadline for a tax audit for applicable LLPs is September 30th, while LLPs not requiring a tax audit must file by July 31st.
`,
      },
      {
        id: 26,
        heading:
          "Do LLPs involved in international transactions have additional filing requirements?",
        text: `Yes, LLPs engaged in international transactions must file Form 3CEB, certified by a chartered accountant, by November 30th.`,
      },
      {
        id: 27,
        heading: "Which Income Tax Return (ITR) form should LLPs use?",
        text: `LLPs must file their income tax return using Form ITR-5.`,
      },
      {
        id: 28,
        heading: "When is the due date for ITR filing by LLPs?",
        text: `The due date for ITR filing is July 31st, or September 30th if a tax audit is required.`,
      },
      {
        id: 29,
        heading: "What are the benefits of LLP Annual Filings?",
        text: `<div>
            <p>Benefits include::</p>
            <ul>
            <li>Enhanced credibility</li>
            <li>A comprehensive financial track record</li>
            <li>Penalty avoidance</li>
            <li>Simplified conversion or closure processes</li>
          
            </ul>
            </div>`,
      },
      {
        id: 30,
        heading: "Why choose Easy Works for LLP annual filings?",
        text: `Easy Works provides expert guidance, timely filings, document preparation, and certification services to streamline the annual filing process and reduce the risk of non-compliance.`,
      },
      {
        id: 31,
        heading:
          "Can Easy Works assist with other compliance services for LLPs?",
        text: `Yes, Easy Works offers a range of compliance services, including GST registration, accounting, and more, to meet all your LLP's needs.`,
      },
      {
        id: 32,
        heading:
          "What happens if an LLP fails to comply with annual filing requirements?",
        text: `Non-compliance can result in substantial penalties, loss of credibility, and potential legal issues.`,
      },
      {
        id: 33,
        heading:
          "How can I get started with LLP annual filings through Easy Works?",
        text: `Visit the Easy Works website and follow the steps outlined to begin your LLP annual filings.`,
      },
      {
        id: 34,
        heading: "How does timely filing of annual returns benefit LLPs?",
        text: `Timely filing helps maintain the LLP's good standing, avoid penalties, and ensure smooth business operations`,
      },
      {
        id: 35,
        heading: "What is the process for filing Form 11 (Annual Return)?",
        text: `Collect all necessary information about the LLP and its partners, complete Form 11, and submit it online via the Ministry of Corporate Affairs portal by May 30th.`,
      },
      {
        id: 36,
        heading:
          "What documents are needed for filing Form 8 (Statement of Accounts)?",
        text: `Required documents include a solvency statement, income and expenditure statement, and a detailed account of assets and liabilities, digitally signed and certified by designated professionals.`,
      },
      {
        id: 37,
        heading:
          "How does maintaining proper books of accounts benefit an LLP?",
        text: `Proper bookkeeping provides a clear financial picture, aids decision-making, and ensures statutory compliance, preventing legal issues and penalties.`,
      },
      {
        id: 38,
        heading:
          "What are the new threshold limits for tax audits from Assessment Year 2021-22?",
        text: `The threshold limit for tax audits has increased to Rs. 5 crore if cash receipts and payments are less than 5% of total receipts and payments.`,
      },
      {
        id: 39,
        heading: "How can Easy Works help with GST registration for LLPs?",
        text: `Easy Works offers comprehensive GST registration services, ensuring your LLP complies with all GST requirements and deadlines, facilitating smooth business operations`,
      },
    ],
  },
  {
    label: "APoB & VPoB",
    link: "/vpob-apob",
    name: "",
    description: "",
    faq_Config: [],
  },
  {
    serviceId: 4,
    planId: 25,
    label: "Company Registration",
    link: "/contact-us",
    name: "companyRegistration",
    value: "companyRegistration",
    faq_Config: [
      {
        id: 1,
        heading: "What is a company?",
        text: `A company is a legal entity formed by individuals known as shareholders or members to conduct business activities. It operates as a separate entity from its owners, offering limited liability protection to shareholders. Companies have perpetual existence, centralized management by a board of directors, and ownership represented by shares. Various types of companies exist, including private limited, public limited, one-person company (OPC), and limited liability partnership (LLP).`,
      },
      {
        id: 2,
        heading: "What are the Types of companies?",
        text: `
            <div>
              <p>
                The various types of companies:
              </p>
            <ul>
            <li>Private Limited Company </li>
            <li>Public Limited Company </li>
            <li>Limited Liability Company</li>
            <li>One Person company</li>
            </ul>
            </div>`,
      },
      {
        id: 3,
        heading: "What are the steps to register a company?",
        text: `
            <div>
              <p>
             The steps to register a company:
              </p>
            <ul>
            <li>Choose a Business Structure </li>
            <li>Decide on a Company Name and Check Availability </li>
            <li>Obtain Digital Signature Certificate (DSC)</li>
            <li>Obtain Director Identification Number (DIN)</li>
            <li>Draft Memorandum of Association (MOA) and Articles of Association (AOA)</li>
            <li>Pay Registration Fees and Stamp Duty</li>
            <li>Verification and Approval by ROC</li>
            <li>Receive Certificate of Incorporation</li>
            <li>Obtain PAN and TAN for the Company</li>
            <li>Open a Bank Account</li>
            <li>Start Business Operations</li>
            </ul>
            </div>`,
      },
      {
        id: 4,
        heading: "What is MOA and AOA?",
        text: `
            <div>
              <p>
                  <strong> MOA</strong> stands for Memorandum of Association, while AOA stands for Articles of Association. These are legal documents that define the structure, objectives, and internal rules of a company.
              </p>
              <p>
              MOA outlines the company's main objectives, scope of operations, and its relationship with shareholders and stakeholders. 
              </p>
                <p>AOA, on the other hand, lays down the rules and regulations for the internal management and conduct of the company, including the roles and responsibilities of directors, shareholders, and officers.</p>
                <p>Both MOA and AOA are essential documents required during the company registration process, and they provide a framework for the functioning and governance of the company.
                 </p>
         
            </div>`,
      },
      {
        id: 5,
        heading: "What is DSC?",
        text: `DSC stands for Digital Signature Certificate. It is a secure digital key issued by certifying authorities to verify the authenticity and integrity of electronic documents and transactions. DSCs are used to electronically sign documents and forms during various online transactions, including company registration, income tax filing, and e-filing of documents with government authorities.`,
      },
      {
        id: 6,
        heading: "What is DIN?",
        text: `DIN stands for Director Identification Number. It is a unique identification number assigned to individuals who wish to be appointed as directors of companies registered in India. DIN serves as a means to track and regulate the activities of directors and helps maintain a centralized database of directors' information. It is mandatory for individuals to obtain DIN before being appointed as directors of Indian companies`,
      },
      {
        id: 7,
        heading: "What is DPIN?",
        text: `DPIN is a unique identification number assigned to individuals who wish to become designated partners in a Limited Liability Partnership (LLP) registered in India. It serves a similar purpose to Director Identification Number (DIN) for directors of companies, helping track and regulate the activities of designated partners in LLPs. Obtaining a DPIN is mandatory for individuals before they can be appointed as designated partners of an LLP.`,
      },
    ],
    description:
      "Starting a business involves various legal formalities, and company registration is one of the primary steps. We assist entrepreneurs in choosing the right business structure (e.g., private limited company, LLP, sole proprietorship) and guide them through the entire registration process. Our services encompass drafting necessary documents, obtaining Digital Signature Certificates (DSC), filing incorporation forms with the Registrar of Companies (ROC), and obtaining the Certificate of Incorporation.",
  },
  {
    value: "gstReg",
    label: "GST Registration",
    link: "/contact-us",
    faq_Config: [
      {
        id: 1,
        heading: "What is GST registration?",
        text: `GST registration is the process by which businesses or individuals register themselves under the Goods and Services Tax (GST) regime. It is mandatory for entities involved in the supply of goods or services with an aggregate turnover above the threshold limit specified by the government.`,
      },
      {
        id: 2,
        heading: "Who needs to register for GST?",
        text: ` <ul>
                  <li>Businesses engaged in taxable supplies with turnover exceeding the threshold limit.</li>
                  <li>E-commerce operators, inter-state suppliers, and certain other categories mandated by the government.</li>
            </ul>`,
      },
      {
        id: 3,
        heading: "What is the threshold turnover for GST registration?",
        text: ` <ul>
                  <li>The threshold limit is Rs. 40 lacs or higher for the manufacturing sector.</li>
                  <li>The service sector has a threshold of Rs. 20 lacs or higher.</li>
                  <li>The threshold limit is Rs. 10 lacs or more for particular category states.</li>
            </ul>`,
      },
      {
        id: 4,
        heading:
          "Can businesses below the threshold turnover voluntarily register for GST?",
        text: "Yes, businesses below the threshold turnover can opt for voluntary registration to avail benefits like input tax credit and interstate trade participation.",
      },
      {
        id: 5,
        heading: "What documents are required for GST registration?",
        text: "PAN card, identity/address proof, business registration documents, bank account details, and digital signature (for certain entities).",
      },
      {
        id: 6,
        heading: "How long does it take to get GST registration?",
        text: "The processing time varies, but typically it takes 7 to 14 working days for GST registration.",
      },
      {
        id: 7,
        heading: "What is GSTIN, and how is it obtained?",
        text: "GSTIN is the unique 15-digit Goods and Services Tax Identification Number assigned to registered taxpayers. It is obtained upon successful GST registration.",
      },
      {
        id: 8,
        heading: "Is there a fee for GST registration?",
        text: "No, there is no fee for GST registration as of now.",
      },
      {
        id: 9,
        heading: "Do I need separate GST registration for each state?",
        text: "Yes, businesses operating in multiple states need to obtain separate GST registration for each state they operate in.",
      },
      {
        id: 10,
        heading: "Can I amend my GST registration details after registration?",
        text: "Yes, you can amend certain details like address, contact information, etc., after registration through the GST portal",
      },
      {
        id: 11,
        heading: "What happens if I don't register for GST?",
        text: "Non-registration or non-compliance with GST regulations may result in penalties and legal consequences.",
      },
    ],
    description:
      "GST registration is the process by which businesses or individuals register themselves under the Goods and Services Tax (GST) regime. It is mandatory for entities involved in the supply of goods or services with an aggregate turnover above the threshold limit specified by the government.",
  },
  {
    planId: 13,
    serviceId: 2,
    label: "Private Limited Company ",
    link: "/contact-us",
    name: "pvtLtd",
    description:
      "A Private Limited Company is a type of business structure where shares are privately held by a limited number of shareholders. It offers limited liability protection to its shareholders, separating their personal assets from the company’s liabilities. Private limited companies are governed by the Companies Act, 2013 and must adhere to various regulatory requirements set by the Ministry of Corporate Affairs (MCA).",

    faq_Config: [
      {
        id: 1,
        heading: "What is a Private Limited Company?",
        text: `A Private Limited Company (PLC) is a type of corporate entity where the liability of its members or shareholders is limited to the amount of shares held by them. It is a separate legal entity distinct from its owners and is governed by the Companies Act, 2013.`,
      },
      {
        id: 2,
        heading:
          "How many members are required to form a Private Limited Company?",
        text: `<div>
            <p>A Private Limited Company must have a minimum of two shareholders (members) and a maximum of 200 shareholders. There must be at least two directors.
            </p>
            <ul>
            <li>Section: As per Section 3(1)(b) of the Companies Act, 2013.</li>
            </ul>
            </div>`,
      },
      {
        id: 3,
        heading:
          "What is the minimum capital requirement for incorporating a Private Limited Company?",
        text: `<div>
            <p>There is no minimum capital requirement for incorporating a Private Limited Company. The company can be registered with any amount of authorized capital as decided by the promoters.
            </p>
            <ul>
            <li>Section: Companies (Incorporation) Rules, 2014.</li>
            </ul>
            </div>`,
      },
      {
        id: 4,
        heading:
          "How is the management of a Private Limited Company structured?",
        text: `<div>
            <p>The management of a Private Limited Company is structured through the board of directors, who are appointed by the shareholders. The directors are responsible for the day-to-day management and decision-making of the company.
            </p>
            <ul>
            <li>Section: As per Section 149 of the Companies Act, 2013.</li>
            </ul>
            </div>`,
      },
      {
        id: 5,
        heading: "Can a Private Limited Company issue shares to the public?",
        text: `<div>
            <p>No, a Private Limited Company cannot issue shares to the public. Shares can only be issued to its existing shareholders, promoters, or through private placements.
            </p>
            <ul>
            <li>Section: As per Section 23(2) of the Companies Act, 2013.</li>
            </ul>
            </div>`,
      },
      {
        id: 6,
        heading:
          "Can a Private Limited Company be converted into another business structure?",
        text: `<div>
            <p>Yes, a Private Limited Company can be converted into other business structures such as a public limited company, LLP, or partnership firm by following the prescribed procedures and fulfilling certain conditions specified under the Companies Act, 2013.

            </p>
            <ul>
            <li>Section: As per Section 18 of the Companies Act, 2013.</li>
            </ul>
            </div>`,
      },
      {
        id: 7,
        heading:
          "What is the process for incorporating a Private Limited Company?",
        text: `<div>
            <p>The process for incorporating a Private Limited Company involves:

            </p>
            <ul>
            <li>Obtaining Digital Signature Certificates (DSC) for the directors.</li>
            <li>Obtaining Director Identification Numbers (DIN).</li>
            <li>Registering the company name.</li>
            <li>Filing incorporation documents with the Registrar of Companies (ROC).</li>
            <li>Obtaining the Certificate of Incorporation (COI) and other necessary documents.</li>
            <li>Section: As per Section 7 of the Companies Act, 2013.</li>
            </ul>
            </div>`,
      },
      {
        id: 8,
        heading:
          "Can NRIs or foreign nationals or foreign entities register a company in India?",
        text: `<div>
            <p>Yes, NRIs, foreign nationals, and foreign entities can register a company and invest in India, subject to the Foreign Direct Investment norms set by the RBI.


            </p>
            <ul>
            <li>Section: As per the Foreign Exchange Management Act, 1999 (FEMA).</li>
            </ul>
            </div>`,
      },
      {
        id: 9,
        heading: "What are the compliances for a Private Limited Company?",
        text: `<div>
            <p>A Private Limited Company must comply with:</p>
            <ul>
            <li>Filing of GST returns.</li>
            <li>Filing of Income Tax returns.</li>
            <li>Annual filing of financial statements and annual returns with the ROC.</li>
            <li>Holding board meetings and general meetings as required.</li>
            <li>Maintaining statutory registers and records.</li>
            <li>Compliance with local laws and regulations (e.g., Shops and Establishment Act, Professional Tax, etc.).</li>
            </ul>
            </div>`,
      },
      {
        id: 10,
        heading:
          "What is the due date for filing annual returns and financial statements?",
        text: `Annual returns (Form MGT-7) must be filed within 60 days of the AGM, and financial statements (Form AOC-4) must be filed within 30 days of the AGM.`,
      },
      {
        id: 11,
        heading: "What are the penalties for non-compliance?",
        text: `Non-compliance with statutory requirements can lead to penalties, fines, and disqualification of directors. For example, failing to file annual returns and financial statements can attract significant penalties per day of default.`,
      },
      {
        id: 12,
        heading: "How can Easy Works help with compliance?",
        text: `Easy Works offers end-to-end compliance services, including annual return filing, financial statement preparation, director KYC, and event-based filings. Our expert team ensures timely and accurate compliance, helping you avoid penalties and focus on your business growth.For more detailed information and assistance with establishing and managing your Private Limited Company, contact Easy Works today. Our expert team is here to help you navigate the complexities of business compliance and ensure your success.`,
      },
    ],
  },
];

export const foursteps = [
  {
    title: "STEP 1",
    description: "Plans",
    disabled: true,
  },
  {
    title: "STEP 3",
    description: "Add Ons",
    disabled: true,
  },
  {
    title: "STEP 4",
    description: "Payment Details",
    disabled: true,
  },
];
export const threeSteps = [
  {
    title: "STEP 1",
    description: "Plans",
    disabled: true,
  },
  {
    title: "STEP 2",
    description: "Add Ons",
    disabled: true,
  },
  {
    title: "STEP 3",
    description: "Payment Details",
    disabled: true,
  },
];
