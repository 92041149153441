import React, { useRef, useState } from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { Button, Card, Form, Input, Select } from "antd";
import { useEffect } from "react";
import Lottie from "lottie-web";
import phone from "../../Assets/Lotties/call.json";
import fancyLine from "../../Assets/Icons/styled-white.svg";
import dropdown from "../../Assets/Icons/select-drpdwn.svg";
import { fancyUlTextRenderer } from "../../Utils/renderer";

const GetStarted = () => {
  const container = useRef();

  useEffect(() => {
    Lottie.loadAnimation({
      name: `animation`,
      container: container.current,
      renderer: "svg",
      loop: true,
      // autoplay: false,
      animationData: phone,
    });

    return () => {
      Lottie.destroy();
    };
  }, []);
  return (
    <div className="get-started-container">
      <div className="get-started-left">
        <Title level={1} className="touch-text  bold text-white">
          {" "}
          Get in touch with us for a{" "}
          {fancyUlTextRenderer(
            fancyLine,
            "bold",
            "Free Consultation call!",
            "#FFFFFF"
          )}
        </Title>
        <Paragraph className="text-16 text-grey-4  get-started-description">
          Get a personalised quote for all your compliance needs. From Startups
          compliance to Fortune 500 legals we cover it all.
        </Paragraph>
      </div>

      <div className=" banner-form-wrapper get-started-form-wrapper">
        <div className="banner-form">
          <Title className="consultation-form-header">
            Get your free consultation by expert
          </Title>
          <Form>
            <Form.Item
              // label=""
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Please enter company name",
                },
              ]}
            >
              <Input placeholder="Company name" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter your name",
                },
              ]}
            >
              <Input placeholder="Your name" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email id",
                },
              ]}
            >
              <Input placeholder="Email ID" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="service"
              rules={[]}
            >
              <Select
                defaultValue="vpobAndApob"
                size="large"
                suffixIcon={<img src={dropdown} width="24px" alt="ddwn" />}
                options={[
                  {
                    value: "vpobAndApob",
                    label: "VPOB & APOB",
                  },
                  {
                    value: "startupCompliance",
                    label: "Startup and Compliance",
                  },
                  {
                    value: "gstAdvisory",
                    label: "GST Advisory",
                  },
                  {
                    value: "fixedDesk",
                    label: "Fixed Desk",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                // offset: 8,
                span: 32,
              }}
              style={{ marginBottom: "0" }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", padding: "12px, 24px, 12px, 24p" }}
                className="contained-primary home-bottom-form-cta"
                size="large"
              >
                Contact Us Now
              </Button>
            </Form.Item>
          </Form>
        </div>
        {/* <img src={human} alt="human" className="human-image" /> */}
      </div>
    </div>
  );
};

export default GetStarted;
