import Title from "antd/es/typography/Title";
import React, { useEffect } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import BlogCard from "../../../../Components/BlogCard";
import { getAllBlogs } from "../../../../Redux/Slices/BlogSlice";
import arrowRight from "../../../../Assets/Images/thin-arr-rt.svg";
import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const OurBlogs = () => {
  const { blogs } = useSelector((state) => state.blogSlice);
  const temp = [...blogs];
  temp?.shift();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllBlogs({ page: 1, limit: 5 }));
  }, []);

  const isMobile = useMediaQuery({ query: "(max-width: 450px)" });

  return (
    <div className="our-blogs-container">
      <Title level={2} className="client-say-heading   text-black ">
        Our <span className="text-primary bold">Blogs</span>
      </Title>
      <Row className="blog-list-container" gutter={[32, 0]}>
        <Col xs={24} md={12}>
          {" "}
          <BlogCard {...blogs[0]} singleBlog />
        </Col>
        <Col xs={24} md={12} className="blogs-rt-container">
          {temp.map((data) => (
            <BlogCard {...data} singleBlog={isMobile} />
          ))}
        </Col>
      </Row>
      <div className="our-blogs-action-container d-flex justify-center">
        <Link to="blogs">
          <Button
            className="contained-primary fingertip-cta"
            icon={<img src={arrowRight} width="100%" alt="banner-img" />}
          >
            View all blogs
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default OurBlogs;
