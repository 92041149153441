import React, { useEffect } from "react";
import "./index.scss";
import {
  Button,
  Col,
  Divider,
  Form,
  Grid,
  Row,
  Skeleton,
  Steps,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  additionalStepRender,
  calculateTotal,
  currencyFormatter,
  grandTotalCalculator,
  planPriceBasedUponState,
  taxCalculator,
} from "../../Utils/helpers";
import {
  selectedBooking3TabRender,
  selectedBookingTabRender,
} from "../../Utils/renderer";
import {
  createRazorpayOrder,
  getAllPlans,
  openPaymentSuccessDialog,
  setSelectedPlanNew,
  setSelectedServiceNew,
  setSelectedStateNew,
  setSelectedStep,
  setSummaryPlanPrice,
} from "../../Redux/Slices/PlanSlice";
import { useLocation } from "react-router-dom";

const Booking = () => {
  const {
    allServices,
    selectedPlanNew,
    selectedStep,
    selectedStateNew,
    selectedTenure,
    summary,
    disabledContinue,
    loading,
  } = useSelector((state) => state.planSlice);
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  let retries = 0;
  console.log("state", location);
  const handleStepClick = (e) => dispatch(setSelectedStep(e));
  const subTotal = calculateTotal(summary.planPrice, summary.addons);
  const tax = taxCalculator(subTotal, selectedPlanNew?.taxPercentage);
  const grandTotal = grandTotalCalculator(subTotal, tax);

  const noPlanSelected = Object.keys(selectedPlanNew).length === 0;
  const handleSuccessRazorpayResponse = () => {
    dispatch(openPaymentSuccessDialog());
  };
  const totalSteps = additionalStepRender(selectedPlanNew).length;
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const handleFormFinish = (values) => {
    const {
      companyName,
      phone,
      line1,
      line2,
      pin,
      email,
      gst,
      name,
      city,
      state,
    } = values;

    const addonIds = summary?.addons?.map((addon) => addon.id);
    const addOnStateIds = [];

    summary?.addons?.map(
      (addon) => addon.stateId && addOnStateIds.push(addon.stateId)
    );
    const loadScript = (src) =>
      new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          console.log("razorpay loaded successfully");
          resolve(true);
        };
        script.onerror = () => {
          console.log("error in loading razorpay");
          resolve(false);
        };
        document.body.appendChild(script);
      });
    const addonAdditionalItemIds = [];
    const addonStateIds = [];
    summary?.addons?.map((addon) => {
      if (
        addon.additionalStateId &&
        !addonStateIds.includes(addon.additionalStateId)
      ) {
        addonStateIds.push(addon.additionalStateId);
      } else if (
        addon.additionalItemId &&
        !addonStateIds.includes(addon.additionalItemId)
      ) {
        addonAdditionalItemIds.push(addon.additionalItemId);
      }
    });

    const razorPayData = {
      amount: grandTotal,

      data: {
        companyName,
        gst,
        temporary: true,
        phone,
        email,
        name,
        address: {
          line1,
          line2,
          pin,
          city: city?.toUpperCase(),
          state: state?.toUpperCase(),
        },
        planId: selectedPlanNew?.id,
        addOnIds: addonIds,
        planStateId: selectedStateNew.id,
        additionalItemId: selectedTenure.id,
        addOnStateIds: addonStateIds,
        addOnAdditionalItemIds: addonAdditionalItemIds,
      },
    };
    dispatch(
      createRazorpayOrder({
        data: razorPayData,
        loadScript,
        handleSuccessRazorpayResponse,
        dispatch,
      })
    );
  };
  const handleContinue = (e) => {
    if (selectedStep < totalSteps - 1) {
      dispatch(setSelectedStep(selectedStep + 1));
      xs && window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      form.submit();
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    allServices.length === 0 &&
      !location?.state?.shouldReset &&
      dispatch(getAllPlans());
    location?.state?.shouldReset && dispatch(getAllPlans());
  }, []);

  useEffect(() => {
    // const statedPlanid = location?.state?.planId;
    console.log(" location?.state?.", location?.state);
    const stateStateId = location?.state?.stateId;
    const statePlanid = location?.state?.planId;
    const stateServiceId = location?.state?.serviceId;
    if (!loading && location?.state?.planId && allServices.length > 0) {
      const requiredService = allServices.filter(
        (service) => service.id === stateServiceId
      )[0];
      const requiredPlan = requiredService.plans.filter(
        (plan) => plan.id === statePlanid
      )[0];
      console.log("setting new", requiredService);
      dispatch(setSelectedServiceNew(requiredService));
      dispatch(setSelectedPlanNew(requiredPlan));
      dispatch(
        setSummaryPlanPrice(
          planPriceBasedUponState(
            selectedStateNew,
            requiredService,
            requiredPlan.name,
            requiredPlan.statePricing,
            requiredPlan.price,
            requiredPlan.taxPercentage,
            "isNum"
          )
        )
      );
    } else if (stateStateId && allServices.length > 0) {
      const requiredService = allServices[0];
      const requiredPlan = requiredService.plans[0];
      const requiredState = requiredPlan.states.filter(
        (state) => state.id === stateStateId
      )[0];
      dispatch(setSelectedServiceNew(requiredService));
      dispatch(setSelectedPlanNew(requiredPlan));
      dispatch(setSelectedStateNew(requiredState));
      dispatch(setSummaryPlanPrice(requiredState.price));
    }
    // ( stateId:)
  }, [
    location?.state?.planId,
    location?.state?.serviceId,
    location?.state?.stateStateId,
  ]);

  const disableChecker = () => {
    let temp = 0;
    summary.addons.map((addon) => {
      if (addon.haveMore === "hasMoreAddons") {
        if (!addon.additionalItemId) {
          temp += 1;
        }
      } else if (addon.haveMore === "hasMoreStates") {
        if (!addon.additionalStateId) {
          temp += 1;
        }
      }
    });
    if (temp === 0) return false;
    else return true;
  };
  return (
    <div className="booking-container">
      <Row className="booking-row" gutter={[16, 16]}>
        <Col xs={24} md={16}>
          <div className="booking-column">
            <Steps
              current={selectedStep}
              items={additionalStepRender(selectedPlanNew)}
              onChange={(e) => handleStepClick(e)}
              responsive={false}
            />
            <Divider />
            <div className="booking-left-body">
              {totalSteps === 3
                ? selectedBooking3TabRender(
                    selectedStep,
                    form,
                    handleFormFinish
                  )
                : selectedBookingTabRender(
                    selectedStep,
                    form,
                    handleFormFinish
                  )}
            </div>
          </div>
        </Col>
        <Col xs={24} md={8}>
          <div className="booking-column">
            {loading ? (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            ) : (
              <>
                <Typography className="text-16 bold"> Order Summary</Typography>
                {!noPlanSelected && (
                  <div>
                    <Divider />
                    <Typography className="text-14 bold"> Plan Type</Typography>
                    {selectedStateNew?.state?.name &&
                      selectedPlanNew.statePricing && (
                        <div className="summary-line-container">
                          <Typography className="font-grey-2">
                            {" "}
                            State
                          </Typography>
                          <Typography className="text-14 bold">
                            {" "}
                            {selectedStateNew?.state?.name}
                          </Typography>
                        </div>
                      )}
                    <div className="summary-line-container">
                      <Typography className="font-grey-2">
                        {" "}
                        {selectedPlanNew.name}
                      </Typography>
                      <Typography className="text-14 bold">
                        {" "}
                        ₹{currencyFormatter(summary.planPrice)}
                      </Typography>
                    </div>
                    {summary.tenure ? (
                      <div className="summary-line-container">
                        <Typography className="font-grey-2">
                          {" "}
                          {selectedPlanNew?.additionalStepTitle}
                        </Typography>
                        <Typography className="text-14 bold">
                          {" "}
                          {summary.tenure}
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                    <Divider />
                    <div className="add-on-container">
                      {summary?.addons?.length > 0 && (
                        <Typography className="text-14 bold">
                          {" "}
                          Add On’s
                        </Typography>
                      )}
                      {summary?.addons?.length > 0 &&
                        summary?.addons?.map(({ price, name }) => (
                          <div className="summary-line-container">
                            <Typography className="font-grey-2">
                              {" "}
                              {name}
                            </Typography>
                            <Typography className="text-14 bold">
                              {" "}
                              {price ? `₹${currencyFormatter(price)}` : "N/A"}
                            </Typography>
                          </div>
                        ))}
                    </div>
                    <Divider />
                    <div className="total-container">
                      <Typography className="text-14 bold"> Total</Typography>
                      <div className="summary-line-container">
                        <Typography className="font-grey-2">
                          {" "}
                          Subtotal
                        </Typography>
                        <Typography className="text-14 bold">
                          ₹{currencyFormatter(subTotal)}
                        </Typography>
                      </div>
                      <div className="summary-line-container">
                        <Typography className="font-grey-2"> Tax</Typography>
                        <Typography className="text-14 bold">
                          {console.log("tax", tax)}₹{currencyFormatter(tax)}
                        </Typography>
                      </div>
                      <Divider />
                      <div className="summary-line-container">
                        <Typography className="text-16 bold">
                          {" "}
                          Grand Total
                        </Typography>
                        <Typography className="text-14 bold">
                          ₹{currencyFormatter(grandTotal)}
                        </Typography>
                      </div>
                    </div>
                    <Button
                      type="primary"
                      style={{ width: "100%" }}
                      onClick={handleContinue}
                      disabled={disableChecker() || disabledContinue}
                    >
                      Continue
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Booking;
