import React from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { Link, useLocation } from "react-router-dom";
import TrustedBy from "../../Components/TrustedBy";
import { useEffect } from "react";
import Body2 from "./Body2";
import { customerLogos, sellerLogos } from "../../Utils/staticsData";
import FAQ from "../../Components/FAQ";
import {
  contactUsExtraRenderer,
  renderInternalTable,
  renderInternalTable2,
} from "../../Utils/renderer";
import { Button, Form, Grid, Input, Select } from "antd";
import arrowRight from "../../Assets/Images/btn-rt-arrow.svg";
import dropdown from "../../Assets/Icons/select-drpdwn.svg";
import internalTable from "../../Assets/Images/internalTable.svg";

const ContactUs = () => {
  const { state } = useLocation();
  const faq_Config = JSON.parse(state?.faq);
  const pageVariant = state?.name;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [state?.label]);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  console.log("state", state);
  return (
    <div className="contact-us-container">
      <div className="contact-banner-container">
        <div className="banner-top">
          <div className="banner-content-left">
            <div className="contact-us-banner">
              {state.label ? (
                <Title
                  level={1}
                  className="compliance-banner-middle-text bold typography-reset-margin"
                >
                  {state.label}
                </Title>
              ) : (
                <Title
                  level={1}
                  className="compliance-banner-middle-text bold typography-reset-margin"
                >
                  Contact us
                </Title>
              )}

              <Paragraph className="text-center banner-description font-300 text-16 text-black  typography-reset-margin">
                {state.description
                  ? state.description
                  : "Our accounting services cover a wide range of tasks, including bookkeeping, financial statement preparation, budgeting, payroll processing, and tax planning. We ensure that your financial records are accurate, up-to-date, and compliant with relevant accounting standards and regulations. By outsourcing your accounting needs to us, you can focus on core business activities while having peace of mind knowing that your finances are in good hands."}
              </Paragraph>
              {(state.planId || state.stateId) && (
                <Link
                  to="/booking"
                  state={{
                    serviceId: state.serviceId,
                    planId: state.planId,
                    stateId: state.stateId,
                    dontCall: true,
                  }}
                  style={{ width: "fit-content" }}
                >
                  <Button
                    className="contained-primary   finest-ofc-cta"
                    icon={
                      <img src={arrowRight} width="100%" alt="banner-img" />
                    }
                    size="sm"
                  >
                    Book Now
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div className="banner-content-right-home" />
        </div>
        {/* {!xs && (
          <Title
            level={4}
            className="banner-consultation-upper-heading-home text "
          >
            Get your <span className="text-primary">free consultation </span>by
            expert
          </Title>
        )} */}

        {/* <div className="banner-form"> */}
        <div className="desktop-form-wrapper">
          <Title level={4} className="banner-consultation-lower-heading text ">
            Get your <span className="text-primary">free consultation</span> by
            expert
          </Title>
          <Form className="banner-form">
            <Form.Item
              // label=""
              name="companyName"
              className="banner-field-form-item"
              rules={[
                {
                  required: false,
                  message: "Please enter your company name",
                },
              ]}
            >
              <Input placeholder="Company name" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="number"
              className="banner-field-form-item"
              rules={[
                {
                  pattern:
                    /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
                  message: "Please enter valid number",
                },
                {
                  required: false,
                  message: "Please enter your contact number",
                },
              ]}
            >
              <Input placeholder="Phone Number" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="name"
              rules={[
                {
                  required: false,
                  message: "Please enter your name",
                },
              ]}
              className="banner-field-form-item"
            >
              <Input placeholder="Your name" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="email"
              rules={[
                {
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Enter valid email only",
                },
                {
                  required: false,
                  message: "Please enter your email id",
                },
              ]}
              className="banner-field-form-item"
            >
              <Input placeholder="Email ID" size="large" />
            </Form.Item>
            <Form.Item
              // label=""
              name="service"
              rules={[]}
              // style={{ flex: 1, marginRight: "12px", width: "100%" }}
              className="banner-field-form-item"
            >
              <Select
                defaultValue="vpobAndApob"
                size="large"
                suffixIcon={<img src={dropdown} width="24px" alt="ddwn" />}
                options={[
                  {
                    value: "vpobAndApob",
                    label: "VPOB & APOB",
                  },
                  {
                    value: "startupCompliance",
                    label: "Startup and Compliance",
                  },
                  {
                    value: "gstAdvisory",
                    label: "GST Advisory",
                  },
                  {
                    value: "fixedDesk",
                    label: "Fixed Desk",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                // offset: 8,
                span: 32,
              }}
              // style={{ width: "100%" }}
              className="banner-cta-form-item"
            >
              <Button
                type="primary"
                htmlType="submit"
                // style={{ width: "100%" }}
                className="contained-primary  banner-form-cta"
                size="small"
                icon={<img src={arrowRight} width="100%" alt="banner-img" />}
              >
                {xs && "Continue"}
              </Button>
            </Form.Item>
          </Form>
        </div>
        {/* </div> */}
      </div>

      <div className="rest-trusted">
        {" "}
        <TrustedBy
          companyLogos={sellerLogos}
          width={xs ? ":100%" : "1100px"}
          customerLogos={customerLogos}
        />
      </div>
      <div className="contact-us-extra">
        {contactUsExtraRenderer(pageVariant)}
      </div>
      {renderInternalTable(state?.name) && (
        <div className="internal-page-table-container">
          <Title level={2} className="gst-services-heading ">
            Features
          </Title>
          <div className="internal-table-wrapper">
            <img
              src={internalTable}
              className="internal-table"
              alt="internal-table"
            />
          </div>
        </div>
      )}
      {renderInternalTable2(state?.name)}

      <div className="fullwidth">
        <FAQ faq_Config={faq_Config} />
      </div>
      <Body2 />
    </div>
  );
};

export default ContactUs;
